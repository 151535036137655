import React from 'react'

import ConfigType from '../../../types/ConfigType'

import { FIELDS, TRIALS } from '../../../constants/instruments'

const config = [] as ConfigType[]

// PARTE I
config.push({
    type: TRIALS.QUESTION,
    title: <>
            Questionário de Experiência e Proficiência Linguística - QuExPLi <br/><br/>
            PARTE I - Informações pessoais
        </>,
    questions: [
        {prompt: 'Nome completo:', type: FIELDS.INPUT},
        {prompt: 'Data de Nascimento', type: FIELDS.DATE},
        {prompt: 'Local de nascimento (Cidade, Estado, e País):', type: FIELDS.INPUT},
    ]
})


// PARTE II
config.push({
    type: TRIALS.QUESTION,
    title: 'PARTE II - Questionário acerca da aquisição de inglês',
    questions: [
        {
            prompt: 
                <>
                    Indique onde você aprendeu inglês (marque tantas opções quanto forem necessárias):
                </>, 
            type: FIELDS.MULTIPLE, options: [
                {label: 'Casa', value: 'Casa'}, 
                {label: 'Escola', value: 'Escola'}, 
                {label: 'Curso de Línguas', value: 'Curso'}, 
                {label: 'Ouvindo música', value: 'Música'},
                {label: 'Jogando jogos (online e/ou offline)', value: 'Jogando'},
                {label: 'Usando a internet em geral', value: 'Internet'},
                {label: 'Lendo leituras gerais', value: 'Lendo'},
                {label: 'Assistindo TV, séries, e/ou filmes', value: 'Assistindo'},
                {label: 'Interagindo com alguém que falava inglês', value: 'Interagindo'}, 
                {label: 'Outro (especifique abaixo)', value: 'Outro'}
        ]},
        {prompt: 'Outro lugar', type: FIELDS.INPUT},
        {prompt: <span>Informe a idade em que você <strong>começou a aprender inglês:</strong></span>, type: FIELDS.RADIO, options: [
            {label: '0-3 anos', value: '0-3'},
            {label: '4-7 anos', value: '4-7'},
            {label: '8-11 anos', value: '8-11'},
            {label: '12-15 anos', value: '12-15'},
            {label: '16-19 anos', value: '16-19'},
            {label: '20-23 anos', value: '20-23'},
            {label: '24-27 anos', value: '24-27'},
            {label: '28-31 anos', value: '28-31'}
        ]},
        {prompt: <span>Informe a idade em que você <strong>tornou-se fluente em inglês:</strong></span>, type: FIELDS.RADIO, options: [
            {label: '0-3 anos', value: '0-3'},
            {label: '4-7 anos', value: '4-7'},
            {label: '8-11 anos', value: '8-11'},
            {label: '12-15 anos', value: '12-15'},
            {label: '16-19 anos', value: '16-19'},
            {label: '20-23 anos', value: '20-23'},
            {label: '24-27 anos', value: '24-27'},
            {label: '28-31 anos', value: '28-31'}
        ]}
    ]
})


// PARTE III
config.push({
    type: TRIALS.QUESTION,
    title: 'PARTE III - Fatores de contribuição para a aprendizagem de inglês',
    questions: [
        {
            prompt: 'Em uma escala de 0 a 6 (0 = nada; 3 = razoavelmente; 6 = muito), indique o quanto cada um destes fatores contribuiu para a aprendizagem de inglês:',
            type: FIELDS.INSTRUCTIONS
        },
        {prompt: 'Interagir com a família', type: FIELDS.RANGE, options: [{label: 'nada', value: 'nada'}, {label: 'muito', value: 'muito'}]},
        {prompt:'Interagir com as/os amigas/os', type: FIELDS.RANGE, options: [{label: 'nada', value: 'nada'}, {label: 'muito', value: 'muito'}]},
        {prompt:'Ler por prazer', type: FIELDS.RANGE, options: [{label: 'nada', value: 'nada'}, {label: 'muito', value: 'muito'}]},
        {prompt:'Ler textos acadêmicos', type: FIELDS.RANGE, options: [{label: 'nada', value: 'nada'}, {label: 'muito', value: 'muito'}]},
        {prompt:'Assistir TV, séries e/ou filmes e vídeos no Youtube ou outro serviço de streaming', type: FIELDS.RANGE, options: [{label: 'nada', value: 'nada'}, {label: 'muito', value: 'muito'}]},
        {prompt:'Ouvir rádio e/ou música', type: FIELDS.RANGE, options: [{label: 'nada', value: 'nada'}, {label: 'muito', value: 'muito'}]},
        {prompt:'Usar internet', type: FIELDS.RANGE, options: [{label: 'nada', value: 'nada'}, {label: 'muito', value: 'muito'}]},
        {prompt:'Jogar jogos online e/ou videogame', type: FIELDS.RANGE, options: [{label: 'nada', value: 'nada'}, {label: 'muito', value: 'muito'}]},
        {prompt:'Fazer curso de línguas', type: FIELDS.RANGE, options: [{label: 'nada', value: 'nada'}, {label: 'muito', value: 'muito'}]},
        
        {prompt: (
            <span>
                Selecione a frequência em que você realiza as seguintes atividades <strong>em inglês</strong>. 
                Não marque nada caso não se aplique:
            </span>
        ), type: FIELDS.INSTRUCTIONS},
        {prompt: 'Fala com sua família', type: FIELDS.TOGGLEBUTTONS, options: [
            {label: '1) algumas vezes por ano', value: '1'},
            {label: '2) uma vez por mês', value: '2'},
            {label: '3) uma vez a cada duas semanas', value: '3'},
            {label: '4) uma vez por semana', value: '4'},
            {label: '5) mais de uma vez por semana', value: '5'},
            {label: '6) todos os dias', value: '6'}
        ]},
        {prompt: 'Fala com amigos(as)', type: FIELDS.TOGGLEBUTTONS, options: [
            {label: '1) algumas vezes por ano', value: '1'},
            {label: '2) uma vez por mês', value: '2'},
            {label: '3) uma vez a cada duas semanas', value: '3'},
            {label: '4) uma vez por semana', value: '4'},
            {label: '5) mais de uma vez por semana', value: '5'},
            {label: '6) todos os dias', value: '6'}
        ]},
        {prompt: 'Fala no trabalho/universidade', type: FIELDS.TOGGLEBUTTONS, options: [
            {label: '1) algumas vezes por ano', value: '1'},
            {label: '2) uma vez por mês', value: '2'},
            {label: '3) uma vez a cada duas semanas', value: '3'},
            {label: '4) uma vez por semana', value: '4'},
            {label: '5) mais de uma vez por semana', value: '5'},
            {label: '6) todos os dias', value: '6'}
        ]},
        {prompt: 'Lê/escreve no trabalho/universidade', type: FIELDS.TOGGLEBUTTONS, options: [
            {label: '1) algumas vezes por ano', value: '1'},
            {label: '2) uma vez por mês', value: '2'},
            {label: '3) uma vez a cada duas semanas', value: '3'},
            {label: '4) uma vez por semana', value: '4'},
            {label: '5) mais de uma vez por semana', value: '5'},
            {label: '6) todos os dias', value: '6'}
        ]},
        {prompt: 'Lê livros/revistas/jornais impressos', type: FIELDS.TOGGLEBUTTONS, options: [
            {label: '1) algumas vezes por ano', value: '1'},
            {label: '2) uma vez por mês', value: '2'},
            {label: '3) uma vez a cada duas semanas', value: '3'},
            {label: '4) uma vez por semana', value: '4'},
            {label: '5) mais de uma vez por semana', value: '5'},
            {label: '6) todos os dias', value: '6'}
        ]},
        {prompt: 'Lê livros/revistas/notícias online', type: FIELDS.TOGGLEBUTTONS, options: [
            {label: '1) algumas vezes por ano', value: '1'},
            {label: '2) uma vez por mês', value: '2'},
            {label: '3) uma vez a cada duas semanas', value: '3'},
            {label: '4) uma vez por semana', value: '4'},
            {label: '5) mais de uma vez por semana', value: '5'},
            {label: '6) todos os dias', value: '6'}
        ]},
        {prompt: 'Lê redes sociais', type: FIELDS.TOGGLEBUTTONS, options: [
            {label: '1) algumas vezes por ano', value: '1'},
            {label: '2) uma vez por mês', value: '2'},
            {label: '3) uma vez a cada duas semanas', value: '3'},
            {label: '4) uma vez por semana', value: '4'},
            {label: '5) mais de uma vez por semana', value: '5'},
            {label: '6) todos os dias', value: '6'}
        ]},
        {prompt: 'Escreve em geral (e-mails, mensagens, chats, redes sociais...)', type: FIELDS.TOGGLEBUTTONS, options: [
            {label: '1) algumas vezes por ano', value: '1'},
            {label: '2) uma vez por mês', value: '2'},
            {label: '3) uma vez a cada duas semanas', value: '3'},
            {label: '4) uma vez por semana', value: '4'},
            {label: '5) mais de uma vez por semana', value: '5'},
            {label: '6) todos os dias', value: '6'}
        ]},
        {prompt: 'Assiste TV, filmes, séries, Youtube e outros vídeos', type: FIELDS.TOGGLEBUTTONS, options: [
            {label: '1) algumas vezes por ano', value: '1'},
            {label: '2) uma vez por mês', value: '2'},
            {label: '3) uma vez a cada duas semanas', value: '3'},
            {label: '4) uma vez por semana', value: '4'},
            {label: '5) mais de uma vez por semana', value: '5'},
            {label: '6) todos os dias', value: '6'}
        ]},
        {prompt: 'Ouve música, podcasts e outros áudios', type: FIELDS.TOGGLEBUTTONS, options: [
            {label: '1) algumas vezes por ano', value: '1'},
            {label: '2) uma vez por mês', value: '2'},
            {label: '3) uma vez a cada duas semanas', value: '3'},
            {label: '4) uma vez por semana', value: '4'},
            {label: '5) mais de uma vez por semana', value: '5'},
            {label: '6) todos os dias', value: '6'}
        ]},
        {prompt: 'Joga videogame e/ou jogos online', type: FIELDS.TOGGLEBUTTONS, options: [
            {label: '1) algumas vezes por ano', value: '1'},
            {label: '2) uma vez por mês', value: '2'},
            {label: '3) uma vez a cada duas semanas', value: '3'},
            {label: '4) uma vez por semana', value: '4'},
            {label: '5) mais de uma vez por semana', value: '5'},
            {label: '6) todos os dias', value: '6'}
        ]}
    ]
})

// PARTE IV
config.push({
    type: TRIALS.QUESTION,
    title: 'PARTE IV - Proficiência autoavaliada',
    questions: [
        {
            prompt: 'Em uma escala de 1 a 6, indique sua proficiência em inglês:',
            type: FIELDS.INSTRUCTIONS
        },
        {prompt: 'Leitura', type: FIELDS.TOGGLEBUTTONS, options: [
            {label: '1) muito baixo', value: '1'},
            {label: '2) baixo', value: '2'},
            {label: '3) razoável', value: '3'},
            {label: '4) bom', value: '4'},
            {label: '5) muito bom', value: '5'},
            {label: '6) proficiente', value: '6'}
        ]},
        {prompt: 'Escrita', type: FIELDS.TOGGLEBUTTONS, options: [
            {label: '1) muito baixo', value: '1'},
            {label: '2) baixo', value: '2'},
            {label: '3) razoável', value: '3'},
            {label: '4) bom', value: '4'},
            {label: '5) muito bom', value: '5'},
            {label: '6) proficiente', value: '6'}
        ]},
        {prompt: 'Compreensão Auditiva', type: FIELDS.TOGGLEBUTTONS, options: [
            {label: '1) muito baixo', value: '1'},
            {label: '2) baixo', value: '2'},
            {label: '3) razoável', value: '3'},
            {label: '4) bom', value: '4'},
            {label: '5) muito bom', value: '5'},
            {label: '6) proficiente', value: '6'}
        ]},
        {prompt: 'Fala', type: FIELDS.TOGGLEBUTTONS, options: [
            {label: '1) muito baixo', value: '1'},
            {label: '2) baixo', value: '2'},
            {label: '3) razoável', value: '3'},
            {label: '4) bom', value: '4'},
            {label: '5) muito bom', value: '5'},
            {label: '6) proficiente', value: '6'}
        ]},
        
       {prompt: 'Há alguma informação que você considere importante mencionar sobre sua experiência com o inglês?', type: FIELDS.TEXTAREA}
    ]
})

export default config