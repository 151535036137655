import React, { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { FaPlus } from 'react-icons/fa'

import DataType from '../../../types/DataType'

function AudioElement(props:{
    show: boolean,
    instrument: string,
    instrumentId: string, 
    url: string,
    data: DataType[], 
    addData: (data: any) => void,
    next: () => void,
    fixationPoint?: boolean
}) {
    const [finish, setFinish] = useState(false)
    const [refreshId, setRefreshId] = useState<string>()

    useEffect(() => {
        let audio = new Audio(props.url)

        const finishListener = () => {
            setFinish(true)
        }

        if (props.show) {
            const playPromise = audio.play()
            
            audio.addEventListener('ended', finishListener)

            playPromise.then(() => {
                /*if (Math.random() > .5) {
                    throw new Error('erro falso')
                }*/
            }).catch(() => {
                console.error(`Audio file ${audio.src} can not be played`)
                setRefreshId(uuid())
            })
        }

        return () => {
            if (audio) {
                audio.remove()
                audio.srcObject = null

                if (typeof audio.removeEventListener === 'function') {
                    audio.removeEventListener('ended', finishListener)
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show, refreshId])

    useEffect(() => {
        if (finish) {
            props.addData({
                instrument: props.instrument, 
                instrumentId: props.instrumentId, 
                type: 'Audio', 
                response: props.url, 
                correct: [], 
                time: 0
            })
            props.next()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finish])

    return (
        !props.show ? null : 
        <div>{props.fixationPoint && <FaPlus/>}</div>
    )
}

export default AudioElement