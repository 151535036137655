import React, { useState, useEffect, useContext } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import { useRouteMatch, useHistory } from 'react-router-dom'

import { FirebaseContext } from '../../Firebase'
import { IconButton, ICONS, Message } from '../../Layout'

import * as ROLES from '../../../constants/roles'
import * as ROUTES from '../../../constants/routes'

function ParticipantSignUp() {
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [error, setError] = useState(null as null|firebase.auth.Error)
    const [language, setLanguage] = useState('')
    const match = useRouteMatch<{id: string}>(ROUTES.DATA_COLLECTION_SIGN_UP)
    const firebase = useContext(FirebaseContext)
    const history = useHistory()
    const isInvalid = 
        password !== passwordConfirm ||
        password === '' ||
        email === '' ||
        username === ''

    const handleSubmit = (e:React.FormEvent) => {
        e.preventDefault()

        const roles = {
            [ROLES.RESEARCHER]: ROLES.RESEARCHER,
            [ROLES.PARTICIPANT]: ROLES.PARTICIPANT
        } as ROLES.Type

        firebase?.doCreateUserWithEmailAndPassword(email, password)
            .then((authUser: any) => {
                return firebase?.user(authUser.user?.uid)
                    .set({
                        displayName: username,
                        email,
                        roles
                    })
            })
            .then(() => {
                return firebase?.doSendEmailVerification()
            })
            .then(() => {
                setUsername('')
                setEmail('')
                setPassword('')
                setPasswordConfirm('')
                setError(null)

                history.push(`/experimento/${match?.params.id}`)
            }).catch((error: firebase.auth.Error) => {
                setError(error)
            })
    }

    const handleGoogleSignIn = () => {
        firebase?.doSignInWithGoogle()
            .then((socialAuthUser: any) => {
                if (socialAuthUser.additionalUserInfo.isNewUser) {
                    return firebase?.user(socialAuthUser.user.uid)
                        .set({
                            displayName: socialAuthUser.user.displayName,
                            email: socialAuthUser.user.email,
                            roles: {[ROLES.PARTICIPANT]: ROLES.PARTICIPANT}
                        })
                } else {
                    return firebase?.user(socialAuthUser.user.uid)
                        .update({
                            displayName: socialAuthUser.user.displayName,
                            email: socialAuthUser.user.email
                        })
                }
            })
            .then(() => {
                setError(null)
                history.push(`/experimento/${match?.params.id}`)
            })
            .catch(error => {
                setError(error)
            })
    }

    useEffect(() => {
        if (password !== '' && passwordConfirm !== '' && password !== passwordConfirm) {
            setError({code: 'auth/passwordConfirmFailed', message: language === 'pt-BR' ? 'As senhas não correspondem' : 'Passwords do not match'})
        } else {
            setError(null)
        }
    }, [password, passwordConfirm, language])

    useEffect(() => {
        setLanguage(navigator.language)
    }, [])

    return (
        <Container className='my-5'>
            <Row className='my-3'>
                <Col>
                    <ButtonGroup size='sm'>
                        <Button
                            id='language-1'
                            variant={language === 'pt-BR' ? 'secondary' : 'outline-secondary'}
                            onClick={() => setLanguage('pt-BR')}>
                            Português
                        </Button>
                        <Button
                            id="language-2"
                            variant={language === 'pt-BR' ? 'outline-secondary' : 'secondary'}
                            onClick={() => setLanguage('en')}>
                            English
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col xs={{span: 10, offset: 1}} md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Text><h1>{language === 'pt-BR' ? 'Criar conta' : 'Sign up'}</h1></Form.Text>
                        <Form.Group className='my-5'>
                            <IconButton icon={ICONS.GOOGLE} onClick={handleGoogleSignIn}>
                                {language === 'pt-BR' ? 'Registre-se com sua conta Google' : 'Sign up with your Google account'}
                            </IconButton>
                        </Form.Group>
                        <Form.Text>
                            <h5>
                                {language === 'pt-BR' ? 'Ou preencha os campos abaixo:' : 'Or fill out the form below:'}
                            </h5>
                        </Form.Text>
                        <Form.Group controlId='username'>
                            <Form.Label>{language === 'pt-BR' ? 'Nome:' : 'Name:'}</Form.Label>
                            <Form.Control 
                                type='text' 
                                placeholder={language === 'pt-BR' ? 'Insira seu nome' : 'Inform your name'}
                                value={username}
                                onChange={e => setUsername(e.target.value)}/>
                        </Form.Group>
                        <Form.Group controlId='email'>
                            <Form.Label>Email</Form.Label>
                            <Form.Control 
                                type='email' 
                                placeholder={language === 'pt-BR' ? 'Informe seu email' : 'Inform your email'}
                                value={email}
                                onChange={e => setEmail(e.target.value)}/>
                        </Form.Group>
                        <Form.Group controlId='password'>
                            <Form.Label>{language === 'pt-BR' ? 'Senha' : 'Password'}</Form.Label>
                            <Form.Control 
                                type='password' 
                                placeholder={language === 'pt-BR' ? 'Informe uma senha' : 'Inform a password'}
                                value={password}
                                onChange={e => setPassword(e.target.value)}/>
                        </Form.Group>
                        <Form.Group controlId='password-confirm'>
                            <Form.Label>{language === 'pt-BR' ? 'Confirmar senha' : 'Confirm password'}</Form.Label>
                            <Form.Control 
                                type='password' 
                                placeholder={language === 'pt-BR' ? 'Confirme a senha' : 'Confirm password'}
                                value={passwordConfirm}
                                onChange={e => setPasswordConfirm(e.target.value)}/>
                        </Form.Group>
                        <IconButton icon={ICONS.NEW_USER} type='submit' disabled={isInvalid}>
                            {language === 'pt-BR' ? 'Criar conta' : 'Sign up'}
                        </IconButton>
                        <Message error={error}></Message>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default ParticipantSignUp