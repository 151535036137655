import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'

import SignOut from '../SignOut'
import { AuthUserContext } from '../Session'

import * as ROUTES from '../../constants/routes'
import * as ROLES from '../../constants/roles'

import './index.css'
import logo from './logo.svg'

function Navigation() {
    const authUser: any = useContext(AuthUserContext)
    const location = useLocation()

    return (
        <Navbar bg='light' expand='sm' className='border-bottom'>
            <Nav.Item><Link className='navbar-brand' to={ROUTES.LANDING}><span id='logo'><img src={logo} alt='Logo do Lapsi'/> lapsi</span></Link></Nav.Item>
            <Navbar.Toggle aria-controls='basic-navbar-nav'/>
            {
                authUser 
                ?
                <Navbar.Collapse id='navbar' className='w-100 justify-content-center'>
                    <>
                        <Nav className='w-100 justify-content-center'>
                            {
                                authUser?.roles[ROLES.RESEARCHER]
                                && 
                                <Nav.Item>
                                    <Link 
                                        className={`nav-link${location.pathname.startsWith(ROUTES.RESEARCHER) ? ' active' : ''}`} 
                                        to={ROUTES.RESEARCHER}>

                                        Página do Pesquisador
                                    </Link>
                                </Nav.Item>
                            }
                            {
                                authUser?.roles[ROLES.PARTICIPANT]
                                && 
                                <Nav.Item>
                                    <Link 
                                        className={`nav-link${location.pathname.startsWith(ROUTES.PARTICIPANT) ? ' active' : ''}`} 
                                        to={ROUTES.PARTICIPANT}>
                                            
                                        Página do Participante
                                    </Link>
                                </Nav.Item>
                            }
                            <Nav.Item>
                                <Link 
                                    className={`nav-link${location.pathname.startsWith(ROUTES.ACCOUNT) ? ' active' : ''}`} 
                                    to={ROUTES.ACCOUNT}>
                                        
                                    Configurações
                                </Link>
                            </Nav.Item>
                            {
                                authUser?.roles[ROLES.ADMIN]
                                && 
                                <Nav.Item>
                                    <Link 
                                        className={`nav-link${location.pathname.startsWith(ROUTES.ADMIN) ? ' active' : ''}`} 
                                        to={ROUTES.ADMIN}>
                                        
                                        Admin
                                    </Link>
                                </Nav.Item>
                            }
                        </Nav>
                        <Nav>
                            <Nav.Item><SignOut/></Nav.Item>
                        </Nav>
                    </>
                </Navbar.Collapse>
                :
                <Navbar.Collapse className='justify-content-end'>
                    <Nav>
                        <Nav.Item>
                            <Link className='nav-link link-with-button' to={ROUTES.SIGN_IN}>
                                <Button variant='primary' type='button'>
                                    Entrar
                                </Button>
                            </Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            }
        </Navbar>
    )
}

export default Navigation