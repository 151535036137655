const letters_training = [
    [
        {letters: 'YZG_______YZG', equal: true}, // 1
        {letters: 'DRKSGM_______DRKSGM', equal: true}, // 2
        {letters: 'WDYJTCSNX_______WDYJTCSNX', equal: true}, // 3
        {letters: 'SVX_______SVK', equal: false}, // 4
        {letters: 'TRYQZN_______TRFQZN', equal: false}, // 5
        {letters: 'VMJXFYZLQ_______VMJKFYZLQ', equal: false}, // 6
    ]
]

const letters = [
    [
        {letters: 'BGJWQS_______BGJWQS', equal: true}, // 1
        {letters: 'RKTDCM_______RKTDCM', equal: true}, // 2
        {letters: 'FKGZMSWPX_______FKGZMSWPX', equal: true}, // 3
        {letters: 'SJZHDC_______SJZHDC', equal: true}, // 4
        {letters: 'MYSRGTHBV_______MYSRGTHBV', equal: true}, // 5
        {letters: 'SKY_______SCY', equal: false}, // 6
        {letters: 'FJM_______FJM', equal: true}, // 7
        {letters: 'JZMCBVNPT_______JZMCBVNPT', equal: true}, // 8
        {letters: 'HQTZRYSVB_______HQTZRYDVB', equal: false}, // 9
        {letters: 'KFT_______KFP', equal: false}, // 10
        {letters: 'MLPCJQ_______MLPCXQ', equal: false}, // 11
        {letters: 'QDPVKTGHF_______QDPVKTGHY', equal: false}, // 12
        {letters: 'DNT_______DPT', equal: false}, // 13
        {letters: 'CGPWZS_______CGPWZY', equal: false}, // 14
        {letters: 'BCPJWM_______BCPYWM', equal: false}, // 15
        {letters: 'VXNMPKDQH_______VXNMPZDQH', equal: false}, // 16
        {letters: 'NGJ_______NGY', equal: false}, // 17
        {letters: 'DWR_______DWR', equal: true}, // 18
        {letters: 'XVC_______JVC', equal: false}, // 19
        {letters: 'XGRZDN_______HGRZDN', equal: false}, // 20
        {letters: 'RJCDYTLKQ_______RJCDYTLKQ', equal: true}, // 21
        {letters: 'YVFNWM_______YVFNWM', equal: true}, // 22
        {letters: 'TBFSPX_______TMFSPX', equal: false}, // 23
        {letters: 'CFRSGBWTK_______CFRMGBWTK', equal: false}, // 24
        {letters: 'KSGHFQLYW_______KSGHFQLYW', equal: true}, // 25
        {letters: 'ZHJPDMNLG_______ZHJPDMNLG', equal: true}, // 26
        {letters: 'CWMVLRQTP_______CWMVXRQTP', equal: false}, // 27
        {letters: 'QFNDSY_______QFTDSY', equal: false}, // 28
        {letters: 'CZJWPG_______CZJWPG', equal: true}, // 29
        {letters: 'MVDRXW_______MVDRKW', equal: false}, // 30
        {letters: 'XWJSMR_______XWJSMR', equal: true}, // 31
        {letters: 'RVBYSCDXF_______RVBYSCDKF', equal: false}, // 32
        {letters: 'JWGRLBYFT_______JSGRLBYFT', equal: false}, // 33
        {letters: 'ZPBHWKVFY_______ZPBHWKVFY', equal: true}, // 34
        {letters: 'NHX_______NHX', equal: true}, // 35
        {letters: 'MBW_______MBW', equal: true}, // 36
        {letters: 'TWJDNRBFH_______MWJDNRBFH', equal: false}, // 37
        {letters: 'WTL_______WTL', equal: true}, // 38
        {letters: 'LXGJDMRTN_______LXGJDMRTN', equal: true}, // 39
        {letters: 'YTKXLH_______YTKXLH', equal: true}, // 40
        {letters: 'HLR_______HLR', equal: true}, // 41
        {letters: 'YPWXLN_______YPWXLN', equal: true}, // 42
        {letters: 'YTPZFL_______YTPZFL', equal: true}, // 43
        {letters: 'LBM_______LBV', equal: false}, // 44
        {letters: 'KSN_______KSN', equal: true}, // 45
        {letters: 'GYDBCJ_______GYNBCJ', equal: false}, // 46
        {letters: 'NPQ_______NPQ', equal: true}, // 47
        {letters: 'DWJVZHBFC_______DWJVZHBFC', equal: true}, // 48
        {letters: 'WDQ_______NDQ', equal: false}, // 49
        {letters: 'ZFT_______ZBT', equal: false}, // 50
        {letters: 'FMLZRBGKH_______FMJZRBGKH', equal: false}, // 51
        {letters: 'RCT_______RCT', equal: true}, // 52
        {letters: 'TKFNQX_______MKFNQX', equal: false}, // 53
        {letters: 'QLZ_______JLZ', equal: false}, // 54
    ],
    [
        {letters: 'THKBYZ_______THKBYZ', equal: true}, // 1
        {letters: 'GCFPNX_______GCFPNX', equal: true}, // 2
        {letters: 'QDK_______QDK', equal: true}, // 3
        {letters: 'YJZTHQ_______YJZTHQ', equal: true}, // 4
        {letters: 'JHPZQXNDY_______JHPZQLNDY', equal: false}, // 5
        {letters: 'KXWSVFZGJ_______KXWSVFZGJ', equal: true}, // 6
        {letters: 'SZRVPMTJH_______SZYVPMTJH', equal: false}, // 7
        {letters: 'MXK_______GXK', equal: false}, // 8
        {letters: 'CTBWGZNRL_______CTBDGZNRL', equal: false}, // 9
        {letters: 'KSDPVF_______KSDPVF', equal: true}, // 10
        {letters: 'FVN_______FVY', equal: false}, // 11
        {letters: 'CGJXKS_______CGJXHS', equal: false}, // 12
        {letters: 'ZDRQKMPZH_______ZJRQKMPZH', equal: false}, // 13
        {letters: 'BKMSYDHCL_______BKMSYDHCL', equal: true}, // 14
        {letters: 'NJH_______NJH', equal: true}, // 15
        {letters: 'DJCLVPNWT_______DJCLVPNWT', equal: true}, // 16
        {letters: 'KFS_______BFS', equal: false}, // 17
        {letters: 'MLCGJVQTN_______MLCGSVQTN', equal: false}, // 18
        {letters: 'TPCHKG_______TPCHKG', equal: true}, // 19
        {letters: 'HVC_______LVC', equal: false}, // 20
        {letters: 'VHGSPQ_______LHGSPQ', equal: false}, // 21
        {letters: 'WNZ_______WNZ', equal: true}, // 22
        {letters: 'ZKV_______ZCV', equal: false}, // 23
        {letters: 'NTMCHYFWD_______NTMCHYFWD', equal: true}, // 24
        {letters: 'JQZ_______JQY', equal: false}, // 25
        {letters: 'YMP_______YMP', equal: true}, // 26
        {letters: 'FRZGQT_______FRZGQL', equal: false}, // 27
        {letters: 'CYK_______CYK', equal: true}, // 28
        {letters: 'RJTBYL_______RJTBYL', equal: true}, // 29
        {letters: 'KMX_______KMX', equal: true}, // 30
        {letters: 'PVCMWG_______PVCMWG', equal: true}, // 31
        {letters: 'FNXRZW_______FNXRZH', equal: false}, // 32
        {letters: 'MPD_______MND', equal: false}, // 33
        {letters: 'YQZBXGPMZ_______YQZBXGHMZ', equal: false}, // 34
        {letters: 'RXS_______RXP', equal: false}, // 35
        {letters: 'FHVGJPKWY_______FHVGJPKWN', equal: false}, // 36
        {letters: 'JHWVDKXSQ_______JHWVDKXSQ', equal: true}, // 37
        {letters: 'ZFN_______ZFN', equal: true}, // 38
        {letters: 'RKG_______RHG', equal: false}, // 39
        {letters: 'FSZKNYGTC_______FSZKNYGTC', equal: true}, // 40
        {letters: 'CWFHXGMRD_______NWFHXGMRD', equal: false}, // 41
        {letters: 'PYR_______PYR', equal: true}, // 42
        {letters: 'WDTPZBRJM_______WDTPZBRJM', equal: true}, // 43
        {letters: 'LCPQSD_______LCPQSD', equal: true}, // 44
        {letters: 'JFYPGNSBL_______JFYPGNSBL', equal: true}, // 45
        {letters: 'DWJLPV_______DWJMPV', equal: false}, // 46
        {letters: 'VYGLHS_______VNGLHS', equal: false}, // 47
        {letters: 'GVXRYM_______GVBRYM', equal: false}, // 48
        {letters: 'RKFPTB_______RKFSTB', equal: false}, // 49
        {letters: 'TWHRJC_______TDHRJC', equal: false}, // 50
        {letters: 'LYRHZT_______LYRHZT', equal: true}, // 51
        {letters: 'CQPSTVWKY_______CQPSTVWNY', equal: false}, // 52
        {letters: 'YHRFBGMZX_______YHRFBGMZX', equal: true}, // 53
        {letters: 'JRL_______JRL', equal: true}, // 54
    ]
]

export {letters_training, letters}