const sentences_G1 = [ 
    "O comando do exército do país confirmou o lançamento",
    "A avenida tem apenas trechos pequenos de pista central",
    "O advogado do vereador negou que tenha ocorrido qualquer irregularidade",
    "O homem não discutiu publicamente nenhuma conversa que teve com o presidente",
    "Neste ano, participantes de vários países tiveram a oportunidade de se apresentar",
    "Até o momento o suspeito não foi identificado ou preso",
    "Alguns lotes chegaram a alcançar entre mil a dois mil euros",
    "Ainda de acordo com o médico, a criança não teve nenhuma sequela",
    "A juíza ainda ressaltou os riscos da prática da irregularidade no setor",
    "Os presos serão indiciados por associação criminosa, estelionato e lavagem de dinheiro",
    "As mulheres já sabem o que querem fazer no futuro",
    "Todas as decisões atenderam a pedidos do procurador-geral da república",
    "Segundo o policial, entre os mortos estão três meninos e uma menina",
    "No decorrer da manhã, parentes e vizinhos devem ser chamados para prestar depoimento",
    "Houve ainda um salto nos casos de doenças como malária",
    "A população vive constantes racionamentos - entre eles, o de vacinas",
    "A antropóloga foi a única eleita na área de humanas",
    "O parque disse ter fechado a atração após o acidente",
    "Esses dois primeiros pedidos adiariam o depoimento marcado para esta quarta",
    "Especula-se na mídia americana que o satélite será usado para missões de inteligência",
    "Não há previsão de quando ele vai decidir sobre os pedidos",
    "Em razão da marcha, trinta estações de metrô permaneceram fechadas",
    "O presidente buscou o sucessor alguns minutos antes na tribuna oficial",
    "Eles comunicaram que desejavam fazer uma gravação própria, com som e imagem",
    "O último concurso dos Correios foi realizado para nove mil vagas",
    "Ele teria recebido ofertas de seis países para abrigar a instituição de ensino",
    "Como no resto do país, os ambientalistas têm perdido força",
    "O presidente tanzaniano, após o atentado, apresentou suas condolências às famílias",
    "A comissão abrirá uma investigação interna para apurar o caso",
    "Segundo os policiais, a seita religiosa começou há anos no estado",
    "A equipe do jornal não conseguiu localizar as pessoas citadas na reportagem",
    "Essa explosão matou duas pessoas e feriu outras quatro, acrescentou",
    "O entorno do edifício também terá bloqueios num raio de cem metros",
    "A adolescente acabou sendo incluída no programa de proteção do governo",
    "O homem caiu no chão e um segundo carro freou próximo ao pedestre",
    "O homem morreu devido a um ferimento a bala na cabeça",
    "No sábado, eles tiveram o pedido de liberdade negado pela justiça",
    "Para o professor, a aproximação americana também poderia ser motivada por interesses econômicos",
    "O ministro informou que só vai se manifestar nos autos",
    "De acordo com a empresa, o problema já foi corrigido",
    "A medida ocorreu às duas, conforme post na internet do centro de operações",
    "Já o prefeito pediu desculpas pela ação dos guardas e desejou melhoras",
    "A primeira-ministra expressou a mais profunda gratidão ao príncipe",
    "O aprendiz também foi morto no acidente, junto a outros três soldados afegãos",
    "O último passo é fazer sexo com pessoas do sexo oposto",
    "Sua hipnoterapia busca ensinar ao paciente formas adequadas de se comportar e reagir",
    "Os confrontos deixaram quase trezentos feridos, incluindo dirigentes da oposição",
    "Desde o afastamento dos conselheiros, eles vinham sendo substituídos por auditores",
    "Até a publicação da reportagem, nenhum dos acusados tinha advogado constituído no processo",
    "Alguns bens podem ser utilizados durante longos períodos, como automóveis e geladeira",
    "Houve confronto, mas não há registros de feridos, prisões ou apreensões",
    "Foi divulgado nesta manhã os dados da produção industrial em março",
    "A moeda norte-americana caiu, negociada a três reais na venda",
    "A nota também informa que o empresário não responde a qualquer acusação",
    "Só foi possível saber que a carga é um satélite, sem mais detalhes",
    "Os autores devem responder por homicídio qualificado e associação criminosa",
    "As ações da empresa exibiam queda de dois por cento após o fechamento dos mercados",
    "Os cinco feridos foram levados para hospitais próximos, onde estão sendo atendidos",
    "A autoria do ataque ainda não foi reivindicada por nenhum grupo",
    "As causas e eventuais responsabilidades pelo acidente deverão ser apuradas pela polícia",
    "Três pessoas foram encaminhadas para atendimento médico no hospital universitário",
    "Todas as aulas e atividades no campus nesta segunda-feira foram canceladas",
    "Atualmente, centenas de policiais trabalham para garantir a segurança dentro da comunidade",
    "O filme lançou o ator como um dos mais honrados de sua geração",
    "O hospital informou que o estado de saúde do rapaz é estável",
    "A equipe conversou com o entrevistado sobre os protestos e as possíveis soluções",
    "Conforme a prefeitura, duas unidades municipais de ensino funcionaram normalmente",
    "As duas maiores empresas de ônibus não operaram na cidade",
    "A delegacia de homicídios da capital foi acionada e foi para o local",
    "Representantes da empresa não puderam ser contatados para comentar o assunto",
    "As centrais sindicais entendem que a mudança fragiliza direitos dos trabalhadores",
    "Os juízes estipulam o valor em ações envolvendo danos morais",
    "A homologação da rescisão contratual deve ser feita em sindicatos",
    "Pesquisas de opinião sugerem que as eleições podem resolver esse problema",
    "A economia havia crescido a ritmo de lento no quarto trimestre",
    "O homem disse que sente falta de estar atrás do volante",
    "Para o pesquisador, do instituto de câncer, a descoberta é significativa",
    "São necessários mais testes para confirmar a eficácia do método",
    "A mulher, já idosa, é uma dos pacientes participando dos testes",
    "O homem foi encaminhado para o hospital e está em observação",
    "Cidades de dois estados do sul também amanheceram geladas nesta quinta",
    "Eles deixaram as florestas onde vivem em direção às praias",
    "O destino é a praia, onde depositam os ovos, sob as ondas",
    "Os bebês nascem no mar e depois retornam ao convívio dos pais",
    "O entrevistado ainda reforçou que apresentará o recurso assim que houver a publicação",
    "Uma aluna diz que, sem o álcool, fica um pouco tímida",
    "A sambista passou mal e teve que deixar o cortejo carregada",
    "A filha do presidente promoveu isenções fiscais para o cuidado dos filhos",
    "Apesar da dificuldade, especialistas acreditam em quatro possíveis desdobramentos",
    "A grande pergunta é se o enfrentamento será generalizado ou localizado",
    "Há duas possibilidades mais extremas, porém consideradas menos prováveis por analistas",
    "Ainda segundo a equipe médica, o senador será reavaliado ao longo da semana",
    "O exército ainda não sabe se eles sabiam mesmo nadar",
    "Um policial paraguaio que estava em um carro foi morto pelos bandidos",
    "Também existe a possibilidade de completar todos os participantes com seleções asiáticas",
    "O indicado pelo presidente prometeu exercer o posto com imparcialidade e independência",
    "Outros sete adolescentes ficaram feridos no incidente, disseram as autoridades",
    "As perguntas que eles fazem podem ser produtos de seus preconceitos sociais",
    "O político facilitaria a aprovação de legislação favorável aos interesses da empresa",
    "Quase cinquenta milhões de eleitores franceses devem comparecer às urnas",
    "O pleito é considerado crucial para definir o futuro da união europeia",
    "Por enquanto, nenhuma das duas partes iniciou o processo formal de impugnação",
    "O prazo de entrega do imposto de renda vai até final de abril",
    "A investigação prossegue na polícia federal em relação a outros fatos",
    "Raramente, as expulsões de universidades em Cuba por motivos políticos são tornadas públicas",
    "Segundo o comunicado, a jovem não apoia o projeto social cubano",
    "Ela acrescenta que não quer permanecer apenas com seu diploma do ensino médio",
    "Um outro homem possivelmente ligado ao ataque se entregou à polícia belga",
    "Economistas disseram que cerca de metade da proposta original foi diluída",
    "Segundo a mulher, proprietária do animal, tudo foi muito rápido",
    "O fenômeno foi descrito em uma publicação científica de grande prestígio",
    "A técnica poderia ser usada para entender melhor o fenômeno, dizem os pesquisadores",
    "Você provavelmente nunca viu a Antártida desta forma: pelos olhos de uma baleia",
    "Se for sancionada, a lei terá um prazo de noventa dias de regulamentação",
    "Mas agora as imagens finalmente foram liberadas pelo governo americano",
    "As explosões ocorreram no deserto e no mar, em plena guerra",
    "Ainda não há informações sobre qual dos dois investigados está sendo interrogado primeiro",
    "Campanhas eleitorais feitas no Brasil também estão relacionadas nas delações ainda sob sigilo",
    "Para não cair neste tipo de golpe, vale lembrar aquelas recomendações de segurança",
    "A diferença diminuiu nos últimos dias entre os quatro principais candidatos",
    "A contraindicação é para quem tem alergia severa ao alimento",
    "O crescimento da economia chinesa pode não ser confirmado nos próximos meses",
    "Hoje, adulto, o aluno estuda bioquímica em uma universidade estrangeira",
    "O cenário econômico ajuda a entender essa tendência de rebaixamento voluntário do currículo",
    "No mês passado, a ONU descreveu a situação dos povos sitiados como catastrófica",
    "Na delegacia foi registrado o ato infracional praticado pela mãe da criança",
    "Foram vários executivos delatores, que geraram vários pedidos de inquérito",
    "Essa fábrica, quando inaugurada, também será o maior edifício do mundo",
    "Esse medidor era usado também como referência para calcular o valor dos impostos",
    "Numa reunião, as partes selaram a união acertando a taxa de câmbio",
    "O registro de candidaturas começou na terça-feira e prosseguirá até sábado",
    "Enfrentar a barreira da língua em um país estrangeiro se provou difícil",
    "Ao longo da carreira, o atleta recebeu diversos prêmios por seu trabalho",
    "Os repasses, disse o delator, eram sempre feitos aos partidos políticos",
    "Sua ideia inicial foi ir à farmácia e comprar um colírio simples",
    "O IBGE revisou ainda a taxa de janeiro em relação a dezembro",
    "A assessoria de imprensa informa que ele não vai se pronunciar",
    "Nesta quinta-feira, mais de cinquenta colégios permaneceram fechados, informou o ministério",
    "No pedido de prisão, o promotor considerou a soltura um equívoco",
    "Os deputados federais citados disseram que não estão envolvidos em esquemas ilegais",
    "As drogas são formuladas e licenciadas separadamente para humanos e bichos",
    "Gatos também podem sofrer da mesma condição, ainda que isso seja menos comum",
    "A assessoria informa que desconhece que o nome dele esteja na lista",
    "A compensação, contudo, ainda não foi totalmente paga via tarifa de energia",
    "Por segurança, o shopping foi esvaziado e o setor dos cinemas isolado",
    "A empresa já começou como fabricante de carros totalmente elétricos",
    "O caso é investigado como feminicídio e tentativa de homicídio",
    "A maior parte optou que ele ocorresse em dois domingos seguidos",
    "O incêndio ocorreu na avenida principal durante um protesto",
    "É possível ver pessoas entrando correndo na loja, fugindo para não serem atropeladas",
    "Anteriormente, a imprensa havia divulgado que a colisão havia deixado vinte mortos",
    "Dos quinze feridos, dois continuavam internados neste domingo, em estado crítico",
    "Isto arrastou as potências regionais e internacionais para o conflito, conferindo-lhe outra dimensão",
    "A rebelião armada da oposição evoluiu significativamente desde suas origens",
    "Um fator chave é a intervenção de potências regionais e internacionais",
    "Segundo o secretário, nesses estados haverá ainda rodízio dos fiscais agropecuários",
    "Não houve transtorno no trânsito na região por causa do incidente",
    "Segundo o secretário, esses produtos foram retirados do mercado e descartados",
    "Ele não informou o motivo da abertura do processo contra esses frigoríficos",
    "No local, era esperado por uma guarda militar de honra",
    "Na avaliação do ministro caracterizaria um privilégio em relação às concorrentes",
    "Um porta-voz das forças de segurança disse que o ferido é um gari",
    "Os dois policiais foram presos em flagrante por outro crime",
    "Nos padrões brasileiros, no entanto, o atleta vestiria tamanhos pequenos durante sua carreira",
    "O centro espacial é a principal fonte econômica da região",
    "De acordo com o jornal local, sete policiais também ficaram feridos",
    "Nenhum corpo foi encontrado nesta quarta-feira, relatou a guarda costeira",
    "É neste momento que os pilotos do helicóptero percebem que não podem atacar",
    "Atualmente, usinas de dessalinização ao redor do mundo usam membranas feitas com polímeros",
    "As oportunidades estão distribuídas em todos os estados e no distrito federal",
    "Nos registros do observatório estão duzentos mil homicídios por encomenda",
    "As medidas motivaram protestos da oposição e críticas da comunidade internacional",
    "Um voo internacional foi adiado em consequência do fechamento temporário das instalações",
    "Contado à parte, o segmento de motos recuou muito no mês passado",
    "Na quinta-feira, um motociclista morreu após um acidente na marginal",
    "Fortalecida pela pressão internacional sobre o governo, a oposição reativou suas manifestações",
    "Além da qualidade das frutas, a embalagem também faz diferença",
    "Já à noite, o presidente depositou flores na estação em tributo às vítimas",
    "O homem lamentou o fato e destacou a atuação da embaixatriz",
    "Ao todo, seis sistemas fornecem água para as cidades paulistas"
]

export default sentences_G1