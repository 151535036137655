import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Navigation from '../Navigation'
import Landing from '../Landing'
import SignUp from '../SignUp'
import SignIn from '../SignIn'
import PasswordForget from '../PasswordForget'
import Home from '../Home'
import Account from '../Account'
import Admin from '../Admin'
import Researcher from '../Researcher'
import Participant from '../Participant'
import DataCollection from '../DataCollection'
import ParticipantSignIn from '../SignIn/ParticipantSignIn'
import ParticipantSignUp from '../SignUp/ParticipantSignUp'
import { withAuthentication } from '../Session'
import ReadingLoggerOutput from '../Instruments/ReadingLogger/ReadingLoggerOutput'
import WritingLoggerOutput from '../Instruments/WritingLogger/WritingLoggerOutput'

import * as ROUTES from '../../constants/routes'

import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'


function App() {
    return (
        <Router>
            <Switch>
                <Route exact path={ROUTES.DATA_COLLECTION} component={DataCollection}/>
                <Route exact path={ROUTES.DATA_COLLECTION_SIGN_IN} component={ParticipantSignIn}/>
                <Route exact path={ROUTES.DATA_COLLECTION_SIGN_UP} component={ParticipantSignUp}/>
                <Route exact path={ROUTES.DATA_PROCESSING_READINGLOGGER}>
                    <div id="data-processing" className="d-flex flex-column justify-content-center align-items-center container-fluid">
                        <ReadingLoggerOutput show={true} hideButton={true}/>
                    </div>
                </Route>
                <Route exact path={ROUTES.DATA_PROCESSING_WRITINGLOGGER}>
                    <WritingLoggerOutput show={true} hideButton={true}/>
                </Route>
                <Route>
                    <Navigation/>

                    <Route exact path={ROUTES.LANDING}><Landing /></Route>
                    <Route path={ROUTES.SIGN_UP}><SignUp /></Route>
                    <Route path={ROUTES.SIGN_IN}><SignIn /></Route>
                    <Route path={ROUTES.PASSWORD_FORGET}><PasswordForget /></Route>
                    <Route path={ROUTES.HOME}><Home /></Route>
                    <Route path={ROUTES.RESEARCHER}><Researcher /></Route>
                    <Route path={ROUTES.PARTICIPANT}><Participant /></Route>
                    <Route path={ROUTES.ACCOUNT}><Account /></Route>
                    <Route path={ROUTES.ADMIN}><Admin /></Route>

                    <footer className='bg-light border-top p-3 text-center position-absolute w-100' style={{bottom: '0'}}>
                        Davi Alves Oliveira 
                        <a 
                            rel='license noopener noreferrer'
                            href='http://creativecommons.org/licenses/by-nc-nd/4.0/' 
                            target='_blank'
                            className='mx-3'>

                            <img 
                                alt='Creative Commons License' 
                                style={{borderWidth: '0'}} 
                                src='https://i.creativecommons.org/l/by-nc-nd/4.0/88x31.png' />
                        </a>
                        <a href='https://davi.solutions'>davi.solutions</a>
                    </footer>
                </Route>
            </Switch>
        </Router>
    )
}

export default withAuthentication(App)