const sentences_training = [
    [
        {sentence: 'The children brought nothing to give them light in the tunnel.', sensical: true, word: 'tunnel'}, // 1
        {sentence: 'The family watched the other evenings get onto the airplane.', sensical: false, word: 'airplane'}, // 2
    ],
    [
        {sentence: 'The scientist drank the tea and started to bathe his morning paper.', sensical: false, word: 'paper'}, // 3
        {sentence: 'The woman smiled and put the photo in her coat pocket.', sensical: true, word: 'pocket'}, // 4
        {sentence: 'The young man picked up a bag and walked into the cabin.', sensical: true, word: 'cabin'}, // 5
    ],
    [
        {sentence: 'We will land at another airport before we finish our journey.', sensical: true, word: 'journey'}, //6
        {sentence: 'I will study while you invite me a cup of coffee.', sensical: false, word: 'coffee'}, // 7
        {sentence: 'This is not serious enough for somebody to be in trouble.', sensical: true, word: 'trouble'}, // 8
        {sentence: 'John saw the rain as he looked out of the knife window.', sensical: false, word: 'window'}, // 9
    ]
]

const sentences = [
    [
        {sentence: 'There were three big yellow buses spoken outside the school building.', sensical: false, word: 'building'}, // 1
        {sentence: 'Their life is just filled with adventures around every corner.', sensical: true, word: 'corner'}, // 2
    ],
    [
        {sentence: 'The government of your country holds two of our brothers in prison.', sensical: true, word: 'prison'}, // 3
        {sentence: 'My friend left his ticket and passport on the honest table.', sensical: false, word: 'table'}, // 4
    ],
    [
        {sentence: 'The chief was waiting in the control meal with a soldier.', sensical: false, word: 'soldier'}, // 5
        {sentence: 'Her oldest son had always asked her lots of questions.', sensical: true, word: 'questions'}, // 6
    ],
    [
        {sentence: 'Both the French and the British prime animals were difficult people.', sensical: false, word: 'people'}, // 7
        {sentence: 'The shopping mall received some rare feet and pretty flowers.', sensical: false, word: 'flowers'}, // 8
        {sentence: 'The other patients in the local hospital are from this city.', sensical: true, word: 'city'}, // 9
    ],
    [
        {sentence: 'My uncle wants to protect the innocent as a freedom fighter.', sensical: true, word: 'fighter'}, // 10
        {sentence: 'She stared at her new husband for a long moment.', sensical: true, word: 'moment'}, // 11
        {sentence: 'The colonel paused and dried the major for his advice.', sensical: false, word: 'advice'}, //12
    ],
    [
        {sentence: 'He remembered other times he had been with his daughter.', sensical: true, word: 'daughter'}, // 13
        {sentence: 'My cousin Jane ate pizza and chocolate bench for her birthday.', sensical: false, word: 'birthday'}, // 14
        {sentence: 'The chef found some cheap fish this afternoon in the market.', sensical: true, word: 'market'}, // 15
    ],
    [
        {sentence: 'I must make the decisions while you must obey my orders.', sensical: true, word: 'orders'}, // 16
        {sentence: 'The maid arrived home and cooked delicious sunshines for breakfast.', sensical: false, word: 'breakfast'}, // 17
        {sentence: 'A stranger with a cart stole the boxes from the garden.', sensical: true, word: 'garden'}, // 18
        {sentence: 'One of Peter’s favorite presents was a blue shell rocket.', sensical: false, word: 'rocket'}, // 19
    ],
    [
        {sentence: 'You should not pretend to feel shoe and call for a doctor.', sensical: false, word: 'doctor'}, // 20
        {sentence: 'They also had a wonderful mother who would tell them stories.', sensical: true, word: 'stories'}, // 21
        {sentence: 'The Swiss businessman needed to go to work by taxi.', sensical: true, word: 'taxi'}, // 22
        {sentence: 'A professional from the village waved our place for dinner.', sensical: false, word: 'dinner'}, // 23
    ],
    [
        {sentence: 'The lost travelers were moving up and down the train station.', sensical: true, word: 'station'}, // 24
        {sentence: 'The upstairs neighbor searched for the key under the doormat. ', sensical: true, word: 'doormat'}, // 25
        {sentence: 'Bobby chose to sleep in the dog with the warm blankets.', sensical: false, word: 'blankets'}, // 26
        {sentence: 'The famous detective and his assistant could not grass the killer.', sensical: false, word: 'killer'}, // 27
    ],
    [
        {sentence: 'They went to the railway line at the bottom of the mountain.', sensical: true, word: 'mountain'}, // 28
        {sentence: 'The principal sat down on her chair to stand a letter.', sensical: false, word: 'letter'}, // 29
        {sentence: 'It is not every day a girl cries into our steam engine.', sensical: false, word: 'engine'}, // 30
        {sentence: 'The inspector in the London case listed some possible suspects.', sensical: true, word: 'suspects'}, // 31
        {sentence: 'Jim took his sister to meet the nice disease and fireman.', sensical: false, word: 'fireman'}, // 32
    ],
    [
        {sentence: 'The gentleman told him what happened at the bank through a message.', sensical: true, word: 'message'}, // 33
        {sentence: 'Phyllis heard a different voice coming out of the darkness.', sensical: true, word: 'darkness'}, // 34
        {sentence: 'You can buy beautiful houses and fine clothes if you rest money.', sensical: false, word: 'money'}, // 35
        {sentence: 'The classmates helped each other think about the noisy answer.', sensical: false, word: 'answer'}, // 36
        {sentence: 'He spent hours chasing the actor before finding him at a hotel.', sensical: true, word: 'hotel'}, // 37
    ],
    [
        {sentence: 'The officers used four red towns to signal the immediate danger.', sensical: false, word: 'danger'}, // 38
        {sentence: 'The boy could not believe his father kept a terrible secret.', sensical: true, word: 'secret'}, // 39
        {sentence: 'The teacher sent the parents a short but skyscraper report.', sensical: false, word: 'report'}, // 40
        {sentence: 'I was stupid to worry about a few careless mistakes.', sensical: true, word: 'mistakes'}, // 41
        {sentence: 'They bought many balloons and candles for her wooden party.', sensical: false, word: 'party'}, // 42
    ]
]

export {sentences_training, sentences}