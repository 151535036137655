import app from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/database'

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
}

class Firebase {
    auth: firebase.auth.Auth
    db: firebase.database.Database
    googleProvider: firebase.auth.GoogleAuthProvider

    constructor() {
        app.initializeApp(config)
        app.analytics()

        this.auth = app.auth()
        this.db = app.database()
        this.googleProvider = new app.auth.GoogleAuthProvider()
    }

    // AUTH API
    doCreateUserWithEmailAndPassword = (email: string, password: string) => {
        return this.auth.createUserWithEmailAndPassword(email, password)
    }

    doSendEmailVerification = () => {
        return this.auth.currentUser?.sendEmailVerification({
            url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT as string
        })
    }

    doSignInWithEmailAndPassword = (email: string, password: string) => {
        return this.auth.signInWithEmailAndPassword(email, password)
    }

    doSignInWithGoogle = () => {
        return this.auth.signInWithPopup(this.googleProvider)
    }

    doSignOut = () => {
        return this.auth.signOut()
    }

    doPasswordReset = (email: string) => {
        return this.auth.sendPasswordResetEmail(email)
    }

    doPasswordUpdate = (password: string) => {
        return this.auth.currentUser?.updatePassword(password)
    }

    user = (uuid: string) => {
        return this.db.ref(`users/${uuid}`)
    }

    users = () => {
        return this.db.ref('users')
    }

    onAuthUserListener = (next: (authUser: any) => void, fallback: () => void) => {
        return this.auth.onAuthStateChanged(authUser => {
            if (authUser) {
                this.user(authUser.uid).once('value').then(snapshot => {
                    const dbUser = snapshot.val()

                    if (!dbUser.roles) {
                        dbUser.roles = {}
                    }

                    authUser = {
                        uid: authUser?.uid,
                        displayName: authUser?.displayName,
                        email: authUser?.email,
                        emailVerified: authUser?.emailVerified,
                        providerData: authUser?.providerData,
                        ...dbUser
                    }

                    next(authUser)
                })
            } else {
                fallback()
            }
        })
    }

    // Experiment API
    experiment = (uid: string) => this.db.ref(`experiments/${uid}`)

    experiments = () => this.db.ref(`experiments`)

    // Instrument API
    instrument = (experimentId: string, instrumentId: string) => (
        this.db.ref(`experiments/${experimentId}/instruments/${instrumentId}`)
    )

    // Survey Questions API
    questions = (experimentId: string, instrumentId: string) => (
        this.db.ref(`experiments/${experimentId}/instruments/${instrumentId}/options/questions`)
    )

    // Data API
    data = (uid: string) => this.db.ref(`data/${uid}`)

    dataset = () => this.db.ref(`data`)
}

export default Firebase