import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import { v4 as uuid } from 'uuid'
import swal from 'sweetalert'

import DataType from '../../../types/DataType'
import ConfigType from '../../../types/ConfigType'
import Question from '../Question'
import { FirebaseContext } from '../../Firebase'

import { TRIALS, INSTRUMENTS, FIELDS } from '../../../constants/instruments'
import { Message } from '../../Layout'

function TCLE(props: {
    id: string,
    name: string,
    show: boolean,
    data: DataType[], 
    experimentId: string,
    language: 'en'|'br',
    addData: Dispatch<SetStateAction<DataType[]>>,
    next: (finish?: boolean) => void,
    hideButton?: boolean
}) {
    const [finish, setFinish] = useState(false)
    const [config, setConfig] = useState([] as ConfigType[])
    const [keys, setKeys] = useState([] as string[])
    const [show, setShow] = useState([] as boolean[])
    const [options, setOptions] = useState([] as any)
    const firebase = useContext(FirebaseContext)

    const handleAddData = (newData: DataType) => {
        props.addData(data => {
            return [...data, newData]
        })
    }

    const handleNext = () => {
        if (!show[show.length - 1]) {
            let setTrue = false

            setShow(show.map((s: boolean) => {
                if (s) {
                    setTrue = true
                    return false
                }

                if (setTrue) {
                    setTrue = false
                    return true
                }

                return false
            }))
        } else {
            setFinish(true)
        }
    }

    useEffect(() => {
        firebase?.experiment(props.experimentId).once(
            'value', snapshot => {
                setOptions(
                    snapshot.val().instruments.filter(
                        (i: any) => i.id === props.id)[0].options
                    )
            }
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let config: ConfigType[]
        
        if (options && options.length > 0) {
            config = [{
                type: TRIALS.QUESTION,
                questions: [{
                    prompt: <strong>{props.language === 'en' ? 'Please, select one option:' : 'Por favor, escolha uma das opções:'}</strong>, 
                    type: FIELDS.RADIO,
                    options: [
                        { label: options?.filter((o: any) => ('agree' in o))[0]?.agree, value: 'agree' }, 
                        { label: options?.filter((o: any) => ('disagree' in o))[0]?.disagree, value: 'disagree' }
                    ]
                }]
            }]
        } else {
            config = [{
                type: TRIALS.TEXT,
                prompt: 'O TCLE não foi configurado corretamente'
            }]
        }

        setConfig(config)
        setKeys(config.map(() => uuid()))
        setShow(config.map((c: ConfigType, i: number) => i === 0 ? true : false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options])

    useEffect(() => {
        if (props.show && finish) {
            const TCLEResponses = props.data.filter((d: DataType) => d.instrument === INSTRUMENTS.TCLE)
            
            if (TCLEResponses[TCLEResponses.length - 1].response[0].answer === '') {
                swal({
                    title: props.language === 'en' ? 'You must select one option' : 'É preciso escolher uma oção',
                    text: props.language === 'en' ? 
                        'Please, select one option declaring if you agree or disagree with your participation in the study.' : 
                        'Por favor, selecione uma opção informando se concorda ou não com a participação na pesquisa.',
                    icon: 'warning'
                }).then(() => setFinish(false))
            } else if (TCLEResponses[TCLEResponses.length - 1].response[0].answer === 'disagree') {
                props.next(true)
            } else {
                props.next()
            }       
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finish])

    return (
        !props.show ? null :
        <Container className='instrument-container d-flex flex-column justify-content-center align-items-center position-relative' fluid>
            <div className='container'>
                <h2 className='my-5 text-center'>
                    {
                        props.language === 'en' ?
                        'Informed Consent Form' :
                        'Termo de Consentimento Livre e Esclarecido'
                    }
                </h2>
                {options?.filter((o: any) => ('title' in o)).map(
                    (o: {title: string, text: string}) => (
                    <React.Fragment key={uuid()}>
                        <h3 className='my-2'>{o.title}</h3>
                        <p className='text-justify'>{o.text}</p>
                    </React.Fragment>
                ))}
            </div>
            {
                config.map((p: ConfigType, i: number) => {
                    switch (p.type) {
                        case TRIALS.QUESTION:
                            return (
                                <Question 
                                    key={keys[i]}
                                    show={show[i]}
                                    instrument={INSTRUMENTS.TCLE}
                                    instrumentId={props.id}
                                    instrumentName={props.name}
                                    questions={p.questions} 
                                    correctResponses={p.correctResponses} 
                                    addData={handleAddData}
                                    next={handleNext}
                                    training={p.training}
                                    language={props.language}/>
                            )
                        default:
                            return <Message key={keys[i]} warning='O TCLE não foi configurado corretamente.'/>
                    }
                })
            }
        </Container>
    )
}

export default TCLE