import React from 'react'
import shuffle from 'shuffle-array'

import ConfigType from '../../../types/ConfigType'
import { sentences_training, sentences } from './sentences'

import { FIELDS, TRIALS } from '../../../constants/instruments'


const config: ConfigType[] = []

// Instructions - Training
config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>In this test you will read English sentences while you also try to memorize the last word of each sentence.</p>
            <p>In the next few minutes, you will have some practice to get you familiar with how the test works.</p> 
            <p>You will read sets of two, three, and four sentences presented randomly.</p>
            <p>Click the continue button to begin.</p>
        </>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>The sentences will appear on the screen one at a time, like this:</p>
            <p><em>“I like to run in the park”</em>.</p>
            <p>As soon as you see the sentence, you should read it and determine if it makes sense or not. The above sentence makes sense.</p>
            <p>An example of a sentence that does not make sense would be:</p>
            <p><em>“I like to run in the sky”</em>.</p>
            <p>You also need to memorize the last word of the sentence you saw.</p>
            <p>When you have read the sentence to determine whether it makes sense or not and memorized its last word, click the continue button.</p>
            <p>Take a moment to make sure you understand the instructions.</p>
            <p>Click the continue button to proceed.</p>
        </>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>
            You will then see “The previous sentence makes sense.” displayed on the next screen, along with boxes labeled TRUE and FALSE.
            </p>
            <p>If the sentence on the previous screen made sense, click on the TRUE box. If the sentence did not make sense, click the FALSE box.</p>
            <p>After you click on one of the boxes, the computer will tell you if you made the right choice.</p>
            <p>It is VERY important to read the sentences and judge them as QUICKLY and as ACCURATELY as possible.</p>
            <p>Take a moment to make sure you understand the instructions.</p>
            <p>Click the continue button to proceed.</p>
        </>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>Another sentence will appear for you to read to decide whether it makes sense or not and memorize its last word.</p>
            <p>
                When you have judged all the sentences in a set, a recall screen will appear for you to write the last word of each sentence in the order they were presented.
            </p>
            <p>If you forget one of the words, leave the corresponding spot blank.</p>
            <p>Remember, it is VERY important to write the words in the same order you saw them.</p>
            <p>Take a moment to make sure you understand the instructions.</p>
            <p>Click the continue button to proceed.</p>
        </>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>It is VERY important that you answer the sentence problems CORRECTLY.</p>
            <p>
                It is also important that you try and read the sentences as QUICKLY as you can WHILE doing your best to recall as many last words as possible in the order they were presented.
            </p>
            <p>
                <strong>IMPORTANT:</strong> you cannot take notes of the last words during the sentence presentation. You should rely on your memory to remember them.
            </p>
            <p>Take a moment to make sure you understand the instructions.</p>
            <p>When you are ready, click the continue button to try some practice problems.</p>
        </>
    )
})

// Training
shuffle(sentences_training)

// Training
for (let set of sentences_training) {
    for (let sentence of set) {
        // Sentence
        config.push({
            type: TRIALS.STIMULUS,
            stimulus: sentence.sentence,
            response: {
                type: 'click',
            },
            training: true
        })

        // Semantic acceptability judgement
        config.push({
            type: TRIALS.STIMULUS,
            stimulus: 'The previous sentence makes sense.',
            response: {
                type: 'button',
                buttons: [`${sentence.sensical ? 'TRUE' : 'FALSE'}`, `${!sentence.sensical ? 'TRUE' : 'FALSE'}`],
                centralizeButtons: true,
                showCorrect: true, //Only in trainning
                order: 'desc'
            },
            training: true
        })

        // Blank
        config.push({
            type: TRIALS.BLANK,
            training: true
        })
    }

    // Recall
    config.push({
        type: TRIALS.QUESTION,
        title: <span>Write the last word<br/> of each sentence in the order<br/> they appeared.</span>,
        questions: set.map(() => ({prompt: '', type: FIELDS.INPUT})),
        correctResponses: set.map((s) => s.word),
        training: true
    })
}

// Instructions
config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>That is the end of the practice. The real trials will look just like the practice trials you just completed.</p>
            <p>You will read sets of 2 to 5 sentences presented randomly.</p>
            <p>It is important that you do your best both on deciding whether the sentences make sense and memorizing the last word of each sentence.</p>
            <p>You will not be told if you judged the sentences correctly.</p>
            <p>Take a moment to make sure you understand the instructions. </p>
            <p>Click the continue button to begin the test.</p>
        </>
    )
})

// Test
shuffle(sentences)

// Test
for (let set of sentences) {
    for (let sentence of set) {
        // Sentence
        config.push({
            type: TRIALS.STIMULUS,
            stimulus: sentence.sentence,
            response: {
                type: 'click',
            },
        })

        // Semantic acceptability judgement
        config.push({
            type: TRIALS.STIMULUS,
            stimulus: 'The previous sentence makes sense.',
            response: {
                type: 'button',
                buttons: [`${sentence.sensical ? 'TRUE' : 'FALSE'}`, `${!sentence.sensical ? 'TRUE' : 'FALSE'}`],
                centralizeButtons: true,
                order: 'desc'
            },
        })

        // Blank
        config.push({
            type: TRIALS.BLANK,
        })
    }

    // Recall
    config.push({
        type: TRIALS.QUESTION,
        title: <span>Write the last word<br/> of each sentence in the order<br/> they appeared.</span>,
        questions: set.map(() => ({prompt: '', type: FIELDS.INPUT})),
        correctResponses: set.map((s) => s.word)
    })
}


export default config