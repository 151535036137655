import React, { useContext, useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useRouteMatch, useHistory } from 'react-router-dom'

import { FirebaseContext } from '../../Firebase'
import { IconButton, ICONS, Loading} from '../../Layout'

import * as ROUTES from '../../../constants/routes'

function DRMOptions() {
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState({} as {list: string})
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true)
    const match = useRouteMatch<{experimentId: string, instrumentId: string}>(ROUTES.RESEARCHER_EXPERIMENTS_INSTRUMENTS_DETAILS)
    const firebase = useContext(FirebaseContext)
    const history = useHistory()

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        setLoading(true)

        firebase?.instrument(match?.params.experimentId as string, match?.params.instrumentId as string).child('options')
            .set(options).then(() => {
                setSaveButtonDisabled(true)
                history.push(`/pesquisador/experimentos/${match?.params.experimentId}`)
            })
    }

    const handleChange = (e: any) => {
        setOptions({list: e.currentTarget.value})
        setSaveButtonDisabled(false)
    }

    useEffect(() => {
        firebase?.instrument(match?.params.experimentId as string, match?.params.instrumentId as string).on('value', snapshot => {
            setOptions(snapshot.val().options)
        })

        return () => {
            firebase?.instrument(
                match?.params.experimentId as string, 
                match?.params.instrumentId as string
            ).off()}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        loading ? <Loading/> :
        <>
            <h2 className='mb-5'>Editar Tarefa DRM</h2>
            <Form onSubmit={handleSubmit}>
                <Row className='mb-5'>
                    <Col>
                        <Form.Text>
                            <h3>Selecionar lista</h3>
                        </Form.Text>
                        <Form.Control as='select' value={options.list} onChange={handleChange}>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3A'>3A</option>
                            <option value='3B'>3B</option>
                        </Form.Control>
                    </Col>
                </Row>
                
                <Row>
                    <Col>
                        <IconButton
                            icon={ICONS.BACK}
                            onClick={() => {history.goBack()}}>
                            
                            Voltar
                        </IconButton>
                        <IconButton 
                            type='submit' 
                            icon={ICONS.SAVE}
                            className='ml-1'
                            disabled={saveButtonDisabled}
                            variant={!saveButtonDisabled ? 'warning' : undefined}>
                            
                            Salvar
                        </IconButton>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default DRMOptions