import React, { useContext, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'

import AuthUserContext from './contex'
import { FirebaseContext } from '../Firebase'
import { Message } from '../Layout'

function withEmailVerification(Component: React.FunctionComponent<any>) {
    function WithEmailVerification(props: React.ComponentProps<any>) {
        const [isSent, setIsSent] = useState(false)
        const firebase = useContext(FirebaseContext)
        
        const onSendEmailVerification = () => {
            firebase?.doSendEmailVerification()?.then(() => setIsSent(true))
        }

        return (
            <AuthUserContext.Consumer>
                {authUser => (
                    needsEmailVerification(authUser) 
                    ? 
                    isSent
                        ?
                        <Container>
                            <Row>
                                <Col>
                                    <Alert type='warning'>
                                        Email enviado. Confira sua caixa de entrada e de span e siga as instruções.
                                    </Alert>
                                </Col>
                            </Row>
                        </Container>
                        :
                        <Container>
                            <Row>
                                <Col>
                                <Message 
                                    error={{
                                    code: 'auth/verificationNeeded', 
                                    message: 
                                        'Você precisa verificar seu email. ' + 
                                        'Confira sua caixa de entrada e span. Caso não tenha encontre o email, ' + 
                                        'você pode clicar no botão abaixo para receber outro link'
                                    }}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button variant='primary' type='button' onClick={onSendEmailVerification}>
                                        Enviar link de verificação
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    : 
                    <Component {...props}/>
                )}
            </AuthUserContext.Consumer>
        )
    }

    return WithEmailVerification
}

const needsEmailVerification = (authUser: any) => {
    return authUser 
        && !authUser.emailVerified 
        && authUser.providerData.map((provider: firebase.auth.AuthProvider) => provider.providerId).includes('password')
}

export default withEmailVerification