import React from 'react'
import pattern_practice1 from './images/Practice1.bmp'
import pattern_practice2 from './images/Practice2.bmp'
import pattern_practice3 from './images/Practice3.bmp'
import pattern_test1 from './images/Test1.bmp'
import pattern_test2 from './images/Test2.bmp'
import pattern_test3 from './images/Test3.bmp'
import pattern_test4 from './images/Test4.bmp'
import pattern_test5 from './images/Test5.bmp'
import pattern_test6 from './images/Test6.bmp'
import pattern_test7 from './images/Test7.bmp'
import pattern_test8 from './images/Test8.bmp'
import pattern_test9 from './images/Test9.bmp'
import pattern_test10 from './images/Test10.bmp'
import pattern_test11 from './images/Test11.bmp'
import pattern_test12 from './images/Test12.bmp'
import pattern_test13 from './images/Test13.bmp'
import pattern_test14 from './images/Test14.bmp'
import pattern_test15 from './images/Test15.bmp'
import pattern_test16 from './images/Test16.bmp'
import pattern_test17 from './images/Test17.bmp'
import pattern_test18 from './images/Test18.bmp'
import pattern_test19 from './images/Test19.bmp'
import pattern_test20 from './images/Test20.bmp'
import pattern_test21 from './images/Test21.bmp'
import pattern_test22 from './images/Test22.bmp'
import pattern_test23 from './images/Test23.bmp'
import pattern_test24 from './images/Test24.bmp'
import pattern_test25 from './images/Test25.bmp'
import pattern_test26 from './images/Test26.bmp'
import pattern_test27 from './images/Test27.bmp'
import pattern_test28 from './images/Test28.bmp'
import pattern_test29 from './images/Test29.bmp'
import pattern_test30 from './images/Test30.bmp'
import pattern_test31 from './images/Test31.bmp'
import pattern_test32 from './images/Test32.bmp'
import pattern_test33 from './images/Test33.bmp'
import pattern_test34 from './images/Test34.bmp'
import pattern_test35 from './images/Test35.bmp'
import pattern_test36 from './images/Test36.bmp'
import pattern_test37 from './images/Test37.bmp'
import pattern_test38 from './images/Test38.bmp'
import pattern_test39 from './images/Test39.bmp'
import pattern_test40 from './images/Test40.bmp'
import pattern_test41 from './images/Test41.bmp'
import pattern_test42 from './images/Test42.bmp'
import pattern_test43 from './images/Test43.bmp'
import pattern_test44 from './images/Test44.bmp'
import pattern_test45 from './images/Test45.bmp'
import pattern_test46 from './images/Test46.bmp'
import pattern_test47 from './images/Test47.bmp'
import pattern_test48 from './images/Test48.bmp'
import pattern_test49 from './images/Test49.bmp'
import pattern_test50 from './images/Test50.bmp'
import pattern_test51 from './images/Test51.bmp'
import pattern_test52 from './images/Test52.bmp'
import pattern_test53 from './images/Test53.bmp'
import pattern_test54 from './images/Test54.bmp'
import pattern_test55 from './images/Test55.bmp'
import pattern_test56 from './images/Test56.bmp'
import pattern_test57 from './images/Test57.bmp'
import pattern_test58 from './images/Test58.bmp'
import pattern_test59 from './images/Test59.bmp'
import pattern_test60 from './images/Test60.bmp'

const patterns_training = [
    [
        {patterns: <img src={pattern_practice1} alt='' />, equal: true}, // 1
        {patterns: <img src={pattern_practice2} alt='' />, equal: false}, // 2
        {patterns: <img src={pattern_practice3} alt='' />, equal: false}, // 3
    ]
]

const patterns = [
    [
        {patterns: <img src={pattern_test1} alt='' />, equal: true}, // 1
        {patterns: <img src={pattern_test2} alt='' />, equal: false}, // 2
        {patterns: <img src={pattern_test3} alt='' />, equal: true}, // 3
        {patterns: <img src={pattern_test4} alt='' />, equal: false}, // 4
        {patterns: <img src={pattern_test5} alt='' />, equal: true}, // 5
        {patterns: <img src={pattern_test6} alt='' />, equal: false}, // 6
        {patterns: <img src={pattern_test7} alt='' />, equal: true}, // 7
        {patterns: <img src={pattern_test8} alt='' />, equal: false}, // 8
        {patterns: <img src={pattern_test9} alt='' />, equal: true}, // 9
        {patterns: <img src={pattern_test10} alt='' />, equal: false}, // 10
        {patterns: <img src={pattern_test11} alt='' />, equal: true}, // 11
        {patterns: <img src={pattern_test12} alt='' />, equal: false}, // 12
        {patterns: <img src={pattern_test13} alt='' />, equal: false}, // 13
        {patterns: <img src={pattern_test14} alt='' />, equal: false}, // 14
        {patterns: <img src={pattern_test15} alt='' />, equal: true}, // 15
        {patterns: <img src={pattern_test16} alt='' />, equal: false}, // 16
        {patterns: <img src={pattern_test17} alt='' />, equal: true}, // 17
        {patterns: <img src={pattern_test18} alt='' />, equal: false}, // 18
        {patterns: <img src={pattern_test19} alt='' />, equal: false}, // 19
        {patterns: <img src={pattern_test20} alt='' />, equal: true}, // 20
        {patterns: <img src={pattern_test21} alt='' />, equal: true}, // 21
        {patterns: <img src={pattern_test22} alt='' />, equal: true}, // 22
        {patterns: <img src={pattern_test23} alt='' />, equal: true}, // 23
        {patterns: <img src={pattern_test24} alt='' />, equal: true}, // 24
        {patterns: <img src={pattern_test25} alt='' />, equal: false}, // 25
        {patterns: <img src={pattern_test26} alt='' />, equal: false}, // 26
        {patterns: <img src={pattern_test27} alt='' />, equal: false}, // 27
        {patterns: <img src={pattern_test28} alt='' />, equal: true}, // 28
        {patterns: <img src={pattern_test29} alt='' />, equal: false}, // 29
        {patterns: <img src={pattern_test30} alt='' />, equal: true}, // 30
    ],
    [
        {patterns: <img src={pattern_test31} alt='' />, equal: false}, // 31
        {patterns: <img src={pattern_test32} alt='' />, equal: false}, // 32
        {patterns: <img src={pattern_test33} alt='' />, equal: true}, // 33
        {patterns: <img src={pattern_test34} alt='' />, equal: true}, // 34
        {patterns: <img src={pattern_test35} alt='' />, equal: true}, // 35
        {patterns: <img src={pattern_test36} alt='' />, equal: true}, // 36
        {patterns: <img src={pattern_test37} alt='' />, equal: false}, // 37
        {patterns: <img src={pattern_test38} alt='' />, equal: false}, // 38
        {patterns: <img src={pattern_test39} alt='' />, equal: true}, // 39
        {patterns: <img src={pattern_test40} alt='' />, equal: true}, // 40
        {patterns: <img src={pattern_test41} alt='' />, equal: false}, // 41
        {patterns: <img src={pattern_test42} alt='' />, equal: false}, // 42
        {patterns: <img src={pattern_test43} alt='' />, equal: true}, // 43
        {patterns: <img src={pattern_test44} alt='' />, equal: false}, // 44
        {patterns: <img src={pattern_test45} alt='' />, equal: false}, // 45
        {patterns: <img src={pattern_test46} alt='' />, equal: false}, // 46
        {patterns: <img src={pattern_test47} alt='' />, equal: true}, // 47
        {patterns: <img src={pattern_test48} alt='' />, equal: true}, // 48
        {patterns: <img src={pattern_test49} alt='' />, equal: true}, // 49
        {patterns: <img src={pattern_test50} alt='' />, equal: true}, // 50
        {patterns: <img src={pattern_test51} alt='' />, equal: true}, // 51
        {patterns: <img src={pattern_test52} alt='' />, equal: false}, // 52
        {patterns: <img src={pattern_test53} alt='' />, equal: false}, // 53
        {patterns: <img src={pattern_test54} alt='' />, equal: false}, // 54
        {patterns: <img src={pattern_test55} alt='' />, equal: true}, // 55
        {patterns: <img src={pattern_test56} alt='' />, equal: false}, // 56
        {patterns: <img src={pattern_test57} alt='' />, equal: false}, // 57
        {patterns: <img src={pattern_test58} alt='' />, equal: true}, // 58
        {patterns: <img src={pattern_test59} alt='' />, equal: true}, // 59
        {patterns: <img src={pattern_test60} alt='' />, equal: false}, // 60
    ]
]

export {patterns_training, patterns}