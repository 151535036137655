import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { FirebaseContext } from '../Firebase'
import { AuthUserContext } from '../Session'

import * as ROUTES from '../../constants/routes'

function withAuthorization(condition: (authUser: any) => boolean) {
    return function(Component: React.FunctionComponent<any>) {
        function WithAuthorization() {
            const firebase = useContext(FirebaseContext)
            const history = useHistory()

            useEffect(() => {
                const listener = firebase?.onAuthUserListener(
                    authUser => {
                        if (!condition(authUser)) {
                            history.push(ROUTES.SIGN_IN)
                        }
                    },
                    () => {history.push(ROUTES.SIGN_IN)}
                )

                return () => listener && listener()
            }, [firebase, history])

            return (
                <AuthUserContext.Consumer>
                    {authUser => (
                        condition(authUser) && <Component />
                    )}
                </AuthUserContext.Consumer>
            )
        }
        
        return WithAuthorization
    }
}

export default withAuthorization