import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { v4 as uuid } from 'uuid'

function MultipleChoice(props: {
  id: string,
  prompt: React.ReactNode,
  options?: {label: string, value: string}[],
  questionIndex: number,
  responses: never[],
  language: 'en'|'br',
  setResponses: React.Dispatch<React.SetStateAction<never[]>>
}) {
  const [checkedStates, setCheckedStates] = useState<boolean[]>([])

  const handleCheckboxChange = (value: string, valueIndex: number) => {
    setCheckedStates(current => {
      const states = current.map((s, index) => valueIndex !== index ? s : !s)
      const newResponses: any = props.responses

      newResponses[props.questionIndex] = (!newResponses[props.questionIndex] ? newResponses[props.questionIndex] = [] : newResponses[props.questionIndex])
      newResponses[props.questionIndex][valueIndex] = (states[valueIndex] ? value : '')
      props.setResponses(newResponses)

      return states
    })
  }

  const handleCheckAll = () => {
    setCheckedStates(current => {
      const states = current.map(() => true)
      const newResponses: any = props.responses

      props.options?.forEach((o, valueIndex) => {
        newResponses[props.questionIndex] = (!newResponses[props.questionIndex] ? newResponses[props.questionIndex] = [] : newResponses[props.questionIndex])
        newResponses[props.questionIndex][valueIndex] = (states[valueIndex] ? o.value : '')
      })

      props.setResponses(newResponses)

      return states
    })
  }

  const handleUncheckAll = () => {
    setCheckedStates(current => {
      const states = current.map(() => false)
      const newResponses: any = props.responses

      props.options?.forEach((o, valueIndex) => {
        newResponses[props.questionIndex] = (!newResponses[props.questionIndex] ? newResponses[props.questionIndex] = [] : newResponses[props.questionIndex])
        newResponses[props.questionIndex][valueIndex] = (states[valueIndex] ? o.value : '')
      })

      props.setResponses(newResponses)

      return states
    })
  }

  useEffect(() => {
    setCheckedStates(props.options ? props.options.map(() => false) : [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment key={uuid()}>
      <label className='form-label' htmlFor={props.id}>{props.prompt}</label>
      <div className="my-3">
        <ButtonGroup size="sm">
          <Button
            variant='primary'
            onClick={handleCheckAll}>
              {props.language === 'en' ? 'Check all' : 'Marcar todos'}
          </Button>
          <Button
            variant='primary'
            onClick={handleUncheckAll}>
              {props.language === 'en' ? 'Uncheck all' : 'Desmarcar todos'}
          </Button>
        </ButtonGroup>
      </div>
      <div key={props.id} className='text-left'>
        {
          props.options?.map((o: {label: string, value: string}, index: number) => (
            <Form.Check 
              key={uuid()}
              id={`${props.id}-${index}`} 
              name={props.id}
              label={o.label}
              onChange={() => handleCheckboxChange(o.value, index)}
              checked={checkedStates[index]} />
          ))
        }
      </div>
    </React.Fragment>
  )
}

export default MultipleChoice