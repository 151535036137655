import React, { useState, useContext, useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { FirebaseContext } from '../../Firebase'
import { IconButton, ICONS, Loading } from '../../Layout'

import * as ROUTES from '../../../constants/routes'

function TextOptions() {
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState([] as any)
    const [newTitle, setNewTitle] = useState('')
    const [newParagraph, setNewParagraph] = useState('')
    const [newImage, setNewImage] = useState('')
    const [newLink, setNewLink] = useState('')
    const [newPopup, setNewPopup] = useState('')
    const [playSong, setPlaySong] = useState(false)
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true)
    const match = useRouteMatch<{experimentId: string, instrumentId: string}>(ROUTES.RESEARCHER_EXPERIMENTS_INSTRUMENTS_DETAILS)
    const firebase = useContext(FirebaseContext)
    const history = useHistory()
    const addParagraphButtonDisabled = !newParagraph
    const addTitleButtonDisabled = !newTitle
    const addLinkButtonDisabled = !newLink || !newPopup

    const handleRemoveOption = (index: number) => {
        setOptions(options.filter((q: any, i: number) => i !== index))
        setSaveButtonDisabled(false)
    }

    const handleAddParagraph = () => {
        if (options) {
            setOptions(
                [...options, {text: newParagraph}]
            )
        } else {
            setOptions([{text: newParagraph}])
        }

        setNewParagraph('')
        setSaveButtonDisabled(false)
    }

    const handleAddTitle = () => {
        if (options) {
            setOptions([...options, {title: newTitle}])
        } else {
            setOptions([{title: newTitle}])
        }

        setNewTitle('')
        setSaveButtonDisabled(false)
    }

    const handleAddLink = () => {
        if (options) {
            setOptions([...options, {link: newLink, popup: newPopup}])
        } else {
            setOptions([{link: newLink, popup: newPopup}])
        }

        setNewLink('')
        setNewPopup('')
        setSaveButtonDisabled(false)
    }

    const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (options) {
            setOptions([...options.filter((o: any) => !('img' in o)), {img: e.currentTarget.value}])
        } else {
            setOptions([{img: e.currentTarget.value}])
        }

        setNewImage(e.currentTarget.value)
        setSaveButtonDisabled(false)
    }

    const handleChangePlaySong = () => {
        setPlaySong(playSong => {
            if (options) {
                setOptions([...options.filter((o: any) => !('playSong' in o)), {playSong: !playSong}])
            } else {
                setOptions([{playSong: !playSong}])
            }
    
            setSaveButtonDisabled(false)

            return !playSong
        })
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        setLoading(true)

        firebase?.instrument(match?.params.experimentId as string, match?.params.instrumentId as string).child('options')
            .set(options).then(() => {
                setSaveButtonDisabled(true)
                history.push(`/pesquisador/experimentos/${match?.params.experimentId}`)
            })
    }

    useEffect(() => {
        firebase?.instrument(match?.params.experimentId as string, match?.params.instrumentId as string).on('value', snapshot => {
            setOptions(snapshot.val().options)
        })

        return () => {
            firebase?.instrument(
                match?.params.experimentId as string, 
                match?.params.instrumentId as string
            ).off()}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        options?.filter((o: any) => ('img' in o)).map(
            (o: {img: string}) => {
                setNewImage(o.img)
            }
        )

        options?.filter((o: any) => ('playSong' in o)).map(
            (o: {playSong: boolean}) => {
                setPlaySong(o.playSong)
            }
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options])

    return (
        loading ? <Loading/> :
        <>
            <h2>Editar Texto</h2>
            <Table striped bordered hover responsive className='my-3'>
                <thead>
                    <tr>
                        <th>Conteúdo</th>
                        <th>Tipo</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        options?.filter((o: any) => !('img' in o) && !('playSong' in o)).map(
                            (o: {title?: string, text?: string, link?: string, popup?: string}, i: number) => (
                            <tr key={uuid()}>
                                <td>{o.text || o.title || `${o.link} [${o.popup}]`}</td>
                                <td>
                                    {o.text && 'Texto'}
                                    {o.title && 'Título'}
                                    {o.link && 'Link'}
                                </td>
                                <td className='text-center'>
                                    <IconButton 
                                        icon={ICONS.DELETE} 
                                        variant='danger' 
                                        size='sm'
                                        onClick={() => handleRemoveOption(i)}/>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
            
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Form.Text>
                            <h3>Adicionar conteúdo</h3>
                        </Form.Text>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Título</Form.Label>
                            <InputGroup>
                                <Form.Control 
                                    as='textarea'
                                    value={newTitle}
                                    onChange={e => setNewTitle(
                                        e.target.value
                                    )}/>

                                <InputGroup.Append>
                                    <IconButton 
                                        icon={ICONS.PLUS} 
                                        variant='success'
                                        onClick={handleAddTitle}
                                        disabled={addTitleButtonDisabled}/>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Parágrafo</Form.Label>
                            <InputGroup>
                                <Form.Control 
                                    as='textarea'
                                    value={newParagraph}
                                    onChange={e => setNewParagraph(
                                        e.target.value
                                    )}/>

                                <InputGroup.Append>
                                    <IconButton 
                                        icon={ICONS.PLUS} 
                                        variant='success'
                                        onClick={handleAddParagraph}
                                        disabled={addParagraphButtonDisabled}/>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Link</Form.Label>
                            <InputGroup>
                                <Form.Control 
                                    as='textarea'
                                    value={newLink}
                                    onChange={e => setNewLink(
                                        e.target.value
                                    )}/>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Popup</Form.Label>
                            <InputGroup>
                                <Form.Control 
                                    as='textarea'
                                    value={newPopup}
                                    onChange={e => setNewPopup(
                                        e.target.value
                                    )}/>

                                <InputGroup.Append>
                                    <IconButton 
                                        icon={ICONS.PLUS} 
                                        variant='success'
                                        onClick={handleAddLink}
                                        disabled={addLinkButtonDisabled}/>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Text>
                            <h3>Imagem de cabeçalho</h3>
                        </Form.Text>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Endereço da imagem</Form.Label>
                            <Form.Control
                                type='text'
                                value={newImage}
                                onChange={handleChangeImage}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId={uuid()}>
                            <Form.Check
                                label='Tocar música'
                                checked={playSong}
                                onChange={handleChangePlaySong}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <IconButton
                            icon={ICONS.BACK}
                            onClick={() => {history.goBack()}}>
                            
                            Voltar
                        </IconButton>
                        <IconButton 
                            type='submit' 
                            icon={ICONS.SAVE}
                            className='ml-1'
                            disabled={saveButtonDisabled}
                            variant={!saveButtonDisabled ? 'warning' : undefined}>
                            
                            Salvar
                        </IconButton>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default TextOptions