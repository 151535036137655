import React, { useContext, useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { FirebaseContext } from '../../Firebase'
import { Loading } from '../../Layout'
import SurveyOptions from '../SurveyOptions'
import TCLEOptions from '../TCLEOptions'
import InstructionPageOptions from '../InstructionPageOptions'
import TextOptions from '../TextOptions'
import SongSelectorOptions from '../SongSelectorOptions'
import DRMOptions from '../DRMOptions'
import ReadingLoggerOptions from '../ReadingLoggerOptions'
import LetComOptions from '../LetComOptions'
import LetComEnglishOptions from '../LetComEnglishOptions'
import PatComOptions from '../PatComOptions'
import PatComEnglishOptions from '../PatComEnglishOptions'
import WordByWordOptions from '../WordByWordOptions'
import PrimingTaskOptions from '../PrimingTaskOptions'
import LexicalDecisionOptions from '../LexicalDecisionOptions'

import * as ROUTES from '../../../constants/routes'
import { INSTRUMENTS } from '../../../constants/instruments'

function InstrumentOptions() {
    const [instrument, setInstrument] = useState([] as any)
    const [loading, setLoading] = useState(true)
    const match = useRouteMatch<{experimentId: string, instrumentId: string}>(ROUTES.RESEARCHER_EXPERIMENTS_INSTRUMENTS_DETAILS)
    const firebase = useContext(FirebaseContext)

    useEffect(() => {
        firebase?.instrument(
            match?.params.experimentId as string, 
            match?.params.instrumentId as string
        ).on('value', snapshot => {
            setInstrument(snapshot.val())
        })

        return () => {
            firebase?.instrument(
                match?.params.experimentId as string, 
                match?.params.instrumentId as string
            ).off()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setLoading(false)
    }, [instrument])

    return (
        loading ? <Loading/> :
        instrument.type === INSTRUMENTS.SURVEY ?
            <SurveyOptions/> :
        instrument.type === INSTRUMENTS.TCLE ?
            <TCLEOptions/> :
        instrument.type === INSTRUMENTS.INSTRUCTIONPAGE ?
            <InstructionPageOptions/> :
        instrument.type === INSTRUMENTS.TEXT ?
            <TextOptions/> :
        instrument.type === INSTRUMENTS.SONGSELECTOR ?
            <SongSelectorOptions/> :
        instrument.type === INSTRUMENTS.DRM ?
            <DRMOptions/> :
        instrument.type === INSTRUMENTS.READINGLOGGER ?
            <ReadingLoggerOptions/> :
        instrument.type === INSTRUMENTS.PRIMINGTASK ?
            <PrimingTaskOptions/> :
        instrument.type === INSTRUMENTS.LETCOM ?
            <LetComOptions/> :
        instrument.type === INSTRUMENTS.LETCOMEnglish ?
            <LetComEnglishOptions/> :
        instrument.type === INSTRUMENTS.PATCOM ?
            <PatComOptions/> :
        instrument.type === INSTRUMENTS.PATCOMEnglish ?
            <PatComEnglishOptions/> :
        instrument.type === INSTRUMENTS.WORDBYWORD ?
            <WordByWordOptions/> :
        instrument.type === INSTRUMENTS.LEXICALDECISION ?
            <LexicalDecisionOptions/> :
        null
    )
}

export default InstrumentOptions