import React, { useState, useContext, useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { FirebaseContext } from '../../Firebase'
import { IconButton, ICONS, Loading } from '../../Layout'

import * as ROUTES from '../../../constants/routes'

function TCLEOptions() {
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState([] as any)
    const [agreeText, setAgreeText] = useState('')
    const [disagreeText, setDisagreeText] = useState('')
    const [newOptionTitle, setNewOptionTitle] = useState('')
    const [newOptionText, setNewOptionText] = useState('')
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true)
    const match = useRouteMatch<{experimentId: string, instrumentId: string}>(ROUTES.RESEARCHER_EXPERIMENTS_INSTRUMENTS_DETAILS)
    const firebase = useContext(FirebaseContext)
    const history = useHistory()
    const addButtonDisabled = !newOptionText

    const handleRemoveOption = (index: number) => {
        setOptions(options.filter((q: any, i: number) => i !== index))
        setSaveButtonDisabled(false)
    }

    const handleAddOption = () => {
        if (options) {
            setOptions(
                [...options, {title: newOptionTitle, text: newOptionText}]
            )
        } else {
            setOptions([{title: newOptionTitle, text: newOptionText}])
        }

        setNewOptionTitle('')
        setNewOptionText('')
        setSaveButtonDisabled(false)
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        setLoading(true)

        firebase?.instrument(match?.params.experimentId as string, match?.params.instrumentId as string).child('options')
            .set(options).then(() => {
                setSaveButtonDisabled(true)
                history.push(`/pesquisador/experimentos/${match?.params.experimentId}`)
            })
    }

    const handleChangeAgreeText = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSaveButtonDisabled(false)
        setAgreeText(e.currentTarget.value)
        setOptions(
            options ?
            [
                ...options.filter((o: any) => !('agree' in o)), 
                {agree: e.currentTarget.value, disagree: disagreeText}
            ] :
            [{agree: e.currentTarget.value, disagree: disagreeText}]
        )
    }

    const handleChangeDisagreeText = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSaveButtonDisabled(false)
        setDisagreeText(e.currentTarget.value)
        setOptions(
            [
                ...options.filter((o: any) => !('agree' in o)), 
                {agree: agreeText, disagree: e.currentTarget.value}
            ]
        )
    }

    useEffect(() => {
        firebase?.instrument(match?.params.experimentId as string, match?.params.instrumentId as string).on('value', snapshot => {
            setOptions(snapshot.val().options)
        })

        return () => {
            firebase?.instrument(
                match?.params.experimentId as string, 
                match?.params.instrumentId as string
            ).off()}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        options?.filter((o: any) => ('agree' in o)).map(
            (o: {agree: string, disagree: string}) => {
                setAgreeText(o.agree)
                setDisagreeText(o.disagree)
            }
        )
    }, [options])

    return (
        loading ? <Loading/> :
        <>
            <h2>Editar TCLE</h2>
            <Table striped bordered hover responsive className='my-3'>
                <thead>
                    <tr>
                        <th>Título da seção</th>
                        <th>Texto</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        options?.filter((o: any) => ('title' in o)).map(
                            (o: {title: string, text: string}, i: number) => (
                            <tr key={uuid()}>
                                <td>{o.title}</td>
                                <td>{o.text}</td>
                                <td className='text-center'>
                                    <IconButton 
                                        icon={ICONS.DELETE} 
                                        variant='danger' 
                                        size='sm'
                                        onClick={() => handleRemoveOption(i)}/>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
            
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Form.Text>
                            <h3>Adicionar seção</h3>
                        </Form.Text>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Título</Form.Label>
                            <Form.Control 
                                type='text' 
                                value={newOptionTitle}
                                onChange={e => setNewOptionTitle(e.target.value)}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Texto</Form.Label>
                            <InputGroup>
                                <Form.Control 
                                    type='text'
                                    value={newOptionText}
                                    onChange={e => setNewOptionText(
                                        e.target.value
                                    )}/>

                                <InputGroup.Append>
                                    <IconButton 
                                        icon={ICONS.PLUS} 
                                        variant='success'
                                        onClick={handleAddOption}
                                        disabled={addButtonDisabled}/>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Text>
                            <h3>Declaração</h3>
                        </Form.Text>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Texto de concordância</Form.Label>
                            <Form.Control
                                type='text'
                                value={agreeText}
                                onChange={handleChangeAgreeText}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Texto de discordância</Form.Label>
                            <Form.Control
                                type='text'
                                value={disagreeText}
                                onChange={handleChangeDisagreeText}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <IconButton
                            icon={ICONS.BACK}
                            onClick={() => {history.goBack()}}>
                            
                            Voltar
                        </IconButton>
                        <IconButton 
                            type='submit' 
                            icon={ICONS.SAVE}
                            className='ml-1'
                            disabled={saveButtonDisabled}
                            variant={!saveButtonDisabled ? 'warning' : undefined}>
                            
                            Salvar
                        </IconButton>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default TCLEOptions