import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { Message } from '../Layout'

import logo from './logo.png'

function Landing() {
    return (
        <Container className='my-5 text-center'>
            <Row>
                <Col>
                    <img src={logo} alt='Logo do Lapsi' className='mb-3'/>
                    <h1 className='mb-3'>Laboratório de Psicolinguística na Web</h1>
                </Col>
            </Row>
            <Row>
                <Col xs={{span: 8, offset: 2}}>
                    <p className='text-justify'>
                        O projeto Lapsi objetiva oferecer a pesquisadores um 
                        laboratório digital para experimentos na área de 
                        Psicolinguística ou outras áreas de investigação de
                        fenômenos relacionados a língua e cognição humanas.
                    </p>
                    <Message 
                        warning='O Projeto Lapsi encontra-se em desenvolvimento (versão alfa), 
                                podendo ser usado em pesquisas reais apenas com acompanhamento
                                do desenvolvedor da plataforma. 
                                O uso indevido da plataforma é de responsabilidade do usuário.'/>
                </Col>
            </Row>
        </Container>
    )
}

export default Landing