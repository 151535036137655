import React, { useContext, useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { FirebaseContext } from '../../Firebase'
import { IconButton, ICONS, Loading } from '../../Layout'

import * as ROUTES from '../../../constants/routes'
import { FIELDS } from '../../../constants/instruments'

function SurveyOptions() {
    const [questions, setQuestions] = useState<any>([])
    const [newQuestion, setNewQuestion] = useState('')
    const [newQuestionType, setNewQuestionType] = useState('')
    const [newLikertMin, setNewLikertMin] = useState(0)
    const [newLikertMinLabel, setNewLikertMinLabel] = useState('')
    const [newLikertMax, setNewLikertMax] = useState(6)
    const [newLikertMaxLabel, setNewLikertMaxLabel] = useState('')
    const [newRadioOption, setNewRadioOption] = useState('')
    const [newRadioOptions, setNewRadioOptions] = useState<string[]>([])
    const [newRadioOptionList, setNewRadioOptionList] = useState<string[]>([])
    const [newCheckboxOption, setNewCheckboxOption] = useState('')
    const [newCheckboxOptions, setNewCheckboxOptions] = useState<string[]>([])
    const [newCheckboxOptionList, setNewCheckboxOptionList] = useState<string[]>([])
    const [newToggleButtonOption, setNewToggleButtonOption] = useState('')
    const [newToggleButtonOptions, setNewToggleButtonOptions] = useState<string[]>([])
    const [newToggleButtonOptionList, setNewToggleButtonOptionList] = useState<string[]>([])
    const [newFillInPre, setNewFillInPre] = useState('')
    const [newFillInPost, setNewFillInPost] = useState('')
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true)
    const [instrumentName, setInstrumentName] = useState('')
    const [timer, setTimer] = useState(0)
    const [loading, setLoading] = useState(false)
    const match = useRouteMatch<{experimentId: string, instrumentId: string}>(ROUTES.RESEARCHER_EXPERIMENTS_INSTRUMENTS_DETAILS)
    const firebase = useContext(FirebaseContext)
    const questionsRef = firebase?.questions(match?.params.experimentId as string, match?.params.instrumentId as string)
    const history = useHistory()
    const addButtonDisabled = (!newQuestion || !newQuestionType) ||
        (newQuestionType === FIELDS.RANGE && (newLikertMinLabel === '' || newLikertMaxLabel === '' || !(newLikertMin < newLikertMax))) ||
        (newQuestionType === FIELDS.RADIO && (newRadioOptionList.length === 0)) ||
        (newQuestionType === FIELDS.MULTIPLE && (newCheckboxOptionList.length === 0)) ||
        (newQuestionType === FIELDS.TOGGLEBUTTONS && (newToggleButtonOptionList.length === 0)) ||
        (newQuestionType === FIELDS.FILLIN && (newFillInPre === '' || newFillInPost === ''))

    const handleAddField = () => {
        let newQuestionObject: {type: string, prompt: React.ReactNode, options?: any}

        switch(newQuestionType) {
            case FIELDS.RANGE:
                newQuestionObject = {
                    type: newQuestionType, 
                    prompt: newQuestion, 
                    options: [
                        {label: newLikertMinLabel, value: newLikertMinLabel}, 
                        {label: newLikertMaxLabel, value: newLikertMaxLabel}, 
                        {label: newLikertMin, value: newLikertMin},
                        {label: newLikertMax, value: newLikertMax}
                    ]
                }
                break
            case FIELDS.RADIO:
                newQuestionObject = {
                    type: newQuestionType,
                    prompt: newQuestion,
                    options: newRadioOptionList.map((o: string) => ({label: o, value: o}))
                }
                break
            case FIELDS.MULTIPLE:
                newQuestionObject = {
                    type: newQuestionType,
                    prompt: newQuestion,
                    options: newCheckboxOptionList.map((o: string) => ({label: o, value: o}))
                }
                break
            case FIELDS.TOGGLEBUTTONS:
                newQuestionObject = {
                    type: newQuestionType,
                    prompt: newQuestion,
                    options: newToggleButtonOptionList.map((o: string) => ({label: o, value: o}))
                }
                break
            case FIELDS.FILLIN:
                newQuestionObject = {
                    type: newQuestionType,
                    prompt: newQuestion,
                    options: [
                        {label: newFillInPre},
                        {label: newFillInPost}
                    ]
                }
                break
            default:
                newQuestionObject = {type: newQuestionType, prompt: newQuestion}
        }

        if (questions) {
            setQuestions([...questions, newQuestionObject])
        } else {
            setQuestions([newQuestionObject])
        }

        setNewQuestion('')
        setNewQuestionType('')
        setNewRadioOptionList([])
        setNewCheckboxOptionList([])
        setNewToggleButtonOptionList([])
        setSaveButtonDisabled(false)
    }

    const handleRemoveField = (index: number) => {
        setQuestions(questions.filter((q: any, i: number) => i !== index))
        setSaveButtonDisabled(false)
    }

    const handleChangeTimer = (e: React.ChangeEvent<any>) => {
        firebase?.instrument(
            match?.params.experimentId as string, 
            match?.params.instrumentId as string
        ).child('timer').set(e.target.value)
        
        setTimer(e.target.value)
    }

    const handleChangeInstrumentName = (e: React.ChangeEvent<any>) => {
        firebase?.instrument(
            match?.params.experimentId as string, 
            match?.params.instrumentId as string
        ).child('name').set(e.target.value)

        setInstrumentName(e.target.value)
    }

    const handleSave = () => {
        setLoading(true)

        questionsRef?.set(questions).then(() => {
                setSaveButtonDisabled(true)
                setLoading(false)
            })
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        setLoading(true)

        questionsRef?.set(questions).then(() => {
                setSaveButtonDisabled(true)
                history.push(`/pesquisador/experimentos/${match?.params.experimentId}`)
            })
    }

    const handleShiftUp = (index: number) => {
        const reversedQuestions = questions.slice(index - 1, index + 1).reverse()
        const updatedQuestionsList = questions.filter((i: any, iIndex: number) => iIndex !== index - 1 && iIndex !== index)
        
        updatedQuestionsList?.splice(index - 1, 0, ...(reversedQuestions || []))
        
        questionsRef?.set(updatedQuestionsList).then(() => {
            setQuestions(updatedQuestionsList)
        })
    }

    const handleShiftDown = (index: number) => {
        const reversedQuestions = questions.slice(index, index + 2).reverse()
        const updatedQuestionsList = questions.filter((i: any, iIndex: number) => iIndex !== index && iIndex !== index + 1)
        
        updatedQuestionsList?.splice(index, 0, ...(reversedQuestions || []))
        
        questionsRef?.set(updatedQuestionsList).then(() => {
            setQuestions(updatedQuestionsList)
        })
    }

    useEffect(() => {
        firebase?.instrument(
            match?.params.experimentId as string, 
            match?.params.instrumentId as string
        ).on('value', snapshot => {
            if (snapshot.val().timer) {
                setTimer(snapshot.val().timer)
            }

            if (snapshot.val().name) {
                setInstrumentName(snapshot.val().name)
            }
        })

        questionsRef?.on('value', snapshot => {
            setQuestions(snapshot.val())
        })

        return () => {questionsRef?.off()}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        loading ? <Loading/> :
        <>
            <h2>Editar questionário</h2>
            <Row>
                <Col xs={6}>
                    <Form.Group>
                        <Form.Label>Nome do questionário</Form.Label>
                        <Form.Control 
                            value={instrumentName}
                            onChange={handleChangeInstrumentName}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <Form.Group>
                        <Form.Label>Timer (ms)</Form.Label>
                        <Form.Control 
                            type='number'
                            min={0}
                            step={1000}
                            value={timer}
                            onChange={e => handleChangeTimer(e)}/>
                    </Form.Group>
                </Col>
            </Row>
            <Table striped bordered hover responsive className='my-3'>
                <thead>
                    <tr>
                        <th>Ordem</th>
                        <th>Questão</th>
                        <th>Tipo</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        questions?.map((q: any, i: number, a: any[]) => (
                            <tr key={uuid()}>
                                <td  style={{minWidth: '130px'}}>
                                    <ButtonGroup className='mr-1'>
                                        <IconButton 
                                            className='hide-tip'
                                            disabled={i === 0}
                                            icon={ICONS.UP} 
                                            size='sm' 
                                            variant='outline-info'
                                            onClick={() => handleShiftUp(i)}/>
                                        <IconButton 
                                            className='hide-tip'
                                            disabled={i === a.length - 1}
                                            icon={ICONS.DOWN} 
                                            size='sm' 
                                            variant='outline-info'
                                            onClick={() => handleShiftDown(i)}/>
                                    </ButtonGroup>
                                    {i + 1}º
                                </td>
                                <td>
                                    {q.prompt}
                                    {q.options && ` [${q.options.map((o: {label: string, value: string}) => o.label)}]`}
                                </td>
                                <td>{q.type}</td>
                                <td className='text-center'>
                                    <IconButton 
                                        icon={ICONS.DELETE} 
                                        variant='danger' 
                                        size='sm'
                                        onClick={() => handleRemoveField(i)}/>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
            <h3>Adicionar questão</h3>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Tipo</Form.Label>
                            <InputGroup>
                                <Form.Control 
                                    as='select'
                                    value={newQuestionType}
                                    onChange={e => setNewQuestionType(e.target.value)}>

                                    <option></option>
                                    {Object.values(FIELDS).map(f => (
                                        <option key={uuid()} value={f}>{f}</option>
                                    ))}
                                </Form.Control>
                                <InputGroup.Append>
                                    <IconButton 
                                        icon={ICONS.PLUS} 
                                        variant='success'
                                        onClick={handleAddField}
                                        disabled={addButtonDisabled}/>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
                {
                    newQuestionType &&
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Enunciado</Form.Label>
                                <Form.Control 
                                    type='text' 
                                    value={newQuestion}
                                    onChange={e => setNewQuestion(e.target.value)}/>
                            </Form.Group>
                        </Col>
                    </Row>
                }
                {
                    newQuestionType === FIELDS.RANGE &&
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Mínimo</Form.Label>
                                <Form.Control
                                    type='number'
                                    min={0}
                                    max={9}
                                    value={newLikertMin}
                                    onChange={e => setNewLikertMin(parseInt(e.target.value))}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Legenda</Form.Label>
                                <Form.Control
                                    type='text'
                                    value={newLikertMinLabel}
                                    onChange={e => setNewLikertMinLabel(e.target.value)}/>
                            </Form.Group>                        
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Máximo</Form.Label>
                                <Form.Control
                                    type='number'
                                    min={1}
                                    max={10}
                                    value={newLikertMax}
                                    onChange={e => setNewLikertMax(parseInt(e.target.value))}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Legenda</Form.Label>
                                <Form.Control
                                    type='text'
                                    value={newLikertMaxLabel}
                                    onChange={e => setNewLikertMaxLabel(e.target.value)}/>
                            </Form.Group> 
                        </Col>
                    </Row>
                }
                {
                    newQuestionType === FIELDS.RADIO &&
                    <>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Adicionar opção</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type='text'
                                            value={newRadioOption}
                                            onChange={(e) => setNewRadioOption(e.target.value)}/>
                                        <InputGroup.Append>
                                            <IconButton 
                                                icon={ICONS.PLUS} 
                                                variant='primary'
                                                onClick={() => {
                                                    setNewRadioOptionList(ol => [...ol, newRadioOption])
                                                    setNewRadioOption('')
                                                }}/>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Adicionar múltiplas opções (separar por vírgula)</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type='text'
                                            value={newRadioOptions.join(',')}
                                            onChange={(e) => setNewRadioOptions(e.target.value.split(','))}/>
                                        <InputGroup.Append>
                                            <IconButton 
                                                icon={ICONS.PLUS} 
                                                variant='primary'
                                                onClick={() => {
                                                    setNewRadioOptionList(ol => [...ol, ...newRadioOptions])
                                                    setNewRadioOptions([])
                                                }}/>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                    Opções: {newRadioOptionList.join(', ')}
                                    <IconButton 
                                        icon={ICONS.MINUS} 
                                        variant='danger' 
                                        size='sm'
                                        className='ml-1'
                                        disabled={newRadioOptionList.length === 0}
                                        onClick={() => setNewRadioOptionList(ol => [...ol].slice(0, ol.length - 1))} />
                                </p>
                            </Col>
                        </Row>
                    </>
                }
                {
                    newQuestionType === FIELDS.MULTIPLE &&
                    <>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Adicionar opção</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type='text'
                                            value={newCheckboxOption}
                                            onChange={(e) => setNewCheckboxOption(e.target.value)}/>
                                        <InputGroup.Append>
                                            <IconButton 
                                                icon={ICONS.PLUS} 
                                                variant='primary'
                                                onClick={() => {
                                                    setNewCheckboxOptionList(ol => [...ol, newCheckboxOption])
                                                    setNewCheckboxOption('')
                                                }}/>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Adicionar múltiplas opções (separar por vírgula)</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type='text'
                                            value={newCheckboxOptions.join(',')}
                                            onChange={(e) => setNewCheckboxOptions(e.target.value.split(','))}/>
                                        <InputGroup.Append>
                                            <IconButton 
                                                icon={ICONS.PLUS} 
                                                variant='primary'
                                                onClick={() => {
                                                    setNewCheckboxOptionList(ol => [...ol, ...newCheckboxOptions])
                                                    setNewCheckboxOptions([])
                                                }}/>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                    Opções: {newCheckboxOptionList.join(', ')}
                                    <IconButton 
                                        icon={ICONS.MINUS} 
                                        variant='danger' 
                                        size='sm'
                                        className='ml-1'
                                        disabled={newCheckboxOptionList.length === 0}
                                        onClick={() => setNewCheckboxOptionList(ol => [...ol].slice(0, ol.length - 1))} />
                                </p>
                            </Col>
                        </Row>
                    </>
                }
                {
                    newQuestionType === FIELDS.TOGGLEBUTTONS &&
                    <>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Adicionar opção</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type='text'
                                            value={newToggleButtonOption}
                                            onChange={(e) => setNewToggleButtonOption(e.target.value)}/>
                                        <InputGroup.Append>
                                            <IconButton 
                                                icon={ICONS.PLUS} 
                                                variant='primary'
                                                onClick={() => {
                                                    setNewToggleButtonOptionList(ol => [...ol, newToggleButtonOption])
                                                    setNewToggleButtonOption('')
                                                }}/>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Adicionar múltiplas opções (separar por vírgula)</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type='text'
                                            value={newToggleButtonOptions.join(',')}
                                            onChange={(e) => setNewToggleButtonOptions(e.target.value.split(','))}/>
                                        <InputGroup.Append>
                                            <IconButton 
                                                icon={ICONS.PLUS} 
                                                variant='primary'
                                                onClick={() => {
                                                    setNewToggleButtonOptionList(ol => [...ol, ...newToggleButtonOptions])
                                                    setNewToggleButtonOptions([])
                                                }}/>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                    Opções: {newToggleButtonOptionList.join(', ')}
                                    <IconButton 
                                        icon={ICONS.MINUS} 
                                        variant='danger' 
                                        size='sm'
                                        className='ml-1'
                                        disabled={newToggleButtonOptionList.length === 0}
                                        onClick={() => setNewToggleButtonOptionList(ol => [...ol].slice(0, ol.length - 1))} />
                                </p>
                            </Col>
                        </Row>
                    </>
                }
                {
                    newQuestionType === FIELDS.FILLIN &&
                    <>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Texto antes da lacuna</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type='text'
                                            value={newFillInPre}
                                            onChange={(e) => setNewFillInPre(e.target.value)}/>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Texto depois da lacuna</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type='text'
                                            value={newFillInPost}
                                            onChange={(e) => setNewFillInPost(e.target.value)}/>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                    </>
                }
                <Row>
                    <Col>
                        <IconButton
                            icon={ICONS.BACK}
                            onClick={() => {history.goBack()}}>
                            
                            Voltar
                        </IconButton>
                        <IconButton 
                            icon={ICONS.SAVE}
                            className='ml-1'
                            disabled={saveButtonDisabled}
                            variant={!saveButtonDisabled ? 'warning' : undefined}
                            onClick={handleSave}>
                            
                            Salvar
                        </IconButton>
                        <IconButton 
                            type='submit' 
                            icon={ICONS.SAVE}
                            className='ml-1'
                            disabled={saveButtonDisabled}
                            variant={!saveButtonDisabled ? 'warning' : undefined}>
                            
                            Salvar e voltar
                        </IconButton>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default SurveyOptions