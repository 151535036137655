import React, { useState, useEffect, useRef, ChangeEvent } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
// import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Modal from 'react-bootstrap/Modal'
import Plotly from 'plotly.js-cartesian-dist'
import createPlotlyComponent from 'react-plotly.js/factory'
import { v4 as uuid } from 'uuid'
import { 
    Editor,
    ContentBlock, 
    EditorState,
    DraftStyleMap,
    convertFromRaw
} from 'draft-js'
import {
    BsClockFill,
    BsShiftFill,
    BsBackspaceFill,
    BsBackspaceReverseFill,
    BsArrowUp,
    BsArrowRight,
    BsArrowDown,
    BsArrowLeft,
    BsArrowReturnLeft,
    BsBoxArrowRight,
    BsBoxArrowInRight,
    BsMouse2,
    BsCursor
} from 'react-icons/bs'
import { 
    mean as getMean, 
    sampleStandardDeviation, 
    median as getMedian, 
    interquartileRange 
} from 'simple-statistics'

import DataType from '../../../../types/DataType'
import WritingLogType from '../../../../types/WritingLogType'
import { IconButton, ICONS, Loading} from '../../../Layout'

import { INSTRUMENTS } from '../../../../constants/instruments'

import '../index.css'

function WritingLoggerOutput(props: {
    id?: string,
    show: boolean,
    data?: DataType[], 
    next?: (finish?: boolean) => void,
    hideButton?: boolean
}) {
    const outputOptions = {
        PRODUCT: 'Produto',
        TIMELINE: 'Linha do tempo',
        // SIMPLE: 'Simples'
    }
    const [finish, setFinish] = useState(false)
    const [pageWidth, setPageWidth] = useState(0)
    const [pageHeight, setPageHeight] = useState(0)
    const [output, setOutput] = useState<React.ReactNode>(null)
    const [outputType, setOutputType] = useState(outputOptions.PRODUCT)
    const [pauseDuration, setPauseDuration] = useState(2000)
    const [process, setProcess] = useState<WritingLogType[]>([])
    const [mean, setMean] = useState(0)
    const [standardError, setStandardError] = useState(0)
    const [median, setMedian] = useState(0)
    //const [streakCount, setStreakCount] = useState<number>()
    const [expandPlot, setExpandPlot] = useState(false)
    const [expandedPlot, setExpandedPlot] = useState<React.ReactNode>(null)
    const [customData, setCustomData] = useState<DataType[]>()
    const [loadData, setLoadData] = useState(false)
    const [loading, setLoading] = useState(false)
    const container = useRef<HTMLDivElement | null>(null)
    const editor = useRef<Editor>({} as Editor)
    // const leftBracket = useRef<string[]>([])
    // const rightBracket = useRef<string[]>([])
    const fontSize = 12
    const styleMap: DraftStyleMap = {
        'NORMAL': {
            textTransform: 'none'
        },
        'UPPERCASE': {
            textTransform: 'uppercase'
        },
        'LOWERCASE': {
            textTransform: 'lowercase'
        },
        'CAPITALIZE': {
            textTransform: 'capitalize'
        },
        'FONT-8': {
            fontSize: `${pageWidth * 8 * .00168095238}px`
        },
        'FONT-9': {
            fontSize: `${pageWidth * 9 * .00168095238}px`
        },
        'FONT-10': {
            fontSize: `${pageWidth * 10 * .00168095238}px`
        },
        'FONT-11': {
            fontSize: `${pageWidth * 11 * .00168095238}px`
        },
        'FONT-12': {
            fontSize: `${pageWidth * 12 * .00168095238}px`
        },
        'FONT-14': {
            fontSize: `${pageWidth * 14 * .00168095238}px`
        },
        'FONT-16': {
            fontSize: `${pageWidth * 16 * .00168095238}px`
        },
        'FONT-18': {
            fontSize: `${pageWidth * 18 * .00168095238}px`
        },
        'FONT-20': {
            fontSize: `${pageWidth * 20 * .00168095238}px`
        },
        'FONT-22': {
            fontSize: `${pageWidth * 22 * .00168095238}px`
        },
        'FONT-24': {
            fontSize: `${pageWidth * 24 * .00168095238}px`
        },
        'FONT-26': {
            fontSize: `${pageWidth * 26 * .00168095238}px`
        },
        'FONT-28': {
            fontSize: `${pageWidth * 28 * .00168095238}px`
        }
    }
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
    // const [showPauses, setShowPauses] = useState(true)
    // const [showNumbers, setShowNumbers] = useState(true)
    // const [showBrackets, setShowBrackets] = useState(true)
    // const [pausesInToken, setPausesInToken] = useState(0)
    // const [pausesBetweenTokens, setPausesBetweenTokens] = useState(0)
    // const [pausesBetweenSentences, setPausesBetweenSentences] = useState(0)
    // const [pausesBetweenParagraphs, setPausesBetweenParagraphs] = useState(0)

    const handleResize = () => {
        const current: any = container.current

        if (current) {
            setPageWidth(current.offsetWidth)
            setPageHeight(current.offsetWidth * Math.SQRT2)
        }
    }

    const onChange = (editorState: EditorState) => {
        return setEditorState(editorState)
    }

    const myBlockStyle = (contentBlock: ContentBlock) => {
        const align = contentBlock.getData().get('align')

        switch (align)  {
            case 'left':
                return 'text-left'
            case 'center':
                return 'text-center'
            case 'right':
                return 'text-right'
            case 'justify':
                return 'text-justify'
        }

        return ''
    }

    const handleLoadData = (e: ChangeEvent<HTMLInputElement>) => {
        const fileReader = new FileReader()

        if (e.target.files && e.target.files[0]) {
            fileReader.readAsText(e.target.files[0], 'UTF-8')

            setLoading(true)

            fileReader.onload = (e) => {
                const jsonData = JSON.parse(e?.target?.result as string)
                
                setCustomData(jsonData)
                setLoadData(false)
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show])

    useEffect(() => {
        if (props.data || customData) {
            let writingLoggerData: DataType[]

            if (props.data) {
                writingLoggerData = props.data.filter(v => v.instrument === INSTRUMENTS.WRITINGLOGGER)
            } else if (customData) {
                writingLoggerData = customData.filter(v => v.instrument === INSTRUMENTS.WRITINGLOGGER)
                console.log(writingLoggerData)
            } else {
                writingLoggerData = []
            }

            const product = writingLoggerData?.filter(d => d.type === 'Product')[0]
            const process = writingLoggerData?.filter(d => d.type === 'Process')[0]
            
            if (product && product.response) {
                onChange(EditorState.createWithContent(convertFromRaw(JSON.parse(product.response))))
            }

            if (process && process.response) {
                let processData = process.response.map((l: WritingLogType, i: number) => ({...l, finalPosition: i}))
                let formattedOutput: React.ReactNode
                
                switch (outputType) {
                    case outputOptions.TIMELINE:
                        // let count = 0
                        // const streaks = processData.map((l: WritingLogType) => {
                        //     const space = [' ', 'Enter', 'Tab']
                        //     let returnValue =  (l.iki >= pauseDuration ? 'PAUSE' : '')

                        //     returnValue += space.includes(l.key) ? ' ' : l.id

                        //     return returnValue
                        // })
                        // .join('|')
                        // .split('PAUSE')
                        // .flatMap((ids: string) => {
                        //     if (/\S+\s\S+\s\S+/.test(ids)) {
                        //         count++

                        //         return  ids.split(/(\s|\|)/).filter(id => !['', '|'].includes(id)).map(id => id.trim())
                        //     }
                        // })

                        // setStreakCount(count)
                    
                        formattedOutput = [...processData].sort((a: WritingLogType, b: WritingLogType) => a.timeIndex - b.timeIndex).map((l: WritingLogType) => (
                            <span key={uuid()} style={{
                                //textDecoration: (streaks.includes(l.id) ? 'underline' : 'none'),
                                textDecorationColor: '#28A744'}}>
                                {
                                    l.iki >= pauseDuration &&
                                    <BsClockFill title={l.iki.toString()} className='text-warning'/>
                                }
                                {
                                    l.key === 'Shift' ?
                                        <BsShiftFill title={l.iki.toString()} className='text-primary'/> :
                                    l.key === 'Backspace' ?
                                        <BsBackspaceFill title={l.iki.toString()} className='text-danger'/> :
                                    l.key === 'Delete' ?
                                        <BsBackspaceReverseFill title={l.iki.toString()} className='text-danger'/> :
                                    l.key === 'ArrowLeft' ?
                                        <BsArrowLeft title={l.iki.toString()} className='text-primary'/> :
                                    l.key === 'ArrowRight' ?
                                        <BsArrowRight title={l.iki.toString()} className='text-primary'/> :
                                    l.key === 'ArrowUp' ?
                                        <BsArrowUp title={l.iki.toString()} className='text-primary'/> :
                                    l.key === 'ArrowDown' ?
                                        <BsArrowDown title={l.iki.toString()} className='text-primary'/> :
                                    l.key === 'FocusOut' ?
                                        <BsBoxArrowRight title={l.iki.toString()} className='text-danger'/> :
                                    l.key === 'FocusIn' ?
                                        <BsBoxArrowInRight title={l.iki.toString()} className='text-success'/> :
                                    l.key === 'MouseMovement' ?
                                        <BsMouse2 title={l.iki.toString()} className='text-primary'/>:
                                    l.key === 'MouseClick' ?
                                        <>
                                            <BsCursor title={l.iki.toString()} className='text-primary'/>
                                            {/* <sub className='text-primary' key={uuid()}>{l.to?.join('|')}</sub> */}
                                        </>
                                        :
                                    l.key === 'Control' || l.key === 'Alt' || l.key === 'AltGraph' || l.key === 'Escape' || 
                                    l.key === 'Home' || l.key === 'End' || l.key === 'PageUp' || l.key === 'PageDown' || 
                                    l.key === 'NumLock' || l.key === 'CapsLock' || l.key === 'Tab' || l.key === 'Insert' ||
                                    l.key === 'F1' || l.key === 'F2' || l.key === 'F3' || l.key === 'F4' || l.key === 'F5' ||
                                    l.key === 'F6' || l.key === 'F7' || l.key === 'F8' || l.key === 'F9' || l.key === 'F10' || 
                                    l.key === 'F11' || l.key === 'F12' ?
                                        <kbd>{l.key}</kbd> :
                                    l.key === 'Enter' ?
                                        <>
                                            <BsArrowReturnLeft title={l.iki.toString()} className='text-primary'/><br/>
                                        </> :
                                    <span title={l.iki.toString()}>{l.key !== 'Dead' && l.key !== 'Unidentified' && l.key}</span>
                                }
                            </span>
                        ))
                        break
                    // case outputOptions.SIMPLE:
                    //     processData.filter((l: WritingLogType) => !l.special)
                    //                 .sort((a: WritingLogType, b: WritingLogType) => a.timeIndex - b.timeIndex)
                    //                 .forEach((l: WritingLogType, i: number, a: WritingLogType[]) => {
                    //                     if (l.added && (!a[i - 1] || !a[i - 1].added)) {
                    //                         leftBracket.current.push(l.id)
                    //                     }

                    //                     if (l.deleted && (!a[i - 1] || !a[i-1].deleted)) {
                    //                         leftBracket.current.push(l.id)
                    //                     }

                    //                     if (l.added && (!a[i + 1] || !a[i + 1].added)) {
                    //                         rightBracket.current.push(l.id)
                    //                     }

                    //                     if (l.deleted && (!a[i + 1] || !a[i + 1].deleted)) {
                    //                         rightBracket.current.push(l.id)
                    //                     }
                    //                 })

                    //     formattedOutput = processData.map((l: WritingLogType, i: number) => {
                    //         return (
                    //             <span key={uuid()}>
                    //                 {
                    //                     showNumbers && l.toLeft && <sub className='text-primary' key={uuid()}>{l.toLeft.join('|')}</sub>
                    //                 }
                    //                 {
                    //                     showNumbers && l.fromLeft && <sup className='text-primary' key={uuid()}>{l.fromLeft.join('|')}</sup>
                    //                 }
                    //                 {
                    //                     showBrackets && leftBracket.current.includes(l.id) && <span>[</span>
                    //                 }
                    //                 {
                    //                     showPauses && l.iki >= pauseDuration &&
                    //                     <BsClockFill title={l.iki.toString()} className='text-warning'/>
                    //                 }
                    //                 {
                    //                     l.key === 'Tab' ?
                    //                         <span style={{whiteSpace: 'pre-wrap'}}>    </span> :
                    //                     l.key === 'Enter'? (
                    //                         !l.deleted ? <br/> : <BsArrowReturnLeft title={l.iki.toString()} className='text-danger'/>
                    //                     ) :
                    //                     <span 
                    //                         title={l.iki.toString()}
                    //                         style={{
                    //                             color: (l.deleted ? '#B00' : (l.added ? '#0B0' : '#212529'))
                    //                         }}>
                    //                             {!l.special && l.key !== 'Dead' && l.key !== 'Unidentified' && l.key}
                    //                     </span>
                    //                 }
                    //                 {
                    //                     showBrackets && rightBracket.current.includes(l.id) && <span>]</span>
                    //                 }
                    //                 {
                    //                     showNumbers && l.to && <sub className='text-primary' key={uuid()}>{l.to.join('|')}</sub>
                    //                 }
                    //                 {
                    //                     showNumbers && l.from && <sup className='text-primary' key={uuid()}>{l.from.join('|')}</sup>
                    //                 }
                    //             </span>
                    //         )
                    //     })
                    //     break
                    default:
                        formattedOutput = null
                }

                setOutput(formattedOutput)
                setProcess(processData)
                console.table(processData)
            }
        } else {
            setLoadData(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show, outputType, pauseDuration, /*showPauses, showNumbers, showBrackets,*/ customData])

    useEffect(() => {
        if (process?.length > 0) {
            setMean(getMean(process?.map(l => l.iki)))
            setStandardError(sampleStandardDeviation(process?.map(l => l.iki))/Math.sqrt(process?.length))
            setMedian(getMedian(process?.map(l => l.iki)))
        }
    }, [process])

    useEffect(() => {
        if (finish) {
            if (props.next) {
                props.next()
            } else {
                throw new Error('"Next" prop is undefined');
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finish])

    const Plot = createPlotlyComponent(Plotly)
    const plot1 = (
        <Plot 
            data={[{
                x: process?.map(l => l.iki),
                type: 'histogram'
            }]}
            layout={{
                title: 'Distribuição das pausas',
                xaxis: {
                    title: 'Duração'
                },
                yaxis: {
                    title: 'Frequência'
                },
                shapes: [
                    {
                        type: 'rect',
                        xref: 'x',
                        yref: 'paper',
                        x0: mean - standardError,
                        x1: mean + standardError,
                        y0: 0,
                        y1: 1,
                        fillcolor: '#DDDDDD88',
                        line: {
                            width: 0
                        }
                    },
                    {
                        type: 'line',
                        xref: 'x',
                        yref: 'paper',
                        x0: mean,
                        x1: mean,
                        y0: 0,
                        y1: 1
                    },
                    {
                        type: 'line',
                        xref: 'x',
                        yref: 'paper',
                        x0: median,
                        x1: median,
                        y0: 0,
                        y1: 1,
                        line: {
                            dash: 'dot'
                        }
                    }
                ],
                annotations: [
                    {
                        yref: 'paper',
                        x: mean - 10,
                        y: .5,
                        text: 'Média',
                        textangle: '-90',
                        showarrow: false
                    },
                    {
                        yref: 'paper',
                        x: median - 10,
                        y: .5,
                        text: 'Mediana',
                        textangle: '-90',
                        showarrow: false
                    }
                ]
            }}
            style={{width: '100%', height: '100%'}}/>
    )

    const shapes: Partial<any>[] = []
    const sortedProcess = [...process].sort((a, b) => a.timeIndex - b.timeIndex)

    sortedProcess.filter(l => l.key === 'FocusOut' || l.key === 'FocusIn').forEach((l, i, a) => {
        if (l.key === 'FocusOut') {
            shapes.push({
                type: 'rect',
                xref: 'x',
                yref: 'paper',
                x0: l.elapsedTime,
                x1: a[i + 1].elapsedTime,
                y0: 0,
                y1: 1,
                fillcolor: '#FF000022',
                line: {
                    width: 0
                },
                layer: 'below'
            })
        }
    })

    const plot2 = (
        <Plot
            data={[
                {
                    x: sortedProcess.filter(l => !l.special).map(l => new Date(l.elapsedTime)),
                    y: sortedProcess.filter(l => !l.special).map((l, i) => i),
                    type: 'scatter',
                    name: 'Processo',
                    yaxis: 'y2'
                },
                {
                    x: sortedProcess.filter(l => !l.special).map(l => new Date(l.elapsedTime)),
                    y: sortedProcess.filter(l => !l.special).map(l => l.cursorPosition),
                    type: 'scatter',
                    name: 'Posição do cursor',
                    line: {
                        dash: 'dash'
                    },
                    yaxis: 'y2'
                },
                {
                    x: sortedProcess.filter(l => !l.special).map(l => new Date(l.elapsedTime)),
                    y: sortedProcess.filter(l => !l.special).map(l => l.characterCount),
                    type: 'scatter',
                    name: 'Produto',
                    yaxis: 'y2'
                },
                {
                    x: sortedProcess.filter(l => !l.special).map(l => new Date(l.elapsedTime)),
                    y: sortedProcess.filter(l => !l.special).map(l => l.iki >= pauseDuration ? l.iki : null),
                    type: 'scatter',
                    name: `Pausas (>= ${pauseDuration})`,
                    mode: 'markers'
                }
            ]}
            layout={{
                title: 'Processo e produto por tempo',
                xaxis: {
                    title: 'Tempo',
                    type: 'date',
                    tickformat: '%M:%S.%L'
                },
                yaxis: {
                    title: 'Pausas'
                },
                yaxis2: {
                    title: 'Caracteres',
                    side: 'right',
                    overlaying: 'y',
                    showgrid: false,
                    zeroline: false
                },
                legend: {
                    x: 1.1,
                    y: 1
                },
                shapes
            }}
            style={{width: '100%', height: '100%'}}/>
    )

    const tokenCountProcess = process.filter(l => !l.special)
                                     .map((l:  WritingLogType) => l.key)
                                     .join('')
                                     .replace(/(\s*Enter\s*|\s+)/g, ' ')
                                     .trim()
                                     .split(' ')
                                    .length
    const tokenCountProduct = process.filter(l => !l.special && !l.deleted)
                                     .map(l => l.key)
                                     .join('')
                                     .replace(/(\s*Enter\s*|\s+)/g, ' ')
                                     .trim()
                                     .split(' ')
                                     .length
    
    return (
        !props.show ? null :
        <Container className='instrument-container position-relative'>
            <Row>
                <Col>
                    <h1 className='mt-5'>Dados do WritingLogger</h1>
                </Col>
            </Row>

            <Row>
                <Col>
                    <h2 className='mt-3'>Opções de visualização</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>Tipo de output</Form.Label>
                        <FormControl 
                            as='select'
                            value={outputType}
                            onChange={e => setOutputType(e.target.value)}>
                            
                            {
                                Object.values(outputOptions).map(o => <option key={uuid()}>{o}</option>)
                            }
                        </FormControl>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Duração da pausa</Form.Label>
                        <FormControl 
                            type='number' 
                            min={0} 
                            step={100}
                            value={pauseDuration}
                            onChange={e => setPauseDuration(Number.parseInt(e.target.value))}/>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col>
                    <h2 className='mt-5'>Métricas</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Processo</h3>
                    <ul>
                        <li>Caracteres: {process.filter((l:  WritingLogType) => !l.special && l.key !== 'Enter').length} ({process.filter((l:  WritingLogType) => !l.special && l.key !== 'Enter' && l.key !== ' ').length})</li>
                        <li>
                            Tokens: {tokenCountProcess}
                        </li>
                        <li>
                            Períodos: {
                                process.filter(l => !l.special)
                                       .map(l => l.key)
                                       .join('')
                                       .replace(/(\s*Enter\s*|\s+)/g, ' ')
                                       .trim()
                                       .split(/\. +|\? */g)
                                       .length
                            }
                        </li>
                        <li>Parágrafos: {process.filter(l => !l.special).map(l => l.key).join('').split('Enter').length}</li>
                    </ul>
                    
                    <h3>Produto</h3>
                    <ul>
                        <li>Caracteres: {process.filter(l => !l.special && !l.deleted && l.key !== 'Enter').length} ({process.filter(l => !l.special && !l.deleted && l.key !== 'Enter' && l.key !== ' ').length})</li>
                        <li>
                            Tokens: {tokenCountProduct}
                        </li>
                        <li>
                            Períodos: {
                                process.filter(l => !l.special && !l.deleted)
                                       .map(l => l.key)
                                       .join('')
                                       .replace(/(\s*Enter\s*|\s+)/g, ' ')
                                       .trim()
                                       .split(/\. +|\? */g)
                                       .length
                            }
                        </li>
                        <li>Parágrafos: {process.filter(l => !l.special && !l.deleted).map((l:  WritingLogType) => l.key).join('').split('Enter').length}</li>
                    </ul>
                </Col>
                <Col>    
                    <h3>Gerais</h3>
                    <ul>
                        <li>Caracteres apagados: {process.filter(l => !l.special && l.key !== 'Enter' && l.deleted).length}</li>
                        <li>Caracteres adicionados: {process.filter(l => !l.special && l.key !== 'Enter' && l.added).length}</li>
                        <li>Tokens apagados: {tokenCountProcess - tokenCountProduct}</li>
                        <li>
                            Tokens adicionados: {
                                process.filter(l => l.key === ' ' || l.added)
                                       .map(l => l.key)
                                       .join('')
                                       .replace(/(\s*Enter\s*|\s+)/g, ' ')
                                       .trim()
                                       .split(' ')
                                       .length
                            }
                        </li>
                        <li>Adições: {[...process.map(l => l.added ? '1' : '0').join('').matchAll(/1+/g)].length}</li>
                        {/*<li>Rajadas: {streakCount !== undefined ? streakCount : 'Escolha "Linha do Tempo" para cacular'}</li>*/}
                    </ul>
                    <h3>Pausas</h3>
                    <ul>
                        <li>Pausas &gt;= {pauseDuration}: {process.filter((l:  WritingLogType) => l.iki >= pauseDuration).length}</li>
                        <ul>
                            <li>Antes de parágrafo: {process.filter((l:  WritingLogType) => l.pauseType === 'PRE_PARAGRAPH').length}</li>
                            <li>Depois de parágrafo: {process.filter((l:  WritingLogType) => l.pauseType === 'POST_PARAGRAPH').length}</li>
                            <li>Antes de período: {process.filter((l:  WritingLogType) => l.pauseType === 'PRE_SENTENCE').length}</li>
                            <li>Depois de período: {process.filter((l:  WritingLogType) => l.pauseType === 'POST_SENTENCE').length}</li>
                            <li>Antes de token: {process.filter((l:  WritingLogType) => l.pauseType === 'PRE_TOKEN').length}</li>
                            <li>Depois de token: {process.filter((l:  WritingLogType) => l.pauseType === 'POST_TOKEN').length}</li>
                            <li>Em token: {process.filter((l:  WritingLogType) => l.pauseType === 'IN_TOKEN').length}</li>
                        </ul>
                        <li>Média = {mean.toFixed(2)}</li>
                        <li>Erro Padrão = {standardError.toFixed(2)}</li>
                        <li>
                            Mediana = {median.toFixed(2)}
                        </li>
                        <li>
                            Intervalo Interquartil = {process.length === 0 ? <Loading/> : interquartileRange(process.map(l => l.iki))}
                        </li>
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col style={{position: 'relative'}}>
                    {plot1}
                    <Button 
                        style={{position: 'absolute', top: 15, left: 15}}
                        onClick={() => {
                            setExpandedPlot(plot1)
                            setExpandPlot(true)
                        }}>
                            
                        Ampliar
                    </Button>
                </Col>
                <Col style={{position: 'relative'}}>
                    {plot2}
                    <Button 
                        style={{position: 'absolute', top: 15, left: 15}}
                        onClick={() => {
                            setExpandedPlot(plot2)
                            setExpandPlot(true)
                        }}>
                        
                        Ampliar
                    </Button>
                </Col>
            </Row>

            {/* {
                outputType === outputOptions.SIMPLE &&
                <Row>
                    <Col>
                        <ButtonGroup>
                            <Button variant='outline-secondary' active={showPauses} onClick={() => setShowPauses(prev => !prev)}>
                                {showPauses ? 'Ocultar' : 'Exibir'} pausas
                            </Button>
                            <Button variant='outline-secondary' active={showNumbers} onClick={() => setShowNumbers(prev => !prev)}>
                                {showNumbers ? 'Ocultar' : 'Exibir'} números
                            </Button>
                            <Button variant='outline-secondary' active={showBrackets} onClick={() => setShowBrackets(prev => !prev)}>
                                {showBrackets ? 'Ocultar' : 'Exibir'}  colchetes
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            } */}

            <Row>
                <Col>
                    <div 
                        className='WritingLogger-container position-relative' 
                        style={{height: `${pageHeight + (pageWidth * .1)}px`}}>
                        <div
                            className='WritingLogger page my-5'
                            style={{
                                width: '100%', 
                                height: `${pageHeight}px`,
                                padding: `${pageWidth * .1}px`,
                                fontSize: `${pageWidth * fontSize * .00168095238}px`
                            }}
                            ref={container}>
                                {
                                    outputType === outputOptions.PRODUCT ?
                                    <Editor 
                                        readOnly
                                        editorState={editorState}
                                        customStyleMap={styleMap}
                                        blockStyleFn={myBlockStyle}
                                        onChange={onChange}
                                        ref={editor}/>
                                    :
                                    output
                                }
                        </div>
                    </div>
                </Col>
            </Row>
            <IconButton icon={ICONS.CONTINUE} type='submit' onClick={() => setFinish(true)}>Continuar</IconButton>
            <Modal show={expandPlot} onHide={() => setExpandPlot(false)} id="Plot">
                <Modal.Header closeButton>
                    <Modal.Title>Gráfico ampliado</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {expandedPlot}
                </Modal.Body>
            </Modal>
            <Modal show={loadData} onHide={() => setLoadData(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Carregar dados</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            loading
                            ? <Loading />
                            :
                            <Form>
                                <Form.Group controlId="formData" className="mb-3">
                                    <Form.Label>Escolher arquivo JSON com dados de WritingLogger</Form.Label>
                                    <Form.Control type="file" size="lg" onChange={handleLoadData} />
                                </Form.Group>
                            </Form>
                        }
                    </Modal.Body>
                </Modal>
        </Container>
        )
}

export default WritingLoggerOutput