import React, { useEffect } from 'react'

import { IconButton, ICONS } from '../../Layout'
import DataType from '../../../types/DataType'

import { TRIALS } from '../../../constants/instruments'

function Text(props:{
    show: boolean,
    instrument: string,
    instrumentId: string,
    instrumentName: string,
    addData: (data: any) => void, 
    next: () => void,
    children: React.ReactNode,
    hideButton?: boolean,
    preventSpacebar?: boolean,
    language?: 'br'|'en'
}) {
    const time = new Date().getTime()

    const handleClick = () => {
        let data: DataType = {
            instrument: props.instrument, 
            instrumentId: props.instrumentId,
            instrumentName: props.instrumentName || '',
            type: TRIALS.TEXT, 
            response: '', 
            correct: [], 
            time: new Date().getTime() - time
        }
        
        props.addData(data)
        props.next()
    }

    const handleKeyup = (e: KeyboardEvent) => {
        if (props.show && !props.preventSpacebar && e.key === ' ' && (e.target as Element).nodeName === 'BODY') {
            handleClick()
        }
    }

    useEffect(() => {
        if (props.show) {
            document.addEventListener('keyup', handleKeyup)
        }

        return () => {document.removeEventListener('keyup', handleKeyup)}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show])

    return (
        !props.show ? null :
        <div className='text-center container'>
            <div className='instrunctions text-justify'>{props.children}</div>
            {
                !props.hideButton &&
                <p className='my-5'>
                    <IconButton 
                        icon={ICONS.CONTINUE}
                        onClick={handleClick}>
                            
                        {
                            props.language === 'en' ?
                            'Continue' :
                            'Continuar'
                        }
                    </IconButton>
                </p>
            }
        </div>
    )
}

export default Text