import React, { useState, useEffect, useContext } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

import { FirebaseContext } from '../Firebase'
import { Message, IconButton, ICONS } from '../Layout'

function PasswordChange() {
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [error, setError] = useState(null as null|firebase.auth.Error)
    const firebase = useContext(FirebaseContext)
    const isInvalid = 
        password === '' ||
        password !== passwordConfirm

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        
        firebase?.doPasswordUpdate(password)
            ?.then(() => {
                setPassword('')
                setPasswordConfirm('')
                setError(null)
            })
            .catch(error => {
                setError(error)
            })
    }

    useEffect(() => {
        if (password !== '' && passwordConfirm !== '' && password !== passwordConfirm) {
            setError({code: 'auth/passwordConfirmFailed', message: 'As senhas não correspondem'})
        } else {
            setError(null)
        }
    }, [password, passwordConfirm])
    
    return (
        <Container>
            <Row>
                <Col>
                    <Form onSubmit={handleSubmit}>
                        <Form.Text><h1>Mudar senha</h1></Form.Text>
                        <Form.Group controlId='password'>
                            <Form.Label>Nova senha</Form.Label>
                            <Form.Control 
                                type='password' 
                                placeholder='Crie uma senha nova'
                                value={password}
                                onChange={e => setPassword(e.target.value)}/>
                        </Form.Group>
                        <Form.Group controlId='password-confirm'>
                            <Form.Label>Confirmar senha</Form.Label>
                            <Form.Control 
                                type='password' 
                                placeholder='Confirme a nova senha'
                                value={passwordConfirm}
                                onChange={e => setPasswordConfirm(e.target.value)}/>
                        </Form.Group>
                        <IconButton icon={ICONS.SAVE} type='submit' disabled={isInvalid}>Mudar</IconButton>
                        <Message error={error}/>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default PasswordChange