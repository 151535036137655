import React from 'react'
import shuffle from 'shuffle-array'

import ConfigType from '../../../types/ConfigType'
import sentences_training from './sentences_training'
import sentences_training_CHAVEFolha95 from './sentences_training_CHAVEFolha95'

import { FIELDS, TRIALS } from '../../../constants/instruments'

const configure = (sentences: string[], timePerWord = 550, trainingList?: 1|2) => {
    const config: ConfigType[] = []

    // Initial Instructions
    config.push({
        type: TRIALS.TEXT,
        prompt: (
            <>
                <h1 className='text-center mb-3'>Teste de Alcance de Leitura Aleatorizado</h1>
                <p>
                    Esse teste requer concentração e, por isso, deve ser feito em
                    ambiente tranquilo e sem interrupções. Se não estiver nessas
                    condições no momento, por favor realize o teste em outro momento.
                </p>
                <p className='text-center'>
                    Caso esteja em condições ideiais para realização do teste, clique em continuar
                </p>
            </>
        )
    })

    config.push({
        type: TRIALS.TEXT,
        prompt: (
            <>
                <h1 className='text-center mb-3'>Teste de Alcance de Leitura Aleatorizado</h1>
                <p>
                    Nesse teste, você deverá ler algumas frases. A cada uma delas, 
                    você terá alguns segundos para ler e selecionar dentre duas 
                    palavras a mais apropriada para completar o sentido da frase. 
                    Além disso, você terá que tentar lembrar as palavras 
                    selecionadas exatamente como elas apareceram (preste atenção em 
                    número e gênero), até que seja solicitado que você digite elas. 
                    Caso você acredite que ambas as palavras são apropriadas para 
                    completar o sentido da frase, clique em qualquer uma das duas à 
                    sua escolha. O importante é que você tente lembrar a palavra 
                    que clicou.
                </p>
                <p>
                    Uma barra no inferior da tela irá mostrar quanto tempo você tem 
                    para escolher a resposta. Ao acabar o tempo, a frase será 
                    substituída automaticamente e será desconsiderada no cálculo da 
                    sua pontuação. Peço que, por favor, tente ler todas as palavras 
                    de cada frase, pois escolhas feitas muito rápidas também serão 
                    desconsideradas no cálculo da sua pontuação. Para o teste dar 
                    certo é essencial que a frase seja lida completamente.
                </p>
                <p>
                    O teste é dividido em níveis tornando-se mais desafiador a cada 
                    novo nível, pois você terá de ler um número maior de frases e 
                    realizar as tarefas que foram descritas.
                </p>
            </>
        )
    })

    // Instructions - Trainning
    config.push({
        type: TRIALS.TEXT,
        prompt: (
            <>
                <h1 className='text-center mb-3'>
                    Sessão de familiarização - Nível 1 - duas palavras
                </h1>
                <p>
                    Vamos iniciar com uma sessão de familiarização para você entender melhor o teste.
                    Você deverá ler uma frase, escolher e clicar na palavra mais apropriada e tentar memorizar essa palavra.
                    Depois você deverá ler outra frase, escolher e clicar na palavra mais apropriada e tentar memorizar também essa palavra.
                    Por fim, você terá que digitar as palavras memorizadas na ordem em que apareceram.
                </p>
            </>
        )
    })

    // Training
    {
        let training;
        
        switch (trainingList) {
            case 1:
                training = sentences_training
                break
            case 2:
                training = sentences_training_CHAVEFolha95
                break
            default:
                training = sentences_training
        }

        shuffle(training)

        let stimuli = training.map(s => s.split(' ').splice(0, s.split(' ').length - 1).join(' '))
        let words = training.map(s => s.split(' ').splice(-1).join())
        let alternatives = [...words]

        do {
            shuffle(alternatives)
        } while (words.map((w, i) => w === alternatives[i]).includes(true))

        // Training - Level 1
        for (let i = 0; i < 2; i++) {
            // Sentence
            config.push({
                type: TRIALS.STIMULUS,
                stimulus: stimuli[i],
                response: {
                    type: 'button',
                    buttons: [words[i], alternatives[i]]
                },
                timeout: (stimuli[i].split(' ').length + 1) * timePerWord,
                training: true
            })

            // Blank
            config.push({
                type: TRIALS.BLANK
            })
        }

        // Training - Recall
        config.push({
            type: TRIALS.QUESTION,
            questions: [1, 2].map(() => ({prompt: '', type: FIELDS.INPUT})),
            correctResponses: [words[0], words[1]],
            training: true
        })

        // Trainning - Instructions 2
        config.push({
            type: TRIALS.TEXT,
            prompt: (
                <>
                    <h1 className='text-center mb-3'>
                        Sessão de familiarização - Nível 1 - duas palavras
                    </h1>
                    <p className='text-center'>
                        Entendeu? Cada nível é composto por três séries de frases. Faça mais duas para completar o Nível 1
                    </p>
                </>
            )
        })

        // Training - Level 1
        for (let i = 2; i < 6; i = i + 2) {
            for (let j = i; j < i + 2; j++) {
                // Sentence
                config.push({
                    type: TRIALS.STIMULUS,
                    stimulus: stimuli[j],
                    response: {
                        type: 'button',
                        buttons: [words[j], alternatives[j]]
                    },
                    timeout: (stimuli[j].split(' ').length + 1) * timePerWord,
                    training: true
                })
            
                // Blank
                config.push({
                    type: TRIALS.BLANK
                })
            }
            
            // Training - Recall
            config.push({
                type: TRIALS.QUESTION,
                questions: [1, 2].map(() => ({prompt: '', type: FIELDS.INPUT})),
                correctResponses: [words[i], words[i + 1]],
                training: true
            })
        }

        // Trainning - Instructions 3
        config.push({
            type: TRIALS.TEXT,
            prompt: (
                <>
                    <h1 className='text-center mb-3'>
                        Sessão de familiarização - Nível 2 - três palavras
                    </h1>
                    <p className='text-center'>
                        Para finalizar a sessão de familiarização, faça uma série do Nível 2, com três palavras.
                    </p>
                </>
            )
        })

        for (let i = 6; i < 9; i++) {
            // Sentence
            config.push({
                type: TRIALS.STIMULUS,
                stimulus: stimuli[i],
                response: {
                    type: 'button',
                    buttons: [words[i], alternatives[i]]
                },
                timeout: (stimuli[i].split(' ').length + 1) * timePerWord,
                training: true
            })

            // Blank
            config.push({
                type: TRIALS.BLANK
            })
        }

        // Training - Recall
        config.push({
            type: TRIALS.QUESTION,
            questions: [1, 2, 3].map(() => ({prompt: '', type: FIELDS.INPUT})),
            correctResponses: [words[6], words[7], words[8]],
            training: true
        })

        // Trainning - Final instructions
        config.push({
            type: TRIALS.TEXT,
            prompt: (
                <>
                    <h1 className='text-center mb-3'>
                        Sessão de familiarização concluída
                    </h1>
                    <p>
                        Se tiver alguma dúvida, favor entrar em contato com quem 
                        disponibilizou o link para você e só após tirar as dúvidas
                        continue com a tarefa.
                    </p>
                    <p>
                        Lembre-se que se fechar essa janela ou aba, o experimento será reiniciado.
                        Você pode deixar essa janela ou aba aberta, tirar as dúvidas e
                        depois continuar a tarefa desse ponto.
                    </p>
                    <p>
                        Se optar por começar o teste agora, basta clicar em continuar.
                    </p>
                </>
            )
        })
    }

    // Main task
    {
        shuffle(sentences)

        let stimuli = sentences.slice(0, 60)
        let words = stimuli.map(s => s.split(' ').splice(-1).join())

        stimuli = stimuli.map(s => s.split(' ').splice(0, s.split(' ').length - 1).join(' '))
        
        let alternatives = [...words]

        do {
            shuffle(alternatives)
        } while (words.map((w, i) => w === alternatives[i]).includes(true))

        // Level 1
        // Instructions - Level 1
        config.push({
            type: TRIALS.TEXT,
            prompt: <h1 className='text-center mb-3'>Nível 1 - duas palavras</h1>
        })

        for (let i = 0; i < 60; i++) {
            // Sentence
            config.push({
                type: TRIALS.STIMULUS,
                stimulus: stimuli[i],
                response: {
                    type: 'button',
                    buttons: [words[i], alternatives[i]]
                },
                timeout: (stimuli[i].split(' ').length + 1) * timePerWord
            })

            // Blank
            config.push({
                type: TRIALS.BLANK
            })

            if (i === 1 || i === 3 || i === 5) {
                // Recall
                config.push({
                    type: TRIALS.QUESTION,
                    questions: [1, 2].map(() => ({prompt: '', type: FIELDS.INPUT})),
                    correctResponses: [
                        words[i - 1], 
                        words[i]
                    ]
                })
            }

            if (i === 5) {
                // Instructions - Level 2
                config.push({
                    type: TRIALS.TEXT,
                    prompt: <h1 className='text-center mb-3'>Nível 2 - três palavras</h1>
                })
            }

            if (i === 8 || i === 11 || i === 14) {
                // Recall
                config.push({
                    type: TRIALS.QUESTION,
                    questions: [1, 2, 3].map(() => ({prompt: '', type: FIELDS.INPUT})),
                    correctResponses: [
                        words[i - 2], 
                        words[i - 1], 
                        words[i]
                    ]
                })
            }

            if (i === 14) {
                // Instructions - Level 3
                config.push({
                    type: TRIALS.TEXT,
                    prompt: <h1 className='text-center mb-3'>Nível 3 - quatro palavras</h1>
                })
            }

            if (i === 18 || i === 22 || i === 26) {
                // Recall
                config.push({
                    type: TRIALS.QUESTION,
                    questions: [1, 2, 3, 4].map(() => ({prompt: '', type: FIELDS.INPUT})),
                    correctResponses: [
                        words[i - 3], 
                        words[i - 2], 
                        words[i - 1], 
                        words[i]
                    ]
                })
            }

            if (i === 26) {
                // Instructions - Level 4
                config.push({
                    type: TRIALS.TEXT,
                    prompt: <h1 className='text-center mb-3'>Nível 4 - cinco palavras</h1>
                })
            }

            if (i === 31 || i === 36 || i === 41) {
                // Recall
                config.push({
                    type: TRIALS.QUESTION,
                    questions: [1, 2, 3, 4, 5].map(() => ({prompt: '', type: FIELDS.INPUT})),
                    correctResponses: [
                        words[i - 4], 
                        words[i - 3], 
                        words[i - 2], 
                        words[i - 1], 
                        words[i]
                    ]
                })
            }

            if (i === 41) {
                // Instructions - Level 5
                config.push({
                    type: TRIALS.TEXT,
                    prompt: <h1 className='text-center mb-3'>Nível 5 - seis palavras</h1>
                })
            }

            if (i === 47 || i === 53 || i === 59) {
                // Recall
                config.push({
                    type: TRIALS.QUESTION,
                    questions: [1, 2, 3, 4, 5, 6].map(() => ({prompt: '', type: FIELDS.INPUT})),
                    correctResponses: [
                        words[i - 5], 
                        words[i - 4], 
                        words[i - 3], 
                        words[i - 2], 
                        words[i - 1], 
                        words[i]
                    ]
                })
            }
        }
    }

    return config
}

export default configure