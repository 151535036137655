import React, { useContext, useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import shuffle from 'shuffle-array'
import {v4 as uuid} from 'uuid'

import { TimerContext, TimerComponent } from '../Timer'
import DataType from '../../../types/DataType'
import ResponseType from '../../../types/ResponseType'

import { TRIALS } from '../../../constants/instruments'

import './index.css'

function Stimulus(props:{
    show: boolean,
    instrument: string,
    instrumentId: string,
    instrumentName: string,
    language: 'en'|'br',
    response?: ResponseType,
    correctResponses?: string[],
    timeout?: number|'mean', 
    hideTimer?: boolean,
    training?: boolean,
    prompt?: React.ReactNode,
    data: DataType[],
    addData: (data: any) => void,
    next: (finish?: boolean, skip?: boolean) => void,
    children: React.ReactNode,
    childrenDescription?: string
}) {
    const [correct, setCorrect] = useState<boolean|number|undefined>()
    const time = new Date().getTime()
    const timer = useContext(TimerContext)
    
    const handleNext = (b: {stimulus: string, response: string}) => {
        const correct = (
            props?.response?.buttons ? b.response === props?.response?.buttons[0] : 
            props?.response?.keys ? props?.correctResponses?.includes(b.response) : -1
        )
        
        if (!props.training) {
            let data: DataType = {
                instrument: props.instrument, 
                instrumentId: props.instrumentId,
                instrumentName: props.instrumentName || '',
                type: TRIALS.STIMULUS, 
                response: b, 
                correct: [correct], 
                time: new Date().getTime() - time
            }

            props.addData(data)
        }
        
        if (props.response?.showCorrect) {
            setCorrect(correct)

            setTimeout(() => props.next(), 2000)
        } else {
            props.next()
        }
    }

    let buttons: any = props.response?.buttons && [...props.response.buttons]

    if (props.response?.buttons) {
        if (props.response?.order === 'asc') {
            buttons.sort()
        } else if (props.response?.order === 'desc') {
            buttons.sort().reverse()
        } else {
            shuffle(buttons)
        }

        buttons = buttons.map((b: string) => (
            <Button key={uuid()} variant='light' className='ml-1 border' onClick={() => {
                timer.clearTimeout()
                handleNext({stimulus: props.childrenDescription || JSON.stringify(props.children), response: b})
            }}>
                {b}
            </Button>
        ))
    }

    const handleMouseUp = (e: MouseEvent) => {
        if (props.show) {
            handleNext({stimulus: props.childrenDescription || JSON.stringify(props.children), response: ''})
        }
    }

    const handleKeypress = (e: KeyboardEvent) => {
        if (props.show && (props.response?.keys === undefined || props.response.keys.includes(e.key))) {
            handleNext({stimulus: props.childrenDescription || JSON.stringify(props.children), response: e.key})
        }
    }

    useEffect(() => {
        if (props.show && props.timeout) {
            let timeout: number
            
            if (props.timeout === 'mean') {
                const meanValues = props.data.filter(d => d.type === TRIALS.STIMULUS && d.mean)
                timeout = meanValues[meanValues.length - 1].mean || 0
            } else {
                timeout = props.timeout
            }

            timer.setTimeout(timeout, () => { 
                handleNext({stimulus: props.childrenDescription || JSON.stringify(props.children), response: ''}) 
            })
        } else {
            timer.clearTimeout()
        }

        if (props.show && props.response?.type === 'click') {
            window.addEventListener('mouseup', handleMouseUp)
        }

        if (props.show && props.response?.type === 'key') {
            window.addEventListener('keypress', handleKeypress)
        }

        return () => {
            window.removeEventListener('mouseup', handleMouseUp)
            window.removeEventListener('keypress', handleKeypress)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show])

    return (
        <>
            {
                props.show && props.response?.type === 'click'
                && <div className='click-overlay' onClick={() => handleNext({stimulus: JSON.stringify(props.children), response: ''})} />
            }
            {
                props.show && props.training && 
                <div className='stimulus-prompt'>{props.prompt}</div>
            }
            <p className='stimulus' style={{display: props.show ? 'block' : 'none'}}>
                <span>{props?.children}</span> 

                {
                    (props.response?.type === 'button'
                    && correct === undefined)
                    && (
                        props.response.centralizeButtons 
                        ?   <span className='centralize'>{buttons}</span>
                        : buttons
                    )
                }
                {
                    (props.response?.showCorrect && correct !== undefined)
                    && 
                    <span className='centralize'>
                        <span className='w-100 text-center'>
                            {
                                (correct === true ? 
                                    (props.language === 'en' ? 'Your answer is correct.' : 'Resposta certa.') : 
                                    (props.language === 'en' ? 'Your answer is incorrect.' : 'Resposta errada.'))
                            }
                        </span>
                    </span>
                }
            </p>
            {
                props.show && props.timeout && !props.hideTimer
                && <TimerComponent duration={(props.timeout === 'mean' ? 1000 : props.timeout)}/>
            }
        </>
    )
}

export default Stimulus