export const INSTRUMENTS = {
    INSTRUCTIONPAGE: 'Página de instruções',
    TEXT: 'Texto',
    TCLE: 'TCLE',
    SURVEY: 'Questionário',
    TCLA: 'TCLA',
    QUEXPLI: 'QuExPLi',
    WRITINGLOGGER: 'WritingLogger',
    WRITINGLOGGEROUTPUT: 'Saída do WritingLogger',
    READINGLOGGER: 'ReadingLogger',
    READINGLOGGEROUTPUT: 'Saída do ReadingLogger',
    SONGSELECTOR: 'Seleção de música',
    DRM: 'DRM',
    WORDSPAN: 'Word Span',
    PRIMINGTASK: 'Tarefa de Priming',
    RST: 'Reading Span Test',
    RSTEnglish: 'Reading Span Test (Instruções em inglês)',
    SST: 'Symmetry Span Test',
    SSTEnglish: 'Symmetry Span Test (Instruções em inglês)',
    OST: 'Operational Span Test',
    OSTEnglish: 'Operational Span Test (Instruções em inglês)',
    LETCOM: 'Letter Comparison Test',
    LETCOMEnglish: 'Letter Comparison Test (Instruções em inglês)',
    PATCOM: 'Pattern Comparison Test',
    PATCOMEnglish: 'Pattern Comparison Test (Instruções em inglês)',
    WORDBYWORD: 'Leitura Palavra por Palavra',
    LEXICALDECISION: 'Tarefa de Decisão Lexical'
}

export const TRIALS = {
    TEXT: 'Instructions',
    STIMULUS: 'Stimulus',
    QUESTION: 'Question',
    BLANK: 'Blank',
    AUDIO: 'Audio',
    IMAGE: 'Image',
    MATRIX: 'Matrix',
    SYMMETRY: 'Symmetry Test',
}

export const FIELDS = {
    INSTRUCTIONS: 'Enunciado (instruções)',
    INPUT: 'Texto curto',
    TEXTAREA: 'Texto longo',
    NUMBER: 'Número',
    EMAIL: 'Email',
    CHECK: 'Caixa de seleção',
    CPF: 'CPF',
    MOBILE: 'Celular',
    DATE: 'Data',
    RADIO: 'Múltipla escolha (opção única)',
    TOGGLEBUTTONS: 'Múltipla escolha (opção única, botões)',
    MULTIPLE: 'Múltipla escolha (múltiplas opções)',
    RANGE: 'Escala Likert',
    SELECT: 'Campo de seleção',
    FILLIN: 'Preenchimento de lacuna'
}