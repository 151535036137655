import React, { 
    Dispatch, 
    SetStateAction, 
    useState, 
    useContext, 
    useEffect,
    useRef, 
} from 'react'
import Container from 'react-bootstrap/Container'
import { v4 as uuid } from 'uuid'

import Text from '../Text'
import DataType from '../../../types/DataType'
import ConfigType from '../../../types/ConfigType'
import { Loading, Message } from '../../Layout'
import { FirebaseContext } from '../../Firebase'
import { TimerContext } from '../Timer'

import { TRIALS, INSTRUMENTS } from '../../../constants/instruments'

import './index.css'

function WordByWord(props: {
    id: string,
    name: string,
    show: boolean,
    data: DataType[], 
    experimentId: string,
    addData: Dispatch<SetStateAction<DataType[]>>,
    next: (finish?: boolean) => void,
    hideButton?: boolean,
    language?: 'br'|'en'
}) {
    const [finish, setFinish] = useState(false)
    const [options, setOptions] = useState([] as {text?: string, title?: string, link?: string, popup?: string, playSong?: boolean}[])
    const [config, setConfig] = useState([] as ConfigType[])
    const [keys, setKeys] = useState([] as string[])
    const [show, setShow] = useState([] as boolean[])
    const [loading, setLoading] = useState(true)
    const [wbwShowIndex, setWbwShowIndex] = useState(0)
    const wbwIndex = useRef(0)
    const firebase = useContext(FirebaseContext)
    const timer = useContext(TimerContext)

    const handleNext = () => {
        if (!show[show.length - 1]) {
            let setTrue = false

            setShow(show.map((s: boolean) => {
                if (s) {
                    setTrue = true
                    return false
                }

                if (setTrue) {
                    setTrue = false
                    return true
                }

                return false
            }))
        } else {
            setFinish(true)
        }
    }

    const handleAddData = (newData: DataType) => {
        props.addData(data => {
            return [...data, newData]
        })
    }

    useEffect(() => {
        firebase?.experiment(props.experimentId).once(
            'value', snapshot => {
                setOptions(
                    snapshot.val().instruments.filter(
                        (i: any) => i.id === props.id)[0].options
                    )
            }
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.show) {
            let config: ConfigType[]
            wbwIndex.current = 0
    
            if (options && options.length > 0) {
                let length = 0
            
                options.filter((o: any) => !('img' in o)).forEach(o => {
                    if (o.title) {
                        length += o.title.split(' ').length
                    }

                    if (o.text) {
                        length += o.text.split(' ').length
                    }
                })

                if (wbwShowIndex > length) {
                    handleNext()
                }
                
                config = [
                    {
                        type: TRIALS.TEXT,
                        prompt: options?.filter((o: any) => !('img' in o)).map(
                            (o: {text?: string, title?: string, block?: boolean, heading?: boolean, align?: 'center'|'justify'}, 
                                i: number, 
                                a: {text?: string, title?: string, block?: boolean, heading?: boolean, align?: 'center'|'justify'}[]) => {
                                    if (o.title || o.heading) {
                                        let wbw: {token: string, mask: string, show: boolean, index: number}[] = []
                                        
                                        if (o.title) {
                                            wbw = o.title.split(' ').map(token => {
                                                const index = ++wbwIndex.current
                                                return {
                                                    token, 
                                                    mask: token.replace(/[^ .,]+/g, '____'), 
                                                    show: index === wbwShowIndex, 
                                                    index: index
                                                }
                                                    
                                            })
                                        }
    
                                        if (o.heading && o.text) {
                                            wbw = o.text.split(' ').map(token => {
                                                const index = ++wbwIndex.current
                                                return {
                                                    token, 
                                                    mask: token.replace(/[^ .,]+/g, '____'), 
                                                    show: index === wbwShowIndex, 
                                                    index: index
                                                }
                                            })
                                        }
                                        
                                        return (
                                            <h1 key={uuid()} className='my-5' style={{textAlign: o.align || 'center'}}>
                                                {wbw.map(word => word.show ? word.token : word.mask).join(' ')}
                                            </h1>
                                        )
                                    } else if (o.text) {
                                        const wbw = o.text.split(' ').map(token => {
                                            const index = ++wbwIndex.current
                                            return {
                                                token, 
                                                mask: token.replace(/[^ .,]+/g, '____'), 
                                                show: index === wbwShowIndex, 
                                                index: index
                                            }
                                        })
    
                                        return (
                                            <React.Fragment key={uuid()}>
                                                <span style={{display: o.block ? 'block' : 'inline', textAlign: o.align}}>
                                                    {wbw.map(word => word.show ? word.token : word.mask).join(' ')}
                                                </span>
                                                {a[i + 1] && 'text' in a[i + 1] && <><br/><br/></>}
                                            </React.Fragment>
                                        ) 
                                    }
                            }
                        )
                    }
                ]
                
            } else {
                config = [{
                    type: INSTRUMENTS.TEXT,
                    prompt: <Message warning='O texto não foi configurado corretamente.' />
                }]
            }
    
            setConfig(config)
            setKeys(config.map(() => uuid()))
            setShow(config.map((c: ConfigType, i: number) => i === 0 ? true : false))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show, options, wbwShowIndex])

    useEffect(() => {
        handleAddData({
            instrument: INSTRUMENTS.WORDBYWORD,
            instrumentId: props.id,
            instrumentName: props.name || '',
            type: INSTRUMENTS.TEXT,
            response: wbwShowIndex - 1,
            correct: [],
            time: timer.getInterval(),
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wbwShowIndex])

    const handleKeyup = (e: KeyboardEvent) => {
        if (props.show && e.key === 'n') {
            setWbwShowIndex(current => {
                return current + 1
            })
        }
    }

    useEffect(() => {
        if (props.show) {
            setLoading(false)

            document.addEventListener('keyup', handleKeyup)
        }

        return () => {document.removeEventListener('keyup', handleKeyup)}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show])

    useEffect(() => {
        if (finish) {
            handleAddData({
                instrument: INSTRUMENTS.WORDBYWORD,
                instrumentId: props.id,
                instrumentName: props.name || '',
                type: INSTRUMENTS.TEXT,
                response: wbwShowIndex + 1,
                correct: [],
                time: timer.getInterval(),
            })

            props.next()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finish])

    return (
        !props.show ? null :
        loading ? <Loading /> :
        <>
            <Container 
                className='WordByWord instrument-container d-flex flex-column justify-content-center align-items-center position-relative'
                fluid>
                
                {
                    options &&
                    options.filter((o: any) => 'img' in o).map((o: any) => (
                        o.img && <img key={uuid()} id='' src={o.img} alt='Imagem ilustrativa'/>
                    ))
                }

                {
                    props.language === 'en' ?
                    <p>Press the ‘n’ key to see the next word.</p> :
                    <p>Pressione a tecla ‘n’ para ver a próxima palavra.</p>
                }

                {
                    config.map((p: ConfigType, i: number) => {
                        switch (p.type) {
                            case TRIALS.TEXT:
                                return (
                                    <Text 
                                        key={keys[i]}
                                        show={show[i]}
                                        instrument={INSTRUMENTS.WORDBYWORD} 
                                        instrumentId={props.id}
                                        instrumentName={props.name}
                                        addData={() => {}}
                                        next={handleNext}
                                        preventSpacebar={true}
                                        hideButton={true}
                                        language={props.language}>
                                            
                                        {p.prompt}
                                    </Text>
                                )
                            default:
                                return <div key={uuid()}></div>
                        }
                    })
                }
            </Container>
        </>
    )
}

export default WordByWord