import React, { useEffect, useContext, useState, useRef } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import { TimerContext } from '../../Timer';
import { IconButton, ICONS } from '../../../Layout'
import DataType from '../../../../types/DataType';
import { TRIALS } from '../../../../constants/instruments';

import './index.css'

function Matrix(props:{
  show: boolean,
  instrument: string,
  instrumentId: string,
  instrumentName: string,
  language: 'en'|'br',
  timeout?: number|'mean', 
  training?: boolean,
  filled?: number[],
  recall?: boolean,
  addData: (data: any) => void,
  next: (finish?: boolean, skip?: boolean) => void
}) {
  const [filled, setFilled] = useState<{cellId: number, order: number}[]>([])
  const recallCounter = useRef(1)
  const timer = useContext(TimerContext)
  const time = new Date().getTime()

  const handleNext = (b: {stimulus?: string, response?: {cellId: number, order: number}[]}) => {
    if (!props.training) {
        let data: DataType = {
            instrument: props.instrument, 
            instrumentId: props.instrumentId,
            instrumentName: props.instrumentName || '',
            type: TRIALS.MATRIX, 
            response: b, 
            correct: [], 
            time: new Date().getTime() - time
        }

        props.addData(data)
    }
    
    props.next()
  }

  const handleFillCell = (cellId: number) => {
    setFilled([...filled, {cellId, order: recallCounter.current++}])
  }

  useEffect(() => {
    if (props.show && props.timeout) {
      timer.setTimeout((props.timeout === 'mean' ? 10000 : props.timeout), () => { 
          handleNext({stimulus: props.filled?.join(', ') || ''}) 
      })
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show])

  return (
    !props.show ? null :
    !props.recall ?
    <div className="Matrix">
      {
        [1, 2, 3, 4].map(columnId => {
          return (
            <div key={`row${columnId}`} className="Row">
              {
                [1, 2, 3, 4].map(rowId => (
                  <div 
                    key={`cell${rowId}${columnId}`} 
                    className={`Cell${props.filled?.includes((4 * (rowId - 1)) + columnId) ? ' Filled' : ''}`} />
                ))
              }
            </div>
          )
        })
      }
    </div>
    :
    <>
      <p>
        {
          props.language === 'en' ?
            'Select the squares in the presented order.' :
            'Selecione os quadrados na ordem apresentada.'
        }
      </p>
      <p>
        {
          props.language === 'en' ?
          'Use the BLANK button to fill the squares you forgot.' :
          'Use o botão EM BRANCO para preencher os quadrados esquecidos.'
        }
      </p>
      <div className="Matrix">
        {
          [1, 2, 3, 4].map(columnId => {
            return (
              <div key={`row${columnId}`} className="Row">
                {
                  [1, 2, 3, 4].map(rowId => (
                    <div 
                      key={`cell${rowId}${columnId}`} 
                      className={`Cell${filled?.map(f => f.cellId).includes((4 * (rowId - 1)) + columnId) ? ' Filled' : ''}`}
                      onClick={() => handleFillCell((4 * (rowId - 1)) + columnId)}>
                        {
                          filled?.filter(f => f.cellId === (4 * (rowId - 1)) + columnId)[0] && 
                          filled?.filter(f => f.cellId === (4 * (rowId - 1)) + columnId)[0].order
                        }
                      </div>
                  ))
                }
              </div>
            )
          })
        }
      </div>
      <ButtonGroup>
        <IconButton
          icon={ICONS.UNDO}
          className='mt-3'
          variant='warning'
          onClick={() => {
            setFilled([])
            recallCounter.current = 1
          }}>
          {props.language === 'en' ? 'CLEAR' : 'LIMPAR'}
        </IconButton>
        <IconButton
          icon={ICONS.SKIP}
          className='mt-3'
          variant='secondary'
          onClick={() => recallCounter.current++}>
          {props.language === 'en' ? 'BLANK' : 'EM BRANCO'}
        </IconButton>
      </ButtonGroup>
      <IconButton 
        icon={ICONS.CONTINUE}
        className='mt-3'
        onClick={() => handleNext({response: filled})}>
          {
            props.language === 'en' ?
              'Continue' :
              'Continuar'
          }
      </IconButton>
    </>
  )
}

export default Matrix