import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Loading } from '../Layout'
import { AuthUserContext } from '../Session'

import * as ROLES from '../../constants/roles'
import * as ROUTES from '../../constants/routes'

function Home() {
    const authUser: any = useContext(AuthUserContext)
    const history = useHistory()

    useEffect(() => {
        if (authUser?.roles[ROLES.RESEARCHER]) {
            history.push(ROUTES.RESEARCHER)
        } else if (authUser?.roles[ROLES.PARTICIPANT]) {
            history.push(ROUTES.PARTICIPANT)
        }
    }, [authUser, history])

    return <Loading/>
}

export default Home