import React from 'react'
import { v4 as uuid } from 'uuid'

import './index.css'

function TimerComponent(props: {duration: number, fixed?: boolean, top?: boolean}) {
    return (
        <div
            key={uuid()}
            className='Timer'
            style={{
                animation: `timer ${props.duration}ms linear`,
                position: (props.fixed ? 'fixed' : undefined),
                top: (props.top ? 0 : undefined)
            }}></div>
    )
}

export default TimerComponent