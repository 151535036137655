const words_LexPorBR = [
    'baba',
    'bale',
    'beco',
    'bode',
    'bolo',
    'bolso',
    'bone',
    'bufe',
    'cabo',
    'cana',
    'cela',
    'cena',
    'cine',
    'coco',
    'copa',
    'dama',
    'dedo',
    'dica',
    'dolo',
    'dona',
    'face',
    'fama',
    'fase',
    'fato',
    'file',
    'fita',
    'fogo',
    'fone',
    'foto',
    'fuga',
    'gado',
    'gene',
    'goma',
    'gota',
    'guru',
    'hino',
    'jato',
    'jipe',
    'lado',
    'lago',
    'lapa',
    'lema',
    'loja',
    'lote',
    'luva',
    'mala',
    'meta',
    'mito',
    'mote',
    'nuca',
    'paje',
    'pano',
    'polo',
    'raça',
    'ramo',
    'rede',
    'riso',
    'saga',
    'seda',
    'sena',
    'sola',
    'sono',
    'sopa',
    'taxi',
    'tela',
    'tese',
    'teto',
    'tipo',
    'vida',
    'voga',
]

export default words_LexPorBR