import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import Container from 'react-bootstrap/Container'
import { v4 as uuid } from 'uuid'

import {config_training, config_list1, config_list2} from './config'
import Text from '../Text'
import Stimulus from '../Stimulus'
import Blank from '../Blank'
import DataType from '../../../types/DataType'
import ConfigType from '../../../types/ConfigType'

import { INSTRUMENTS, TRIALS } from '../../../constants/instruments'

import './index.css'

function PatComEnglish(props: {
    id: string,
    name: string,
    show: boolean,
    data: DataType[],
    language: 'en'|'br',
    addData: Dispatch<SetStateAction<DataType[]>>,
    next: (finish?: boolean) => void,
    hideButton?: boolean,
    list?: string,
    training?: boolean,
}) {
    const [show, setShow] = useState(-1)
    const [currentConfig] = useState(props.training ? config_training :
        props.list === '1' ? config_list1 : config_list2)
    const [keys] = useState(currentConfig.map(() => uuid()))
    const [finish, setFinish] = useState(false)

    const handleAddData = (newData: DataType) => {
        props.addData(data => [...data, newData])
    }

    const handleNext = (finish?: boolean, skip?: boolean) => {
        if (!finish) {
            if (show < currentConfig.length - 1) {
                setShow(show => show + (!skip ? 1 : 2))
            } else {
                setFinish(true)
            }
        } else {
            setFinish(true)
        }
    }

    useEffect(() => {
        if (props.show) {
            setTimeout(() => setFinish(true), 30000)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show])

    useEffect(() => {
        if (props.show && finish) {
            props.addData(data => {
                let correct = 0

                data.forEach(d => {
                    if (d.type === TRIALS.STIMULUS && d.instrumentId === props.id) {
                        d.correct.forEach(c => {
                            correct += (c === true ? 1 : 0)
                        })
                    }
                })

                return [
                    ...data, 
                    {
                        instrument: INSTRUMENTS.PATCOMEnglish, 
                        instrumentId: props.id,
                        instrumentName: props.name || '',
                        type: 'Summary', 
                        response: '', 
                        correct: [correct], 
                        time: 0
                    }
                ]
            })
            props.next()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finish])

    useEffect(() => {
        if (props.show) {
            setShow(0)
        }
    }, [props.show])

    return (
        !props.show ? null :
        <Container className='instrument-container d-flex justify-content-center align-items-center position-relative' id='PatCom' fluid>
            {
                currentConfig.map((p: ConfigType, i: number) => {
                    switch (p.type) {
                        case TRIALS.TEXT:
                            return <Text 
                                        key={keys[i]}
                                        show={show === i}
                                        instrument={INSTRUMENTS.PATCOMEnglish}
                                        instrumentId={props.id}
                                        instrumentName={props.name}
                                        addData={handleAddData}
                                        next={handleNext}
                                        language={props.language}>
                                            
                                        {p.prompt}
                                    </Text>
                        case TRIALS.STIMULUS:
                            return <Stimulus 
                                        key={keys[i]}
                                        show={show === i}
                                        instrument={INSTRUMENTS.PATCOMEnglish}
                                        instrumentId={props.id}
                                        instrumentName={props.name}
                                        response={p.response}
                                        correctResponses={p.correctResponses}
                                        data={props.data}
                                        addData={handleAddData} 
                                        next={handleNext}
                                        timeout={p.timeout}
                                        training={p.training}
                                        prompt={p.prompt}
                                        childrenDescription={p.description}
                                        language={props.language}>
                                            
                                        {p.stimulus}
                                    </Stimulus>
                        case TRIALS.BLANK:
                            return (
                                <Blank 
                                    key={keys[i]}
                                    show={show === i}
                                    instrument={INSTRUMENTS.PATCOMEnglish} 
                                    instrumentId={props.id}
                                    instrumentName={props.name}
                                    addData={handleAddData}
                                    next={handleNext}/>
                            )
                        default:
                            return <div key={keys[i]}></div>
                    }
                })
            }
        </Container>
    )
}

export default PatComEnglish