import React, { Dispatch, SetStateAction, useState, useEffect, useRef, useContext } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import Immutable from 'immutable'
import swal from 'sweetalert'
import { FaFont } from 'react-icons/fa'
import { v4 as uuid } from 'uuid'
import { 
    Editor, 
    EditorState, 
    RichUtils, 
    getDefaultKeyBinding, 
    KeyBindingUtil, 
    DraftStyleMap,
    Modifier,
    ContentBlock,
    convertToRaw
} from 'draft-js'

import DataType from '../../../types/DataType'
import WritingLogType from '../../../types/WritingLogType'
import { IconButton, ICONS } from '../../Layout'
import { TimerContext } from '../Timer'

import { INSTRUMENTS } from '../../../constants/instruments'

import "draft-js/dist/Draft.css"
import "./index.css"

function WritingLogger(props: {
    id: string,
    name: string,
    show: boolean,
    data: DataType[], 
    addData: Dispatch<SetStateAction<DataType[]>>,
    next: (finish?: boolean) => void,
    hideButton?: boolean
}) {
    const [finish, setFinish] = useState(false)
    const [pageWidth, setPageWidth] = useState(0)
    const [pageHeight, setPageHeight] = useState(0)
    const [log, _setLog] = useState([] as WritingLogType[])
    const [refreshId, setRefreshId] = useState<string>()
    const logRef = useRef(log)
    const setLog = (newLog: WritingLogType[]) => {logRef.current = newLog; _setLog(newLog)}
    const container = useRef(null as HTMLDivElement|null)
    const editor = useRef({} as Editor)
    const movementIndex = useRef(0)
    const previousCharacterCountAtFocus = useRef(0)
    const lastMouseMovement = useRef(0)
    const timer = useContext(TimerContext)
    const { hasCommandModifier } = KeyBindingUtil;
    const fontSize = 12
    const styleMap: DraftStyleMap = {
        'NORMAL': {
            textTransform: 'none'
        },
        'UPPERCASE': {
            textTransform: 'uppercase'
        },
        'LOWERCASE': {
            textTransform: 'lowercase'
        },
        'CAPITALIZE': {
            textTransform: 'capitalize'
        },
        'FONT-8': {
            fontSize: `${pageWidth * 8 * .00168095238}px`
        },
        'FONT-9': {
            fontSize: `${pageWidth * 9 * .00168095238}px`
        },
        'FONT-10': {
            fontSize: `${pageWidth * 10 * .00168095238}px`
        },
        'FONT-11': {
            fontSize: `${pageWidth * 11 * .00168095238}px`
        },
        'FONT-12': {
            fontSize: `${pageWidth * 12 * .00168095238}px`
        },
        'FONT-14': {
            fontSize: `${pageWidth * 14 * .00168095238}px`
        },
        'FONT-16': {
            fontSize: `${pageWidth * 16 * .00168095238}px`
        },
        'FONT-18': {
            fontSize: `${pageWidth * 18 * .00168095238}px`
        },
        'FONT-20': {
            fontSize: `${pageWidth * 20 * .00168095238}px`
        },
        'FONT-22': {
            fontSize: `${pageWidth * 22 * .00168095238}px`
        },
        'FONT-24': {
            fontSize: `${pageWidth * 24 * .00168095238}px`
        },
        'FONT-26': {
            fontSize: `${pageWidth * 26 * .00168095238}px`
        },
        'FONT-28': {
            fontSize: `${pageWidth * 28 * .00168095238}px`
        }
    }

    const [editorState, setEditorState] = useState(() => EditorState.createEmpty())

    const handleResize = () => {
        const current: any = container.current

        if (current) {
            setPageWidth(current.offsetWidth)
            setPageHeight(current.offsetWidth * Math.SQRT2)
        }
    }

    const handleWindowBlur = () => {
        let newLog: WritingLogType[] = [...logRef.current]

        const totalCharacterCount = newLog.filter(l => !l.special && !l.deleted).length

        newLog.push({
            id: uuid(), 
            key: 'FocusOut',
            elapsedTime: timer.getElapsedTime(),
            characterCount: totalCharacterCount,
            cursorPosition: getCharacterCountAtFocus(),
            iki: timer.getInterval(),
            timeIndex: timer.getCount(),
            special: true
        })

        setLog(newLog)
    }

    const handleWindowFocus = () => {
        let newLog: WritingLogType[] = [...logRef.current]

        const totalCharacterCount = newLog.filter(l => !l.special && !l.deleted).length

        newLog.push({
            id: uuid(), 
            key: 'FocusIn',
            elapsedTime: timer.getElapsedTime(),
            characterCount: totalCharacterCount,
            cursorPosition: getCharacterCountAtFocus(),
            iki: timer.getInterval(),
            timeIndex: timer.getCount(),
            special: true
        })

        setLog(newLog)
    }

    const onChange = (editorState: EditorState) => {
        previousCharacterCountAtFocus.current = getCharacterCountAtFocus()

        return setEditorState(editorState)
    }

    const handleKeyCommand = (command: string, editorState: EditorState) => {
        let newState: EditorState|null
        let newLog = [...log]
        
        switch(command) {
            case 'BOLD':
            case 'ITALIC':
            case 'UNDERLINE':
                newState = RichUtils.toggleInlineStyle(editorState, command)
                break
            case 'TAB':
                if (getAnchorRelativePosition() !== 0) {
                    newState = EditorState.push(
                        editorState, 
                        Modifier.replaceText(
                            editorState.getCurrentContent(), 
                            editorState.getSelection(), 
                            '\t'
                        ), 
                        'insert-characters'
                    )

                    newLog = deleteSelectedCharacters(newLog)
                    setLog(newLog)
                } else {
                    newState = EditorState.push(
                        editorState, 
                        Modifier.insertText(
                            editorState.getCurrentContent(), 
                            editorState.getSelection(), 
                            '\t'
                        ), 
                        'insert-characters'
                    )
                }
                break
            case 'PASTE':
                newState = editorState

                navigator.clipboard.readText().then(text => {
                    const elapsedTime = timer.getElapsedTime()
                    const characterCount = newLog.filter(l => !l.special && !l.deleted).length
                    const characterCountAtFocus = getCharacterCountAtFocus()
                    const iki = timer.getInterval()
                    const added = characterCountAtFocus < characterCount
                    const nextCharacter = newLog.filter(l => !l.special && !l.deleted).filter((l, i) => i === characterCountAtFocus)[0]
                    
                    newState = EditorState.push(
                        editorState, 
                        Modifier.insertText(
                            editorState.getCurrentContent(), 
                            editorState.getSelection(),
                            text
                        ),
                        'insert-characters'
                    )

                    if (!added) {
                        text.split('').forEach((t, i) => {
                            newLog.push({
                                id: uuid(), 
                                key: t,
                                elapsedTime,
                                characterCount,
                                cursorPosition: characterCountAtFocus,
                                iki: (i === 0 ? iki : 0),
                                timeIndex: timer.getCount(),
                                added,
                                special: false,
                                pasted: true
                            })
                        })
                    } else {
                        const timeIndex = timer.getCount()
                        const id = uuid()
                        const insertAtIndex = newLog.findIndex(l => {
                            return l.id === (nextCharacter ? nextCharacter.id : '')
                        })
                        
                        text.split('').forEach((t, i) => {
                            newLog.splice(
                                insertAtIndex + i, 
                                0, 
                                {
                                    id: (i === 0 ? id : uuid()),
                                    key: t,
                                    elapsedTime,
                                    characterCount,
                                    cursorPosition: characterCountAtFocus,
                                    iki: (i === 0 ? iki : 0),
                                    timeIndex: (i ===0 ? timeIndex : timer.getCount()),
                                    added,
                                    special: false,
                                    pasted: true
                                }
                            )
                        })
                    }

                    onChange(newState)
                    setLog(newLog)
                }).catch(e => {
                    console.error(e)
                })
                break
            default:
                newState = RichUtils.handleKeyCommand(editorState, command)
        }

        if (newState) {
            onChange(newState)

            return 'handled'
        }

        return 'not-handled'
    }

    const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        let newLog = [...log]
        let previousCharacter = newLog.filter(l => !l.special && !l.deleted).filter((l, i) => i === previousCharacterCountAtFocus.current - 1)[0]
        let currentCharacter = newLog.filter(l => !l.special && !l.deleted).filter((l, i) => i === getCharacterCountAtFocus() - 1)[0]

        if (previousCharacterCountAtFocus.current !== getCharacterCountAtFocus()) {
            movementIndex.current++
            
            if (previousCharacter === undefined) {
                previousCharacter = newLog.filter(l => !l.special && !l.deleted).filter((l, i) => i === previousCharacterCountAtFocus.current)[0]
                
                newLog = newLog.map(l => (l.id === previousCharacter.id ? 
                    {...l, fromLeft: l.fromLeft ? [...l.fromLeft, movementIndex.current] : [movementIndex.current]} 
                    : l))

                newLog = newLog.map(l => (l.id === currentCharacter.id ? 
                    {...l, to: l.to ? [...l.to, movementIndex.current] : [movementIndex.current]} 
                    : l))
            } else if (currentCharacter === undefined) {
                currentCharacter = newLog.filter(l => !l.special && !l.deleted).filter((l, i) => i === getCharacterCountAtFocus())[0]
                
                newLog = newLog.map(l => (l.id === previousCharacter.id ? 
                    {...l, from: l.from ? [...l.from, movementIndex.current] : [movementIndex.current]} 
                    : l))
                
                newLog = newLog.map(l => (l.id === currentCharacter.id ? 
                    {...l, toLeft: l.toLeft ? [...l.toLeft, movementIndex.current] : [movementIndex.current]} 
                    : l))
            } else {
                newLog = newLog.map(l => (l.id === previousCharacter.id ? {...l, from: l.from ? [...l.from, movementIndex.current] : [movementIndex.current]} 
                    : l.id === currentCharacter.id ? {...l, to: l.to ? [...l.to, movementIndex.current] : [movementIndex.current]} 
                    : l))
            }
        }

        newLog.push({
            id: uuid(), 
            key: 'MouseClick',
            elapsedTime: timer.getElapsedTime(),
            characterCount: getTotalCharacterCount(),
            cursorPosition: getCharacterCountAtFocus(),
            iki: timer.getInterval(),
            timeIndex: timer.getCount(),
            special: true,
            to: [movementIndex.current]
        })

        setLog(newLog)
        previousCharacterCountAtFocus.current = getCharacterCountAtFocus()
    }

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (lastMouseMovement.current === 0) {
            lastMouseMovement.current = new Date().getTime()
        } else if (new Date().getTime() - lastMouseMovement.current > 2000) {
            const newLog = [...log]
            const added = getCharacterCountAtFocus() < getTotalCharacterCount()

            if (!added) {
                newLog.push({
                    id: uuid(), 
                    key: 'MouseMovement',
                    elapsedTime: timer.getElapsedTime(),
                    characterCount: getTotalCharacterCount(),
                    cursorPosition: getCharacterCountAtFocus(),
                    iki: timer.getInterval(),
                    timeIndex: timer.getCount(),
                    special: true
                })
            } else {
                const nextCharacter = newLog.filter(l => !l.special && !l.deleted).filter((l, i) => i === getCharacterCountAtFocus())[0]
                const insertAtIndex = newLog.findIndex(l => {
                    return l.id === (nextCharacter ? nextCharacter.id : '')
                })
    
                newLog.splice(
                    insertAtIndex, 
                    0, 
                    {
                        id: uuid(), 
                        key: 'MouseMovement',
                        elapsedTime: timer.getElapsedTime(),
                        characterCount: getTotalCharacterCount(),
                        cursorPosition: getCharacterCountAtFocus(),
                        iki: timer.getInterval(),
                        timeIndex: timer.getCount(),
                        special: true
                    }
                )
            }
            

            lastMouseMovement.current = new Date().getTime()

            setLog(newLog)
        }
    }

    const myKeyBinding = (e: any) => {
        let keyBinding: any
        let special = false
        let newLog: WritingLogType[] = [...log]
        let characterCountAtFocus = getCharacterCountAtFocus()
        let characterCountAtAnchor = getCharacterCountAtAnchor()
        const nextCharacter = newLog.filter(l => !l.special && !l.deleted).filter((l, i) => i === characterCountAtFocus)[0]
        const totalCharacterCount = getTotalCharacterCount()
        const added = characterCountAtFocus < totalCharacterCount
        const anchorRelativePosition = getAnchorRelativePosition()

        if (hasCommandModifier(e) && e.key === 'n') {
            keyBinding = 'BOLD'
            special = true
        } else if (hasCommandModifier(e) && e.key === 'i') {
            keyBinding = 'ITALIC'
            special = true
        } else if (hasCommandModifier(e) && e.key === 's') {
            keyBinding = 'UNDERLINE'
            special = true
        } else if (hasCommandModifier(e) && e.key === 'c') {
            keyBinding = getDefaultKeyBinding(e)
            special = true
        } else if (hasCommandModifier(e) && e.key === 'x') {
            keyBinding = getDefaultKeyBinding(e)
            special = true

            if (anchorRelativePosition !== 0) {
                const selectionStart = (anchorRelativePosition < 0 ? characterCountAtAnchor : characterCountAtFocus)
                const selectionEnd = (anchorRelativePosition < 0 ? characterCountAtFocus : characterCountAtAnchor)

                newLog = newLog.map(l => {
                    const characters = newLog.filter(l => !l.special && !l.deleted).filter((l, i) => i >= selectionStart && i < selectionEnd)

                    return (
                        characters.map(c => c.id).includes(l.id)
                        ? {...l, deleted: true, cut: true}
                        : l
                    )
                })
            }
        } else if (hasCommandModifier(e) && e.key === 'v') {
            keyBinding = 'PASTE'
            special = true
        } else if (e.key === 'Tab') {
            e.preventDefault()

            keyBinding = 'TAB'
        } else if (e.key === 'Backspace' || e.key === 'Delete') {
            special = true
            keyBinding = getDefaultKeyBinding(e)

            if (anchorRelativePosition === 0) {
                newLog = newLog.map(l => {
                    const character = newLog.filter(l => !l.special && !l.deleted).filter((l, i) => i === characterCountAtFocus - (e.key === 'Backspace' ? 1 : 0))[0]
                    
                    return (
                        l.id === (character ? character.id : '')
                        ? {...l, deleted: true}
                        : l
                    )
                })
            } else {
                newLog = deleteSelectedCharacters(newLog)
            }
        } else {
            if ([
                    'Shift', 
                    'ArrowUp', 
                    'ArrowRight', 
                    'ArrowDown', 
                    'ArrowLeft', 
                    'Control', 
                    'Alt', 
                    'AltGraph', 
                    'CapsLock',
                    'NumLock',
                    'Home',
                    'End',
                    'PageUp',
                    'PageDown',
                    'Insert',
                    'Escape',
                    'F1', 'F2', 'F3', 'F4', 'F5', 'F6',
                    'F7', 'F8', 'F9', 'F10', 'F11', 'F12'
                ].includes(e.key)) {
                special = true
            } else {
                if (anchorRelativePosition !== 0) {
                    newLog = deleteSelectedCharacters(newLog)
                }
            }
            
            keyBinding = getDefaultKeyBinding(e)
        }

        if (!added) {
            const charLog = newLog.filter(l => !l.special)
            const pauseType = e?.key.match(/^\w{1}$/) && charLog[charLog.length - 1]?.key.match(/^Enter$/)
            ? 'PRE_PARAGRAPH'
            : e?.key.match(/^Enter$/)
            ? 'POST_PARAGRAPH'
            : e?.key.match(/^ {1}$/) && charLog[charLog.length - 1]?.key.match(/^[.:?!…]{1}$/)
            ? 'PRE_SENTENCE'
            : e?.key.match(/^[.:?!…]{1}$/) && charLog[charLog.length - 1]?.key.match(/^\w{1}$/)
            ? 'POST_SENTENCE'
            : e?.key.match(/^\w{1}$/) && charLog[charLog.length - 1]?.key.match(/^\w{1}$/) 
            ? 'IN_TOKEN' 
            : e?.key.match(/^\w{1}$/) && charLog[charLog.length - 1]?.key.match(/^ {1}$/) 
            ? 'PRE_TOKEN'
            : e?.key.match(/^ {1}$/) && charLog[charLog.length - 1]?.key.match(/^\w{1}$/)
            ? 'POST_TOKEN'
            : 'UNKNOWN'

            newLog.push({
                id: uuid(), 
                key: e.key,
                elapsedTime: timer.getElapsedTime(),
                characterCount: totalCharacterCount,
                cursorPosition: characterCountAtFocus,
                iki: timer.getInterval(),
                timeIndex: timer.getCount(),
                added,
                special,
                pauseType
            })
        } else {
            const insertAtIndex = newLog.findIndex(l => {
                return l.id === (nextCharacter ? nextCharacter.id : '')
            })
            const timeIndex = timer.getCount()
            const id = uuid()

            newLog.splice(
                (e.key === 'Backspace' ? insertAtIndex - 1 : insertAtIndex), 
                0, 
                {
                    id,
                    key: e.key,
                    elapsedTime: timer.getElapsedTime(),
                    characterCount: totalCharacterCount,
                    cursorPosition: characterCountAtFocus,
                    iki: timer.getInterval(),
                    timeIndex,
                    added,
                    special
                }
            )
        }

        setLog(newLog)

        return keyBinding
    }

    const myBlockStyle = (contentBlock: ContentBlock) => {
        const align = contentBlock.getData().get('align')

        switch (align)  {
            case 'left':
                return 'text-left'
            case 'center':
                return 'text-center'
            case 'right':
                return 'text-right'
            case 'justify':
                return 'text-justify'
        }

        return ''
    }

    const handleBold = () => {
        if (editorState) {
            onChange(RichUtils.toggleInlineStyle(editorState, 'BOLD'))
        }
    }

    const handleItalic = () => {
        if (editorState) {
            onChange(RichUtils.toggleInlineStyle(editorState, 'ITALIC'))
        }
    }

    const handleUnderline = () => {
        if (editorState) {
            onChange(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'))
        }
    }

    const handleFontSize = (size: number) => {
        if (editorState) {
            const nextContentState = Modifier.applyInlineStyle(editorState.getCurrentContent(), editorState.getSelection(), `FONT-${size}`)
            const newEditorState = EditorState.push(editorState, nextContentState, 'change-inline-style')

            onChange(newEditorState)
        }
    }
    
    const handleNormal = () => {
        if (editorState) {
            onChange(RichUtils.toggleInlineStyle(editorState, 'NORMAL'))
        }
    }

    const handleUppercase = () => {
        if (editorState) {
            onChange(RichUtils.toggleInlineStyle(editorState, 'UPPERCASE'))
        }
    }

    const handleLowercase = () => {
        if (editorState) {
            onChange(RichUtils.toggleInlineStyle(editorState, 'LOWERCASE'))
        }
    }

    const handleCapitalize = () => {
        if (editorState) {
            onChange(RichUtils.toggleInlineStyle(editorState, 'CAPITALIZE'))
        }
    }

    const handleAlignLeft = () => {
        if (editorState) {
            const nextContentState = Modifier.setBlockData(editorState.getCurrentContent(), editorState.getSelection(), Immutable.Map({'align': 'left'}))
            const newEditorState = EditorState.push(editorState, nextContentState, 'change-block-data')

            onChange(newEditorState)
        }
    }

    const handleAlignCenter = () => {
        if (editorState) {
            const nextContentState = Modifier.setBlockData(editorState.getCurrentContent(), editorState.getSelection(), Immutable.Map({'align': 'center'}))
            const newEditorState = EditorState.push(editorState, nextContentState, 'change-block-data')

            onChange(newEditorState)
        }
    }

    const handleAlignRight = () => {
        if (editorState) {
            const nextContentState = Modifier.setBlockData(editorState.getCurrentContent(), editorState.getSelection(), Immutable.Map({'align': 'right'}))
            const newEditorState = EditorState.push(editorState, nextContentState, 'change-block-data')

            onChange(newEditorState)
        }
    }

    const handleAlignJustify = () => {
        if (editorState) {
            const nextContentState = Modifier.setBlockData(editorState.getCurrentContent(), editorState.getSelection(), Immutable.Map({'align': 'justify'}))
            const newEditorState = EditorState.push(editorState, nextContentState, 'change-block-data')

            onChange(newEditorState)
        }
    }

    const handleUndo = () => {
        if (editorState) {
            onChange(EditorState.undo(editorState))
        }
    }

    const handleRedo = () => {
        if (editorState) {
            onChange(EditorState.redo(editorState))
        }
    }

    const getTotalCharacterCount = () => {
        return log.filter(l => !l.special && !l.deleted).length
    }
    
    const getCharacterCountAtFocus = () => {
        const focusBlockKey = editorState.getSelection().getFocusKey()
        const focusPositionInLine = editorState.getSelection().getFocusOffset()
        let characterCountAtFocus = 0

        editorState.getCurrentContent().getBlocksAsArray().every((b, i) => {
            if (b.getKey() !== focusBlockKey) {
                characterCountAtFocus += b.getLength()
                return true
            }

            characterCountAtFocus += focusPositionInLine + i
            
            return false
        })

        return characterCountAtFocus
    }

    const getCharacterCountAtAnchor = () => {
        let characterCountAtAnchor = 0
        const anchorBlockKey = editorState.getSelection().getAnchorKey()
        const anchorPositionInLine = editorState.getSelection().getAnchorOffset()

        editorState.getCurrentContent().getBlocksAsArray().every((b, i) => {
            if (b.getKey() !== anchorBlockKey) {
                characterCountAtAnchor += b.getLength()
                return true
            }

            characterCountAtAnchor += anchorPositionInLine + i
            
            return false
        })

        return characterCountAtAnchor
    }

    const getAnchorRelativePosition = () => {
        return getCharacterCountAtAnchor() - getCharacterCountAtFocus()
    }

    const deleteSelectedCharacters = (newLog: WritingLogType[]) => {
        let characterCountAtFocus = getCharacterCountAtFocus()
        let characterCountAtAnchor = getCharacterCountAtAnchor()
        const anchorRelativePosition = getAnchorRelativePosition()
        const selectionStart = (anchorRelativePosition < 0 ? characterCountAtAnchor : characterCountAtFocus)
        const selectionEnd = (anchorRelativePosition < 0 ? characterCountAtFocus : characterCountAtAnchor)

        newLog = newLog.map(l => {
            const characters = newLog.filter(l => !l.special && !l.deleted).filter((l, i) => i >= selectionStart && i < selectionEnd)

            return (
                characters.map(c => c.id).includes(l.id)
                ? {...l, deleted: true}
                : l
            )
        })

        return newLog
    }

    useEffect(() => {
        navigator.permissions.query({name: 'clipboard-read' as PermissionName}).then(result => {
            if (result.state !== 'granted') {
                navigator.clipboard.readText()

                swal({
                    title: 'Por favor, dê permissão ao site para interagir com a área de transferência.',
                    text: 'Basta clicar em "Permitir" no canto superior esquerdo.',
                    icon: 'warning'
                }).then(() => {
                    setRefreshId(uuid())
                })
            }
        })
        
        if (props.show) {
            handleResize()
            editor.current.focus()
            timer.setInitialTimestamp()

            window.addEventListener('resize', handleResize)
            window.addEventListener('blur', handleWindowBlur)
            window.addEventListener('focus', handleWindowFocus)
        }

        return () => {
            window.removeEventListener('resize', handleResize)
            window.removeEventListener('blur', handleWindowBlur)
            window.removeEventListener('focus', handleWindowFocus)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshId, props.show])

    useEffect(() => {
        if (props.show && finish) {
            props.addData(data => {
                return [
                    ...data, 
                    {
                        instrument: INSTRUMENTS.WRITINGLOGGER, 
                        instrumentId: props.id, 
                        instrumentName: props.name || '',
                        type: 'Product', 
                        response: JSON.stringify(convertToRaw(editorState.getCurrentContent())), 
                        correct: [], 
                        time: 0
                    }, 
                    {
                        instrument: INSTRUMENTS.WRITINGLOGGER, 
                        instrumentId: props.id, 
                        instrumentName: props.name || '',
                        type: 'Process', 
                        response: log, 
                        correct: [], 
                        time: 0
                    }
                ]
            })
            
            props.next()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finish])
    return (
        !props.show ? null :
        <>
            <Navbar 
                bg='light' 
                fixed='top' 
                className='justify-content-center border-bottom py-3' 
                onMouseDown={(e: React.MouseEvent) => e.preventDefault()}>
                <ButtonGroup className='mx-2'>
                    <IconButton 
                        icon={ICONS.BOLD} 
                        variant='outline-secondary'
                        onClick={handleBold}
                        title='Negrito (Ctrl + N)'
                        active={editorState.getCurrentInlineStyle().has('BOLD')}/>
                    <IconButton 
                        icon={ICONS.ITALIC} 
                        variant='outline-secondary'
                        onClick={handleItalic}
                        title='Itálico (Ctrl + I)'
                        active={editorState.getCurrentInlineStyle().has('ITALIC')}/>
                    <IconButton 
                        icon={ICONS.UNDERLINE} 
                        variant='outline-secondary'
                        onClick={handleUnderline}
                        title='Sublinhado (Ctrl + S)'
                        active={editorState.getCurrentInlineStyle().has('UNDERLINE')}/>
                </ButtonGroup>

                <ButtonGroup className='mx-2'>
                    <IconButton 
                        icon={ICONS.ALIGN_LEFT}
                        variant='outline-secondary'
                        onClick={handleAlignLeft}
                        title='Alinhar à esquerda'/>
                    <IconButton
                        icon={ICONS.ALIGN_CENTER}
                        variant='outline-secondary'
                        onClick={handleAlignCenter}
                        title='Centralizar'/>
                    <IconButton
                        icon={ICONS.ALIGN_RIGHT}
                        variant='outline-secondary'
                        onClick={handleAlignRight}
                        title='Alinhar à direita'/>
                    <IconButton
                        icon={ICONS.ALIGN_JUSTIFY}
                        variant='outline-secondary'
                        onClick={handleAlignJustify}
                        title='Justificar'/>
                </ButtonGroup>

                <Dropdown className='mx-2'>
                    <Dropdown.Toggle 
                        onMouseDown={(e: React.MouseEvent) => e.preventDefault()}
                        variant="outline-secondary" 
                        id="dropdown-basic">
                        <FaFont/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Header>Tamanho</Dropdown.Header>
                        {
                            [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 28].map((n: number) => 
                                <Dropdown.Item 
                                    key={uuid()}
                                    onClick={() => handleFontSize(n)}
                                    onMouseDown={(e: React.MouseEvent) => e.preventDefault()}>
                                        {n}pt
                                </Dropdown.Item>
                            )
                        }
                        <Dropdown.Divider/>
                        <Dropdown.Header>Capitalização</Dropdown.Header>
                        <Dropdown.Item 
                            onClick={handleNormal}
                            onMouseDown={(e: React.MouseEvent) => e.preventDefault()}>
                                Normal
                        </Dropdown.Item>
                        <Dropdown.Item 
                            onClick={handleUppercase}
                            onMouseDown={(e: React.MouseEvent) => e.preventDefault()}>
                                MAIÚSCULAS
                        </Dropdown.Item>
                        <Dropdown.Item 
                            onClick={handleLowercase}
                            onMouseDown={(e: React.MouseEvent) => e.preventDefault()}>
                                minúsculas
                        </Dropdown.Item>
                        <Dropdown.Item 
                            onClick={handleCapitalize}
                            onMouseDown={(e: React.MouseEvent) => e.preventDefault()}>
                                Primeira Letra Maiúscula
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <ButtonGroup className='mx-2'>
                    <IconButton
                        icon={ICONS.UNDO}
                        variant='outline-secondary'
                        onClick={handleUndo}
                        title='Desfazer (Ctrl + Z)'/>
                    <IconButton
                        icon={ICONS.REDO}
                        variant='outline-secondary'
                        onClick={handleRedo}
                        title='Refazer (Ctrl + Y)'/>
                </ButtonGroup>
            </Navbar>
            <Container className='instrument-container position-relative my-5'>
                <Row>
                    <Col>
                        <div 
                            className='WritingLogger-container position-relative' 
                            style={{height: `${pageHeight + (pageWidth * .1)}px`}}>
                            <div
                                className='WritingLogger page my-5'
                                style={{
                                    width: '100%', 
                                    height: `${pageHeight}px`,
                                    padding: `${pageWidth * .1}px`,
                                    fontSize: `${pageWidth * fontSize * .00168095238}px`
                                }}
                                ref={container}
                                onClick={handleClick}
                                onMouseMove={handleMouseMove}>
                                    <Editor 
                                        editorState={editorState}
                                        handleKeyCommand={handleKeyCommand} 
                                        keyBindingFn={myKeyBinding}
                                        customStyleMap={styleMap}
                                        blockStyleFn={myBlockStyle}
                                        onChange={onChange}
                                        ref={editor}/>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <IconButton icon={ICONS.CONTINUE} type='submit' onClick={() => setFinish(true)}>Continuar</IconButton>
                    </Col>
                </Row>
            </Container>
        </>
        )
}

export default WritingLogger