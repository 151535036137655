import React from 'react'
import shuffle from 'shuffle-array'

import ConfigType from '../../../types/ConfigType'
import { equations_training, equations } from './equations'

import { FIELDS, TRIALS } from '../../../constants/instruments'


const config: ConfigType[] = []

// Instructions - Training
config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>In this test you will solve simple math problems while you also try to memorize the result of each problem.</p>
            <p>In the next few minutes, you will have some practice to get you familiar with how the task works.</p> 
            <p>You will see sets of two, three, and four math problems presented randomly.</p>
            <p>Click the continue button to begin.</p>
        </>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>The math problems will appear on the screen one at a time, like this: “(2 * 1) + 1 = 3”.</p>
            <p>As soon as you see the math problem, you should compute the correct answer. In the above problem, the answer 3 is correct.</p>
            <p>An example of a math problem with an incorrect answer would be: “(9 ÷ 3) - 2 = 2”.</p>
            <p>You also need to memorize the result of the math problem you saw, regardless of it being correct.</p>
            <p>When you have solved the equation to determine whether it is correct and memorized its result, click the mouse button.</p>
            <p>Take a moment to make sure you understand the instructions.</p>
            <p>Click the mouse to continue.</p>
        </>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>
                You will then see “The math problem was...” displayed on the next screen, along with a box marked CORRECT and a box marked INCORRECT.
            </p>
            <p>If the math problem on the previous screen was correct, click on the CORRECT box. If the math problem was incorrect, click the INCORRECT box.</p>
            <p>After you click on one of the boxes, the computer will tell you if you made the right choice.</p>
            <p>It is VERY important to solve the math problems as QUICKLY and as ACCURATELY as possible.</p>
            <p>Take a moment to make sure you understand the instructions.</p>
            <p>Click the mouse to continue.</p>
        </>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>Another math problem will appear for you to solve to decide whether it is correct or incorrect and memorize its result.</p>
            <p>
                When you have solved all the problems in a set, a recall screen will appear for you to write the result of each problem in the order they were presented.
            </p>
            <p>If you forget one of the results, leave the corresponding spot blank.</p>
            <p>Remember, it is VERY important to write the results in the same order you saw them.</p>
            <p>Take a moment to make sure you understand the instructions.</p>
            <p>Click the mouse to continue.</p>
        </>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>It is VERY important that you solve the math problems CORRECTLY.</p>
            <p>
                It is also important that you try and solve the problems as QUICKLY as you can WHILE doing your best to recall as many results as possible in the order they were presented.
            </p>
            <p>
                <strong>IMPORTANT:</strong> you cannot take notes of the results during the problem presentation. You should rely on your memory to remember them.
            </p>
            <p>Take a moment to make sure you understand the instructions.</p>
            <p>When you are ready, click the continue button to try some practice problems.</p>
        </>
    )
})

// Training
shuffle(equations_training)

// Training
for (let set of equations_training) {
    for (let equation of set) {
        // Sentence
        config.push({
            type: TRIALS.STIMULUS,
            stimulus: equation.equation,
            response: {
                type: 'click',
            },
            training: true
        })

        // Semantic acceptability judgement
        config.push({
            type: TRIALS.STIMULUS,
            stimulus: 'The math problem was...',
            response: {
                type: 'button',
                buttons: [`${equation.true ? 'CORRECT' : 'INCORRECT'}`, `${!equation.true ? 'CORRECT' : 'INCORRECT'}`],
                centralizeButtons: true,
                showCorrect: true, //Only in trainning
                order: 'asc'
            },
            training: true
        })

        // Blank
        config.push({
            type: TRIALS.BLANK,
            training: true
        })
    }

    // Recall
    config.push({
        type: TRIALS.QUESTION,
        title: <span>Write the results<br/> of the problems in the order<br/> they appeared.</span>,
        questions: set.map(() => ({prompt: '', type: FIELDS.INPUT})),
        correctResponses: set.map((e) => e.result),
        training: true
    })
}

// Instructions
config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>That is the end of the practice.</p>
            <p>The real trials will look just like the practice trials you just completed.</p>
            <p>You will see sets of 2 to 5 math problems presented randomly.</p>
            <p>It is important that you do your best both on deciding whether the problems are correct and memorizing the result of each problem.</p>
            <p>You will not be told if you judged the problems accurately.</p>
            <p>Take a moment to make sure you understand the instructions.</p>
            <p>Click the continue button to begin the task.</p>
        </>
    )
})

// Test
shuffle(equations)

// Test
for (let set of equations) {
    for (let equation of set) {
        // Sentence
        config.push({
            type: TRIALS.STIMULUS,
            stimulus: equation.equation,
            response: {
                type: 'click',
            },
        })

        // Semantic acceptability judgement
        config.push({
            type: TRIALS.STIMULUS,
            stimulus: 'The math problem was...',
            response: {
                type: 'button',
                buttons: [`${equation.true ? 'CORRECT' : 'INCORRECT'}`, `${!equation.true ? 'CORRECT' : 'INCORRECT'}`],
                centralizeButtons: true,
                order: 'asc'
            },
        })

        // Blank
        config.push({
            type: TRIALS.BLANK,
        })
    }

    // Recall
    config.push({
        type: TRIALS.QUESTION,
        title: <span>Write the results<br/> of the problems in the order<br/> they appeared.</span>,
        questions: set.map(() => ({prompt: '', type: FIELDS.INPUT})),
        correctResponses: set.map((e) => e.result)
    })
}


export default config