import React, { useState, useContext, useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { FirebaseContext } from '../../Firebase'
import { IconButton, ICONS, Loading, Audio } from '../../Layout'

import * as ROUTES from '../../../constants/routes'
import { Button } from 'react-bootstrap'

function PrimingTaskOptions() {
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState([] as any)
    const [newPrimeString, setNewPrimeString] = useState('')
    const [newPrimeImage, setNewPrimeImage] = useState('')
    const [newPrimeAudio, setNewPrimeAudio] = useState('')
    const [newTargetString, setNewTargetString] = useState('')
    const [newTargetImage, setNewTargetImage] = useState('')
    const [newTargetAudio, setNewTargetAudio] = useState('')
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true)
    const match = useRouteMatch<{experimentId: string, instrumentId: string}>(ROUTES.RESEARCHER_EXPERIMENTS_INSTRUMENTS_DETAILS)
    const firebase = useContext(FirebaseContext)
    const history = useHistory()

    const handleRemoveOption = (index: number) => {
        setOptions(options.filter((q: any, i: number) => i !== index))
        setSaveButtonDisabled(false)
    }

    const handleAddStimuli = () => {
        if (options) {
            setOptions(
                [...options, {
                    prime: {text: newPrimeString, img: newPrimeImage, audio: newPrimeAudio},
                    target: {text: newTargetString, img: newTargetImage, audio: newTargetAudio}
                }]
            )
        } else {
            setOptions([{
                prime: {text: newPrimeString, img: newPrimeImage, audio: newPrimeAudio},
                target: {text: newTargetString, img: newTargetImage, audio: newTargetAudio}
            }])
        }

        setNewPrimeString('')
        setNewPrimeImage('')
        setNewPrimeAudio('')
        setNewTargetString('')
        setNewTargetImage('')
        setNewTargetAudio('')
        setSaveButtonDisabled(false)
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        setLoading(true)

        firebase?.instrument(match?.params.experimentId as string, match?.params.instrumentId as string).child('options')
            .set(options).then(() => {
                setSaveButtonDisabled(true)
                history.push(`/pesquisador/experimentos/${match?.params.experimentId}`)
            })
    }

    useEffect(() => {
        setNewPrimeAudio('')
        setNewPrimeImage('')
    }, [newPrimeString])

    useEffect(() => {
        setNewPrimeString('')
        setNewPrimeImage('')
    }, [newPrimeAudio])

    useEffect(() => {
        setNewPrimeAudio('')
        setNewPrimeString('')
    }, [newPrimeImage])

    useEffect(() => {
        setNewTargetAudio('')
        setNewTargetImage('')
    }, [newTargetString])

    useEffect(() => {
        setNewTargetString('')
        setNewTargetImage('')
    }, [newTargetAudio])

    useEffect(() => {
        setNewTargetAudio('')
        setNewTargetString('')
    }, [newTargetImage])

    useEffect(() => {
        firebase?.instrument(match?.params.experimentId as string, match?.params.instrumentId as string).on('value', snapshot => {
            setOptions(snapshot.val().options)
        })

        return () => {
            firebase?.instrument(
                match?.params.experimentId as string, 
                match?.params.instrumentId as string
            ).off()}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        loading ? <Loading/> :
        <>
            <h2>Editar Tarefa de Priming</h2>
            <Table striped bordered hover responsive className='my-3'>
                <thead>
                    <tr>
                        <th>Prime</th>
                        <th>Alvo</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        options?.map(
                            (o: {
                                prime: {text?: string, img?: string, audio?: string},
                                target: {text?: string, img?: string, audio?: string}
                            }, i: number) => (
                            <tr key={uuid()}>
                                <td>
                                  {o.prime.text}
                                  {
                                    o.prime.img &&
                                    <>
                                      <img src={o.prime.img} alt='Prime imagético' width={300}/> <br/>
                                      ...{o.prime.img.slice(-15)}
                                    </>
                                  } 
                                  {
                                    o.prime.audio && 
                                    <>
                                      <Audio src={o.prime.audio} /> <br/>
                                      ...{o.prime.audio.slice(-15)}
                                    </>
                                  }
                                </td>
                                <td>
                                  {o.target.text}
                                  {
                                    o.target.img &&
                                    <>
                                      <img src={o.target.img} alt='Alvo imagético' width={300}/> <br/>
                                      ...{o.target.img.slice(-15)}
                                    </>
                                  } 
                                  {
                                    o.target.audio && 
                                    <>
                                      <Audio src={o.target.audio} /> <br/>
                                      ...{o.target.audio.slice(-15)}
                                    </>
                                  }
                                </td>
                                <td className='text-center'>
                                    <IconButton 
                                        icon={ICONS.DELETE} 
                                        variant='danger' 
                                        size='sm'
                                        onClick={() => handleRemoveOption(i)}/>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
            
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Form.Text>
                            <h3>Adicionar estímulos</h3>
                        </Form.Text>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Text>
                            <h3>Prime</h3>
                        </Form.Text>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Sequência de caracteres</Form.Label>
                            <Form.Control 
                                value={newPrimeString}
                                onChange={e => setNewPrimeString(
                                    e.target.value
                                )}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Áudio</Form.Label>
                            <Form.Control 
                                value={newPrimeAudio}
                                onChange={e => setNewPrimeAudio(
                                    e.target.value
                                )}
                                placeholder='URL de arquivo de áudio'/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Imagem</Form.Label>
                            <Form.Control
                                value={newPrimeImage}
                                onChange={e => setNewPrimeImage(
                                    e.target.value
                                )}
                                placeholder='URL de arquivo de imagem'/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Text>
                            <h3>Alvo</h3>
                        </Form.Text>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Sequência de caracteres</Form.Label>
                                <Form.Control 
                                    value={newTargetString}
                                    onChange={e => setNewTargetString(
                                        e.target.value
                                    )}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Áudio</Form.Label>
                            <Form.Control 
                                value={newTargetAudio}
                                onChange={e => setNewTargetAudio(
                                    e.target.value
                                )}
                                placeholder='URL de arquivo de áudio'/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Imagem</Form.Label>
                            <Form.Control
                                value={newTargetImage}
                                onChange={e => setNewTargetImage(
                                    e.target.value
                                )}
                                placeholder='URL de arquivo de imagem'/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Button 
                            onClick={handleAddStimuli} 
                            variant="success"
                            disabled={(!newPrimeString && !newPrimeImage && !newPrimeAudio) || (!newTargetString && !newTargetImage && !newTargetAudio)}>
                            
                            Adicionar
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <IconButton
                            icon={ICONS.BACK}
                            onClick={() => {history.goBack()}}>
                            
                            Voltar
                        </IconButton>
                        <IconButton 
                            type='submit' 
                            icon={ICONS.SAVE}
                            className='ml-1'
                            disabled={saveButtonDisabled}
                            variant={!saveButtonDisabled ? 'warning' : undefined}>
                            
                            Salvar
                        </IconButton>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default PrimingTaskOptions