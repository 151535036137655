import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { withAuthorization, withEmailVerification } from '../Session'
import UserList from  './UserList'
import UserItem from './UserItem'

import * as ROLES from '../../constants/roles'
import * as ROUTES from '../../constants/routes'

function Admin() {
    return (
        <>
            <Container className='my-5 text-center'>
                <Row>
                    <Col>
                        <h1>Admin</h1>
                    </Col>
                </Row>
            </Container>
            <Switch>
                <Route exact path={ROUTES.ADMIN_USERS_DETAILS} component={UserItem}/>
                <Route exact path={ROUTES.ADMIN} component={UserList}/>
            </Switch>
        </>
    )
}

const condition = (authUser: any) => authUser && !!authUser.roles[ROLES.ADMIN]

export default withAuthorization(condition)(withEmailVerification(Admin))