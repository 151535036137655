import React, { useEffect, useState, useContext, useRef } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import md5 from 'crypto-js/md5'

import { FirebaseContext } from '../Firebase'
import { Loading, IconButton, ICONS } from '../Layout'
import { AuthUserContext } from '../Session'
import DataType from '../../types/DataType'
import InstrumentType from '../../types/InstrumentType'
import TextPage from '../Instruments/TextPage'
import TCLE from '../Instruments/TCLE'
import Survey from '../Instruments/Survey'
import TCLA from '../Instruments/TCLA'
import QuExPLi from '../Instruments/QuExPLi'
import WritingLogger from '../Instruments/WritingLogger'
import WritingLoggerOutput from '../Instruments/WritingLogger/WritingLoggerOutput'
import ReadingLogger from '../Instruments/ReadingLogger/index'
import ReadingLoggerOutput from '../Instruments/ReadingLogger/ReadingLoggerOutput'
import SongSelector from '../Instruments/SongSelector'
import DRM from '../Instruments/DRM'
import WordSpan from '../Instruments/WordSpan'
import PrimingTask from '../Instruments/PrimingTask'
import RST from '../Instruments/RST'
import RSTEnglish from '../Instruments/RSTEnglish'
import SST from '../Instruments/SST'
import SSTEnglish from '../Instruments/SSTEnglish'
import OST from '../Instruments/OST'
import OSTEnglish from '../Instruments/OSTEnglish'
import LetCom from '../Instruments/LetCom'
import LetComEnglish from '../Instruments/LetComEnglish'
import PatCom from '../Instruments/PatCom'
import PatComEnglish from '../Instruments/PatComEnglish'
import WordByWord from '../Instruments/WordByWord'
import LexicalDecision from '../Instruments/LexicalDecision'

import * as ROUTES from '../../constants/routes'
import * as ROLES from '../../constants/roles'
import { INSTRUMENTS } from '../../constants/instruments'

import './index.css'
import { TimerComponent, TimerContext } from '../Instruments/Timer'

function DataCollection() {
    const match = useRouteMatch<{id: string}>(ROUTES.DATA_COLLECTION)
    const [loading, setLoading] = useState(true)
    const [experiment, setExperiment] = useState(null as any)
    const [data, setData] = useState([] as DataType[])
    const [finish, setFinish] = useState(false)
    const [show, setShow] = useState(-1)
    const authUser: any = useContext(AuthUserContext)
    const firebase = useContext(FirebaseContext)
    const timer = useContext(TimerContext)
    const history = useHistory()
    const showNext = useRef(false)

    const handleStart = () => {
        const date = new Date()
        
        document.documentElement.requestFullscreen()
        setShow(0)
        setData(data => [...data, {
            instrument: 'START', 
            instrumentId: 'START',
            instrumentName: 'START',
            type: 'START', 
            response: `Date: ${date}`,
            correct: [], 
            time: date.getTime()
        }])
    }

    const handleNext = (finish?: boolean, skip?: boolean) => {
        if (!finish) {
            if (show < experiment?.instruments.length - 1) {
                setShow(show => show + (!skip ? 1 : 2))
            } else {
                setFinish(true)
            }
        } else {
            setFinish(true)
        }
    }

    const handleFinish = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen()
        }

        const date = new Date()

        firebase?.dataset().push({
            experimentId: experiment.uid,
            experiment: experiment.title,
            participantId: md5(authUser.email).toString(),
            data: [...data, {
                instrument: 'END', 
                instrumentId: 'END',
                instrumentName: 'END',
                type: 'END', 
                response: `Date: ${date}`, 
                correct: [], 
                time: date.getTime()
            }]
        })

        history.push(ROUTES.PARTICIPANT)
    }

    useEffect(() => {
        if (show >= experiment?.instruments.length) {
            setFinish(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])

    useEffect(() => {
        if (experiment && experiment.timer && experiment.timer > 0) {
            setTimeout(() => setFinish(true), experiment.timer)
        }
    }, [experiment])

    useEffect(() => {
        if (!authUser?.roles[ROLES.PARTICIPANT]) {
            history.push(`/experimento/${match?.params.id}/entrar`)
        } else {
            firebase?.experiment(match?.params.id as string).on('value', snapshot => {
                const experiment = snapshot.val()
    
                experiment.uid = snapshot.key

                setExperiment(experiment)
                setLoading(false)
                timer.setInitialTimestamp()
            })
        }

        return () => {firebase?.experiment(match?.params.id as string).off()}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        window.scrollTo(0,0)
    })

    return (
        <Container id='data-collection' className='d-flex flex-column justify-content-center align-items-center' fluid>
            {
                !finish ?
                    
                    loading  ? 
                    <Loading /> :
                    show === -1 ?
                    <>
                        <Row>
                            <Col xs={{span: 8, offset: 2}} >
                                <h1 className='text-center'>{experiment?.title}</h1>
                            </Col>
                        </Row>
                        <Row className='my-3'>
                            <Col>
                                {
                                    experiment?.language === 'en' ?
                                    'Please click on the button below to start the experiment in fullscreen mode.' :
                                    'Por favor, clique no botão abaixo para iniciar o experimento em tela cheia.'
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <IconButton 
                                    icon={ICONS.START}
                                    onClick={handleStart}>
                                    
                                    {
                                        experiment?.language === 'en' ?
                                        'Start' :
                                        'Iniciar'
                                    }
                                </IconButton>
                            </Col>
                        </Row>
                    </>
                    :
                    experiment?.instruments.map((i: InstrumentType, index: number, a: any[]) => {
                        let instrument: React.ReactNode
                        
                        switch (i.type) {
                            case INSTRUMENTS.INSTRUCTIONPAGE:
                            case INSTRUMENTS.TEXT:
                                instrument = (
                                    <TextPage
                                        key={i.id}
                                        id={i.id}
                                        name={i.name}
                                        experimentId={experiment.uid}
                                        language={experiment.language}
                                        show={show === index || showNext.current}
                                        data={data}
                                        addData={setData}
                                        hideButton={a[index + 1] && a[index + 1].linked && show === index}
                                        next={(finish) => handleNext(finish, i.linked)}/>
                                )
                                break
                            case INSTRUMENTS.TCLE:
                                instrument = (
                                    <TCLE
                                        key={i.id}
                                        id={i.id}
                                        name={i.name}
                                        experimentId={experiment.uid}
                                        show={show === index || showNext.current}
                                        data={data}
                                        addData={setData}
                                        hideButton={a[index + 1] && a[index + 1].linked && show === index}
                                        next={(finish) => handleNext(finish, i.linked)}
                                        language={experiment.language}/>
                                )
                                break
                            case INSTRUMENTS.SURVEY:
                                instrument = (
                                    <Survey
                                        key={i.id}
                                        id={i.id}
                                        name={i.name}
                                        show={show === index || showNext.current}
                                        questions={i.options?.questions} 
                                        data={data} 
                                        addData={setData}
                                        hideButton={a[index + 1] && a[index + 1].linked && show === index}
                                        next={(finish) => handleNext(finish, i.linked)}
                                        nextLinked={a[index + 1] && a[index + 1].linked}
                                        timer={i.timer}
                                        language={experiment.language} />
                                )
                                break
                            case INSTRUMENTS.TCLA:
                                instrument = (
                                    <TCLA 
                                        key={i.id}
                                        id={i.id}
                                        name={i.name}
                                        show={show === index || showNext.current}
                                        data={data} 
                                        addData={setData}
                                        hideButton={a[index + 1] && a[index + 1].linked && show === index}
                                        next={(finish) => handleNext(finish, i.linked)}
                                        list={i.list}
                                        language='br'/>
                                )
                                break
                            case INSTRUMENTS.QUEXPLI:
                                instrument = (
                                    <QuExPLi
                                        key={i.id}
                                        id={i.id}
                                        name={i.name}
                                        show={show === index || showNext.current}
                                        data={data}
                                        addData={setData}
                                        hideButton={a[index + 1] && a[index + 1].linked && show === index}
                                        next={(finish) => handleNext(finish, i.linked)}
                                        language='br'/>
                                )
                                break
                            case INSTRUMENTS.WRITINGLOGGER:
                                instrument = (
                                    <WritingLogger
                                        key={i.id}
                                        id={i.id}
                                        name={i.name}
                                        show={show === index || showNext.current}
                                        data={data}
                                        addData={setData}
                                        hideButton={a[index + 1] && a[index + 1].linked && show === index}
                                        next={(finish) => handleNext(finish, i.linked)}/>
                                )
                                break
                            case INSTRUMENTS.WRITINGLOGGEROUTPUT:
                                instrument = (
                                    <WritingLoggerOutput
                                        key={i.id}
                                        id={i.id}
                                        show={show === index || showNext.current}
                                        data={data}
                                        hideButton={a[index + 1] && a[index + 1].linked && show === index}
                                        next={(finish) => handleNext(finish, i.linked)}/>
                                )
                                break
                            case INSTRUMENTS.READINGLOGGER:
                                instrument = (
                                    <ReadingLogger
                                        key={i.id}
                                        id={i.id}
                                        name={i.name}
                                        experimentId={experiment.uid}
                                        show={show === index || showNext.current}
                                        data={data}
                                        addData={setData}
                                        hideButton={a[index + 1] && a[index + 1].linked && show === index}
                                        next={(finish) => handleNext(finish, i.linked)}/>
                                )
                                break
                            case INSTRUMENTS.READINGLOGGEROUTPUT:
                                instrument = (
                                    <ReadingLoggerOutput
                                        key={i.id}
                                        id={i.id}
                                        show={show === index || showNext.current}
                                        data={data}
                                        hideButton={a[index + 1] && a[index + 1].linked && show === index}
                                        next={(finish) => handleNext(finish, i.linked)}/>
                                )
                                break
                            case INSTRUMENTS.SONGSELECTOR:
                                instrument = (
                                    <SongSelector
                                        key={i.id}
                                        id={i.id}
                                        name={i.name}
                                        experimentId={experiment.uid}
                                        show={show === index || showNext.current}
                                        data={data}
                                        hideButton={a[index + 1] && a[index + 1].linked && show === index}
                                        addData={setData}
                                        next={(finish) => handleNext(finish, i.linked)}
                                        language='br'/>
                                )
                                break
                            case INSTRUMENTS.DRM:
                                instrument = (
                                    <DRM 
                                        key={i.id}
                                        id={i.id}
                                        name={i.name}
                                        show={show === index || showNext.current}
                                        data={data} 
                                        addData={setData}
                                        hideButton={a[index + 1] && a[index + 1].linked && show === index}
                                        next={(finish) => handleNext(finish, i.linked)}
                                        language={experiment.language}
                                        list={i.options?.list}/>
                                )
                                break
                            case INSTRUMENTS.WORDSPAN:
                                instrument = (
                                    <WordSpan 
                                        key={i.id}
                                        id={i.id}
                                        name={i.name}
                                        show={show === index || showNext.current}
                                        data={data} 
                                        addData={setData}
                                        hideButton={a[index + 1] && a[index + 1].linked && show === index}
                                        next={(finish) => handleNext(finish, i.linked)}
                                        list={i.options?.list}
                                        language='br'/>
                                )
                                break
                            case INSTRUMENTS.PRIMINGTASK:
                                instrument = (
                                    <PrimingTask 
                                        key={i.id}
                                        id={i.id}
                                        name={i.name}
                                        experimentId={experiment.uid}
                                        show={show === index || showNext.current}
                                        data={data}
                                        addData={setData}
                                        hideButton={a[index + 1] && a[index + 1].linked && show === index}
                                        next={(finish) => handleNext(finish, i.linked)}
                                        language={experiment.language}/>
                                )
                                break
                            case INSTRUMENTS.RST:
                                instrument = (
                                    <RST 
                                        key={i.id}
                                        id={i.id}
                                        name={i.name}
                                        show={show === index || showNext.current}
                                        data={data} 
                                        addData={setData}
                                        hideButton={a[index + 1] && a[index + 1].linked && show === index}
                                        next={(finish) => handleNext(finish, i.linked)}
                                        list={i.list}
                                        language='br'/>
                                )
                                break
                                case INSTRUMENTS.RSTEnglish:
                                    instrument = (
                                        <RSTEnglish 
                                            key={i.id}
                                            id={i.id}
                                            name={i.name}
                                            show={show === index || showNext.current}
                                            data={data} 
                                            addData={setData}
                                            hideButton={a[index + 1] && a[index + 1].linked && show === index}
                                            next={(finish) => handleNext(finish, i.linked)}
                                            list={i.list}
                                            language='en'/>
                                    )
                                    break
                            case INSTRUMENTS.SST:
                                instrument = (
                                    <SST 
                                        key={i.id}
                                        id={i.id}
                                        name={i.name}
                                        show={show === index || showNext.current}
                                        data={data} 
                                        addData={setData}
                                        hideButton={a[index + 1] && a[index + 1].linked && show === index}
                                        next={(finish) => handleNext(finish, i.linked)}
                                        list={i.list}
                                        language='br'/>
                                )
                                break
                                case INSTRUMENTS.SSTEnglish:
                                    instrument = (
                                        <SSTEnglish 
                                            key={i.id}
                                            id={i.id}
                                            name={i.name}
                                            show={show === index || showNext.current}
                                            data={data} 
                                            addData={setData}
                                            hideButton={a[index + 1] && a[index + 1].linked && show === index}
                                            next={(finish) => handleNext(finish, i.linked)}
                                            list={i.list}
                                            language='en'/>
                                    )
                                    break
                            case INSTRUMENTS.OST:
                                instrument = (
                                    <OST 
                                        key={i.id}
                                        id={i.id}
                                        name={i.name}
                                        show={show === index || showNext.current}
                                        data={data} 
                                        addData={setData}
                                        hideButton={a[index + 1] && a[index + 1].linked && show === index}
                                        next={(finish) => handleNext(finish, i.linked)}
                                        list={i.list}
                                        language='br'/>
                                )
                                break
                                case INSTRUMENTS.OSTEnglish:
                                    instrument = (
                                        <OSTEnglish 
                                            key={i.id}
                                            id={i.id}
                                            name={i.name}
                                            show={show === index || showNext.current}
                                            data={data} 
                                            addData={setData}
                                            hideButton={a[index + 1] && a[index + 1].linked && show === index}
                                            next={(finish) => handleNext(finish, i.linked)}
                                            list={i.list}
                                            language='en'/>
                                    )
                                    break
                            case INSTRUMENTS.LETCOM:
                                instrument = (
                                    <LetCom 
                                        key={i.id}
                                        id={i.id}
                                        name={i.name}
                                        show={show === index || showNext.current}
                                        data={data} 
                                        addData={setData}
                                        hideButton={a[index + 1] && a[index + 1].linked && show === index}
                                        next={(finish) => handleNext(finish, i.linked)}
                                        list={i.options?.list || '1'}
                                        training={i.options?.training || false}
                                        language={experiment.language}/>
                                )
                                break
                                case INSTRUMENTS.LETCOMEnglish:
                                    instrument = (
                                        <LetComEnglish 
                                            key={i.id}
                                            id={i.id}
                                            name={i.name}
                                            show={show === index || showNext.current}
                                            data={data} 
                                            addData={setData}
                                            hideButton={a[index + 1] && a[index + 1].linked && show === index}
                                            next={(finish) => handleNext(finish, i.linked)}
                                            list={i.options?.list || '1'}
                                            training={i.options?.training || false}
                                            language={experiment.language}/>
                                    )
                                    break
                            case INSTRUMENTS.PATCOM:
                                instrument = (
                                    <PatCom 
                                        key={i.id}
                                        id={i.id}
                                        name={i.name}
                                        show={show === index || showNext.current}
                                        data={data} 
                                        addData={setData}
                                        hideButton={a[index + 1] && a[index + 1].linked && show === index}
                                        next={(finish) => handleNext(finish, i.linked)}
                                        list={i.options?.list || '1'}
                                        training={i.options?.training || false}
                                        language={experiment.language}/>
                                )
                                break
                                case INSTRUMENTS.PATCOMEnglish:
                                    instrument = (
                                        <PatComEnglish 
                                            key={i.id}
                                            id={i.id}
                                            name={i.name}
                                            show={show === index || showNext.current}
                                            data={data} 
                                            addData={setData}
                                            hideButton={a[index + 1] && a[index + 1].linked && show === index}
                                            next={(finish) => handleNext(finish, i.linked)}
                                            list={i.options?.list || '1'}
                                            training={i.options?.training || false}
                                            language={experiment.language}/>
                                    )
                                    break
                            case INSTRUMENTS.WORDBYWORD:
                                instrument = (
                                    <WordByWord 
                                        key={i.id}
                                        id={i.id}
                                        name={i.name}
                                        experimentId={experiment.uid}
                                        show={show === index || showNext.current}
                                        data={data} 
                                        addData={setData}
                                        hideButton={a[index + 1] && a[index + 1].linked && show === index}
                                        next={(finish) => handleNext(finish, i.linked)}
                                        language={experiment.language}/>
                                )
                                break
                            case INSTRUMENTS.LEXICALDECISION:
                                instrument = (
                                    <LexicalDecision 
                                        key={i.id}
                                        id={i.id}
                                        name={i.name}
                                        experimentId={experiment.uid}
                                        show={show === index || showNext.current}
                                        data={data}
                                        addData={setData}
                                        hideButton={a[index + 1] && a[index + 1].linked && show === index}
                                        next={(finish) => handleNext(finish, i.linked)}
                                        language={experiment.language}/>
                                )
                                break
                            default:
                                instrument = <div>Erro: instrumento inexistente</div>
                        }

                        showNext.current = a[index + 1] && a[index + 1].linked && show === index
                        return instrument
                    })
                :
                <div className='text-center'>
                    <h3 className='mb-3'>
                        {
                            experiment.language === 'en' ?
                            'This is the end of the experiment. Thank you very much for your participation!' : 
                            'Fim do experimento. Agradecemos imensamente sua participação!'
                        }
                    </h3>
                    <Button variant='primary' onClick={handleFinish}>
                        {
                            experiment.language === 'en' ?
                            'Click here to finish' :
                            'Clique para finalizar'
                        }
                    </Button>
                </div>
                
            }
            {
                experiment && experiment.timer && experiment.timer > 0 && !finish &&
                <TimerComponent duration={experiment.timer}/>
            }
        </Container>
    )
}

export default DataCollection