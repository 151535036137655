export const LANDING = '/'
export const SIGN_UP = '/registrar'
export const SIGN_IN = '/entrar'
export const HOME = '/inicial'
export const ACCOUNT = '/conta'
export const PASSWORD_FORGET = '/recuperar-senha'
export const ADMIN = '/admin'
export const ADMIN_USERS = '/admin/usuarios'
export const ADMIN_USERS_DETAILS = '/admin/usuarios/:id'
export const RESEARCHER = '/pesquisador'
export const RESEARCHER_EXPERIMENTS_DETAILS = '/pesquisador/experimentos/:id'
export const RESEARCHER_EXPERIMENTS_INSTRUMENTS_DETAILS = '/pesquisador/experimentos/:experimentId/:instrumentId'
export const PARTICIPANT = '/participante'
export const PARTICIPANT_DATA = '/participante/dados/:id'
export const DATA_COLLECTION = '/experimento/:id'
export const DATA_COLLECTION_SIGN_IN = '/experimento/:id/entrar'
export const DATA_COLLECTION_SIGN_UP = '/experimento/:id/registrar'
export const DATA_PROCESSING_READINGLOGGER = '/processamento/readinglogger'
export const DATA_PROCESSING_WRITINGLOGGER = '/processamento/writinglogger'