import React from 'react'
import shuffle from 'shuffle-array'

import ConfigType from '../../../types/ConfigType'
import { equations_training, equations } from './equations'

import { FIELDS, TRIALS } from '../../../constants/instruments'


const config: ConfigType[] = []

// Instructions - Training
config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <h1 className='text-center mb-3'>Instruções Sessão de Prática</h1>
            <p>Neste teste, você resolverá problemas matemáticos simples enquanto também tenta memorizar o resultado de cada problema.</p>
            <p>Nos próximos minutos, você fará uma sessão de prática para se familiarizar com o funcionamento da tarefa.</p> 
            <p>Você verá conjuntos de dois, três e quatro problemas matemáticos apresentados aleatoriamente.</p>
            <p>Clique com o mouse para começar.</p>
        </>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>Os problemas matemáticos aparecerão na tela um de cada vez, assim: “(2 x 1) + 1 = 3”.</p>
            <p>Assim que o problema aparecer na tela, você deve calcular a resposta correta. No problema acima, a resposta 3 está correta.</p>
            <p>Um exemplo de problema com resposta incorreta seria: “(9 ÷ 3) - 2 = 2”.</p>
            <p>Você também precisa memorizar o resultado do problema que viu, independentemente de estar correto.</p>
            <p>Quando tiver resolvido a equação para determinar se está correta e memorizado seu resultado, clique com o botão do mouse.</p>
            <p>Reserve um momento para certificar-se de que entendeu as instruções.</p>
            <p>Clique com o mouse para continuar.</p>
        </>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>
                Então, a mensagem “O problema matemático estava...” será exibido na próxima tela, seguida de um botão escrito ‘CORRETO’ e outro botão escrito ‘INCORRETO’.
            </p>
            <p>Se o problema da tela anterior estiver correto, clique em ‘CORRETO’. Se o problema estiver incorreto, clique em ‘INCORRETO’.</p>
            <p>Após clicar em um dos botões, o computador informará se você fez a escolha certa.</p>
            <p>É MUITO importante resolver os problemas de matemática o mais RÁPIDO e PRECISAMENTE possível.</p>
            <p>Reserve um momento para certificar-se de que entendeu as instruções.</p>
            <p>Clique com o mouse para continuar.</p>
        </>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>Outro problema de matemática aparecerá para você resolver e decidir se está correto ou incorreto e memorizar seu resultado.</p>
            <p>
                Quando você tiver resolvido todos os problemas em um conjunto, uma tela de recordação aparecerá para você escrever o resultado de cada problema na ordem em que foram apresentados.
            </p>
            <p>Se você esquecer um dos resultados, deixe o espaço correspondente em branco.</p>
            <p>Lembre-se, é MUITO importante escrever os resultados na mesma ordem em que você os viu.</p>
            <p>Reserve um momento para certificar-se de que entendeu as instruções.</p>
            <p>Clique com o mouse para continuar.</p>
        </>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>É MUITO importante que você resolva os problemas de matemática CORRETAMENTE.</p>
            <p>
                Também é importante que você tente resolver os problemas o mais RAPIDAMENTE possível, ENQUANTO faz o seu melhor para lembrar o maior número possível de resultados na ordem em que foram apresentados.
            </p>
            <p>
                <strong>IMPORTANTE:</strong> você não pode anotar os resultados durante a apresentação do problema. Você deve confiar em sua memória para lembrá-los.
            </p>
            <p>Reserve um momento para certificar-se de que entendeu as instruções.</p>
            <p>Quando estiver pronto, clique com o mouse para tentar alguns problemas de prática.</p>
        </>
    )
})

// Training
shuffle(equations_training)

// Training
for (let set of equations_training) {
    for (let equation of set) {
        // Sentence
        config.push({
            type: TRIALS.STIMULUS,
            stimulus: equation.equation,
            response: {
                type: 'click',
            },
            training: true
        })

        // Semantic acceptability judgement
        config.push({
            type: TRIALS.STIMULUS,
            stimulus: 'O problema matemático estava...',
            response: {
                type: 'button',
                buttons: [`${equation.true ? 'CORRETO' : 'INCORRETO'}`, `${!equation.true ? 'CORRETO' : 'INCORRETO'}`],
                centralizeButtons: true,
                showCorrect: true, //Only in trainning
                order: 'asc'
            },
            training: true
        })

        // Blank
        config.push({
            type: TRIALS.BLANK,
            training: true
        })
    }

    // Recall
    config.push({
        type: TRIALS.QUESTION,
        title: <span>Escreva os resultados<br/> dos problemas na ordem<br/> em que apareceram.</span>,
        questions: set.map(() => ({prompt: '', type: FIELDS.INPUT})),
        correctResponses: set.map((e) => e.result),
        training: true
    })
}

// Instructions
config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>Esse é o fim da prática.</p>
            <p>Os testes reais serão parecidos com os testes práticos que você acabou de completar.</p>
            <p>Você verá conjuntos de 2 a 5 problemas matemáticos apresentados aleatoriamente.</p>
            <p>É importante que você faça o seu melhor tanto para decidir se os problemas estão corretos quanto para memorizar o resultado de cada problema.</p>
            <p>Você não será informado se julgou os problemas com precisão.</p>
            <p>Reserve um momento para certificar-se de que entendeu as instruções.</p>
            <p>Clique com o mouse para iniciar a tarefa.</p>
        </>
    )
})

// Test
shuffle(equations)

// Test
for (let set of equations) {
    for (let equation of set) {
        // Sentence
        config.push({
            type: TRIALS.STIMULUS,
            stimulus: equation.equation,
            response: {
                type: 'click',
            },
        })

        // Semantic acceptability judgement
        config.push({
            type: TRIALS.STIMULUS,
            stimulus: 'O problema matemático estava...',
            response: {
                type: 'button',
                buttons: [`${equation.true ? 'CORRETO' : 'INCORRETO'}`, `${!equation.true ? 'CORRETO' : 'INCORRETO'}`],
                centralizeButtons: true,
                order: 'asc'
            },
        })

        // Blank
        config.push({
            type: TRIALS.BLANK,
        })
    }

    // Recall
    config.push({
        type: TRIALS.QUESTION,
        title: <span>Escreva os resultados<br/> dos problemas na ordem<br/> em que apareceram.</span>,
        questions: set.map(() => ({prompt: '', type: FIELDS.INPUT})),
        correctResponses: set.map((e) => e.result)
    })
}


export default config