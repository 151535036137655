import React, { 
    Dispatch, 
    SetStateAction, 
    useState, 
    useContext, 
    useEffect, 
    useRef,
    MutableRefObject
} from 'react'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { v4 as uuid } from 'uuid'

import Text from '../Text'
import DataType from '../../../types/DataType'
import ConfigType from '../../../types/ConfigType'
import { Loading, Message } from '../../Layout'
import { FirebaseContext } from '../../Firebase'

import { TRIALS, INSTRUMENTS } from '../../../constants/instruments'

import './index.css'

function TextPage(props: {
    id: string,
    name: string,
    show: boolean,
    data: DataType[], 
    experimentId: string,
    addData: Dispatch<SetStateAction<DataType[]>>,
    next: (finish?: boolean) => void,
    hideButton?: boolean,
    language?: 'br'|'en'
}) {
    const [finish, setFinish] = useState(false)
    const [options, setOptions] = useState([] as {text?: string, title?: string, link?: string, popup?: string, playSong?: boolean}[])
    const [config, setConfig] = useState([] as ConfigType[])
    const [keys, setKeys] = useState([] as string[])
    const [show, setShow] = useState([] as boolean[])
    const [showModal, setShowModal] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [modalBody, setModalBody] = useState('')
    const [playSong, setPlaySong] = useState(true)
    const [loading, setLoading] = useState(true)
    const firebase = useContext(FirebaseContext)
    const audio: MutableRefObject<HTMLAudioElement> = useRef(new Audio())

    const handleNext = () => {
        if (!show[show.length - 1]) {
            let setTrue = false

            setShow(show.map((s: boolean) => {
                if (s) {
                    setTrue = true
                    return false
                }

                if (setTrue) {
                    setTrue = false
                    return true
                }

                return false
            }))
        } else {
            setFinish(true)
        }
    }

    const handleAddData = (newData: DataType) => {
        props.addData(data => {
            return [...data, newData]
        })
    }

    useEffect(() => {
        firebase?.experiment(props.experimentId).once(
            'value', snapshot => {
                setOptions(
                    snapshot.val().instruments.filter(
                        (i: any) => i.id === props.id)[0].options
                    )
            }
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let config: ConfigType[]

        if (options && options.length > 0) {
            config = [
                {
                    type: TRIALS.TEXT,
                    prompt: options?.filter((o: any) => !('img' in o) && !('playSong' in o)).map(
                        (o: {text?: string, title?: string, link?: string, popup?: string, block?: boolean, heading?: boolean, align?: 'center'|'justify'}, 
                            i: number, 
                            a: {text?: string, title?: string, link?: string, popup?: string, block?: boolean, heading?: boolean, align?: 'center'|'justify'}[]) => {
                                if (o.title || o.heading) {
                                    return <h1 key={uuid()} className='my-5' style={{textAlign: o.align}}>{o.title || o.text}</h1>
                                } else if (o.text) {
                                    return (
                                        <React.Fragment key={uuid()}>
                                            <span style={{display: o.block ? 'block' : 'inline', textAlign: o.align}}>
                                                {o.text}
                                            </span>
                                            {a[i + 1] && 'text' in a[i + 1] && <><br/><br/></>}
                                        </React.Fragment>
                                    ) 
                                } else if (o.link) {
                                    return (
                                        <Button 
                                            key={uuid()} 
                                            variant='link' 
                                            onClick={() => {
                                                setShowModal(true)
                                                setModalTitle((o.link?.charAt(0).toUpperCase() || '') + (o.link?.slice(1) || '') as string)
                                                setModalBody(o.popup as string)
                                            }}>
                                                {o.link}
                                            </Button>
                                        )
                                }
                        }
                    )
                }
            ]
        } else {
            config = [{
                type: TRIALS.TEXT,
                prompt: <Message warning='O texto não foi configurado corretamente.' />
            }]
        }

        setConfig(config)
        setKeys(config.map(() => uuid()))
        setShow(config.map((c: ConfigType, i: number) => i === 0 ? true : false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options])

    useEffect(() => {
        if (playSong && options && options.filter((o: any) => ('playSong' in o) && o.playSong).length === 1) {
            
            const songSelectionData = props.data.filter((d: DataType) => d.type === INSTRUMENTS.SONGSELECTOR)

            if (songSelectionData.length > 0) {
                const song = songSelectionData[0].response

                audio.current.src = song
                
                audio.current.addEventListener('canplay', () => {
                    audio.current.play()
                    setLoading(false)
                })
            }
        } else {
            setLoading(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show, options])

    useEffect(() => {
        if (finish) {        
            audio.current.pause()

            setPlaySong(false)
            
            props.next()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finish])

    return (
        !props.show ? null :
        loading ? <Loading /> :
        <>
            <Container 
                className='InstructionPage instrument-container d-flex flex-column justify-content-center align-items-center position-relative'
                fluid>
                
                {
                    options &&
                    options.filter((o: any) => 'img' in o).map((o: any) => (
                        o.img && <img key={uuid()} id='' src={o.img} alt='Imagem ilustrativa'/>
                    ))
                }

                {
                    config.filter((o: any) => !('playSong' in o)).map((p: ConfigType, i: number) => {
                        switch (p.type) {
                            case TRIALS.TEXT:
                                return (
                                    <Text 
                                        key={keys[i]}
                                        show={show[i]}
                                        instrument={INSTRUMENTS.TEXT} 
                                        instrumentId={props.id}
                                        instrumentName={props.name}
                                        addData={handleAddData}
                                        next={handleNext}
                                        hideButton={props.hideButton}
                                        language={props.language}>
                                            
                                        {p.prompt}
                                    </Text>
                                )
                            default:
                                return <div></div>
                        }
                    })
                }
            </Container>
            <Modal show={showModal} onHide={() => showModal && setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalBody}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setShowModal(false)}>Fechar</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default TextPage