import React, { useState, useEffect, useContext } from 'react'

import AuthUserContext from './contex'
import { FirebaseContext } from '../Firebase'

const withAuthentication = (Component: React.FunctionComponent<any>) => {
    function WithAuthentication(props: React.ComponentProps<any>) {
        const localUser = localStorage.getItem('authUser')
        const firebase = useContext(FirebaseContext)

        const [authUser, setAuthUser] = useState((localUser ? JSON.parse(localUser) : null) as any)
        
        useEffect(() => {
            const listener = firebase?.onAuthUserListener(
                (authUser: any) => {
                    localStorage.setItem('authUser', JSON.stringify(authUser))
                    setAuthUser(authUser)
                }, 
                () => {
                    localStorage.removeItem('authUser')
                    setAuthUser(null)
                }
            )
    
            return () => listener && listener()
        }, [firebase])

        return (
            <AuthUserContext.Provider value={authUser}>
                <Component {...props} />
            </AuthUserContext.Provider>
        )
    }

    return WithAuthentication
}

export default withAuthentication