import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import SignUpForm from './SignUpForm'

function SignUp() {
    return (
        <Container className='my-5'>
            <Row>
                <Col xs={{span: 10, offset: 1}} md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}}>
                    <SignUpForm/>
                </Col>
            </Row>
        </Container>
    )
}



export default SignUp