import React from 'react'
import shuffle from 'shuffle-array';

import ConfigType from "../../../types/ConfigType";
import { TRIALS, FIELDS } from "../../../constants/instruments";

function getConfig(list: string) {
    // Initial config
    const config: ConfigType[] = []

    // Initial instructions
    config.push({
        type: TRIALS.TEXT,
        prompt: (
            <>
                <p className='text-center'>
                Welcome to the experiment and thank you in advance for your participation!
                </p>
            </>
        )
    })

    config.push({
        type: TRIALS.TEXT,
        prompt: (
            <>
                <h1 className='text-center mb-3'>Instructions</h1>
                <ul>
                    <li>
                        In this recall task you will listen to lists of words. 
                        Please be sure the volume of your computer is loud enough 
                        for you to understand the words properly.
                    </li>
                    <li>
                        Each list will start with a presentation of a plus sign (+) 
                        in the middle of the screen. This sign indicates a new trial. 
                        When you are ready to start a trial, click the "Continue" button 
                        to start hearing the list of words.
                    </li>
                    <li>
                        After you listen to all the words in the list, a RECALL screen 
                        will appear and you should type as many words as you can remember 
                        from the list you just heard.
                    </li>
                </ul>
            </>
        )
    })

    config.push({
        type: TRIALS.TEXT,
        prompt: (
            <>
                <h1 className='text-center mb-3'>Instructions</h1>
                <ul>
                    <li>
                        This is a memory test, so please pay attention and try your best at 
                        remembering the words. You should only type them or write them down 
                        after you hear the entire list. Following these instructions is 
                        really important for the reliability of our experimental results.
                    </li>
                    <li>
                        You will have one and a half minutes to type all the words you can recall.
                    </li>
                    <li>
                        Afterwards, a new trial will begin with a plus sign, a new list will be 
                        presented and a RECALL screen will follow and so on.
                    </li>
                </ul>
            </>
        )
    })

    config.push({
        type: TRIALS.TEXT,
        prompt: (
            <>
                <h1 className='text-center mb-3'>Instructions</h1>
                <ul>
                    <li>
                        You will first have a chance to complete practice trials to get 
                        familiar with the task. After those trials, the experiment will begin. 
                        When you are ready, click on the "Continue" button.
                    </li>
                </ul>
            </>
        )
    })

    // Blank
    config.push({
        type: TRIALS.BLANK,
        response: {
            type: 'key'
        },
        fixationPoint: true,
        timeout: 500
    })

    let practice = [
        'clothes_roupa',
        'elevator_elevador'
    ]

    practice.forEach(a => {
        config.push(
            {
                type: TRIALS.AUDIO,
                url: `/audio/${a}.mp3`,
                fixationPoint: true
            }
        )

        // Respose
        config.push({
            type: TRIALS.QUESTION,
            title: 'RECALL',
            questions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(() => ({prompt: '', type: FIELDS.INPUT})),
            timeout: 90000,
            layout: {direction: 'row'}
        })
    })

    config.push({
        type: TRIALS.TEXT,
        prompt: (
            <>
                <h1 className='text-center mb-3'>Instructions</h1>
                <ul>
                    <li>
                        This is the end of practice. Click on the button below to continue.
                    </li>
                </ul>
            </>
        )
    })

    let audios: string[] = [];
    let words: string[][] = [];

    // Experiment 1
    if (list === '1') {
        audios = [
            'actor_tv',
            'arms_maos',
            'beauty_beleza',
            'blue_color',
            'brick_tijolo',
            'broke_quebra',
            'bullet_gun',
            'charm_pingente',
            'contract_deal',
            'current_hoje',
            'danger_perigo',
            'date_data',
            'diet_dieta',
            'farmer_fazendeiro',
            'figure_sombra',
            'foot_pe',
            'grave_cemiterio',
            'hero_heroi',
            'holy_sagrado',
            'interest_interesse',
            'letter_carta',
            'mold_mofo',
            'piano_musica',
            'plane_aviao',
            'plant_jardim',
            'poet_poeta',
            'rabbit_coelho',
            'rose_flor',
            'speaker_oratoria',
            'symbol_icone',
            'term_escola',
            'watch_relogio'
        ]
    
        words = [
            [
                'speaker', 'piano', 'date', 'calendar', 'talk', 'foundation',
                'sleep', 'clock', 'illustration', 'coal', 'butterfly', 'adornment'
            ],
            [
                'toxic', 'fat', 'dictator', 'bread', 'alien', 'diet',
                'brick', 'keyboard', 'Marvel', 'bitter', 'wrist', 'tonight'
            ],
            [
                'safety', 'wake', 'figure', 'rubber', 'stove', 'chair',
                'pocket', 'hero', 'bad', 'eat', 'green', 'latex'
            ],
            [
                'duty', 'nutrition', 'beauty', 'sweet', 'brooch', 'cards',
                'savior', 'fair', 'blue', 'citizen', 'lagoon', 'carpet'
            ],
            [
                'base', 'king', 'songs', 'sky', 'crust', 'town',
                'beast', 'talisman', 'loud', 'speech', 'cottage', 'beautiful'
            ],
            [
                'moth', 'city', 'watch', 'birthday', 'charm', 'danger',
                'photograph', 'wall', 'risk', 'classical', 'cabin', 'trouble'
            ]
        ]
    
        
    } 
    // Experiment 2
    else if (list === '2') {
        audios = [
            'actor_tv',
            'arms_exercito',
            'beauty_beleza',
            'blue_feeling',
            'brick_tijolo',
            'broke_money',
            'bullet_topicos',
            'charm_charme',
            'contract_contrair',
            'current_corrente',
            'danger_perigo',
            'date_encontro',
            'diet_dieta',
            'farmer_fazendeiro',
            'figure_imagine',
            'foot_comprimento',
            'grave_serio',
            'hero_heroi',
            'holy_sagrado',
            'interest_taxa',
            'letter_palavra',
            'mold_modelo',
            'piano_musica',
            'plane_planicie',
            'plant_projeto',
            'poet_poeta',
            'rabbit_coelho',
            'rose_subir',
            'speaker_som',
            'symbol_icone',
            'term_acordo',
            'watch_see',
        ]
    
        words = [
            [
                'pray', 'latex', 'butterfly', 'moth', 'flu', 'alien',
                'city', 'carpet', 'word', 'field', 'plantation', 'sleep'
            ],
            [
                'contract', 'king', 'bad', 'increase', 'text', 'symbol',
                'letter', 'measure', 'jumps', 'dictator', 'sweet', 'farmer'
            ],
            [
                'quarentine', 'wave', 'cottage', 'wake', 'cursive', 'phone',
                'citizen', 'rise', 'mile', 'speaker', 'catch', 'stove'
            ],
            [
                'journal', 'cabin', 'trouble', 'inspiration', 'language', 'coal',
                'amplifier', 'posture', 'brand', 'actress', 'stage', 'sacred'
            ],
            [
                'actor', 'bullet', 'bitter', 'rabbit', 'phrase', 'holy',
                'chair', 'cows', 'bread', 'foot', 'extremity', 'icon'
            ],
            [
                'rubber', 'town', 'culture', 'fluffy', 'crust', 'religion',
                'poem', 'movies', 'concepts', 'poet', 'rose', 'bunny'
            ]
        ]
    }
    // Experiment 3A
    else if (list === '3A') {
        audios = [
            'charm_pingente',
            'contract_deal',
            'letter_carta',
            'mold_mofo',
            'plane_aviao',
            'plant_jardim',
            'rose_flor',
            'arms_exercito',
            'current_corrente',
            'date_encontro',
            'figure_imagine',
            'grave_serio',
            'interest_taxa',
            'term_acordo',
        ]

        words = [
            [
                'date', 'grave', 'economy', 'volt', 'number', 'bloom',
                'estimate', 'herb', 'citizen', 'arms', 'talisman', 'artillery'
            ],
            [
                'wise', 'plane', 'mildew', 'fungi', 'rule', 'bad',
                'nose', 'mold', 'contract', 'pact', 'aircraft', 'agreement'
            ], 
            [
                'discover', 'severe', 'figure', 'theaf', 'letter', 'bottles',
                'clauses', 'flower', 'deal', 'rose', 'water', 'lawyer'
            ],
            [
                'wake', 'whiskey', 'electricity', 'root', 'airplane', 'moth',
                'liberty', 'current', 'dirt', 'war', 'justice', 'king'
            ],
            [
                'plant', 'cottage', 'bitter', 'adornment', 'charm', 'silence',
                'brooch', 'crust', 'sweet', 'cabinet', 'city', 'carpet'
            ],
            [
                'bill', 'reservation', 'writing', 'term', 'sleep', 'admirer',
                'dendelion', 'bread', 'trouble', 'butterfly', 'interest', 'chair'
            ],
            [
                'romantic', 'alien', 'revolver', 'steal', 'financial', 'stem',
                'cabin', 'smell', 'mail', 'dictator', 'calculate', 'town'
            ]
        ]
    }

    // Experiment 3B
    else if (list === '3B') {
        audios = [
            'arms_maos',
            'current_hoje',
            'date_data',
            'figure_sombra',
            'grave_cemiterio',
            'interest_interesse',
            'term_escola',
            'charm_charme',
            'contract_contrair',
            'letter_palavra',
            'mold_modelo',
            'plane_planicie',
            'plant_projeto',
            'rose_subir',
        ]

        words = [
            [
                'chemical', 'actuality', 'cursive', 'lovely', 'smell', 'theaf',
                'carpet', 'nowadays', 'symbol', 'want', 'butterfly', 'wake'
            ], 
            [
                'crust', 'bottles', 'increase', 'arms', 'posture', 'surface',
                'pulse', 'flu', 'town', 'whiskey', 'catch', 'bread'
            ],
            [
                'tonight', 'icon', 'nose', 'architecture', 'cemitery', 'shape',
                'liberty', 'plant', 'rise', 'smile', 'mortality', 'sweet'
            ],
            [
                'steal', 'illustration', 'photograph', 'delight', 'mold', 'current',
                'joy', 'moth', 'birthday', 'contract', 'cottage', 'love'
            ],
            [
                'king', 'plane', 'city', 'gloves', 'language', 'cabin',
                'chair', 'cards', 'mathematics', 'hands', 'charm', 'letter'
            ],
            [
                'interest', 'fold', 'bad', 'citizen', 'grave', 'alien',
                'word', 'justice', 'figure', 'calendar', 'sleep', 'cast'
            ],
            [
                'ongoing', 'trouble', 'date', 'coffin', 'rooms', 'quarentine',
                'impression', 'dictator', 'concepts', 'bitter', 'rose', 'brand'
            ]
        ]
    }

    shuffle(audios)
    
    audios.forEach(a => {
        config.push(
            {
                type: TRIALS.AUDIO,
                url: `/audio/${a}.mp3`,
                fixationPoint: true
            }
        )

        // Respose
        config.push({
            type: TRIALS.QUESTION,
            title: 'RECALL',
            questions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(() => ({prompt: '', type: FIELDS.INPUT})),
            timeout: 90000,
            layout: {direction: 'row'}
        })
    })

    // RECOGNITION TASK
    config.push({
        type: TRIALS.TEXT,
        prompt: (
            <>
                <h1 className='text-center mb-3'>Instructions</h1>
                <ul>
                    <li>
                        This is a memory recognition test related to the recall test you have just completed. 
                        On this recognition test, you will read each word below and decide whether you have 
                        seen it during the recall test you have just finished. If you believe you have seen 
                        the word before, please select “yes”. If you believe you have not seen the word before, 
                        please select “no”.
                    </li>
                </ul>
            </>
        )
    })

    words.forEach(w => {
        config.push({
            type: TRIALS.QUESTION,
            title: 'RECOGNITON',
            questions: [
                {
                    prompt: 'Have you seen these words before in the recall test?',
                    type: FIELDS.INSTRUCTIONS
                },
                ...w.map(w => (
                    {
                        prompt: w, 
                        type: FIELDS.TOGGLEBUTTONS, 
                        options: [{label: 'Yes', value: 'Yes'}, {label: 'No', value: 'No'}]
                    }
                ))
            ]
        })
    })

    return config
}


export default getConfig