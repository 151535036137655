import React, { useState, useContext, useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { FirebaseContext } from '../../Firebase'
import { IconButton, ICONS, Loading } from '../../Layout'

import * as ROUTES from '../../../constants/routes'

function InstructionPageOptions() {
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState([] as any)
    const [newParagraph, setNewParagraph] = useState('')
    const [newImage, setNewImage] = useState('')
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true)
    const [instrumentName, setInstrumentName] = useState('')
    const match = useRouteMatch<{experimentId: string, instrumentId: string}>(ROUTES.RESEARCHER_EXPERIMENTS_INSTRUMENTS_DETAILS)
    const firebase = useContext(FirebaseContext)
    const history = useHistory()
    const addButtonDisabled = !newParagraph

    const handleRemoveOption = (index: number) => {
        const textOptions = [...options].filter((o: any) => ('text' in o))
        const img = [...options].filter((o: any) => ('img' in o))

        setOptions([...textOptions.filter((q: any, i: number) => i !== index), ...img])
        setSaveButtonDisabled(false)
    }

    const handleAddParagraph = () => {
        if (options) {
            setOptions(
                [...options, {text: newParagraph}]
            )
        } else {
            setOptions([{text: newParagraph}])
        }

        setNewParagraph('')
        setNewImage('')
        setSaveButtonDisabled(false)
    }

    const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (options) {
            setOptions([...options.filter((o: any) => !('img' in o)), {img: e.currentTarget.value}])
        } else {
            setOptions([{img: e.currentTarget.value}])
        }

        setNewImage(e.currentTarget.value)
        setSaveButtonDisabled(false)
    }

    const handleChangeAlignment = (index: number, align: string) => {
        const textOptions = [...options].filter((o: any) => ('text' in o))
        const img = [...options].filter((o: any) => ('img' in o))

        setOptions([...textOptions.map((o: any, i: number) => i === index ? {...o, align} : o), ...img])
        setSaveButtonDisabled(false)
    }

    const handleChangeBlock = (index: number) => {
        const textOptions = [...options].filter((o: any) => ('text' in o))
        const img = [...options].filter((o: any) => ('img' in o))

        setOptions([...textOptions.map((o: any, i: number) => i === index ? {...o, block: !o.block} : o), ...img])
        setSaveButtonDisabled(false)
    }

    const handleChangeHeading = (index: number) => {
        const textOptions = [...options].filter((o: any) => ('text' in o))
        const img = [...options].filter((o: any) => ('img' in o))

        setOptions([...textOptions.map((o: any, i: number) => i === index ? {...o, heading: !o.heading} : o), ...img])
        setSaveButtonDisabled(false)
    }

    const handleChangeInstrumentName = (e: React.ChangeEvent<any>) => {
        firebase?.instrument(
            match?.params.experimentId as string, 
            match?.params.instrumentId as string
        ).child('name').set(e.target.value)

        setInstrumentName(e.target.value)
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        setLoading(true)

        firebase?.instrument(match?.params.experimentId as string, match?.params.instrumentId as string).child('options')
            .set(options).then(() => {
                setSaveButtonDisabled(true)
                history.push(`/pesquisador/experimentos/${match?.params.experimentId}`)
            })
    }

    useEffect(() => {
        firebase?.instrument(match?.params.experimentId as string, match?.params.instrumentId as string).on('value', snapshot => {
            setOptions(snapshot.val().options)

            if (snapshot.val().name) {
                setInstrumentName(snapshot.val().name)
            }
        })

        return () => {
            firebase?.instrument(
                match?.params.experimentId as string, 
                match?.params.instrumentId as string
            ).off()}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        options?.filter((o: any) => ('img' in o)).map(
            (o: {img: string}) => {
                setNewImage(o.img)
            }
        )
    }, [options])

    return (
        loading ? <Loading/> :
        <>
            <h2>Editar Instruções</h2>
            <Row>
                <Col xs={6}>
                    <Form.Group>
                        <Form.Label>Nome da página</Form.Label>
                        <Form.Control 
                            value={instrumentName}
                            onChange={handleChangeInstrumentName}/>
                    </Form.Group>
                </Col>
            </Row>
            <Table striped bordered hover responsive className='my-3'>
                <thead>
                    <tr>
                        <th>Parágrafo</th>
                        <th>Formatação</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        options?.filter((o: any) => ('text' in o)).map(
                            (o: {title: string, text: string, block?: boolean, heading?: boolean, align?: string}, i: number) => (
                            <tr key={uuid()}>
                                <td>{o.text}</td>
                                <td className='text-center w-50'>
                                    <ButtonGroup className='mr-1'>
                                        <IconButton 
                                            icon={ICONS.PARAGRAPH} 
                                            size='sm' 
                                            variant='outline-secondary' 
                                            title='Formatar como parágrafo (bloco)'
                                            active={o.block}
                                            onClick={() => handleChangeBlock(i)}/>
                                        <IconButton 
                                            icon={ICONS.HEADING} 
                                            size='sm' 
                                            variant='outline-secondary' 
                                            title='Formatar como Título'
                                            active={o.heading}
                                            className='mr-1'
                                            onClick={() => handleChangeHeading(i)}/>
                                    </ButtonGroup>
                                    <ButtonGroup>
                                        <IconButton 
                                            icon={ICONS.ALIGN_JUSTIFY} 
                                            size='sm' 
                                            variant='outline-secondary' 
                                            title='Justificar'
                                            active={o.align === 'justify'}
                                            onClick={() => handleChangeAlignment(i, 'justify')}/>
                                        <IconButton 
                                            icon={ICONS.ALIGN_CENTER} 
                                            size='sm' 
                                            variant='outline-secondary' 
                                            title='Centralizar'
                                            active={o.align === 'center'}
                                            onClick={() => handleChangeAlignment(i, 'center')}/>
                                    </ButtonGroup>
                                </td>
                                <td className='text-center'>
                                    <IconButton 
                                        icon={ICONS.DELETE} 
                                        variant='danger' 
                                        size='sm'
                                        onClick={() => handleRemoveOption(i)}/>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
            
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Form.Text>
                            <h3>Adicionar parágrafo</h3>
                        </Form.Text>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Parágrafo</Form.Label>
                            <InputGroup>
                                <Form.Control 
                                    as='textarea'
                                    value={newParagraph}
                                    onChange={e => setNewParagraph(
                                        e.target.value
                                    )}/>

                                <InputGroup.Append>
                                    <IconButton 
                                        icon={ICONS.PLUS} 
                                        variant='success'
                                        onClick={handleAddParagraph}
                                        disabled={addButtonDisabled}/>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Text>
                            <h3>Imagem de cabeçalho</h3>
                        </Form.Text>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Endereço da imagem</Form.Label>
                            <Form.Control
                                type='text'
                                value={newImage}
                                onChange={handleChangeImage}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <IconButton
                            icon={ICONS.BACK}
                            onClick={() => {history.goBack()}}>
                            
                            Voltar
                        </IconButton>
                        <IconButton 
                            type='submit' 
                            icon={ICONS.SAVE}
                            className='ml-1'
                            disabled={saveButtonDisabled}
                            variant={!saveButtonDisabled ? 'warning' : undefined}>
                            
                            Salvar
                        </IconButton>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default InstructionPageOptions