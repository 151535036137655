import React, { useContext, useEffect, useState, ChangeEvent } from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import { useRouteMatch, useHistory } from 'react-router-dom'

import { FirebaseContext } from '../../Firebase'
import { IconButton, ICONS, Loading } from '../../Layout'

import * as ROUTES from '../../../constants/routes'

function LetComOptions() {
    const [loading, setLoading] = useState(false)
    const [instrumentName, setInstrumentName] = useState('')
    const [options, setOptions] = useState({} as {list: string, training: boolean})
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true)
    const match = useRouteMatch<{experimentId: string, instrumentId: string}>(ROUTES.RESEARCHER_EXPERIMENTS_INSTRUMENTS_DETAILS)
    const firebase = useContext(FirebaseContext)
    const history = useHistory()

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        setLoading(true)

        firebase?.instrument(match?.params.experimentId as string, match?.params.instrumentId as string).child('options')
            .set(options).then(() => {
                setSaveButtonDisabled(true)
                history.push(`/pesquisador/experimentos/${match?.params.experimentId}`)
            })
    }

    const handleChange = (e: any) => {
      const value = e.currentTarget.value

      setOptions(currentOptions => ({...currentOptions, list: value}))
      setSaveButtonDisabled(false)
    }

    const handleChangeInstrumentName = (e: React.ChangeEvent<any>) => {
      firebase?.instrument(
          match?.params.experimentId as string, 
          match?.params.instrumentId as string
      ).child('name').set(e.target.value)

      setInstrumentName(e.target.value)
  }

    const handleChangeTraining = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value

      firebase?.instrument(match?.params.experimentId as string, match?.params.instrumentId as string).child('options/training').set(value)
      setOptions(currentOptions => ({...currentOptions, training: value === 'true'}))
  }

    useEffect(() => {
        firebase?.instrument(match?.params.experimentId as string, match?.params.instrumentId as string).on('value', snapshot => {
          if (snapshot.val().options === undefined) {
            setOptions({list: '1', training: false})
          } else {
            setOptions(snapshot.val().options)
          }

          if (snapshot.val().name) {
            setInstrumentName(snapshot.val().name)
          }
        })

        return () => {
            firebase?.instrument(
                match?.params.experimentId as string, 
                match?.params.instrumentId as string
            ).off()}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        loading ? <Loading/> :
        <>
            <h2 className='mb-5'>Editar Letter Comparison Test</h2>
            <Form onSubmit={handleSubmit}>
              <Row className='mb-5'>
                <Col xs={6}>
                  <Form.Group>
                    <Form.Label>Nome da página</Form.Label>
                    <Form.Control 
                      value={instrumentName}
                      onChange={handleChangeInstrumentName}/>
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mb-5'>
                <Col xs={6}>
                  <h3 className='mt-5'>
                    Sessão de treino: &nbsp;
                    <ButtonGroup toggle title='Sessão de treino'>
                      <ToggleButton
                        id='true' 
                        name='true'
                        type='radio'
                        variant='outline-primary'
                        value='true'
                        onChange={e => handleChangeTraining(e)}
                        checked={options.training}>
                          Sim
                      </ToggleButton>
                      <ToggleButton
                        id='false' 
                        name='false'
                        type='radio'
                        variant='outline-primary'
                        value='false'
                        onChange={e => handleChangeTraining(e)}
                        checked={!options.training}>
                          Não
                      </ToggleButton>
                    </ButtonGroup> 
                  </h3>
                </Col>
              </Row>
              <Row className='mb-5'>
                <Col xs={6}>
                  <Form.Text>
                    <h3>Selecionar lista</h3>
                  </Form.Text>
                  <Form.Control as='select' value={options.list} onChange={handleChange}>
                    <option value='1'>1</option>
                    <option value='2'>2</option>
                  </Form.Control>
                </Col>
              </Row>
                
              <Row>
                  <Col>
                      <IconButton
                          icon={ICONS.BACK}
                          onClick={() => {history.goBack()}}>
                          
                          Voltar
                      </IconButton>
                      <IconButton 
                          type='submit' 
                          icon={ICONS.SAVE}
                          className='ml-1'
                          disabled={saveButtonDisabled}
                          variant={!saveButtonDisabled ? 'warning' : undefined}>
                          
                          Salvar
                      </IconButton>
                  </Col>
              </Row>
            </Form>
        </>
    )
}

export default LetComOptions