const sentences_CHAVEFolha95 = [ 
    "Temos um grande espaço para crescer nesse campo",
    "Nesse caso, não terá que dar qualquer abono",
    "Prefere deixar o assunto para depois da posse",
    "Tradicionalmente, os homens pagam um dote ao sogro",
    "A medula fica alojada no interior dos ossos",
    "Isso não significa que eu diminuí as obras",
    "Todos sobreviveram ao primeiro réveillon passado na corte",
    "Até que chegou o momento culminante da festa",
    "A distribuição vai ser dividida em três fases",
    "O suicídio é, afinal, o oposto do poema",
    "Grande orador, participou de várias tentativas de golpe",
    "Não houve avanços importantes de nenhum dos lados",
    "A outra atração é a queda de braço",
    "A briga, no entanto, é por poucos itens",
    "A ingenuidade dessa hipótese desmorona diante dos fatos",
    "A história mostrou para onde soprava o vento",
    "A classe média baixa está subindo o morro",
    "Adequadíssimo para o público camisa listrada e cinto",
    "O álbum traz mudanças na formação da banda",
    "Dentro de dois meses, deve lançá-lo em vídeo",
    "Indicadores nas telas permitem o aprofundamento dos temas",
    "Há restaurantes e bares de todos os tipos",
    "Todos sabem que o fumo compromete a saúde",
    "Porta teria sido arrombada a tiros de fuzil",
    "Os tetos são de sapé ou de vidro",
    "No sonho o poema era como uma carta",
    "Os recursos são direcionados para investimentos no setor",
    "Uma das armas do time é a união",
    "Geralmente, quem critica nunca viveu seu próprio sonho",
    "A maioria dos moradores era catador de papel",
    "Vejo três dificuldades básicas na poesia deste autor",
    "Agora está se conhecendo o tamanho da conta",
    "A perícia encontrou no bar dezenas de balas",
    "Não estava a fim de levar uma surra",
    "Os investimentos são cuidadosos, buscando uma futura venda",
    "Os clubes às vezes é que cometem erros",
    "O mundo lá de cima abre-se num leque",
    "O desrespeito a esta legislação implica em multa",
    "A vitória final ficou por conta do acaso",
    "Seus aliados dizem que ele tem seis votos",
    "A prioridade na vida delas é o crack",
    "Deixei muitos amigos e perdi essa minha amiga",
    "Se demorar muito, a perplexidade devorará o homem",
    "Há hoje em circulação seis tipos de moeda",
    "A bateria final foi um festival de tubos",
    "O acidente foi televisoinado para todo o mundo",
    "Assim, você desarma a jogada sem cometer falta",
    "O governo tem espaço para reduzir as taxas",
    "Show de ontem foi frio como a chuva",
    "Afirmações em contrário não encontram respaldo nos dados",
    "Há chances de recuperação, embora a longo prazo",
    "Sofia fica à mercê dos amigos do casal",
    "Já o vermelho vinha de óxido de ferro",
    "O hotel não informou o montante do roubo",
    "A arquibancada estava cheia apenas perto do palco",
    "O vestibular é uma consequência, não uma causa",
    "Os produtos de origem bovina lideraram a queda",
    "A chuva serviu para amenizar o intenso calor",
    "Mas é pouco provável que ele tenha êxito",
    "Muitas pessoas começaram a voltar às suas casas",
    "São ambições altas, por trás de toda graça",
    "Ele concordou com essa abordagem e prometeu apoio",
    "Foi o maior público do grupo na turnê",
    "A droga estava escondida na lataria do carro",
    "Se não der, use o do seu amigo",
    "A única forma de acesso era o metrô",
    "A água da represa municipal invadiu a pista",
    "Ladrão e vigia morrem após tiroteio em banco",
    "Uma vez lá dentro, enfrenta-se a segunda etapa",
    "Nós trabalhamos duro e colhemos hoje o fruto",
    "Como se viu rapidamente, a oposição tinha razão",
    "O livro agora indicado está no primeiro grupo",
    "Mas isso não empresta brilho especial ao álbum",
    "Elas têm muitos funcionários e professores por aluno",
    "Ao se converter, ele perdeu título de bispo",
    "Ei-lo caminhando, indo à praia e jogando tênis",
    "Um projeto urbanístico será elaborado para o local",
    "Nova lei de franquias já está em vigor",
    "Adaptação e mudanças microbianas são o quinto fator",
    "Surgem várias feridas que se espalham pelo corpo",
    "Pode parecer que é enfeitar demais o pavão",
    "E de lá pra cá nasceu essa magia",
    "O grande assunto do momento são as vilas",
    "A instalação no automóvel dura aproximadamente três horas",
    "Algum tempo depois, muitas foram substituídas por muros",
    "Com ensaios abertos ao público, blocos antecipam folia",
    "A dança preferida dos pernambucanos é o frevo",
    "A mudança brusca da temperatura altera o sabor",
    "O pianista de partituras fiscais desapareceu da mídia",
    "A prefeitura iria arrecadar para pagar a folha",
    "Cada curso tem a duração de três meses",
    "Como, por exemplo, suspender por algemas de metal",
    "Sua sorte é que, normalmente, está de terno",
    "Isso é um fator para diminuir o crime",
    "A cantora foi recebida com queima de fogos",
    "Foram as mais criativas e bonitas da noite",
    "Dois átomos de hidrogênio originam um de hélio",
    "O sacerdote egípcio vestia belas roupas de couro",
    "Tem sete apartamentos, restaurante e barcos para pesca",
    "A vida pessoal, conta, acabou em segundo plano",
    "Por isso, a pré-seleção começa no próprio curso",
    "O negócio fará subir o preço das ações",
    "A surpresa talvez ficasse por conta dos shows",
    "As mais populares têm um cadeado como fecho",
    "Os limites percentuais do aumento são dupla farsa",
    "Despesas de valor alto costumam alertar o fisco",
    "Uma presa nunca deve deixar chinelo na porta",
    "A árvore passa pelo serrote e o torno",
    "O período coincide com as férias de verão",
    "Mas ele não pode mais permanecer no cargo",
    "Suas campanhas, seus comerciais, marcam toda uma época",
    "O partido vive hoje sua mais profunda crise",
    "Agora, só o resta dar tempo ao tempo",
    "O governo desvalorizou o real frente ao dólar",
    "Talvez seja esta a principal originalidade do filme",
    "Banqueiros criticaram a limitação das taxas de juros",
    "Eram considerados amantes maravilhosos e ganhavam presentes, jóias",
    "Ali palhoças servem galinhada ou peixe na brasa",
    "Muitos bancos entraram no leilão a este preço",
    "A crise, porém, é um fenômeno de fundo",
    "Caixa em madeira com xadrez, pôquer e damas",
    "Vamos fazer um complexo com seis, sete salas",
    "Suas telas são a representação abstrata das rosas",
    "As inscrições vão até o final de março",
    "A premissa é: quanto mais prédios, mais poder",
    "A oposição acusa o governo por sua morte",
    "Os filmes de horror não costumam trazer armas",
    "Só que não existem lugares vagos no avião",
    "O motorista é obrigado a transformar-se num herói",
    "O próximo duelo já tem data e lugar",
    "Só que eles também fazem parte do circo",
    "Outras associações de classe também compraram a briga",
    "Os traficantes tentaram rechaçar a investida a tiros",
    "A criação artística é o lampejo, a ideia",
    "Pais são acusados de manter filho em jaula",
    "Sozinha, já tomei três, quatro litros de pinga",
    "A força bruta sempre será derrotada no final",
    "A ordem é respeitar o banquete da turma",
    "A entidade é contrária à criação do exame",
    "O sistema deve começar a funcionar até junho",
    "Após as filmagens, a locomotiva retorna ao museu",
    "Tempo suficiente para colocar a mão na forma",
    "Quando chegaram ao local, havia ameaça de bomba",
    "Segundo ele, somente dois bancos teriam tido lucro",
    "Estava levando meus dois filhos para comer pizza",
    "Só posso aceitar encomendas para depois de julho",
    "E não afastou a possibilidade de uma greve",
    "Com a troca, a população corre menos risco",
    "A mudança estava marcada para hoje de manhã",
    "Mas, segundo ele, a ex-namorada ocultou o filho",
    "O filme seria uma extensão visual do livro",
    "Aí é que está a graça da coisa",
    "A prova terminou com dobradinha brasileira na ponta",
    "Tradicionalmente, os programas do gênero priorizam o humor",
    "Daí, é melhor botar as barbas de molho",
    "O poder maior está no homem, na nação",
    "Não importa se essa é uma equipe jovem",
    "Os presos não chegaram a agredir nenhum refém",
    "Escolhemos dois sucos e um prato de frios",
    "Aí que decidiram jogar o corpo no túnel",
    "Eles disseram que eu seria levada como refém",
    "Mas tudo tem seu tempo e seu ritmo",
    "Hoje, vive com o pai e o irmão",
    "O prédio está abandonado e não tem vigia",
    "Palmeirense agrada fãs e sonha com futura filha",
    "Na Bolívia, usuários pingam droga diluída nos olhos",
    "Tem muitos brinquedos que já estão nas lojas",
    "Logo percebi uma dor que dilacerava o tórax",
    "Passei então a viver em função deste termo",
    "Está melhor ao vivo do que em disco",
    "Rapidamente, a chama desaparece e deixa a cinza",
    "Os quatro campeões de chave disputam o turno",
    "Não acho que prejudique o desenrolar da trama",
    "Os times não importam, são artificiais, sem apelo",
    "Este, com cinco assinaturas além da do morto",
    "Por isso morreu, como um perturbador da ordem",
    "Hoje, oito funcionários trabalham como fiscais de caixa",
    "O susto não diminuiu depois da primeira saída",
    "E mandou tudo direto para casa, de navio",
    "São ácidos extraídos de vegetais ou do leite"
]

export default sentences_CHAVEFolha95