import React from 'react'
import shuffle from 'shuffle-array'

import ConfigType from '../../../types/ConfigType'

import { TRIALS } from '../../../constants/instruments'

import example1 from './bmp/example_symm1.bmp'
import example2 from './bmp/example_symm2.bmp'
import example3 from './bmp/example_symm3.bmp'
import example4 from './bmp/example_symm4.bmp'


const config: ConfigType[] = []
const matrices = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(n => [n])
const symmetry_practice = [
    // 1
    {
        cells: [4, 5, 12, 13, 19, 20, 21, 22, 26, 27, 28, 29, 30, 31, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 46, 47, 51, 52, 53, 54, 60, 61], 
        symmetric: true
    },
    // 2
    {
        cells: [1, 2, 7, 8, 9, 10, 11, 14, 15, 16, 17, 18, 20, 21, 23, 24, 25, 27, 28, 29, 30, 32, 33, 36, 37, 40, 41, 43, 44, 45, 46, 48, 49, 50, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64], 
        symmetric: true
    },
    // 3
    {
        cells: [1, 2, 3, 4, 5, 6, 7, 8, 11, 12, 13, 14, 20, 21, 27, 28, 29, 30, 35, 38, 43, 46, 51, 52, 53, 54, 58, 60, 61, 63], 
        symmetric: true
    },
    // 4
    {
        cells: [1, 2, 7, 8, 9, 10, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 27, 28, 29, 30, 35, 36, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 55, 56, 57, 58, 63, 64], 
        symmetric: true
    },
    // 5
    {
        cells: [1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24, 28, 29, 36, 37, 41, 42, 43, 44, 45, 46, 47, 48, 50, 51, 52, 53, 54, 55, 57, 58, 59, 60, 61, 62, 63, 64], 
        symmetric: true
    },
    // 6
    {
        cells: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 15, 16, 18, 23, 25, 26, 28, 29, 31, 32, 33, 34, 36, 37, 39, 40, 42, 47, 49, 50, 52, 53, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64], 
        symmetric: true
    },
    // 7
    {
        cells: [1, 2, 7, 8, 10, 11, 14, 15, 19, 20, 21, 22, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 43, 44, 45, 46, 50, 51, 54, 55, 57, 58, 63, 64], 
        symmetric: true
    },
    // 8
    {
        cells: [1, 2, 3, 4, 5, 7, 8, 11, 14, 15, 17, 18, 23, 26, 27, 28, 29, 30, 31, 32, 34, 35, 36, 37, 38, 39, 40, 41, 42, 47, 51, 54, 55, 57, 58, 59, 60, 61, 63, 64], 
        symmetric: false
    },
    // 9
    {
        cells: [8, 9, 10, 15, 16, 17, 18, 19, 22, 23, 24, 25, 26, 27, 28, 29, 30, 32, 33, 40, 41, 43, 44, 45, 46, 47, 48, 49, 50, 51, 54, 55, 56, 57, 58, 63, 64], 
        symmetric: false
    },
    // 10
    {
        cells: [3, 6, 9, 10, 11, 12, 13, 14, 15, 16, 17, 19, 21, 22, 24, 25, 27, 28, 30, 32, 33, 35, 36, 37, 38, 40, 41, 43, 44, 45, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 59, 62], 
        symmetric: false
    },
    // 11
    {
        cells: [1, 3, 4, 5, 6, 8, 10, 12, 13, 15, 17, 20, 21, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 45, 48, 50, 53, 55, 57, 59, 60, 61, 62, 64], 
        symmetric: false
    },
    // 12
    {
        cells: [1, 2, 3, 6, 7, 8, 9, 11, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 27, 30, 31, 33, 35, 38, 39, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 54, 55, 57, 58, 59, 62, 63, 64], 
        symmetric: false
    },
    // 13
    {
        cells: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 15, 16, 17, 18, 20, 23, 24, 25, 26, 29, 31, 32, 33, 34, 36, 39, 40, 42, 45, 47, 49, 50, 52, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64], 
        symmetric: false
    },
    // 14
    {
        cells: [1, 2, 3, 4, 5, 6, 7, 8, 10, 14, 15, 17, 18, 19, 22, 23, 24, 25, 26, 27, 30, 31, 32, 33, 34, 35, 38, 39, 40, 41, 42, 43, 46, 47, 48, 50, 54, 55, 57, 58, 59, 60, 61, 62, 63, 64], 
        symmetric: false
    },
    // 15
    {
        cells: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 23, 24, 26, 27, 28, 29, 30, 31, 34, 35, 36, 37, 38, 39, 41, 42, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 61, 62, 63, 64], 
        symmetric: false
    }
]
const symmetry_matrices = [
    //1
    {
        cells: [
            1, 2, 3, 4, 5, 6, 7, 8, 
            10, 15, 
            17, 18, 20, 21, 23, 24, 
            25, 27, 28, 29, 30, 32,
            33, 35, 36, 37, 38, 40,
            41, 42, 44, 45, 47, 48,
            50, 55,
            57, 58, 59, 60, 61, 62, 63, 64
        ],
        symmetric: true
    },
    //2
    {
        cells: [
            1, 2, 4, 5, 7, 8,
            11, 14,
            18, 23,
            26, 27, 28, 29, 30, 31,
            34, 36, 37, 39,
            41, 42, 47, 48,
            49, 50, 51, 54, 55, 56,
            60, 61
        ],
        symmetric: true
    },
    //3
    {
        cells: [
            1, 2, 3, 4, 5, 6, 7, 8,
            9, 10, 12, 13, 15, 16,
            18, 19, 22, 23,
            25, 26, 28, 29, 31, 32,
            33, 34, 36, 37, 39, 40,
            42, 43, 46, 47,
            49, 50, 52, 53, 55, 56,
            57, 58, 59, 60, 61, 62, 63, 64
        ],
        symmetric: true
    },
    //4
    {
        cells: [
            1, 2, 7, 8,
            10, 15,
            17, 18, 23, 24,
            25, 27, 28, 29, 30, 32,
            33, 40,
            41, 43, 44, 45, 46, 48,
            49, 50, 55, 56,
            58, 59, 60, 61, 62, 63
        ],
        symmetric: true
    },
    //5
    {
        cells: [
            3, 6,
            11, 14,
            19, 22,
            25, 27, 30, 32,
            33, 35, 36, 37, 38, 40,
            41, 43, 44, 45, 46, 48,
            49, 50, 51, 52, 53, 54, 55, 56,
            59, 62
        ],
        symmetric: true
    },
    //6
    {
        cells: [
            1, 8,
            9, 10, 15, 16,
            17, 18, 19, 20, 21, 22, 23, 24,
            25, 27, 30, 32,
            33, 35, 38, 40,
            41, 43, 46, 48,
            49, 50, 51, 52, 53, 54, 55, 56,
            57, 64
        ],
        symmetric: true
    },
    //7
    {
        cells: [
            1, 8,
            9, 10, 12, 13, 15, 16,
            19, 20, 21, 22,
            28, 29,
            33, 34, 35, 36, 37, 38, 39, 40,
            43, 46,
            49, 50, 55, 56,
            57, 58, 63, 64
        ],
        symmetric: true
    },
    //8
    {
        cells: [
            1, 2, 3, 4, 5, 6, 7, 8,
            9, 16,
            17, 20, 21, 24,
            25, 26, 27, 28, 29, 30, 31, 32,
            33, 36, 37, 40,
            41, 48,
            49, 56,
            57, 58, 59, 60, 61, 62, 63, 64
        ],
        symmetric: true
    },
    //9
    {
        cells: [
            2, 3, 4, 5, 6, 7,
            10, 11, 12, 13, 14, 15,
            17, 18, 19, 22, 23, 24,
            25, 26, 31, 32,
            33, 34, 39, 40,
            41, 42, 43, 46, 47, 48,
            50, 51, 52, 53, 54, 55,
            58, 59, 60, 61, 62, 63
        ],
        symmetric: true
    },
    //10
    {
        cells: [
            1, 2, 3, 4, 5, 6, 7, 8,
            9, 10, 11, 12, 13, 14, 15, 16,
            17, 18, 19, 22, 23, 24,
            26, 27, 30, 31,
            34, 35, 36, 37, 38, 39,
            42, 43, 44, 45, 46, 47,
            51, 52, 53, 54,
            59, 60, 61, 62
        ],
        symmetric: true
    },
    //11
    {
        cells: [
            1, 8,
            9, 10, 15, 16,
            17, 18, 19, 22, 23, 24,
            25, 26, 27, 28, 29, 30, 31, 32,
            33, 35, 36, 37, 38, 40,
            41, 42, 43, 44, 45, 46, 47, 48,
            49, 50, 51, 54, 55, 56,
            57, 58, 63, 64
        ],
        symmetric: true
    },
    //12
    {
        cells: [
            1, 2, 7, 8,
            10, 11, 14, 15,
            17, 18, 23, 24,
            26, 27, 28, 29, 30, 31,
            34, 35, 36, 37, 38, 39,
            41, 42, 47, 48,
            50, 51, 54, 55,
            57, 58, 63, 64
        ],
        symmetric: true
    },
    //13
    {
        cells: [
            4, 5,
            9, 10, 11, 12, 13, 14, 15, 16,
            17, 18, 19, 20, 21, 22, 23, 24,
            26, 28, 29, 31,
            33, 36, 37, 40,
            41, 42, 43, 44, 45, 46, 47, 48,
            49, 50, 51, 52, 53, 54, 55, 56,
            59, 60, 61, 62
        ],
        symmetric: true
    },
    //14
    {
        cells: [
            1, 2, 7, 8,
            9, 10, 15, 16,
            17, 19, 20, 21, 22, 24,
            27, 30,
            35, 36, 37, 38,
            41, 42, 43, 46, 47, 48,
            49, 50, 55, 56,
            57, 58, 63, 64
        ],
        symmetric: true
    },
    //15
    {
        cells: [
            1, 4, 5, 8,
            10, 12, 13, 15,
            19, 20, 21, 22,
            28, 29,
            36, 37,
            43, 44, 45, 46,
            50, 51, 52, 53, 54, 55,
            57, 58, 59, 60, 61, 62, 63, 64
        ],
        symmetric: true
    },
    //16
    {
        cells: [
            1, 2, 7, 8,
            10, 15,
            18, 19, 20, 21, 22, 23,
            28, 29,
            33, 34, 35, 36, 37, 38, 39, 40,
            41, 48,
            49, 50, 55, 56,
            58, 59, 62, 63
        ],
        symmetric: true
    },
    //17
    {
        cells: [
            2, 3, 4, 5, 6, 7,
            9, 10, 11, 12, 13, 14, 15, 16,
            17, 24,
            25, 26, 27, 28, 29, 30, 31, 32,
            34, 35, 36, 37, 38, 39,
            42, 44, 45, 47,
            50, 52, 53, 55,
            57, 58, 63, 64
        ],
        symmetric: true
    },
    //18
    {
        cells: [
            1, 2, 3, 4, 5, 6, 7, 8,
            10, 15,
            17, 18, 19, 20, 21, 22, 23, 24,
            27, 28, 29, 30,
            35, 36, 37, 38,
            41, 42, 43, 44, 45, 46, 47, 48,
            50, 55,
            57, 58, 59, 60, 61, 62, 63, 64
        ],
        symmetric: true
    },
    //19
    {
        cells: [
            1, 2, 3, 4, 5, 6, 7, 8,
            9, 12, 13, 16,
            18, 19, 20, 21, 22, 23,
            26, 28, 29, 31,
            34, 36, 37, 39,
            42, 43, 44, 45, 46, 47,
            49, 52, 53, 56,
            57, 58, 59, 60, 61, 62, 63, 64
        ],
        symmetric: true
    },
    //20
    {
        cells: [
            3, 4, 5, 6,
            10, 15,
            17, 24,
            25, 26, 27, 28, 29, 30, 31, 32,
            33, 34, 35, 36, 37, 38, 39, 40,
            41, 48,
            50, 55,
            59, 60, 61, 62
        ],
        symmetric: true
    },
    //21
    {
        cells: [
            2, 3, 6, 7,
            10, 11, 14, 15,
            17, 20, 21, 24,
            26, 27, 30, 31,
            34, 35, 38, 39,
            41, 44, 45, 48, 
            50, 51, 54, 55,
            58, 59, 62, 63
        ],
        symmetric: true
    },
    //22
    {
        cells: [
            3, 6,
            11, 14,
            17, 18, 19, 20, 21, 22, 23, 24,
            27, 30,
            35, 38,
            41, 42, 43, 44, 45, 46, 47, 48,
            51, 54,
            59, 62
        ],
        symmetric: true
    },
    //23
    {
        cells: [
            3, 4, 5, 6,
            10, 15,
            19, 20, 21, 22,
            25, 26, 31, 32,
            35, 36, 37, 38,
            42, 47,
            51, 52, 53, 54,
            57, 58, 63, 64
        ],
        symmetric: true
    },
    //24
    {
        cells: [
            1, 2, 3, 4, 5, 6, 7, 8,
            9, 10, 15, 16,
            18, 23,
            25, 26, 31, 32,
            33, 34, 39, 40,
            42, 47,
            49, 50, 55, 56,
            57, 58, 59, 60, 61, 62, 63, 64
        ],
        symmetric: true
    },
    //25
    {
        cells: [
            4, 5,
            11, 12, 13,
            19, 20, 21, 22,
            26, 27, 28, 29, 30, 31,
            34, 39,
            42, 43, 44, 45, 46, 47,
            51, 52, 53, 54,
            60, 61, 62
        ],
        symmetric: false
    },
    //26
    {
        cells: [
            1, 2, 3, 4, 5, 6, 7, 8,
            10, 11, 15,
            17, 18, 19, 20, 21, 22, 23, 24,
            26, 28, 29, 31,
            33, 34, 35, 36, 37, 38, 39, 40,
            41, 44, 45, 48,
            49, 50, 51, 52, 53, 54, 55, 56,
            58, 59, 62, 63
        ],
        symmetric: false
    },
    //27
    {
        cells: [
            1, 2, 3, 4, 5, 6, 7, 8,
            10, 11, 12, 13, 14, 15,
            20, 21,
            25, 26, 27, 28, 29, 30, 31,
            35, 38,
            43, 44, 45, 46,
            50, 51, 52, 53, 54, 55, 56,
            60, 61
        ],
        symmetric: false
    },
    //28
    {
        cells: [
            3, 4, 7, 8,
            10, 15,
            17, 18, 21, 23, 24,
            25, 27, 28, 29, 30, 32,
            33, 35, 36, 37, 38, 40,
            41, 42, 45, 47, 48,
            50, 55,
            59, 60, 63, 64
        ],
        symmetric: false
    },
    //29
    {
        cells: [
            1, 2, 3, 6,
            9, 11, 14,
            17, 19, 22,
            25, 27, 28, 29, 30, 31, 32,
            33, 34, 35, 36, 37, 38, 40,
            43, 46, 48,
            51, 54, 56,
            59, 62, 63, 64
        ],
        symmetric: false
    },
    //30
    {
        cells: [
            1, 2, 8,
            9, 10, 11, 12, 13, 14, 15, 16,
            17, 18, 19, 23, 24,
            25, 26, 27, 31, 32,
            33, 34, 35, 39, 40,
            41, 42, 43, 47, 48,
            49, 50, 51, 52, 53, 54, 55, 56,
            57, 58, 64
        ],
        symmetric: false
    },
    //31
    {
        cells: [
            1, 7, 8,
            9, 10, 15,
            19, 22,
            27, 28, 29, 30,
            34, 35, 36, 37, 38, 39,
            42, 43, 46, 47,
            49, 50, 55,
            57, 63, 64
        ],
        symmetric: false
    },
    //32
    {
        cells: [
            2, 3, 4, 5, 6, 7,
            9, 13, 16,
            17, 21,
            27, 28, 29, 30,
            35, 36, 37, 38, 40,
            41, 44, 48,
            49, 52, 56,
            58, 59, 60, 61, 62, 63
        ],
        symmetric: false
    },
    //33
    {
        cells: [
            3, 4, 5, 6,
            11, 12, 13, 14,
            17, 18, 19, 20, 22, 23, 24,
            25, 26, 27, 28, 31, 32,
            33, 34, 37, 38, 39, 40,
            41, 42, 43, 45, 46, 47, 48,
            51, 52, 53, 54,
            59, 60, 61, 62
        ],
        symmetric: false
    },
    //34
    {
        cells: [
            1, 8,
            9, 10, 15, 16,
            17, 18, 19, 22, 23,
            26, 27, 28, 29, 30, 31,
            34, 35, 36, 37, 38, 39,
            42, 43, 44, 45, 46, 47,
            52, 53, 54,
            60, 61, 62
        ],
        symmetric: false
    },
    //35
    {
        cells: [
            1, 3, 4, 5, 6, 8,
            10, 12, 13, 15,
            17, 19, 21, 22, 24,
            25, 26, 27, 28, 29, 30, 31, 32,
            33, 34, 35, 36, 37, 38, 39, 40,
            41, 43, 44, 46, 48,
            50, 52, 53, 55,
            57, 59, 60, 61, 62, 64
        ],
        symmetric: false
    },
    //36
    {
        cells: [
            2, 3, 7, 8,
            10, 11, 13, 14, 15, 16,
            17, 18, 23, 24,
            26, 27, 28, 29, 30, 31,
            34, 35, 36, 37, 38, 39,
            41, 42, 47, 48,
            49, 50, 51, 52, 54, 55,
            57, 58, 62, 63
        ],
        symmetric: false
    },
    //37
    {
        cells: [
            1, 4, 5,
            9, 10, 11, 12, 13, 14,
            19, 20, 21, 22, 23, 24,
            28, 29, 32,
            33, 35, 36, 37, 38,
            41, 42, 43, 44, 45, 46,
            51, 52, 53, 54, 55, 56,
            59, 60, 61, 62, 64
        ],
        symmetric: false
    },
    //38
    {
        cells: [
            1, 2, 6, 7, 8,
            9, 10, 15, 16,
            17, 18, 19, 20, 21, 22,
            27, 30,
            35, 38,
            43, 44, 45, 46, 47, 48,
            49, 50, 55, 56,
            57, 58, 59, 63, 64
        ],
        symmetric: false
    },
    //39
    {
        cells: [
            2, 5, 7, 8,
            10, 12, 13, 15,
            18, 19, 20, 21, 22, 23,
            28, 29,
            36, 37,
            42, 43, 44, 45, 46, 47,
            50, 52, 53, 55,
            57, 58, 60, 63
        ],
        symmetric: false
    },
    //40
    {
        cells: [
            2, 3, 4, 5, 6, 7, 8,
            10, 15,
            17, 18, 19, 20, 21, 22, 23,
            28, 29,
            33, 34, 35, 36, 37, 38, 39, 40,
            41, 48,
            49, 50, 51, 52, 52, 54, 55, 56,
            59, 63
        ],
        symmetric: false
    },
    //41
    {
        cells: [
            1, 2, 3, 4, 5, 6, 7, 8,
            9, 12, 16,
            17, 18, 21, 23, 24,
            26, 27, 28, 29, 30, 31,
            34, 35, 36, 37, 38, 39,
            42, 47,
            50, 55,
            57, 58, 62, 63
        ],
        symmetric: false
    },
    //42
    {
        cells: [
            1, 2, 3, 4, 5, 6, 7, 8,
            14, 15,
            17, 18, 19, 20, 21, 22, 23,
            28, 29, 30,
            35, 36, 37,
            42, 43, 44, 45, 46, 47, 48,
            50, 51,
            57, 58, 59, 60, 61, 62, 63, 64
        ],
        symmetric: false
    },
    //43
    {
        cells: [
            2, 3, 4, 5, 6, 7, 8,
            10, 12, 13, 16,
            18, 19, 20, 21, 22, 24,
            26, 28, 29, 30, 31,
            34, 35, 36, 37, 39,
            41, 43, 44, 45, 46, 47,
            49, 52, 53, 55,
            57, 58, 59, 60, 61, 62, 63
        ],
        symmetric: false
    },
    //44
    {
        cells: [
            2, 8,
            11, 15,
            20, 22,
            25, 26, 27, 28, 29, 30, 31, 32,
            33, 34, 35, 36, 37, 38, 39, 40,
            43, 45,
            50, 54,
            57, 63
        ],
        symmetric: false
    },
    //45
    {
        cells: [
            3, 6, 7, 8,
            11, 14, 15, 16,
            17, 18, 19, 22, 23, 24,
            25, 28, 29,
            36, 37, 40,
            41, 42, 43, 46, 47, 48,
            49, 50, 51, 54,
            57, 58, 59, 62
        ],
        symmetric: false
    },
    //46
    {
        cells: [
            1, 2, 5,
            9, 10, 13, 15, 16,
            19, 20, 21, 22,
            25, 26, 31, 32,
            33, 34, 39, 40,
            43, 44, 45, 46,
            49, 50, 52, 55, 56,
            57, 58, 60
        ],
        symmetric: false
    },
    //47
    {
        cells: [
            3, 4, 5, 6,
            11, 14,
            18, 19, 20, 21, 22, 23,
            25, 26, 31, 32,
            34, 35, 36, 37, 38, 39,
            41, 46, 48,
            51, 52, 53, 54,
            59
        ],
        symmetric: false
    },
    //48
    {
        cells: [
            2, 3, 4, 5, 6, 7,
            10, 15,
            18, 19, 23, 24,
            25, 26, 27, 30, 31, 32,
            33, 34, 35, 38, 39, 40,
            42, 43, 47, 48,
            50, 55,
            58, 59, 60, 61, 62, 63
        ],
        symmetric: false
    },
]

// Instructions - Training
config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <h1 className='text-center mb-3'>Instruções para a sessão de prática</h1>
            <p>
                Neste teste, você tentará memorizar a posição dos quadrados vermelhos que aparecem na tela enquanto também faz julgamentos sobre outras imagens.
            </p>
            <p>Nos próximos minutos, você praticará um pouco para se familiarizar com o funcionamento do experimento.</p>
            <p>Começaremos praticando a parte “dos quadrados” do experimento.</p>
            <p>Clique com o mouse para continuar.</p>
        </>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>Para este conjunto de prática, os quadrados aparecerão na tela um de cada vez.</p>
            <p>Tente lembrar onde cada quadrado estava, na ordem em que foi apresentado.</p>
            <p>Após 2 – 5 quadrados terem sido mostrados, você verá uma grade com os 16 lugares possíveis nos quais os quadrados poderiam estar.</p>
            <p>Seu trabalho é selecionar cada quadrado na ordem apresentada.</p>
            <p>Para fazer isso, use o mouse para selecionar as caixas apropriadas. Os quadrados que você selecionar ficarão vermelhos.</p>
            <p>Clique no botão do mouse para continuar.</p>
        </>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>Quando você tiver selecionado todos os quadrados e eles estiverem na ordem correta, clique no botão ENTER no canto inferior direito da tela.</p>
            <p>Se você cometer um erro, clique no botão LIMPAR para começar de novo.</p>
            <p>Se você esquecer um dos quadrados, clique no botão EM BRANCO para marcar o local do quadrado que está faltando.</p>
            <p>
                Lembre-se, é muito importante colocar os quadrados na mesma ordem em que você os viu. Se você esquecer um, use o botão EM BRANCO para marcar a posição.
            </p>
            <p>Reserve um momento para certificar-se de que entendeu as instruções.</p>
            <p>Quando estiver pronto, clique no botão do mouse para iniciar a prática.</p>
        </>
    )
})

// Training
shuffle(matrices)

for (let m of matrices.slice(0, 2)) {
    config.push({
        type: TRIALS.MATRIX,
        timeout: 650,
        filled: m
    })
}

config.push({
    type: TRIALS.MATRIX,
    recall: true,
})

shuffle(matrices)

for (let m of matrices.slice(0, 2)) {
    config.push({
        type: TRIALS.MATRIX,
        timeout: 650,
        filled: m
    })
}

config.push({
    type: TRIALS.MATRIX,
    recall: true,
})

shuffle(matrices)

for (let m of matrices.slice(0, 3)) {
    config.push({
        type: TRIALS.MATRIX,
        timeout: 650,
        filled: m
    })
}

config.push({
    type: TRIALS.MATRIX,
    recall: true,
})

shuffle(matrices)

for (let m of matrices.slice(0, 3)) {
    config.push({
        type: TRIALS.MATRIX,
        timeout: 650,
        filled: m
    })
}

config.push({
    type: TRIALS.MATRIX,
    recall: true,
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>Agora você praticará a parte de simetria do experimento.</p>
            <p>Uma imagem aparecerá na tela e você terá que decidir se ela é simétrica.</p>
            <p>Uma imagem é simétrica se você puder dobrá-la ao meio verticalmente e a imagem à esquerda se alinhar com a imagem à direita. </p>
            <p>Na próxima tela você verá uma imagem que é SIMÉTRICA.</p>
            <p>Clique com o mouse para continuar.</p>
        </>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <div className='text-center'>
            <p>Observe que esta imagem é simétrica em relação à linha vermelha.</p>
            <p>Nas imagens do teste real a linha vermelha não estará presente.</p>
            <p>Clique com o mouse para continuar.</p>
            <img src={example1} alt='Exemplo de imagem simétrica' />
        </div>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <div className='text-center'>
            <p>Aqui, a imagem NÃO é simétrica.</p>
            <p>Se você a dobrasse na linha vermelha, as caixas NÃO se alinhariam. </p>
            <p>Clique com o mouse para continuar.</p>
            <img src={example2} alt='Exemplo de imagem assimétrica' />
        </div>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <div className='text-center'>
            <p>Este é outro exemplo de uma imagem que É simétrica.</p>
            <p>Se você a dobrasse verticalmente, os dois lados se alinhariam.</p>
            <p>Clique com o mouse para continuar.</p>
            <img src={example3} alt='Exemplo de imagem simétrica' />
        </div>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <div className='text-center'>
            <p>Aqui está outro exemplo de uma imagem que NÃO é simétrica.</p>
            <p>Observe que, se dobrada, os dois lados não se alinhariam.</p>
            <p>Reserve um momento para certificar-se de que entendeu as instruções.</p>
            <p>Clique com o mouse para continuar.</p>
            <img src={example4} alt='Exemplo de imagem simétrica' />
        </div>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>Após decidir se a imagem é simétrica, clique no botão do mouse.</p>
            <p>Na próxima tela aparecerão botões com os dizeres SIM e NÃO.</p>
            <p>Se a imagem que você viu era simétrica, clique no botão SIM. Se não for simétrica, clique no botão NÃO.</p>
            <p>Após clicar em um dos botões, o computador informará se você fez a escolha certa.</p>
            <p>Clique com o mouse para continuar.</p>
        </>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>É MUITO importante que você analise as imagens corretamente.</p>
            <p>Reserve um momento para certificar-se de que entendeu as instruções.</p>
            <p>Quando estiver pronto, clique com o mouse para resolver alguns problemas de prática.</p>
        </>
    )
})

shuffle(symmetry_practice)

for (let s of symmetry_practice) {
    // Symmetry test
    config.push({
        type: TRIALS.SYMMETRY,
        filled: s.cells,
        getMean: true,
        isSymmetric: s.symmetric,
        showCorrect: true,
        training: true
    })
}

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>Agora você praticará as duas partes do teste simultaneamente. No próximo conjunto de prática, você receberá um dos problemas de simetria.</p>
            <p>Após tomar sua decisão sobre a imagem, um quadrado aparecerá na tela. Tente se lembrar da posição do quadrado.</p>
            <p>Na seção anterior, onde você decidiu apenas sobre a simetria da imagem, o computador calculou seu tempo médio para resolver os problemas.</p>
            <p>
                Se você demorar mais do que o seu tempo médio, o computador o moverá automaticamente para a parte dos quadrados, pulando a parte SIM ou NÃO, e contará esse problema como um erro.
            </p>
            <p>Portanto, é MUITO importante resolver os problemas da forma mais rápida e precisa possível.</p>
            <p>Clique com o mouse para continuar. </p>
        </>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>Depois que o quadrado desaparecer, outra imagem de simetria aparecerá e, em seguida, outro quadrado.</p>
            <p>
                Ao final de cada conjunto de figuras e quadrados, uma tela de recuperação aparecerá. Use o mouse para selecionar os quadrados que você acabou de ver.
            </p>
            <p>Faça o seu melhor para lembrar a posição dos quadrados na ordem correta. É importante trabalhar de maneira RÁPIDA e PRECISA.</p>
            <p>Você não será informado se sua resposta para a imagem de simetria está correta.</p>
            <p>
                <strong>IMPORTANTE:</strong> você não pode anotar a posição dos quadrados durante a apresentação. Você deve confiar na sua memória para lembrá-las.
            </p>
            <p>Reserve um momento para certificar-se de que entendeu as instruções.</p>
            <p>Clique com o mouse para tentar alguns problemas de prática.</p>
        </>
    )
})

shuffle(symmetry_practice)
shuffle(matrices)

let symmetry_index = 0
let matrix_index = 0

for (let level = 2; level <= 2; level++) {
    for (let set = 1; set <= 3; set++) {
        for (let i = 1; i <= level; i++) {
            for (let s of symmetry_practice.slice(symmetry_index, symmetry_index + (level - 1))) {
                // Symmetry test
                config.push({
                    type: TRIALS.SYMMETRY,
                    filled: s.cells,
                    timeout: 'mean',
                    isSymmetric: s.symmetric,
                    showCorrect: false,
                    training: true
                })
        
                // Matrix
                config.push({
                    type: TRIALS.MATRIX,
                    timeout: 650,
                    filled: matrices[matrix_index++]
                })
            }
            symmetry_index = symmetry_index + level
        }
    
        config.push({
            type: TRIALS.MATRIX,
            recall: true,
        })
    }
}

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>Esse é o fim da prática.</p>
            <p>O teste real será parecido com a prática que você acabou de completar. Alguns conjuntos terão mais problemas do que outros.</p>
            <p>É importante que você faça o seu melhor tanto na simetria quanto nas partes dos quadrados desta tarefa.</p>
            <p>Reserve um momento para certificar-se de que entendeu as instruções.</p>
            <p>Clique no botão do mouse para iniciar o experimento.</p>
        </>
    )
})

const levels = [2, 3, 4, 5]

shuffle(symmetry_matrices)
shuffle(levels)

symmetry_index = 0

for (let level of levels) {
    shuffle(matrices)

    matrix_index = 0

    for (let set = 1; set <= 3; set++) {
        for (let i = 1; i <= level; i++) {
            // Symmetry test
            config.push({
                type: TRIALS.SYMMETRY,
                filled: symmetry_matrices[symmetry_index].cells,
                timeout: 'mean',
                isSymmetric: symmetry_matrices[symmetry_index].symmetric,
                showCorrect: false,
                training: false
            })

            // Matrix
            config.push({
                type: TRIALS.MATRIX,
                timeout: 650,
                filled: matrices[matrix_index++]
            })
            
            symmetry_index++
        }

        config.push({
            type: TRIALS.MATRIX,
            recall: true,
        })
    }
}

export default config