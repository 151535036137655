import React, { useState, useEffect, Dispatch, SetStateAction, useRef, useContext } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { v4 as uuid } from 'uuid'

import { IconButton, ICONS, Loading } from '../../Layout'
import configure from './configure'
import { TimerContext } from '../Timer'
import Comment from './Comment'
import { FirebaseContext } from '../../Firebase'

import DataType from '../../../types/DataType'
import CharacterObject from '../../../types/CharacterObject'
import ReadingLogType, { CommentType } from '../../../types/ReadingLogType'

import { INSTRUMENTS } from '../../../constants/instruments'

import './index.css'

function ReadingLogger(props: {
  id: string,
  name: string,
  show: boolean,
  data: DataType[],
  experimentId: string,
  addData: Dispatch<SetStateAction<DataType[]>>,
  next: (finish?: boolean) => void,
  hideButton?: boolean
}) {
  const [finish, setFinish] = useState(false)
  const [options, setOptions] = useState([] as {text: string}[])
  const [pageWidth, setPageWidth] = useState(0)
  const [pageHeight, setPageHeight] = useState(0)
  const [textState, setTextState] = useState<CharacterObject[][][]>([])
  const [comments, setComments] = useState<CommentType[]>([])
  const [hidden, setHidden] = useState(true)
  const [commentCounter, setCommentCounter] = useState(1)
  const log = useRef<ReadingLogType[]>([])
  const setLog = (newLog: ReadingLogType[]) => { log.current = newLog }
  const timer = useContext(TimerContext)
  const container = useRef(null as HTMLDivElement | null)
  const selectionStart = useRef<number>()
  const selectionEnd = useRef<number>()
  const fontSize = 12
  const firebase = useContext(FirebaseContext)

  const toggleClass = (className: 'bold' | 'italic' | 'underline' | 'highlight', selectionRange?: Selection|null) => {
    const selection = selectionRange || window.getSelection()

    if (!selection?.isCollapsed) {
      if (selectionStart.current && selectionEnd.current) {
        const start = selectionStart.current
        const end = selectionEnd.current

        if (textState.flat().flat().filter(char => char.i >= start && char.i <= end).every(char => char.classes?.includes(className))) {
          setTextState(textState => (textState.map(page => (
            page.map(paragraph => (
              paragraph.map(char => {
                if (char.i < start || char.i > end) {
                  return char
                }

                return { ...char, classes: char.classes?.filter(c => c !== className) }
              })
            ))
          ))))
        } else {
          setTextState(textState => (textState.map(page => (
            page.map(paragraph => (
              paragraph.map(char => {
                if (char.i < start || char.i > end) {
                  return char
                }

                return { ...char, classes: char.classes ? [...char.classes, className] : [className] }
              })
            ))
          ))))
        }
      }
    }
  }

  const addCommentHighlight = (selectionRange?: Selection|null) => {
    const selection = selectionRange || window.getSelection()

    if (!selection?.isCollapsed) {
      if (selectionStart.current && selectionEnd.current) {
        const start = selectionStart.current
        const end = selectionEnd.current
        const shouldAddComment = !textState.flat().flat().filter(char => char.i >= start && char.i <= end).every(char => char.classes?.includes('comment'))

        if (shouldAddComment) {
          setTextState(textState => (textState.map(page => (
            page.map(paragraph => (
              paragraph.map(char => {
                if (char.i < start || char.i > end) {
                  return char
                }

                return { ...char, classes: char.classes ? [...char.classes, 'comment'] : ['comment'] }
              })
            ))
          ))))
        }

        return shouldAddComment
      }
    }
  }

  const countComment = () => {
    setCommentCounter(n => n + 1)
  }

  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.ctrlKey && ['n', 'i', 's', 'd'].includes(e.key)) {
      e.preventDefault()
    }
  }

  const handleResize = () => {
    const current: any = container.current

    if (current) {
      setPageWidth(current.offsetWidth)
      setPageHeight(current.offsetWidth * Math.SQRT2)
    }
  }

  const handleMouseUp = () => {
    const selection = window.getSelection()

    const anchor = parseInt(selection?.anchorNode?.parentElement?.dataset.i || '')
    const focus = parseInt(selection?.focusNode?.parentElement?.dataset.i || '')

    selectionStart.current = anchor < focus ? anchor : focus
    selectionEnd.current = anchor > focus ? anchor : focus
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.ctrlKey && ['n', 'i', 's', 'd'].includes(e.key)) {
      e.preventDefault()
    }
  }

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const newLog = [...log.current]

    newLog.push({
      id: uuid(),
      type: 'MouseMovement',
      timestamp: timer.getCurrentTimestamp(),
      elapsedTime: timer.getElapsedTime(),
      interval: timer.getInterval(),
      timeIndex: timer.getCount(),
      coordX: e.pageX,
      coordY: e.pageY,
      viewPortWidth: window.innerWidth
    })

    setLog(newLog)
  }

  const handleScroll = function(e: Event) {
    const newLog = [...log.current]

    newLog.push({
      id: uuid(),
      type: 'Scroll',
      timestamp: timer.getCurrentTimestamp(),
      elapsedTime: timer.getElapsedTime(),
      interval: timer.getInterval(),
      timeIndex: timer.getCount(),
      coordX: 0,
      coordY: window.scrollY,
      viewPortHeigth: window.innerHeight
    })

    setLog(newLog)
  }

  const handleBlur = () => {
    const newLog = [...log.current]

    newLog.push({
      id: uuid(),
      type: 'FocusOut',
      timestamp: timer.getCurrentTimestamp(),
      elapsedTime: timer.getElapsedTime(),
      interval: timer.getInterval(),
      timeIndex: timer.getCount(),
      coordX: 0,
      coordY: 0
    })

    setLog(newLog)
  }

  const handleFocus = () => {
    const newLog = [...log.current]

    newLog.push({
      id: uuid(),
      type: 'FocusIn',
      timestamp: timer.getCurrentTimestamp(),
      elapsedTime: timer.getElapsedTime(),
      interval: timer.getInterval(),
      timeIndex: timer.getCount(),
      coordX: 0,
      coordY: 0
    })

    setLog(newLog)
  }

  const handleCommentSubmit = (i: number, text: string) => {
    const newComments = [...comments]
    const newLog = [...log.current]

    newComments[i] = {...newComments[i], text}

    setComments(newComments)

    newLog.push({
      id: uuid(),
      type: 'Comment',
      timestamp: timer.getCurrentTimestamp(),
      elapsedTime: timer.getElapsedTime(),
      interval: timer.getInterval(),
      timeIndex: timer.getCount(),
      coordX: 0,
      coordY: newComments[i].position,
      selectionStart: newComments[i].highlight.start,
      selectionEnd: newComments[i].highlight.end,
      text: `[Comment saved] ${text}`
    })

    setLog(newLog)
  }

  const handleCommentDelete = (i: number) => {
    const newComments = [...comments]
    const start = newComments[i].highlight.start
    const end = newComments[i].highlight.end
    const position = newComments[i].position
    const newLog = [...log.current]

    newComments.splice(i, 1)

    if (start && end) {
      setTextState(textState => (textState.map(page => (
        page.map(paragraph => (
          paragraph.map(char => {
            if (char.i < start || char.i > end) {
              return char
            }
  
            return { ...char, classes: char.classes?.filter(c => c !== 'comment') }
          })
        ))
      ))))
    }

    setComments(newComments)

    newLog.push({
      id: uuid(),
      type: 'Comment',
      timestamp: timer.getCurrentTimestamp(),
      elapsedTime: timer.getElapsedTime(),
      interval: timer.getInterval(),
      timeIndex: timer.getCount(),
      coordX: 0,
      coordY: position,
      selectionStart: start,
      selectionEnd: end,
      text: '[Comment deleted]'
    })

    setLog(newLog)
  }

  const handleCommentAdd = () => {
    let position: number
    const selection = window.getSelection()

    if (addCommentHighlight(selection)) {
      const anchor = selection?.anchorNode?.parentElement
      const focus = selection?.focusNode?.parentElement
      const newLog = [...log.current]

      if (anchor && focus && anchor.dataset && focus.dataset && anchor.dataset.i && focus.dataset.i) {
        if (anchor.dataset.i > focus.dataset.i) {
          position = anchor.getBoundingClientRect().top - 48 + window.scrollY
        } else {
          position = focus.getBoundingClientRect().top - 48 + window.scrollY
        }

        setComments(currentComments => (
          [...currentComments, {text: '', n: commentCounter, position, highlight: {start: selectionStart.current, end: selectionEnd.current}}]
        ))
    
        countComment()

        newLog.push({
          id: uuid(),
          type: 'Comment',
          timestamp: timer.getCurrentTimestamp(),
          elapsedTime: timer.getElapsedTime(),
          interval: timer.getInterval(),
          timeIndex: timer.getCount(),
          coordX: 0,
          coordY: position,
          selectionStart: selectionStart.current,
          selectionEnd: selectionEnd.current,
          text: '[Comment addded]'
        })

        setLog(newLog)
      }
    }
  }

  useEffect(() => {
    firebase?.experiment(props.experimentId).once(
      'value', snapshot => {
        setOptions(
          snapshot.val().instruments.filter(
            (i: any) => i.id === props.id)[0].options
        )
      }
    )
    
    window.addEventListener('resize', handleResize)
    window.addEventListener('mouseup', handleMouseUp)
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)
    window.addEventListener('scroll', handleScroll)
    window.addEventListener('blur', handleBlur)
    window.addEventListener('focus', handleFocus)

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('mouseup', handleMouseUp)
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('blur', handleBlur)
      window.removeEventListener('focus', handleFocus)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (options && options.length > 0) {
      setTextState(configure(options))
    }
  }, [options])

  useEffect(() => {
    timer.setInitialTimestamp()
    handleResize()

    setTimeout(() => {
      setHidden(false)
    }, 2000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show])

  useEffect(() => {
    if (props.show && finish) {
      props.addData(data => {
        return [
          ...data,
          {
            instrument: INSTRUMENTS.READINGLOGGER,
            instrumentId: props.id,
            instrumentName: props.name || '',
            type: 'TextState',
            response: textState,
            correct: [],
            time: 0
          },
          {
            instrument: INSTRUMENTS.READINGLOGGER,
            instrumentId: props.id,
            instrumentName: props.name || '',
            type: 'ReadingLog',
            response: log.current,
            correct: [],
            time: 0
          },
          {
            instrument: INSTRUMENTS.READINGLOGGER,
            instrumentId: props.id,
            instrumentName: props.name || '',
            type: 'Comments',
            response: comments,
            correct: [],
            time: 0
          }
        ]
      })

      props.next()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finish])

  return (
    !props.show ? null :
      <>
        <Navbar
          bg="light"
          fixed="top"
          className="justify-content-center border-bottom py-3"
          onMouseDown={(e: React.MouseEvent) => e.preventDefault()}>
            <Container>
              <Col xs={9} className="d-flex justify-content-center">
                <ButtonGroup className="mx-2">
                  <IconButton
                    icon={ICONS.BOLD}
                    variant="outline-secondary"
                    onClick={() => toggleClass('bold')}
                    title="Negrito"
                  />
                  <IconButton
                    icon={ICONS.ITALIC}
                    variant="outline-secondary"
                    onClick={() => toggleClass('italic')}
                    title="Itálico"
                  />
                  <IconButton
                    icon={ICONS.UNDERLINE}
                    variant="outline-secondary"
                    onClick={() => toggleClass('underline')}
                    title="Sublinhado"
                  />
                  <IconButton
                    icon={ICONS.FONT}
                    variant="outline-secondary"
                    className="highlight"
                    onClick={() => toggleClass('highlight')}
                    title="Destaque"
                  />
                  <IconButton
                    icon={ICONS.ADD_COMMENT}
                    variant="outline-secondary"
                    onClick={handleCommentAdd}
                    title="Adicionar comentário"
                  />
                </ButtonGroup>
              </Col>
            </Container>
        </Navbar>
        <Container className="instrument-container position-relative my-5">
          {hidden && <Loading style={{ marginTop: '40vh' }} />}
          <Row style={{ visibility: hidden ? 'hidden' : 'visible' }}>
            <Col xs={9}>
              <div
                className="ReadingLogger position-relative"
                style={{ minHeight: `${pageHeight + (pageWidth * .1)}px` }}
                ref={container}
                onMouseMove={handleMouseMove}>
                {textState.map(page => (
                  <div
                    key={uuid()}
                    className="page my-5"
                    style={{
                      width: "100%",
                      height: `${pageHeight}px`,
                      padding: `${pageWidth * .1}px`,
                      fontSize: `${pageWidth * fontSize * .00168095238}px`
                    }}>
                    {page.map(paragraph => (
                      <div className="paragraph" key={uuid()}>
                        {paragraph.map((char: CharacterObject) => (
                          <span key={uuid()} className={char.classes?.join(' ')} data-i={char.i}>
                            {char.c}
                          </span>
                        ))}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </Col>
            <Col xs={3}>
              <div className="commentsContainer">
                {
                  comments?.map((c, i) => (
                    <Comment 
                      key={uuid()}
                      text={c.text} 
                      onSubmit={(t) => handleCommentSubmit(i, t)}
                      onDelete={() => handleCommentDelete(i)}
                      n={c.n} 
                      style={{top: c.position}} />
                  ))
                }
              </div>
            </Col>
          </Row>
          <Row style={{ visibility: hidden ? 'hidden' : 'visible' }}>
            <Col>
              <IconButton icon={ICONS.CONTINUE} type="submit" onClick={() => setFinish(true)}>Continuar</IconButton>
            </Col>
          </Row>
        </Container>
      </>
  )
}

export default ReadingLogger