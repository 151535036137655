import React, { 
    Dispatch, 
    SetStateAction, 
    useState, 
    useContext, 
    useEffect 
} from 'react'
import Container from 'react-bootstrap/Container'
import { v4 as uuid } from 'uuid'

import DataType from '../../../types/DataType'
import ConfigType from '../../../types/ConfigType'
import Question from '../Question'
import { FirebaseContext } from '../../Firebase'
import { Audio, Message } from '../../Layout'

import { TRIALS, INSTRUMENTS, FIELDS } from '../../../constants/instruments'

function SongSelector(props: {
    id: string,
    name: string,
    show: boolean,
    data: DataType[], 
    experimentId: string,
    language: 'en'|'br',
    addData: Dispatch<SetStateAction<DataType[]>>,
    next: (finish?: boolean) => void,
    hideButton?: boolean
}) {
    const [finish, setFinish] = useState(false)
    const [options, setOptions] = useState([] as any)
    const [config, setConfig] = useState([] as ConfigType[])
    const [keys, setKeys] = useState([] as string[])
    const [show, setShow] = useState([] as boolean[])
    const firebase = useContext(FirebaseContext)

    const handleNext = () => {
        if (!show[show.length - 1]) {
            let setTrue = false

            setShow(show.map((s: boolean) => {
                if (s) {
                    setTrue = true
                    return false
                }

                if (setTrue) {
                    setTrue = false
                    return true
                }

                return false
            }))
        } else {
            setFinish(true)
        }
    }

    const handleAddData = (newData: DataType) => {
        props.addData(data => {
            return [...data, newData]
        })
    }

    useEffect(() => {
        firebase?.experiment(props.experimentId).once(
            'value', snapshot => {
                setOptions(
                    snapshot.val().instruments.filter(
                        (i: any) => i.id === props.id)[0].options
                    )
            }
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let config: ConfigType[]

        if (options) {
            config = [
                {
                    type: TRIALS.QUESTION,
                    questions: [
                        {
                            prompt: (
                                <span>
                                    Coloque seus fones de ouvido.
                                    Ouça o trecho de cada música abaixo e selecione uma delas.
                                    A música que você escolher irá tocar durante a leitura do texto.
                                </span>
                            ), type: FIELDS.INSTRUCTIONS
                        },
                        ...options.map((o: {song: string, name: string}) => (
                            {
                                prompt: (
                                    <div className='d-flex align-items-center'>
                                        <strong className='mr-3'>{o.name}:</strong> 
                                        <Audio src={o.song} clip/>
                                    </div>
                                ), type: FIELDS.INSTRUCTIONS}
                        )),
                        {prompt: 'Selecione a música', type: FIELDS.TOGGLEBUTTONS, options: options.map(
                            (o: {song: string, name: string}) => ({label: o.name, value: o.song})
                        )},
                    ]
                }
            ]
        } else {
            config = [
                {
                    type: TRIALS.TEXT,
                    prompt: 'O seletor de músicas não foi configurado corretamente.'
                }
            ]
        }

        setConfig(config)
            setKeys(config.map(() => uuid()))
            setShow(config.map((c: ConfigType, i: number) => i === 0 ? true : false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options])

    useEffect(() => {
        if (props.show && finish) {
            props.addData(data => {
                const response = (
                    Object.values(data.filter((d: DataType) => d.instrumentId === props.id)[0].response) as {prompt: string, answer: string}[]
                ).filter((r ) => r.prompt === 'Selecione a música')[0].answer
                return [
                    ...data, {
                        instrument: INSTRUMENTS.SONGSELECTOR, 
                        instrumentId: props.id, 
                        instrumentName: props.name || '',
                        type: INSTRUMENTS.SONGSELECTOR, 
                        response, 
                        correct: [], 
                        time: 0
                    }
                ]
            })
            
            props.next()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finish])

    return (
        !props.show ? null :
        <Container className='instrument-container d-flex justify-content-center align-items-center position-relative'>
            {
                config.map((p: ConfigType, i: number) => {
                    switch (p.type) {
                        case TRIALS.QUESTION:
                            return (
                                <Question 
                                    key={keys[i]}
                                    show={show[i]}
                                    instrument={INSTRUMENTS.SONGSELECTOR} 
                                    instrumentId={props.id}
                                    instrumentName={props.name}
                                    addData={handleAddData}
                                    next={handleNext}
                                    questions={p.questions}
                                    language={props.language}/>
                            )
                        default:
                            return <Message key={keys[i]} warning='O seletor de músicas não foi configurado corretamente.'/>
                    }
                })
            }
        </Container>
    )
}

export default SongSelector