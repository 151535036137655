import React, { ReactNode, useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import { v4 as uuid } from 'uuid'

function Range(props: {
    label: ReactNode, 
    value: string, 
    onChange: (e: any) => void,
    options?: string[],
    controlId?: string
}) {
    const [untouched, setUntouched] = useState(true)
    const [range, setRange] = useState([0, 1, 2, 3, 4, 5, 6])

    useEffect(() => {
        if (props.options && props.options[2] && props.options[3]) {
            const newRange: number[] = []

            for (let i = parseInt(props.options[2]); i <= parseInt(props.options[3]); i++) {
                newRange.push(i)
            }

            setRange(newRange)
        }
    }, [props.options])

    return (
        <Form.Group controlId={props?.controlId} className='Range'>
            <Form.Label>{props.label}</Form.Label>
            
            <div className='d-flex justify-content-center align-items-end'>
                {props.options && props.options[0]}
                <div className='d-flex flex-column mx-3 flex-grow-1'>
                    <div className='d-flex justify-content-between labels'>
                        {range.map((n: number) => <span key={uuid()}>{n}</span>)}
                    </div>
                    <div style={{padding: '0 5px'}}>
                        <Form.Control 
                            type='range'
                            className={'Range ' + (untouched ? 'untouched' : '')}
                            value={props.value}
                            min={range[0]}
                            max={range[range.length - 1]}
                            onClick={(e: any) => {props.onChange(e)}}
                            onChange={props.onChange}
                            onFocus={() => setUntouched(false)} />
                    </div>
                </div>
                {props.options && props.options[1]}
            </div>
        </Form.Group>
    )
}

export default Range