import React, { useState, useEffect, useContext } from "react";
import { v4 as uuid } from 'uuid'

import Stimulus from "../../Stimulus";

import DataType from "../../../../types/DataType";
import { TimerContext } from '../../Timer'

import { TRIALS, INSTRUMENTS } from "../../../../constants/instruments";

import './index.css'

function SymmetryTest(props:{
  show: boolean,
  instrument: string,
  instrumentId: string,
  instrumentName: string,
  language: 'en'|'br',
  training?: boolean,
  filled?: number[],
  isSymmetric?: boolean,
  showCorrect?: boolean,
  getMean?: boolean,
  timeout?: number|'mean',
  data: DataType[],
  addData: (data: any) => void,
  next: (finish?: boolean, skip?: boolean) => void
}) {
  const [showButtons, setShowButtons] = useState(false);
  const time = new Date().getTime()
  const timer = useContext(TimerContext)

  const handleNext = (showPrompt: boolean, b: {stimulus?: string, response?: string}) => {
    if (showPrompt) {
      setShowButtons(true)
    } else {
      if (!props.training || props.getMean) {
        const interval = new Date().getTime() - time
        const previousTimeValues = props.data.filter(d => d.type === TRIALS.SYMMETRY).map(d => d.time)
  
        let data: DataType = {
            instrument: props.instrument, 
            instrumentId: props.instrumentId,
            instrumentName: props.instrumentName || '',
            type: TRIALS.SYMMETRY, 
            response: b, 
            correct: [], 
            time: interval,
            mean: props.getMean ? ([...previousTimeValues, interval].reduce((a, b) => a + b)) / (previousTimeValues.length + 1) : null
        }
  
        props.addData(data)
      }
      
      props.next()
    }
  }

  useEffect(() => {
    if (props.show && props.timeout) {
        let timeout: number
        
        if (props.timeout === 'mean') {
            const meanValues = props.data.filter(d => d.type === TRIALS.SYMMETRY && d.mean)
            timeout = meanValues[meanValues.length - 1].mean || 0
        } else {
            timeout = props.timeout
        }

        timer.setTimeout(timeout, () => { 
            handleNext(false, {stimulus: `Symmetric: ${props.isSymmetric?.toString()}`, response: 'FALSE'})
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show])

  return (
    !props.show ? null :
    showButtons ?
    <>
      <Stimulus 
        key={uuid()}
        show={props.show}
        instrument={INSTRUMENTS.SST}
        instrumentId={props.instrumentId}
        instrumentName={props.instrumentName}
        language={props.language}
        response={{
          type: 'button',
          buttons: [
              `${props.isSymmetric ? (props.language === 'en' ? 'YES' : 'SIM') : (props.language === 'en' ? 'NO' :'NÃO')}`, 
              `${!props.isSymmetric ? (props.language === 'en' ? 'YES' : 'SIM') : (props.language === 'en' ? 'NO' :'NÃO')}`],
          centralizeButtons: true,
          showCorrect: props.showCorrect,
          order: 'desc'
        }}
        data={props.data}
        addData={props.addData} 
        next={() => handleNext(false, {stimulus: `Symmetric: ${props.isSymmetric?.toString()}`, response: ''})}
        training={props.training}>

        {
          props.language === 'en' ?
          'The image is symmetrical.' :
          'A imagem é simétrica.'
        }    
      </Stimulus>
    </>
    :
    <>
      <div className='click-overlay' onClick={() => handleNext(true, {})} />

      <div className="SymmetryMatrix">
        {
          [1, 2, 3, 4, 5, 6, 7, 8].map(columnId => {
            return (
              <div key={`row${columnId}`} className="Row">
                {
                  [1, 2, 3, 4, 5, 6, 7, 8].map(rowId => (
                    <div 
                      key={`cell${rowId}${columnId}`} 
                      className={`Cell${props.filled?.includes((8 * (rowId - 1)) + columnId) ? ' Filled' : ''}`} />
                  ))
                }
              </div>
            )
          })
        }
      </div>
    </>
  )
}

export default SymmetryTest