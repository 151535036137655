import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import loading from './loading.svg'

function Loading(props: {small?: boolean, className?: string, style?: React.CSSProperties}) {
    return (
        props.small ?
        <img src={loading} alt='Loading...' width='50px'/> :
        <Container className={props.className} style={props.style}>
            <Row>
                <Col className='text-center'>
                    <img src={loading} alt='Loading...'/>
                </Col>
            </Row>
        </Container>
    )
}

export default Loading