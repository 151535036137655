const sentences_training = [
    'Mas, calma, convém conservar o champanhe na adega',
    'Ele só tem obrigação de dar aquelas aulas',
    'A trama está prevista para estrear em abril',
    'A loja impedia o escoamento natural das águas',
    'Foram as importações que derrubaram as duas áreas',
    'A posição oficial é de apoio ao bispo',
    'Sua ameaça pode não passar de um blefe',
    'Ele foi abordado pelos policiais dentro da boate',
    'Vários vivem uma sensação de alívio e calma'
]

export default sentences_training