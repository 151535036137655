import React, { useState, useEffect, useRef, ChangeEvent } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Form from 'react-bootstrap/Form'
import { v4 as uuid } from 'uuid'
import Plotly from 'plotly.js-cartesian-dist'
import createPlotlyComponent from 'react-plotly.js/factory'

import DataType from '../../../../types/DataType'
import CharacterObject from '../../../../types/CharacterObject'
import ReadingLogType, { CommentType } from '../../../../types/ReadingLogType'

import { IconButton, ICONS, Loading} from '../../../Layout'
import Comment from '../Comment'

import { INSTRUMENTS } from '../../../../constants/instruments'

import '../index.css'

type MouseMovementType = {
    x1: number
    x2: number
    y1: number
    y2: number
    interval: number
    elapsedTime: number
    viewPortWidth?: number
}

function ReadingLoggerOutput(props: {
    id?: string,
    show: boolean,
    data?: DataType[], 
    next?: (finish?: boolean) => void,
    hideButton?: boolean
}) {
    const [finish, setFinish] = useState(false)
    const [pageWidth, setPageWidth] = useState(0)
    const [pageHeight, setPageHeight] = useState(0)
    const [textState, setTextState] = useState<CharacterObject[][][]>()
    const [log, setLog] = useState<ReadingLogType[]>()
    const [mouseMovementOutput, setMouseMovementOutput] = useState<MouseMovementType[]>([])
    const [scrollMovementOutput, setScrollMovementOutput] = useState<ReadingLogType[]>([])
    const [focusEvents, setFocusEvents] = useState<ReadingLogType[]>([])
    const [comments, setComments] = useState<CommentType[]>([])
    const [showMouseMovement, setShowMouseMovement] = useState(true)
    const [showScrollMovement, setShowScrollMovement] = useState(true)
    const [showComments, setShowComments] = useState(true)
    const [expandPlot, setExpandPlot] = useState(false)
    const [expandedPlot, setExpandedPlot] = useState(null as React.ReactNode)
    const [customData, setCustomData] = useState<DataType[]>()
    const [loadData, setLoadData] = useState(false)
    const [loading, setLoading] = useState(false)
    const [innerWidth, setInnerWidth] = useState(window.innerWidth)
    const container = useRef(null as HTMLDivElement|null)
    const fontSize = 12

    const handleResize = () => {
        const current: any = container.current

        if (current) {
            setPageWidth(current.offsetWidth)
            setPageHeight(current.offsetWidth * Math.SQRT2)
            setInnerWidth(window.innerWidth)
        }
    }

    const handleLoadData = (e: ChangeEvent<HTMLInputElement>) => {
        const fileReader = new FileReader()

        if (e.target.files && e.target.files[0]) {
            fileReader.readAsText(e.target.files[0], 'UTF-8')

            setLoading(true)

            fileReader.onload = (e) => {
                const jsonData = JSON.parse(e?.target?.result as string)
                
                setCustomData(jsonData)
                setLoadData(false)
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show])

    useEffect(() => {
        if (props.data || customData) {
            let readingLoggerData: DataType[]

            if (props.data) {
                readingLoggerData = props.data.filter(v => v.instrument === INSTRUMENTS.READINGLOGGER)
            } else if (customData) {
                readingLoggerData = customData.filter(v => v.instrument === INSTRUMENTS.READINGLOGGER)
            } else {
                readingLoggerData = []
            }
            
            const textState = readingLoggerData.filter(d => d.type === 'TextState')[0]?.response
            const readingLog = readingLoggerData.filter(d => d.type === 'ReadingLog')[0]?.response
            const comments = readingLoggerData.filter(d => d.type === 'Comments')[0]?.response
            const mouseMovement = readingLog?.filter((l: ReadingLogType) => l.type === 'MouseMovement')
            const mouseOutput: MouseMovementType[] = []
            const scrollMovement = readingLog?.filter((l: ReadingLogType) => l.type === 'Scroll')
            const focus = readingLog?.filter((l: ReadingLogType) => l.type === 'FocusIn' || l.type === 'FocusOut')

            mouseMovement?.forEach((d: ReadingLogType, i: number, a: ReadingLogType[]) => {
                if (i > 0) {
                    mouseOutput.push({
                        x1: a[i - 1].coordX, 
                        y1: a[i - 1].coordY, 
                        x2: d.coordX, 
                        y2: d.coordY, 
                        viewPortWidth: d.viewPortWidth,
                        interval: d.interval, 
                        elapsedTime: d.elapsedTime})
                }
            })

            setTextState(textState)
            setLog(log)
            setMouseMovementOutput(mouseOutput)
            setScrollMovementOutput(scrollMovement)
            setComments(comments)
            setFocusEvents(focus)
        } else {
            setLoadData(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show, customData])

    useEffect(() => {
        if (finish) {
            if (props.next) {
                props.next()
            } else {
                throw new Error('"Next" prop is undefined');
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finish])

    // Metrics
    const bold = textState?.flat().flat().map(c => c?.classes?.includes('bold') ? 'b' : ' ').join('').match(/b+/g)?.length || 0
    const italic = textState?.flat().flat().map(c => c?.classes?.includes('italic') ? 'i' : ' ').join('').match(/i+/g)?.length || 0
    const underline = textState?.flat().flat().map(c => c?.classes?.includes('underline') ? 'u' : ' ').join('').match(/u+/g)?.length || 0
    const highlight = textState?.flat().flat().map(c => c?.classes?.includes('highlight') ? 'h' : ' ').join('').match(/h+/g)?.length || 0

    const Plot = createPlotlyComponent(Plotly)
    const shapes: Partial<any>[] = []

    focusEvents?.forEach((fe, i, a) => {
        if (fe.type === 'FocusOut') {
            shapes.push({
                type: 'rect',
                xref: 'x',
                yref: 'paper',
                x0: fe.elapsedTime,
                x1: a[i + 1].elapsedTime,
                y0: 0,
                y1: 1,
                fillcolor: '#FF000022',
                line: {
                    width: 0
                },
                layer: 'below'
            })
        }
    })

    const plot1 = (
        <Plot
            data={[
                {
                    x: scrollMovementOutput?.map(sm => new Date(sm.elapsedTime)),
                    y: scrollMovementOutput?.map(sm => sm.coordY),
                    type: 'scatter',
                    name: 'Posição do topo da janela de exibição',
                },
                {
                    x: mouseMovementOutput?.map(mm => new Date(mm.elapsedTime)),
                    y: mouseMovementOutput?.map(mm => mm.y2),
                    type: 'scatter',
                    name: 'Posição do cursor',
                    line: {
                        dash: 'dash'
                    },
                },
            ]}
            layout={{
                title: 'Eventos de rolagem',
                xaxis: {
                    title: 'Tempo',
                    type: 'date',
                    tickformat: '%M:%S.%L'
                },
                yaxis: {
                    title: 'Eixo vertical da página (pixels)',
                    autorange: 'reversed',
                    zeroline: false
                },
                legend: {
                    x: 1.1,
                    y: 1
                },
                shapes
            }}
            style={{width: '100%', height: '100%'}}/>
    )

    const ticks:{top: number, time: number}[] = []
    const ticksDistance = 100
    const viewPortHeigth = scrollMovementOutput?.map(sm => sm.viewPortHeigth)[0] || 0

    if (scrollMovementOutput) {
        for (let i = 0; i <= Math.ceil(
            (
                Math.max(
                    ...scrollMovementOutput.map(sm => sm.coordY)
                ) + viewPortHeigth
            )/ticksDistance
        ); i++) {
            const time = scrollMovementOutput.filter(
                sm => (i * ticksDistance) >= sm.coordY && (i * ticksDistance) <= (sm.coordY + viewPortHeigth)
            ).map(
                (l => l.interval)
            )

            ticks.push({
                top: i, 
                time: time.length > 0 ? time.reduce((a, b) => a + b) : 0
            })
        }
    }
    
    return (
        !props.show ? null :
        <>
            <svg 
                style={{
                    width: '99vw', 
                    height: '97%',
                    position: 'absolute', 
                    left: 0, 
                    top: 0,
                    zIndex: 1000,
                    pointerEvents: 'none'
                }}
            >
                {
                    showMouseMovement &&
                    mouseMovementOutput?.map(l => (
                        <React.Fragment key={uuid()}>
                            <line 
                                x1={l.x1 + (l.viewPortWidth ? (innerWidth - l.viewPortWidth) / 2 : 0)} 
                                y1={l.y1} 
                                x2={l.x2 + (l.viewPortWidth ? (innerWidth - l.viewPortWidth) / 2 : 0)} 
                                y2={l.y2} style={{stroke: '#F00', strokeWidth: 1}} />
                            {
                                l.interval > 100 &&
                                    <circle 
                                        cx={l.x2 + (l.viewPortWidth ? (innerWidth - l.viewPortWidth) / 2 : 0)} 
                                        cy={l.y2} r={l.interval/100} 
                                        fill='#F003' />
                            }
                        </React.Fragment>
                    ))  
                }
                {
                    showScrollMovement &&
                    ticks.map(t => (
                        <React.Fragment key={uuid()}>
                            <line  
                                x1={0} 
                                x2={'1%'} 
                                y1={t.top * ticksDistance} 
                                y2={t.top * ticksDistance} 
                                style={{stroke: '#fff', strokeWidth: 1}} />
                            <text 
                                x={'2%'} 
                                y={(t.top * ticksDistance) + 17}
                                style={{fill: '#0004', fontSize: '.8em'}}>
                                    {t.top * ticksDistance}px
                            </text>
                            <rect
                                x={0}
                                y={t.top * ticksDistance}
                                height={ticksDistance}
                                width={'1%'}
                                fill='#088'
                                opacity={t.time / Math.max(...ticks.map(ti => ti.time))} />
                            <text 
                                x={'2%'}
                                y={(t.top * ticksDistance) + (ticksDistance / 2) + 8}
                                style={{fill: '#0008', fontSize: '.8em'}}>
                                    {t.time}ms
                            </text>
                        </React.Fragment>
                    ))
                }
            </svg>
            <Navbar
                id="ReadingLoggerOutputNavbar"
                bg="light"
                variant="light"
                fixed="top"
                className="border-bottom py-3"
                onMouseDown={(e: React.MouseEvent) => e.preventDefault()}>
                <NavDropdown title="Métricas" id="metrics">
                    <NavDropdown.Header>Ênfases</NavDropdown.Header>
                    <NavDropdown.ItemText>Negrito: {bold}</NavDropdown.ItemText>
                    <NavDropdown.ItemText>Itálico: {italic}</NavDropdown.ItemText>
                    <NavDropdown.ItemText>Sublinhado: {underline}</NavDropdown.ItemText>
                    <NavDropdown.ItemText>Destaques: {highlight}</NavDropdown.ItemText>
                    <NavDropdown.ItemText>Total: {bold + italic + underline + highlight}</NavDropdown.ItemText>
                    <NavDropdown.Divider />
                    <NavDropdown.Header>Comentários</NavDropdown.Header>
                    <NavDropdown.ItemText>Quantidade: {comments?.length || 0}</NavDropdown.ItemText>
                    <NavDropdown.ItemText>Tokens: {comments?.map(c => c.text).join(' ').match(/\S+/g)?.length || 0}</NavDropdown.ItemText>
                    <NavDropdown.ItemText>Caracteres: {comments?.map(c => c.text).join('').length || 0}</NavDropdown.ItemText>
                </NavDropdown>
                <Nav.Link onClick={() => {setExpandedPlot(plot1); setExpandPlot(true)}}>Gráfico de rolagem</Nav.Link>
                <ButtonGroup>
                    <Button variant='outline-secondary' active={showMouseMovement} onClick={() => setShowMouseMovement(prev => !prev)}>
                        {showMouseMovement ? 'Ocultar' : 'Exibir'} movimento do mouse
                    </Button>
                    <Button variant='outline-secondary' active={showScrollMovement} onClick={() => setShowScrollMovement(prev => !prev)}>
                        {showScrollMovement ? 'Ocultar' : 'Exibir'} movimento de rolagem
                    </Button>
                    <Button variant='outline-secondary' active={showComments} onClick={() => setShowComments(prev => !prev)}>
                        {showComments ? 'Ocultar' : 'Exibir'} comentários
                    </Button>
                </ButtonGroup>
                {
                    !props.data &&
                    <Button className='ml-3' onClick={() => setLoadData(true)}>
                        Carregar dados
                    </Button>
                }
            </Navbar>
            <Container className='instrument-container position-relative my-5'>
                <Row>
                    <Col xs={9}>
                        <div
                            className='ReadingLogger position-relative'
                            style={{ minHeight: `${pageHeight + (pageWidth * .1)}px` }}
                            ref={container}>
                            {textState?.map(page => (
                                <div
                                key={uuid()}
                                className='page my-5'
                                style={{
                                    width: '100%',
                                    height: `${pageHeight}px`,
                                    padding: `${pageWidth * .1}px`,
                                    fontSize: `${pageWidth * fontSize * .00168095238}px`
                                }}>
                                    {page?.map(paragraph => (
                                    <div className='paragraph' key={uuid()}>
                                        {paragraph?.map((char: CharacterObject) => (
                                        <span key={uuid()} className={char.classes?.join(' ')} data-i={char.i}>
                                            {char.c}
                                        </span>
                                        ))}
                                    </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </Col>
                    <Col xs={3}>
                        <div className='commentsContainer'>
                        {
                            showComments && comments?.map((c, i) => (
                            <Comment 
                                key={uuid()}
                                text={c.text} 
                                n={c.n} 
                                style={{top: c.position}}
                                readOnly />
                            ))
                        }
                        </div>
                    </Col>
                </Row>
                {
                    !props.hideButton &&
                    <IconButton icon={ICONS.CONTINUE} type='submit' onClick={() => setFinish(true)}>Continuar</IconButton>
                }
                <Modal show={expandPlot} onHide={() => setExpandPlot(false)} id="Plot">
                    <Modal.Header closeButton>
                        <Modal.Title>Gráfico de rolagem</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {expandedPlot}
                    </Modal.Body>
                </Modal>
                <Modal show={loadData} onHide={() => setLoadData(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Carregar dados</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            loading
                            ? <Loading />
                            :
                            <Form>
                                <Form.Group controlId="formData" className="mb-3">
                                    <Form.Label>Escolher arquivo JSON com dados de ReadingLogger</Form.Label>
                                    <Form.Control type="file" size="lg" onChange={handleLoadData} />
                                </Form.Group>
                            </Form>
                        }
                    </Modal.Body>
                </Modal>
            </Container>
        </>
        )
}

export default ReadingLoggerOutput