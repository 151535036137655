import React, { useEffect, useContext } from 'react'
import { FaPlus } from 'react-icons/fa'

import DataType from '../../../types/DataType'
import ResponseType from '../../../types/ResponseType'
import { TimerContext } from '../Timer'

import { TRIALS } from '../../../constants/instruments'

function Blank(props:{
    show: boolean,
    instrument: string,
    instrumentId: string, 
    instrumentName: string,
    response?: ResponseType
    addData: (data: any) => void,
    next: () => void,
    timeout?: number|'mean'|'never',
    fixationPoint?: boolean
}) {
    const time = new Date().getTime()
    const timer = useContext(TimerContext)

    const finish = () => {
        const data: DataType = {
            instrument: props.instrument, 
            instrumentId: props.instrumentId,
            instrumentName: props.instrumentName || '',
            type: TRIALS.BLANK, 
            response: '', 
            correct: [], 
            time: new Date().getTime() - time
        }

            props.addData(data)
            props.next()
    }

    const handleKeyup = (e: KeyboardEvent) => {
        if (props.show && (props.response?.keys === undefined || props.response?.keys?.includes(e.key))) {
            finish()
        }
    }

    useEffect(() => {
        props.show && 
        props.timeout !== 'never' &&
        timer.setTimeout((props.timeout === 'mean' ? 500 : props.timeout) || 500, finish)

        props.show && props.response?.type === 'key' &&
        document.addEventListener('keyup', handleKeyup)

        return () => {document.removeEventListener('keyup', handleKeyup)}
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props.show])

    return (
        !props.show ? null : 
        <div>{props.fixationPoint && <FaPlus/>}</div>
    )
}

export default Blank