import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import { Button, ToastHeader } from 'react-bootstrap'
import Toast from 'react-bootstrap/Toast'
import { IconButton, ICONS } from '../../../Layout'

import './index.css'

function Comment(props: {
  text: string, 
  onSubmit?: (text: string) => void,
  onDelete?: () => void,
  n?: number, 
  style?: CSSProperties,
  readOnly?: boolean,
}) {
  const [text, setText] = useState(props.text)
  const [editing, setEditing] = useState<boolean>(text === '')
  const textarea = useRef<HTMLTextAreaElement>(null)

  const handleChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setText(e.currentTarget.value)
  }

  const handleSubmit = () => {
    if (text === '' && props.onDelete) {
      props.onDelete()
    } else {
      setEditing(false)

      if (props.onSubmit) {
        props.onSubmit(text)
      }
    }
  }

  useEffect(() => {
    if (editing) {
      textarea.current?.focus()
    }
  }, [editing])

  return (
    <Toast className='Comment' style={props.style}>
      <ToastHeader closeButton={false} className='commentHeader'>
        Comentário {props.n}
        <IconButton 
          icon={ICONS.DELETE} 
          size='sm' 
          variant='outline-secondary'
          onClick={props.readOnly ? undefined : props.onDelete} />
      </ToastHeader>
      <Toast.Body className='commentBody'>
        <textarea 
          value={text} 
          onChange={props.readOnly ? undefined : handleChange}
          onClick={props.readOnly ? undefined : () => setEditing(true)}
          onBlur={props.readOnly ? undefined : handleSubmit}
          readOnly={props.readOnly || !editing}
          ref={textarea} />
        {!props.readOnly && editing && <Button className='mt-2' onClick={handleSubmit}>Salvar</Button>}
      </Toast.Body>
    </Toast>
  )
}

export default Comment