import React, { useContext } from 'react'
import Button from 'react-bootstrap/Button'
import { useHistory } from 'react-router-dom'

import { FirebaseContext } from '../Firebase'

import * as ROUTES from '../../constants/routes' 

function SignOut() {
    const firebase = useContext(FirebaseContext)
    const history = useHistory()

    const handleClick = () => {
        firebase?.doSignOut()
            .then(() => {
                history.push(ROUTES.LANDING)
            })
    } 

    return (
        <Button
            variant='primary'
            type='button'
            onClick={handleClick}>
            
            Sair
        </Button>
    )
}

export default SignOut