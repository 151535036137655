import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'

import { FirebaseContext } from '../../Firebase'
import { Loading, IconButton, ICONS } from '../../Layout'

import * as ROUTES from '../../../constants/routes'

function UserList() {
    const [loading, setLoading] = useState(false)
    const [users, setUsers] = useState([] as any[])
    const firebase = useContext(FirebaseContext)

    useEffect(() => {
        setLoading(true)

        firebase?.users().on('value', (snapshot) => {
            const usersObject = snapshot.val()
            const userList = Object.keys(usersObject).map(key => ({
                ...usersObject[key], uid: key
            }))

            setUsers(userList)
            setLoading(false)
        })

        return () => firebase?.users().off()
    }, [firebase])

    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <h2>Usuários</h2>
                    </Col>
                </Row>
            </Container>
            {
                loading 
                ? <Loading/>
                :
                <Container>
                    <Row>
                        <Col>
                            <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>Email</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.map(u => (
                                            <tr key={u.uid}>
                                                <td>
                                                    {u.displayName}
                                                </td>
                                                <td>{u.email}</td>
                                                <td className='text-center'>
                                                    <Link to={{
                                                        pathname: `${ROUTES.ADMIN_USERS}/${u.uid}`,
                                                        state: u
                                                        }}>
                                                        <IconButton icon={ICONS.USER_EDIT} size='sm'/>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                        </Col>
                    </Row>
                </Container>
            }
        </>
    )
}

export default UserList