import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'react-router-dom'

import SignInForm from './SignInForm'

import * as ROUTES from '../../constants/routes'

function SignIn() {
    return (
        <Container className='my-5'>
            <Row>
                <Col>
                    <SignInForm/>
                </Col>
                <Col className='bg-light border p-4'>
                    <h2 className='mb-3'>Boas vindas!</h2>
                    <p>
                        Faça login para ter acesso à Página do(a) 
                        Pesquisador(a), onde você poderá configurar seu
                        experimento.
                    </p>
                    <p className='text-justify'>
                        Para fazer login no Lapsi, basta entrar com 
                        email e senha informados na criação da sua conta. Caso
                        ainda não tenha criado uma conta, você pode clicar
                        no botão 'Entrar com o Google' para acessar com sua
                        conta Google (por exemplo, Gmail) ou 
                        <Link to={ROUTES.SIGN_UP}> clicar aqui para criar uma nova conta</Link>. 
                    </p>
                </Col>
            </Row>
        </Container>
    )
}

export default SignIn