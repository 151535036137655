import React, { useState } from 'react'

import Loading from '../Loading'

function Audio(props: {src: string, timeLimit?: number, clip?: boolean}) {
    const [loading, setLoading] = useState(true)

    const handleCanPlayThrough = () => {
        setLoading(false)
    }

    return (
        <>
            {
                loading &&
                <>
                    <Loading small/> Carregando...
                </>
            }
            <audio
                className={loading ? 'd-none' : undefined} 
                controls
                onTimeUpdate={(e) => {
                    if (e.currentTarget.currentTime > (props.timeLimit ? props.timeLimit : e.currentTarget.duration)) {
                        e.currentTarget.pause()
                        e.currentTarget.currentTime = 0
                    }
                }}
                onCanPlayThrough={handleCanPlayThrough}>
                    {
                        props.clip && 
                        props.src.endsWith('.ogg') ? 
                            <source src={props.src.replace('.ogg', '_clip.ogg')}/> :
                            <source src={props.src.replace('.mp3', '_clip.mp3')}/>
                    }
                    <source src={props.src}/>
                    Navegador incompatível
            </audio>
        </>
        
    )
}

export default Audio