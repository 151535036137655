import React from 'react'
import Button from 'react-bootstrap/Button'
import { IconType } from 'react-icons'
import { v4 as uuid } from 'uuid'
import { 
    FaGoogle, 
    FaReact, 
    FaPlus, 
    FaUserEdit,
    FaSignInAlt,
    FaUserPlus,
    FaSave,
    FaShoppingBag,
    FaTrash,
    FaPlay,
    FaEdit,
    FaDownload,
    FaArrowLeft,
    FaArrowRight,
    FaCheck,
    FaArrowUp,
    FaArrowDown,
    FaMinus,
    FaParagraph,
    FaAnchor,
    FaHeading,
    FaSearchPlus,
    FaSearchMinus,
    FaAlignJustify,
    FaAlignCenter,
    FaBold,
    FaItalic,
    FaUnderline,
    FaFont,
    FaAlignLeft,
    FaAlignRight,
    FaUndo,
    FaRedo,
    FaCommentMedical,
    FaStepForward,
    FaCopy
} from 'react-icons/fa'

import './index.css'

function IconButton(props:{
    icon?: IconType|IconType[], 
    variant?: string, 
    type?: string,
    size?: 'sm'|'lg'|undefined,
    active?: boolean,
    disabled?: boolean,
    href?: string, 
    download?: string,
    onClick?:() => void,
    onFocus?: (e: React.FocusEvent) => void,
    onMouseDown?: (e: React.MouseEvent) => void,
    className?: string, 
    title?: string,
    children?: React.ReactNode,
    style?: React.CSSProperties
}) {

    const className = props.children ? 'with-text' : ''

    return (
        <Button 
            variant={props.variant || 'primary'} 
            type={props.type || 'button'}
            size={props.size || undefined}
            className={`IconButton${props.className ? ` ${props.className}` : ''}`}
            title={props.title}
            active={props.active}
            disabled={props.disabled} 
            onClick={props.onClick}
            onFocus={props.onFocus}
            onMouseDown={props.onMouseDown}
            style={props.style}>

            {!props.icon && <FaReact  className={className}/>}
            {
                Array.isArray(props.icon) ?
                props.icon.map(icon => (
                    Object.values(ICONS).map(i => icon === i && React.createElement(i, {key: uuid(), className: className}))
                )) :
                Object.values(ICONS).map(i => props.icon === i && React.createElement(i, {key: uuid(), className: className}))
            }
            
            {
                !props.href
                ? props.children
                : <a href={props.href} download={props.download} target='_blank' rel='noopener noreferrer'>{props.children}</a>
            }
        </Button>
    )
}

export default IconButton
export const ICONS  = {
    REACT: FaReact,
    GOOGLE: FaGoogle,
    PLUS: FaPlus,
    USER_EDIT: FaUserEdit,
    SIGN_IN: FaSignInAlt,
    NEW_USER: FaUserPlus,
    SAVE: FaSave,
    BAG: FaShoppingBag,
    DELETE: FaTrash,
    START: FaPlay,
    CONTINUE: FaArrowRight,
    EDIT: FaEdit,
    DOWNLOAD: FaDownload,
    BACK: FaArrowLeft,
    OK: FaCheck,
    UP: FaArrowUp,
    DOWN: FaArrowDown,
    MINUS: FaMinus,
    BOLD: FaBold,
    ITALIC: FaItalic,
    UNDERLINE: FaUnderline,
    ALIGN_JUSTIFY: FaAlignJustify,
    ALIGN_LEFT: FaAlignLeft,
    ALIGN_CENTER: FaAlignCenter,
    ALIGN_RIGHT: FaAlignRight,
    FONT: FaFont,
    PARAGRAPH: FaParagraph,
    HEADING: FaHeading,
    ANCHOR: FaAnchor,
    UNDO: FaUndo,
    REDO: FaRedo,
    ZOOM_PLUS: FaSearchPlus,
    ZOOM_MINUS: FaSearchMinus,
    ADD_COMMENT: FaCommentMedical,
    SKIP: FaStepForward,
    COPY: FaCopy
}