import React, { ReactNode, useEffect, useState } from 'react'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import { v4 as uuid } from 'uuid'

function ToggleButtons(props: {
    label: ReactNode,
    value: string,
    i: number,
    handleChange: (i: number, option: string) => void
    options?: {label: string, value: string}[],
}) {
    const id = uuid()
    const [value, setValue] = useState('')

    useEffect(() => {
        props.handleChange(props.i, value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    return (
        <>
            <div>
                <label className='form-label' htmlFor={id}>{props.label}</label>
            </div>
            <ButtonGroup toggle key={id}>
                {
                    props.options?.map((o: {label: string, value: string}, index: number) => (
                        <ToggleButton
                            key={uuid()}
                            id={`${id}-${index}`} 
                            name={id}
                            type='radio'
                            variant='outline-primary'
                            value={props.value}
                            onChange={() => setValue(o.value)}
                            checked={o.value === value}>
                                {o.label}
                            </ToggleButton>
                    ))
                }
            </ButtonGroup>
        </>
    )
}

export default ToggleButtons