import React, { ReactElement, ReactNode, useState, useContext } from 'react'
import Form from 'react-bootstrap/Form'
import FormGroup from 'react-bootstrap/FormGroup'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import { v4 as uuid } from 'uuid'

import DataType from '../../../types/DataType'
import { IconButton, ICONS, Range, ToggleButtons, MultipleChoice } from '../../Layout'
import { TimerComponent, TimerContext } from '../Timer'

import { TRIALS, FIELDS } from '../../../constants/instruments'

import './index.css'
import { useEffect } from 'react'
import { InputGroup } from 'react-bootstrap'

function Question(props: {
    show: boolean,
    instrument: string,
    instrumentId: string,
    instrumentName: string,
    questions: {prompt: React.ReactNode, type: string, options?: {label: string, value: string}[]}[]|undefined,
    correctResponses?: string[], 
    title?: React.ReactNode,
    timeout?: number|'mean',
    training?: boolean,
    addData: (data: any) => void,
    next: () => void,
    hideButton?: boolean,
    layout?: {direction: 'column'|'row'},
    language: 'br'|'en',
    nextLinked?: boolean
}) {
    const [responses, setResponses] = useState([])
    const time = new Date().getTime()
    const timer = useContext(TimerContext)
    
    const handleSubmit = (e?: React.FormEvent) => {
        e?.preventDefault()

        if (!props.training) {
            let data: DataType = {
                instrument: props.instrument, 
                instrumentId: props.instrumentId, 
                instrumentName: props.instrumentName || '',
                type: TRIALS.QUESTION, 
                response: {}, 
                correct: [], 
                time: 0
            }

            props.questions?.forEach((q, i) => {
                const getNodeText = (node: ReactElement): string|ReactNode => {
                    if (['string', 'number'].includes(typeof node)) return node
                    if (node instanceof Array) return node.map(getNodeText).join('')
                    if (typeof node === 'object' && node) return getNodeText(node?.props.children)
                }

                let prompt
                
                if (typeof q.prompt === 'string') {
                    prompt = q.prompt
                } else {
                    prompt = getNodeText(q.prompt as ReactElement)
                }

                data.response = {...data.response, [i]: {prompt, answer: responses[i] || ''}}
                
                if (props.correctResponses) {
                    data.correct = [...data.correct, (responses[i] as string || '').toLowerCase() === props.correctResponses[i].toLowerCase()]
                }
            })

            data.time = new Date().getTime() - time
            props.addData(data)
        }

        props.next()
        if (props.timeout) {
            timer.clearTimeout()
        }
    }

    const handleChange = (e: any, i: number) => {
        const newResponses: any = responses
        
        newResponses[i] = e.currentTarget.value || (e.currentTarget.checked ? 'Verdadeiro' : 'Falso')
        
        setResponses(newResponses)
    }

    const handleValueChange = (values:NumberFormatValues, i:number) => {
        const {formattedValue} = values;
        const newResponses: any = responses
        
        newResponses[i] = formattedValue
        
        setResponses(newResponses)
    }

    const handleRadioChange = (i: number, value: string) => {
        const newResponses: any = responses

        newResponses[i] = value
        
        setResponses(newResponses)
    }

    useEffect(() => {
        if (props.show && props.timeout) {
            timer.setTimeout((props.timeout === 'mean' ? 10000 : props.timeout), () => {
                handleSubmit()
            })
        }

        return () => {
            if (props.nextLinked) {
                handleSubmit()
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show])

    return (
        !props.show ? null : 
        <div className={props.layout?.direction === 'row' ? 'py-5' : 'w-768px py-5'}>
            <h2 className='mb-3'>{props.title}</h2>
            <Form onSubmit={handleSubmit}>
                <div className={props.layout?.direction === 'row' ? 'layout-row' : undefined}>
                    {
                        props.questions?.map((q, i) => {
                            let element: ReactNode
                            let id: string

                            switch (q.type) {
                                case FIELDS.INSTRUCTIONS:
                                    element = (
                                        <Form.Text><h5>{q.prompt}</h5></Form.Text>
                                    )
                                    break
                                case FIELDS.INPUT:
                                    element = (
                                        <>
                                            <Form.Label>{q.prompt}</Form.Label>
                                            <Form.Control 
                                                type='text'
                                                autoComplete='false'
                                                value={responses[i]}
                                                onChange={e => {handleChange(e, i)}}/>
                                        </>
                                    )
                                    break
                                case FIELDS.NUMBER:
                                    element = (
                                        <>
                                            <Form.Label>{q.prompt}</Form.Label>
                                            <Form.Control 
                                                type='number'
                                                autoComplete='false'
                                                value={responses[i]}
                                                onChange={e => {handleChange(e, i)}}/>
                                        </>
                                    )
                                    break
                                case FIELDS.EMAIL:
                                    element = (
                                        <>
                                            <Form.Label>{q.prompt}</Form.Label>
                                            <Form.Control 
                                                type='email'
                                                autoComplete='false'
                                                value={responses[i]}
                                                onChange={e => {handleChange(e, i)}}/>
                                        </>
                                    )
                                    break
                                case FIELDS.TEXTAREA:
                                    element = (
                                        <>
                                            <Form.Label>{q.prompt}</Form.Label>
                                            <Form.Control 
                                                type='text'
                                                as='textarea'
                                                autoComplete='false'
                                                value={responses[i]}
                                                onChange={e => {handleChange(e, i)}}
                                                rows={5}/>
                                        </>
                                    )
                                    break
                                case FIELDS.CHECK:
                                    element = (
                                        <Form.Check 
                                            label={q.prompt} 
                                            value={responses[i]}
                                            onChange={(e:any) => {handleChange(e, i)}}/>
                                    )
                                    break
                                case FIELDS.CPF:
                                    id = uuid()
                                    element = (
                                        <>
                                            <label className='form-label' htmlFor={id}>{q.prompt}</label>
                                            <NumberFormat 
                                                format='###.###.###-##'
                                                id={id} 
                                                className='form-control' 
                                                autoComplete='false'
                                                value={responses[i]}
                                                onValueChange={values => {handleValueChange(values, i)}} />
                                        </>
                                    )
                                    break
                                case FIELDS.MOBILE:
                                    id = uuid()
                                    element = (
                                        <>
                                            <label className='form-label' htmlFor={id}>{q.prompt}</label>
                                            <NumberFormat 
                                                format='(##) #####-####'
                                                id={id} 
                                                className='form-control' 
                                                autoComplete='false'
                                                value={responses[i]}
                                                onChange={(e:any) => {handleChange(e, i)}} />
                                        </>
                                    )
                                    break
                                case FIELDS.DATE:
                                    id = uuid()
                                    element = (
                                        <>
                                            <label className='form-label' htmlFor={id}>{q.prompt}</label>
                                            <NumberFormat 
                                                format='##/##/####'
                                                id={id} 
                                                className='form-control' 
                                                autoComplete='false'
                                                value={responses[i]}
                                                onChange={(e:any) => {handleChange(e, i)}} />
                                        </>
                                    )
                                    break
                                case FIELDS.RADIO:
                                    id = uuid()
                                    element = (
                                        <>
                                            <label className='form-label' htmlFor={id}>{q.prompt}</label>
                                            <div key={id} className='text-left'>
                                                {
                                                    q.options?.map((o: {label: string, value: string}, index: number) => {
                                                        return (
                                                            <Form.Check 
                                                                key={uuid()}
                                                                id={`${id}-${index}`} 
                                                                name={id}
                                                                label={o.label}
                                                                type='radio'
                                                                onChange={(e: any) => handleRadioChange(i, o.value)} />
                                                        )
                                                    })
                                                }
                                            </div>
                                        </>
                                    )
                                    break
                                case FIELDS.TOGGLEBUTTONS:
                                    id = uuid()
                                    element = (
                                        <ToggleButtons
                                            label={q.prompt}
                                            options={q.options}
                                            i={i}
                                            value={responses[i]}
                                            handleChange={handleRadioChange}/>
                                    )
                                    break
                                case FIELDS.MULTIPLE:
                                    id = uuid()
                                    element = (
                                        <MultipleChoice
                                            id={id}
                                            prompt={q.prompt}
                                            options={q.options}
                                            questionIndex={i}
                                            responses={responses}
                                            setResponses={setResponses}
                                            language={props.language}/>
                                    )
                                    break
                                case FIELDS.RANGE:
                                    id = uuid()
                                    element = (
                                        <Range 
                                            controlId={uuid()}
                                            label={q.prompt}
                                            options={q.options?.map((o: {label: string, value: string}) => o.label)}
                                            value={responses[i]}
                                            onChange={
                                                (e: any) => handleChange(e, i)
                                            }/>
                                    )
                                    break
                                case FIELDS.SELECT:
                                    id = uuid()
                                    element = (
                                        <FormGroup controlId={id}>
                                            <Form.Label>{q.prompt}</Form.Label>
                                            <Form.Control 
                                                as='select'
                                                onChange={(e: any) => handleChange(e, i)}>
                                                
                                                <option></option>

                                                {
                                                    q.options?.map((o: {label: string, value: string}, index: number) => (
                                                        <option key={uuid()} value={o.value}>{o.label}</option>
                                                    ))
                                                }
                                            </Form.Control>
                                        </FormGroup>
                                    )
                                    break
                                case FIELDS.FILLIN:
                                    id = uuid()
                                    element = (
                                        <FormGroup controlId={id} className='FillIn'>
                                            <Form.Label>{q.prompt}</Form.Label>
                                            <InputGroup>
                                                {
                                                    q.options?.map((o: {label: string}, index: number) => (
                                                        index === 0 ?
                                                        <React.Fragment key={uuid()}>
                                                            <Form.Text>{o.label}</Form.Text>
                                                            <Form.Control 
                                                                type='text'
                                                                size='sm'
                                                                className='mx-2 blank'
                                                                autoComplete='false'
                                                                value={responses[i]}
                                                                onChange={e => {handleChange(e, i)}}/>
                                                        </React.Fragment>
                                                        :
                                                        <Form.Text key={uuid()}>{o.label}</Form.Text>
                                                    ))
                                                }
                                            </InputGroup>
                                            <Form.Text></Form.Text>
                                        </FormGroup>
                                    )
                                    break
                                default:
                                    element = (
                                        <>
                                            <Form.Label>{q.prompt}</Form.Label>
                                            <Form.Control 
                                                type='text'
                                                autoComplete='false'
                                                value={responses[i]}
                                                onChange={e => {handleChange(e, i)}} />
                                        </>
                                    )
                            }

                            return (
                                <Form.Group key={uuid()} controlId={uuid()} className='my-4 mx-1'>
                                    { element }
                                </Form.Group>    
                            )
                        })
                    }
                </div>
                {
                    !props.hideButton &&
                    <IconButton icon={ICONS.CONTINUE} type='submit'>
                        {
                            props.language === 'en' ?
                            'Continue' :
                            'Continuar'
                        }
                    </IconButton>
                }
            </Form>
            {
                props.timeout
                && <TimerComponent duration={props.timeout === 'mean' ? 10000 : props.timeout}/>
            }
        </div>
    )
}

export default Question