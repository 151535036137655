/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import shuffle from 'shuffle-array'

import ConfigType from '../../../types/ConfigType'

import { TRIALS } from '../../../constants/instruments'

import example1 from './bmp/example_symm1.bmp'
import example2 from './bmp/example_symm2.bmp'
import example3 from './bmp/example_symm3.bmp'
import example4 from './bmp/example_symm4.bmp'


const config: ConfigType[] = []
const matrices = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(n => [n])
const symmetry_practice = [
    // 1
    {
        cells: [4, 5, 12, 13, 19, 20, 21, 22, 26, 27, 28, 29, 30, 31, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 46, 47, 51, 52, 53, 54, 60, 61], 
        symmetric: true
    },
    // 2
    {
        cells: [1, 2, 7, 8, 9, 10, 11, 14, 15, 16, 17, 18, 20, 21, 23, 24, 25, 27, 28, 29, 30, 32, 33, 36, 37, 40, 41, 43, 44, 45, 46, 48, 49, 50, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64], 
        symmetric: true
    },
    // 3
    {
        cells: [1, 2, 3, 4, 5, 6, 7, 8, 11, 12, 13, 14, 20, 21, 27, 28, 29, 30, 35, 38, 43, 46, 51, 52, 53, 54, 58, 60, 61, 63], 
        symmetric: true
    },
    // 4
    {
        cells: [1, 2, 7, 8, 9, 10, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 27, 28, 29, 30, 35, 36, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 55, 56, 57, 58, 63, 64], 
        symmetric: true
    },
    // 5
    {
        cells: [1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24, 28, 29, 36, 37, 41, 42, 43, 44, 45, 46, 47, 48, 50, 51, 52, 53, 54, 55, 57, 58, 59, 60, 61, 62, 63, 64], 
        symmetric: true
    },
    // 6
    {
        cells: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 15, 16, 18, 23, 25, 26, 28, 29, 31, 32, 33, 34, 36, 37, 39, 40, 42, 47, 49, 50, 52, 53, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64], 
        symmetric: true
    },
    // 7
    {
        cells: [1, 2, 7, 8, 10, 11, 14, 15, 19, 20, 21, 22, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 43, 44, 45, 46, 50, 51, 54, 55, 57, 58, 63, 64], 
        symmetric: true
    },
    // 8
    {
        cells: [1, 2, 3, 4, 5, 7, 8, 11, 14, 15, 17, 18, 23, 26, 27, 28, 29, 30, 31, 32, 34, 35, 36, 37, 38, 39, 40, 41, 42, 47, 51, 54, 55, 57, 58, 59, 60, 61, 63, 64], 
        symmetric: false
    },
    // 9
    {
        cells: [8, 9, 10, 15, 16, 17, 18, 19, 22, 23, 24, 25, 26, 27, 28, 29, 30, 32, 33, 40, 41, 43, 44, 45, 46, 47, 48, 49, 50, 51, 54, 55, 56, 57, 58, 63, 64], 
        symmetric: false
    },
    // 10
    {
        cells: [3, 6, 9, 10, 11, 12, 13, 14, 15, 16, 17, 19, 21, 22, 24, 25, 27, 28, 30, 32, 33, 35, 36, 37, 38, 40, 41, 43, 44, 45, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 59, 62], 
        symmetric: false
    },
    // 11
    {
        cells: [1, 3, 4, 5, 6, 8, 10, 12, 13, 15, 17, 20, 21, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 45, 48, 50, 53, 55, 57, 59, 60, 61, 62, 64], 
        symmetric: false
    },
    // 12
    {
        cells: [1, 2, 3, 6, 7, 8, 9, 11, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 27, 30, 31, 33, 35, 38, 39, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 54, 55, 57, 58, 59, 62, 63, 64], 
        symmetric: false
    },
    // 13
    {
        cells: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 15, 16, 17, 18, 20, 23, 24, 25, 26, 29, 31, 32, 33, 34, 36, 39, 40, 42, 45, 47, 49, 50, 52, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64], 
        symmetric: false
    },
    // 14
    {
        cells: [1, 2, 3, 4, 5, 6, 7, 8, 10, 14, 15, 17, 18, 19, 22, 23, 24, 25, 26, 27, 30, 31, 32, 33, 34, 35, 38, 39, 40, 41, 42, 43, 46, 47, 48, 50, 54, 55, 57, 58, 59, 60, 61, 62, 63, 64], 
        symmetric: false
    },
    // 15
    {
        cells: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 23, 24, 26, 27, 28, 29, 30, 31, 34, 35, 36, 37, 38, 39, 41, 42, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 61, 62, 63, 64], 
        symmetric: false
    }
]
const symmetry_matrices = [
    //1
    {
        cells: [
            1, 2, 3, 4, 5, 6, 7, 8, 
            10, 15, 
            17, 18, 20, 21, 23, 24, 
            25, 27, 28, 29, 30, 32,
            33, 35, 36, 37, 38, 40,
            41, 42, 44, 45, 47, 48,
            50, 55,
            57, 58, 59, 60, 61, 62, 63, 64
        ],
        symmetric: true
    },
    //2
    {
        cells: [
            1, 2, 4, 5, 7, 8,
            11, 14,
            18, 23,
            26, 27, 28, 29, 30, 31,
            34, 36, 37, 39,
            41, 42, 47, 48,
            49, 50, 51, 54, 55, 56,
            60, 61
        ],
        symmetric: true
    },
    //3
    {
        cells: [
            1, 2, 3, 4, 5, 6, 7, 8,
            9, 10, 12, 13, 15, 16,
            18, 19, 22, 23,
            25, 26, 28, 29, 31, 32,
            33, 34, 36, 37, 39, 40,
            42, 43, 46, 47,
            49, 50, 52, 53, 55, 56,
            57, 58, 59, 60, 61, 62, 63, 64
        ],
        symmetric: true
    },
    //4
    {
        cells: [
            1, 2, 7, 8,
            10, 15,
            17, 18, 23, 24,
            25, 27, 28, 29, 30, 32,
            33, 40,
            41, 43, 44, 45, 46, 48,
            49, 50, 55, 56,
            58, 59, 60, 61, 62, 63
        ],
        symmetric: true
    },
    //5
    {
        cells: [
            3, 6,
            11, 14,
            19, 22,
            25, 27, 30, 32,
            33, 35, 36, 37, 38, 40,
            41, 43, 44, 45, 46, 48,
            49, 50, 51, 52, 53, 54, 55, 56,
            59, 62
        ],
        symmetric: true
    },
    //6
    {
        cells: [
            1, 8,
            9, 10, 15, 16,
            17, 18, 19, 20, 21, 22, 23, 24,
            25, 27, 30, 32,
            33, 35, 38, 40,
            41, 43, 46, 48,
            49, 50, 51, 52, 53, 54, 55, 56,
            57, 64
        ],
        symmetric: true
    },
    //7
    {
        cells: [
            1, 8,
            9, 10, 12, 13, 15, 16,
            19, 20, 21, 22,
            28, 29,
            33, 34, 35, 36, 37, 38, 39, 40,
            43, 46,
            49, 50, 55, 56,
            57, 58, 63, 64
        ],
        symmetric: true
    },
    //8
    {
        cells: [
            1, 2, 3, 4, 5, 6, 7, 8,
            9, 16,
            17, 20, 21, 24,
            25, 26, 27, 28, 29, 30, 31, 32,
            33, 36, 37, 40,
            41, 48,
            49, 56,
            57, 58, 59, 60, 61, 62, 63, 64
        ],
        symmetric: true
    },
    //9
    {
        cells: [
            2, 3, 4, 5, 6, 7,
            10, 11, 12, 13, 14, 15,
            17, 18, 19, 22, 23, 24,
            25, 26, 31, 32,
            33, 34, 39, 40,
            41, 42, 43, 46, 47, 48,
            50, 51, 52, 53, 54, 55,
            58, 59, 60, 61, 62, 63
        ],
        symmetric: true
    },
    //10
    {
        cells: [
            1, 2, 3, 4, 5, 6, 7, 8,
            9, 10, 11, 12, 13, 14, 15, 16,
            17, 18, 19, 22, 23, 24,
            26, 27, 30, 31,
            34, 35, 36, 37, 38, 39,
            42, 43, 44, 45, 46, 47,
            51, 52, 53, 54,
            59, 60, 61, 62
        ],
        symmetric: true
    },
    //11
    {
        cells: [
            1, 8,
            9, 10, 15, 16,
            17, 18, 19, 22, 23, 24,
            25, 26, 27, 28, 29, 30, 31, 32,
            33, 35, 36, 37, 38, 40,
            41, 42, 43, 44, 45, 46, 47, 48,
            49, 50, 51, 54, 55, 56,
            57, 58, 63, 64
        ],
        symmetric: true
    },
    //12
    {
        cells: [
            1, 2, 7, 8,
            10, 11, 14, 15,
            17, 18, 23, 24,
            26, 27, 28, 29, 30, 31,
            34, 35, 36, 37, 38, 39,
            41, 42, 47, 48,
            50, 51, 54, 55,
            57, 58, 63, 64
        ],
        symmetric: true
    },
    //13
    {
        cells: [
            4, 5,
            9, 10, 11, 12, 13, 14, 15, 16,
            17, 18, 19, 20, 21, 22, 23, 24,
            26, 28, 29, 31,
            33, 36, 37, 40,
            41, 42, 43, 44, 45, 46, 47, 48,
            49, 50, 51, 52, 53, 54, 55, 56,
            59, 60, 61, 62
        ],
        symmetric: true
    },
    //14
    {
        cells: [
            1, 2, 7, 8,
            9, 10, 15, 16,
            17, 19, 20, 21, 22, 24,
            27, 30,
            35, 36, 37, 38,
            41, 42, 43, 46, 47, 48,
            49, 50, 55, 56,
            57, 58, 63, 64
        ],
        symmetric: true
    },
    //15
    {
        cells: [
            1, 4, 5, 8,
            10, 12, 13, 15,
            19, 20, 21, 22,
            28, 29,
            36, 37,
            43, 44, 45, 46,
            50, 51, 52, 53, 54, 55,
            57, 58, 59, 60, 61, 62, 63, 64
        ],
        symmetric: true
    },
    //16
    {
        cells: [
            1, 2, 7, 8,
            10, 15,
            18, 19, 20, 21, 22, 23,
            28, 29,
            33, 34, 35, 36, 37, 38, 39, 40,
            41, 48,
            49, 50, 55, 56,
            58, 59, 62, 63
        ],
        symmetric: true
    },
    //17
    {
        cells: [
            2, 3, 4, 5, 6, 7,
            9, 10, 11, 12, 13, 14, 15, 16,
            17, 24,
            25, 26, 27, 28, 29, 30, 31, 32,
            34, 35, 36, 37, 38, 39,
            42, 44, 45, 47,
            50, 52, 53, 55,
            57, 58, 63, 64
        ],
        symmetric: true
    },
    //18
    {
        cells: [
            1, 2, 3, 4, 5, 6, 7, 8,
            10, 15,
            17, 18, 19, 20, 21, 22, 23, 24,
            27, 28, 29, 30,
            35, 36, 37, 38,
            41, 42, 43, 44, 45, 46, 47, 48,
            50, 55,
            57, 58, 59, 60, 61, 62, 63, 64
        ],
        symmetric: true
    },
    //19
    {
        cells: [
            1, 2, 3, 4, 5, 6, 7, 8,
            9, 12, 13, 16,
            18, 19, 20, 21, 22, 23,
            26, 28, 29, 31,
            34, 36, 37, 39,
            42, 43, 44, 45, 46, 47,
            49, 52, 53, 56,
            57, 58, 59, 60, 61, 62, 63, 64
        ],
        symmetric: true
    },
    //20
    {
        cells: [
            3, 4, 5, 6,
            10, 15,
            17, 24,
            25, 26, 27, 28, 29, 30, 31, 32,
            33, 34, 35, 36, 37, 38, 39, 40,
            41, 48,
            50, 55,
            59, 60, 61, 62
        ],
        symmetric: true
    },
    //21
    {
        cells: [
            2, 3, 6, 7,
            10, 11, 14, 15,
            17, 20, 21, 24,
            26, 27, 30, 31,
            34, 35, 38, 39,
            41, 44, 45, 48, 
            50, 51, 54, 55,
            58, 59, 62, 63
        ],
        symmetric: true
    },
    //22
    {
        cells: [
            3, 6,
            11, 14,
            17, 18, 19, 20, 21, 22, 23, 24,
            27, 30,
            35, 38,
            41, 42, 43, 44, 45, 46, 47, 48,
            51, 54,
            59, 62
        ],
        symmetric: true
    },
    //23
    {
        cells: [
            3, 4, 5, 6,
            10, 15,
            19, 20, 21, 22,
            25, 26, 31, 32,
            35, 36, 37, 38,
            42, 47,
            51, 52, 53, 54,
            57, 58, 63, 64
        ],
        symmetric: true
    },
    //24
    {
        cells: [
            1, 2, 3, 4, 5, 6, 7, 8,
            9, 10, 15, 16,
            18, 23,
            25, 26, 31, 32,
            33, 34, 39, 40,
            42, 47,
            49, 50, 55, 56,
            57, 58, 59, 60, 61, 62, 63, 64
        ],
        symmetric: true
    },
    //25
    {
        cells: [
            4, 5,
            11, 12, 13,
            19, 20, 21, 22,
            26, 27, 28, 29, 30, 31,
            34, 39,
            42, 43, 44, 45, 46, 47,
            51, 52, 53, 54,
            60, 61, 62
        ],
        symmetric: false
    },
    //26
    {
        cells: [
            1, 2, 3, 4, 5, 6, 7, 8,
            10, 11, 15,
            17, 18, 19, 20, 21, 22, 23, 24,
            26, 28, 29, 31,
            33, 34, 35, 36, 37, 38, 39, 40,
            41, 44, 45, 48,
            49, 50, 51, 52, 53, 54, 55, 56,
            58, 59, 62, 63
        ],
        symmetric: false
    },
    //27
    {
        cells: [
            1, 2, 3, 4, 5, 6, 7, 8,
            10, 11, 12, 13, 14, 15,
            20, 21,
            25, 26, 27, 28, 29, 30, 31,
            35, 38,
            43, 44, 45, 46,
            50, 51, 52, 53, 54, 55, 56,
            60, 61
        ],
        symmetric: false
    },
    //28
    {
        cells: [
            3, 4, 7, 8,
            10, 15,
            17, 18, 21, 23, 24,
            25, 27, 28, 29, 30, 32,
            33, 35, 36, 37, 38, 40,
            41, 42, 45, 47, 48,
            50, 55,
            59, 60, 63, 64
        ],
        symmetric: false
    },
    //29
    {
        cells: [
            1, 2, 3, 6,
            9, 11, 14,
            17, 19, 22,
            25, 27, 28, 29, 30, 31, 32,
            33, 34, 35, 36, 37, 38, 40,
            43, 46, 48,
            51, 54, 56,
            59, 62, 63, 64
        ],
        symmetric: false
    },
    //30
    {
        cells: [
            1, 2, 8,
            9, 10, 11, 12, 13, 14, 15, 16,
            17, 18, 19, 23, 24,
            25, 26, 27, 31, 32,
            33, 34, 35, 39, 40,
            41, 42, 43, 47, 48,
            49, 50, 51, 52, 53, 54, 55, 56,
            57, 58, 64
        ],
        symmetric: false
    },
    //31
    {
        cells: [
            1, 7, 8,
            9, 10, 15,
            19, 22,
            27, 28, 29, 30,
            34, 35, 36, 37, 38, 39,
            42, 43, 46, 47,
            49, 50, 55,
            57, 63, 64
        ],
        symmetric: false
    },
    //32
    {
        cells: [
            2, 3, 4, 5, 6, 7,
            9, 13, 16,
            17, 21,
            27, 28, 29, 30,
            35, 36, 37, 38, 40,
            41, 44, 48,
            49, 52, 56,
            58, 59, 60, 61, 62, 63
        ],
        symmetric: false
    },
    //33
    {
        cells: [
            3, 4, 5, 6,
            11, 12, 13, 14,
            17, 18, 19, 20, 22, 23, 24,
            25, 26, 27, 28, 31, 32,
            33, 34, 37, 38, 39, 40,
            41, 42, 43, 45, 46, 47, 48,
            51, 52, 53, 54,
            59, 60, 61, 62
        ],
        symmetric: false
    },
    //34
    {
        cells: [
            1, 8,
            9, 10, 15, 16,
            17, 18, 19, 22, 23,
            26, 27, 28, 29, 30, 31,
            34, 35, 36, 37, 38, 39,
            42, 43, 44, 45, 46, 47,
            52, 53, 54,
            60, 61, 62
        ],
        symmetric: false
    },
    //35
    {
        cells: [
            1, 3, 4, 5, 6, 8,
            10, 12, 13, 15,
            17, 19, 21, 22, 24,
            25, 26, 27, 28, 29, 30, 31, 32,
            33, 34, 35, 36, 37, 38, 39, 40,
            41, 43, 44, 46, 48,
            50, 52, 53, 55,
            57, 59, 60, 61, 62, 64
        ],
        symmetric: false
    },
    //36
    {
        cells: [
            2, 3, 7, 8,
            10, 11, 13, 14, 15, 16,
            17, 18, 23, 24,
            26, 27, 28, 29, 30, 31,
            34, 35, 36, 37, 38, 39,
            41, 42, 47, 48,
            49, 50, 51, 52, 54, 55,
            57, 58, 62, 63
        ],
        symmetric: false
    },
    //37
    {
        cells: [
            1, 4, 5,
            9, 10, 11, 12, 13, 14,
            19, 20, 21, 22, 23, 24,
            28, 29, 32,
            33, 35, 36, 37, 38,
            41, 42, 43, 44, 45, 46,
            51, 52, 53, 54, 55, 56,
            59, 60, 61, 62, 64
        ],
        symmetric: false
    },
    //38
    {
        cells: [
            1, 2, 6, 7, 8,
            9, 10, 15, 16,
            17, 18, 19, 20, 21, 22,
            27, 30,
            35, 38,
            43, 44, 45, 46, 47, 48,
            49, 50, 55, 56,
            57, 58, 59, 63, 64
        ],
        symmetric: false
    },
    //39
    {
        cells: [
            2, 5, 7, 8,
            10, 12, 13, 15,
            18, 19, 20, 21, 22, 23,
            28, 29,
            36, 37,
            42, 43, 44, 45, 46, 47,
            50, 52, 53, 55,
            57, 58, 60, 63
        ],
        symmetric: false
    },
    //40
    {
        cells: [
            2, 3, 4, 5, 6, 7, 8,
            10, 15,
            17, 18, 19, 20, 21, 22, 23,
            28, 29,
            33, 34, 35, 36, 37, 38, 39, 40,
            41, 48,
            49, 50, 51, 52, 52, 54, 55, 56,
            59, 63
        ],
        symmetric: false
    },
    //41
    {
        cells: [
            1, 2, 3, 4, 5, 6, 7, 8,
            9, 12, 16,
            17, 18, 21, 23, 24,
            26, 27, 28, 29, 30, 31,
            34, 35, 36, 37, 38, 39,
            42, 47,
            50, 55,
            57, 58, 62, 63
        ],
        symmetric: false
    },
    //42
    {
        cells: [
            1, 2, 3, 4, 5, 6, 7, 8,
            14, 15,
            17, 18, 19, 20, 21, 22, 23,
            28, 29, 30,
            35, 36, 37,
            42, 43, 44, 45, 46, 47, 48,
            50, 51,
            57, 58, 59, 60, 61, 62, 63, 64
        ],
        symmetric: false
    },
    //43
    {
        cells: [
            2, 3, 4, 5, 6, 7, 8,
            10, 12, 13, 16,
            18, 19, 20, 21, 22, 24,
            26, 28, 29, 30, 31,
            34, 35, 36, 37, 39,
            41, 43, 44, 45, 46, 47,
            49, 52, 53, 55,
            57, 58, 59, 60, 61, 62, 63
        ],
        symmetric: false
    },
    //44
    {
        cells: [
            2, 8,
            11, 15,
            20, 22,
            25, 26, 27, 28, 29, 30, 31, 32,
            33, 34, 35, 36, 37, 38, 39, 40,
            43, 45,
            50, 54,
            57, 63
        ],
        symmetric: false
    },
    //45
    {
        cells: [
            3, 6, 7, 8,
            11, 14, 15, 16,
            17, 18, 19, 22, 23, 24,
            25, 28, 29,
            36, 37, 40,
            41, 42, 43, 46, 47, 48,
            49, 50, 51, 54,
            57, 58, 59, 62
        ],
        symmetric: false
    },
    //46
    {
        cells: [
            1, 2, 5,
            9, 10, 13, 15, 16,
            19, 20, 21, 22,
            25, 26, 31, 32,
            33, 34, 39, 40,
            43, 44, 45, 46,
            49, 50, 52, 55, 56,
            57, 58, 60
        ],
        symmetric: false
    },
    //47
    {
        cells: [
            3, 4, 5, 6,
            11, 14,
            18, 19, 20, 21, 22, 23,
            25, 26, 31, 32,
            34, 35, 36, 37, 38, 39,
            41, 46, 48,
            51, 52, 53, 54,
            59
        ],
        symmetric: false
    },
    //48
    {
        cells: [
            2, 3, 4, 5, 6, 7,
            10, 15,
            18, 19, 23, 24,
            25, 26, 27, 30, 31, 32,
            33, 34, 35, 38, 39, 40,
            42, 43, 47, 48,
            50, 55,
            58, 59, 60, 61, 62, 63
        ],
        symmetric: false
    },
]

// Instructions - Training
config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>
                In this test you will try to memorize the position of red squares you see on the screen while you also make judgments about other pictures.
            </p>
            <p>In the next few minutes, you will have some practice to get you familiar with how the experiment works.</p>
            <p>We will begin by practicing the “square” part of the experiment.</p>
            <p>Click the mouse to continue.</p>
        </>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>For this practice set, squares will appear on the screen one at a time.</p>
            <p>Try to remember where each square was, in the order it was presented in.</p>
            <p>After 2 – 5 squares have been shown, you will see a grid of the 16 possible places the squares could have been.</p>
            <p>Your job is to select each square in the order presented.</p>
            <p>To do this, use the mouse to select the appropriate boxes. The squares you select will turn red. </p>
            <p>Click the mouse button to continue.</p>
        </>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>When you have selected all the squares, and they are in the correct order, hit the ENTER box at the bottom right of the screen.</p>
            <p>If you make a mistake, hit the CLEAR box to start over.</p>
            <p>If you forget one of the squares, click the BLANK box to mark the spot for the missing square.</p>
            <p>
                Remember, it is very important to get the squares in the same order as you see them. If you forget one, use the BLANK box to mark the position.
            </p>
            <p>Take a moment to make sure you understand the instructions.</p>
            <p>When you are ready, click the mouse button to start the square practice.</p>
        </>
    )
})

// Training
shuffle(matrices)

for (let m of matrices.slice(0, 2)) {
    config.push({
        type: TRIALS.MATRIX,
        timeout: 650,
        filled: m
    })
}

config.push({
    type: TRIALS.MATRIX,
    recall: true,
})

shuffle(matrices)

for (let m of matrices.slice(0, 2)) {
    config.push({
        type: TRIALS.MATRIX,
        timeout: 650,
        filled: m
    })
}

config.push({
    type: TRIALS.MATRIX,
    recall: true,
})

shuffle(matrices)

for (let m of matrices.slice(0, 3)) {
    config.push({
        type: TRIALS.MATRIX,
        timeout: 650,
        filled: m
    })
}

config.push({
    type: TRIALS.MATRIX,
    recall: true,
})

shuffle(matrices)

for (let m of matrices.slice(0, 3)) {
    config.push({
        type: TRIALS.MATRIX,
        timeout: 650,
        filled: m
    })
}

config.push({
    type: TRIALS.MATRIX,
    recall: true,
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>Now you will practice doing the symmetry part of the experiment.</p>
            <p>A picture will appear on the screen, and you will have to decide if it is symmetrical.</p>
            <p>A picture is symmetrical if you can fold it in half vertically and the picture on the left lines up with the picture on the right. </p>
            <p>On the next screen you will see a picture that is SYMMETRICAL.</p>
            <p>Click the mouse to continue.</p>
        </>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <div className='text-center'>
            <p>Notice that this picture is symmetrical about the red line.</p>
            <p>In the real pictures the red line will not be present. </p>
            <p>Click the mouse to continue.</p>
            <img src={example1} alt='Example of symmetrical image' />
        </div>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <div className='text-center'>
            <p>Here, the picture is NOT symmetrical.</p>
            <p>If you folded this across the red line, the boxes would NOT line up. </p>
            <p>Click the mouse to continue.</p>
            <img src={example2} alt='Example of asymmetrical image' />
        </div>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <div className='text-center'>
            <p>This is another example of a picture that IS symmetrical.</p>
            <p>If you folded it vertically, the two sides would line up. </p>
            <p>Click the mouse to continue.</p>
            <img src={example3} alt='Example of symmetrical image' />
        </div>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <div className='text-center'>
            <p>Here is another example of a picture that is NOT symmetrical.</p>
            <p>Notice that if folded, the two sides would not line up.</p>
            <p>Take a moment to make sure you understand the instructions.</p>
            <p>Click the mouse to continue.</p>
            <img src={example4} alt='Example of asymmetrical image' />
        </div>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>Once you have decided if the picture is symmetrical, click the mouse button.</p>
            <p>On the next screen a YES and NO box will appear.</p>
            <p>If the picture you saw was symmetrical, click the YES box. If it was not symmetrical, click the NO box.</p>
            <p>After you click on one of the boxes, the computer will tell you if you made the right choice.</p>
            <p>Click the mouse to continue.</p>
        </>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>It is VERY important that you get the pictures correct.</p>
            <p>Take a moment to make sure you understand the instructions.</p>
            <p>When you are ready, click the mouse to try some practice problems.</p>
        </>
    )
})

shuffle(symmetry_practice)

for (let s of symmetry_practice) {
    // Symmetry test
    config.push({
        type: TRIALS.SYMMETRY,
        filled: s.cells,
        getMean: true,
        isSymmetric: s.symmetric,
        showCorrect: true,
        training: true
    })
}

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>
                Now you will practice doing both parts of the test at the same time. In the next practice set, you will be given one of the symmetry problems.

            </p>
            <p>Once you make your decision about the picture, a square will appear on the screen. Try and remember the position of the square.</p>
            <p>In the previous section where you only decided about the picture symmetry, the computer computed your average time to solve the problems.</p>
            <p>
                If you take longer than your average time, the computer will automatically move you onto the square part, thus skipping the YES or NO part and will count that problem as an error.
            </p>
            <p>Therefore, it is VERY important to solve the problems as quickly and as accurately as possible.</p>
            <p>Click the mouse to continue. </p>
        </>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>After the square goes away, another symmetry picture will appear, and then another square.</p>
            <p>
                At the end of each set of pictures and squares, a recall screen will appear. Use the mouse to select the squares you just saw.
            </p>
            <p>Try your best to get the squares in the correct order. It is important to work QUICKLY and ACCURATELY.</p>
            <p>You will not be told if your answer to the symmetry picture is correct.</p>
            <p>
                <strong>IMPORTANT:</strong> you cannot take notes of the square positions during the presentation. You should rely on your memory to remember them.
            </p>
            <p>Take a moment to make sure you understand the instructions.</p>
            <p>Click the mouse to try some practice problems.</p>
        </>
    )
})

shuffle(symmetry_practice)
shuffle(matrices)

let symmetry_index = 0
let matrix_index = 0

for (let level = 2; level <= 2; level++) {
    for (let set = 1; set <= 3; set++) {
        for (let i = 1; i <= level; i++) {
            for (let s of symmetry_practice.slice(symmetry_index, symmetry_index + (level - 1))) {
                // Symmetry test
                config.push({
                    type: TRIALS.SYMMETRY,
                    filled: s.cells,
                    timeout: 'mean',
                    isSymmetric: s.symmetric,
                    showCorrect: false,
                    training: true
                })
        
                // Matrix
                config.push({
                    type: TRIALS.MATRIX,
                    timeout: 650,
                    filled: matrices[matrix_index++]
                })
            }
            symmetry_index = symmetry_index + level
        }
    
        config.push({
            type: TRIALS.MATRIX,
            recall: true,
        })
    }
}

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>That is the end of the practice.</p>
            <p>The real trials will look just like the practice trials you just completed. Some sets will have more problems than others. </p>
            <p>It is important that you do your best on both the symmetry and the square parts of this task.</p>
            <p>Take a moment to make sure you understand instructions.</p>
            <p>Click the mouse button to begin the experiment.</p>
        </>
    )
})

const levels = [2, 3, 4, 5]

shuffle(symmetry_matrices)
shuffle(levels)

symmetry_index = 0

for (let level of levels) {
    shuffle(matrices)

    matrix_index = 0

    for (let set = 1; set <= 3; set++) {
        for (let i = 1; i <= level; i++) {
            // Symmetry test
            config.push({
                type: TRIALS.SYMMETRY,
                filled: symmetry_matrices[symmetry_index].cells,
                timeout: 'mean',
                isSymmetric: symmetry_matrices[symmetry_index].symmetric,
                showCorrect: false,
                training: false
            })

            // Matrix
            config.push({
                type: TRIALS.MATRIX,
                timeout: 650,
                filled: matrices[matrix_index++]
            })
            
            symmetry_index++
        }

        config.push({
            type: TRIALS.MATRIX,
            recall: true,
        })
    }
}

export default config