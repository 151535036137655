import React, { useState, useEffect, useContext } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import md5 from 'crypto-js/md5'
import { Link, Switch, Route, useRouteMatch } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { withAuthorization, withEmailVerification, AuthUserContext } from '../Session'
import { FirebaseContext } from '../Firebase'
import { Loading, Message, IconButton, ICONS } from '../Layout'
import DataType from '../../types/DataType'

import * as ROLES from '../../constants/roles'
import * as ROUTES from '../../constants/routes'
import { INSTRUMENTS } from '../../constants/instruments'

function Participant() {
    const [loading, setLoading] = useState(true)
    const [dataset, setDataset] = useState(null as any[]|null)
    const authUser: any = useContext(AuthUserContext)
    const match = useRouteMatch<{id: string}>(ROUTES.PARTICIPANT_DATA)
    const firebase = useContext(FirebaseContext)

    const handleRefuseAuthorization = (id: string) => {
        firebase?.data(id)
            .child('data/0/response/0/answer')
            .set('')
    }

    const handleGrantAuthorization = (id: string) => {
        firebase?.data(id)
            .child('data/0/response/0/answer')
            .set('Verdadeiro')
    }

    useEffect(() => {
        setLoading(true)
        
        firebase?.dataset()
            .orderByChild('participantId')
            .equalTo(md5(authUser.email).toString())
            .on('value', snapshot => {

                const datasetObject = snapshot.val()

                if (datasetObject) {
                    const datasetList = Object.keys(datasetObject).map(k => ({...datasetObject[k], uid: k}))
                
                    setDataset(datasetList)
                }

            setLoading(false)
        })

        return () => firebase?.dataset().off()
    }, [firebase, authUser])

    return (
        loading
        ? <Loading/>
        :
        <Container className='my-5'>
            <AuthUserContext.Consumer>
                {(authUser: any) => (
                    <Row>
                        <Col lg={5} className='bg-light border p-4 d-flex flex-column'>
                            <div className='d-flex'>
                                {
                                    authUser.providerData[0].photoURL
                                    && 
                                    <img 
                                        src={authUser.providerData[0].photoURL} 
                                        alt='Foto do perfil'
                                        className='img-thumbnail rounded-circle mx-3' />
                                }
                                <div className='d-flex flex-column justify-content-center'>
                                    <span>{authUser.displayName}</span>
                                    <span>{authUser.email}</span>
                                </div>
                            </div>
                            
                            <h3 className='mt-3'>Seus Dados</h3>

                            <div className='my-3'>
                                {
                                    dataset
                                    ?
                                    <ul>
                                        {
                                            dataset?.map(d => (
                                                <li key={d.uid}>
                                                    <Link to={`/participante/dados/${d.uid}`}>{d.experiment}</Link>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                    :
                                        loading 
                                        ? <Loading/>
                                        : <Message warning='Você ainda não participou de nenhuma pesquisa'/>
                                }
                            </div>
                        </Col>
                        <Switch>
                            <Route exact path={ROUTES.PARTICIPANT}>
                                <Col className='my-4'>
                                    <p className='text-justify'>
                                        Clique no menu ao lado no título de uma das pesquisas que você participou para ver seus dados.
                                    </p>
                                </Col>        
                            </Route>
                            <Route exact path={ROUTES.PARTICIPANT_DATA}>
                                <Col className='my-4'>
                                    {
                                        dataset?.map(data => (
                                            data.uid === match?.params.id && 
                                            <React.Fragment key={uuid()}>
                                                <h2 className='mb-4'>{data.experiment}</h2>
                                                {
                                                    data.data.map((d: DataType) => {
                                                        if (d.instrument === INSTRUMENTS.TCLA && d.type === 'Summary') {
                                                            return <p key={uuid()}>Sua pontuação no teste {d.instrument} foi <strong>{d.correct[0]}</strong></p>
                                                        } else if (d.instrument === INSTRUMENTS.SURVEY && d.type === 'Survey') {
                                                            return (
                                                                <div key={uuid()}>
                                                                    <ul>
                                                                        {
                                                                            d.response.map((p: {prompt: string, answer: string}) => (
                                                                                <li key={uuid()} className='my-3'>
                                                                                    <strong>{p.prompt}</strong><br/>
                                                                                    {p.answer}
                                                                                </li>
                                                                            ))    
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            )
                                                        } else if (d.instrument === INSTRUMENTS.TCLE && d.type === 'Survey') {
                                                            return (
                                                                <div key={uuid()} className='mb-5'>
                                                                    {
                                                                        d.response[0].answer === 'Verdadeiro'
                                                                        ? 
                                                                        <>
                                                                            <h3 className='text-success mb-3'>
                                                                                Você concordou em participar dessa pesquisa.
                                                                            </h3>
                                                                            <IconButton 
                                                                                icon={ICONS.DELETE} 
                                                                                variant='danger'
                                                                                onClick={() => handleRefuseAuthorization(data.uid)}>

                                                                                Remover autorização
                                                                            </IconButton>
                                                                        </>
                                                                        : 
                                                                        <>
                                                                            <h3 className='text-danger mb-3'>
                                                                                Você não concordou em participar dessa pesquisa. 
                                                                                Seus dados não serão utilizados na análise.
                                                                            </h3>
                                                                            <IconButton 
                                                                                icon={ICONS.OK} 
                                                                                variant='success'
                                                                                onClick={() => handleGrantAuthorization(data.uid)}>

                                                                                Autorizar (Concordar com o Termo de Consetimento Livre e Esclarecido)
                                                                            </IconButton>
                                                                        </>
                                                                    }
                                                                </div>
                                                            )
                                                        } else {
                                                            return null
                                                        }
                                                    })
                                                }
                                            </React.Fragment>
                                        ))
                                    }
                                </Col>
                            </Route>
                        </Switch>
                    </Row>
                )}
            </AuthUserContext.Consumer>
        </Container>
    )
}

const condition = (authUser: any) => authUser && !!authUser.roles[ROLES.PARTICIPANT]

export default withAuthorization(condition)(withEmailVerification(Participant))