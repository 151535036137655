import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import Container from 'react-bootstrap/Container'
import { v4 as uuid } from 'uuid'

import config from './config'
import Text from '../Text'
import Stimulus from '../Stimulus'
import Question from '../Question'
import Blank from '../Blank'
import DataType from '../../../types/DataType'
import ConfigType from '../../../types/ConfigType'

import { INSTRUMENTS, TRIALS } from '../../../constants/instruments'

import './index.css'

function OSTEnglish(props: {
    id: string,
    name: string,
    show: boolean,
    data: DataType[],
    language: 'en'|'br',
    addData: Dispatch<SetStateAction<DataType[]>>,
    next: (finish?: boolean) => void,
    hideButton?: boolean,
    list?: string
}) {
    const [show, setShow] = useState(-1)
    const [keys] = useState(config.map(() => uuid()))
    const [finish, setFinish] = useState(false)

    const handleAddData = (newData: DataType) => {
        props.addData(data => [...data, newData])
    }

    const handleNext = (finish?: boolean, skip?: boolean) => {
        if (!finish) {
            if (show < config.length - 1) {
                setShow(show => show + (!skip ? 1 : 2))
            } else {
                setFinish(true)
            }
        } else {
            setFinish(true)
        }
    }

    useEffect(() => {
        if (props.show && finish) {
            props.addData(data => {
                let correct = 0

                data.forEach(d => {
                    if (d.type === TRIALS.QUESTION && d.instrumentId === props.id) {
                        d.correct.forEach(c => {
                            correct += (c === true ? 1 : 0)
                        })
                    }
                })

                return [
                    ...data, 
                    {
                        instrument: INSTRUMENTS.OSTEnglish, 
                        instrumentId: props.id, 
                        instrumentName: props.name || '', 
                        type: 'Summary', 
                        response: '', 
                        correct: [correct], 
                        time: 0
                    }
                ]
            })
            props.next()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finish])

    useEffect(() => {
        if (props.show) {
            setShow(0)
        }
    }, [props.show])

    return (
        !props.show ? null :
        <Container className='instrument-container d-flex justify-content-center align-items-center position-relative' id='OST' fluid>
            {
                config.map((p: ConfigType, i: number) => {
                    switch (p.type) {
                        case TRIALS.TEXT:
                            return <Text 
                                        key={keys[i]}
                                        show={show === i}
                                        instrument={INSTRUMENTS.OSTEnglish}
                                        instrumentId={props.id}
                                        instrumentName={props.name}
                                        addData={handleAddData}
                                        next={handleNext}
                                        language={props.language}>
                                            
                                        {p.prompt}
                                    </Text>
                        case TRIALS.STIMULUS:
                            return <Stimulus 
                                        key={keys[i]}
                                        show={show === i}
                                        instrument={INSTRUMENTS.OSTEnglish}
                                        instrumentId={props.id}
                                        instrumentName={props.name}
                                        response={p.response}
                                        data={props.data}
                                        addData={handleAddData} 
                                        next={handleNext}
                                        timeout={p.timeout}
                                        training={p.training}
                                        prompt={p.prompt}
                                        language={props.language}>
                                            
                                        {p.stimulus}
                                    </Stimulus>
                        case TRIALS.BLANK:
                            return (
                                <Blank 
                                    key={keys[i]}
                                    show={show === i}
                                    instrument={INSTRUMENTS.OSTEnglish} 
                                    instrumentId={props.id}
                                    instrumentName={props.name}
                                    addData={handleAddData}
                                    next={handleNext}/>
                            )
                        case TRIALS.QUESTION:
                            return (
                                <Question 
                                    key={keys[i]}
                                    show={show === i}
                                    instrument={INSTRUMENTS.OSTEnglish}
                                    instrumentId={props.id}
                                    instrumentName={props.name}
                                    questions={p.questions} 
                                    correctResponses={p.correctResponses} 
                                    title={p.title} 
                                    addData={handleAddData}
                                    next={handleNext}
                                    training={p.training}
                                    language={props.language}/>
                            )
                        default:
                            return <div></div>
                    }
                })
            }
        </Container>
    )
}

export default OSTEnglish