import React, { Dispatch, SetStateAction, useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import { v4 as uuid } from 'uuid'

import DataType from '../../../types/DataType'
import ConfigType from '../../../types/ConfigType'
import Text from '../Text'
import Question from '../Question'
import { Message } from '../../Layout'
import { TimerComponent } from '../Timer'

import { INSTRUMENTS, TRIALS } from '../../../constants/instruments'

function Survey(props: {
    id: string,
    name: string,
    show: boolean,
    questions: any[],
    data: DataType[], 
    language: 'en'|'br',
    addData: Dispatch<SetStateAction<DataType[]>>,
    next: (finish?: boolean) => void,
    hideButton?: boolean,
    nextLinked?: boolean,
    timer?: number
}) {
    const [finish, setFinish] = useState(false)
    const [config, setConfig] = useState([] as ConfigType[])
    const [keys, setKeys] = useState([] as string[])
    const [show, setShow] = useState([] as boolean[])

    const handleAddData = (newData: DataType) => {
        props.addData(data => {
            return [...data, newData]
        })
    }

    const handleNext = () => {
        if (!show[show.length - 1]) {
            let setTrue = false

            setShow(show.map((s: boolean) => {
                if (s) {
                    setTrue = true
                    return false
                }

                if (setTrue) {
                    setTrue = false
                    return true
                }

                return false
            }))
        } else {
            setFinish(true)
        }
    }

    useEffect(() => {
        const config = [{
            type: TRIALS.QUESTION,
            questions: (props.questions ? props.questions.map(q => ({prompt: q.prompt, type: q.type, options: q.options})) : [])
        }]

        setConfig(config)
        setKeys(config.map(() => uuid()))
        setShow(config.map((c: ConfigType, i: number) => i === 0 ? true : false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.show && props.timer && props.timer > 0) {
            setTimeout(() => setFinish(true), props.timer)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show])

    useEffect(() => {
        if (props.show && finish) {
            props.addData(data => {
                let correct = 0

                data.forEach(d => {
                    if (d.type === TRIALS.QUESTION && d.instrumentId === props.id) {
                        d.correct.forEach(c => {
                            correct += (c === true ? 1 : 0)
                        })
                    }
                })

                return [
                    ...data, 
                    {
                        instrument: INSTRUMENTS.SURVEY, 
                        instrumentId: props.id, 
                        instrumentName: props.name || '',
                        type: 'Summary', 
                        response: '', 
                        correct: [correct], 
                        time: 0
                    }
                ]
            })
            
            props.next()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finish])
    
    return (
        !props.show ? null :
        <Container className='instrument-container d-flex justify-content-center align-items-center position-relative'>
            {
                props.questions && props.questions.length > 0 ?
                config.map((p: ConfigType, i: number) => {
                    switch (p.type) {
                        case TRIALS.TEXT:
                            return <Text 
                                        key={keys[i]}
                                        show={show[i]}
                                        instrument={INSTRUMENTS.SURVEY} 
                                        instrumentId={props.id}
                                        instrumentName={props.name}
                                        addData={handleAddData}
                                        next={handleNext}
                                        hideButton={props.hideButton}
                                        language={props.language}>
                                            
                                        {p.prompt}
                                    </Text>
                        case TRIALS.QUESTION:
                            return (
                                <Question 
                                    key={keys[i]}
                                    show={show[i]}
                                    instrument={INSTRUMENTS.SURVEY}
                                    instrumentId={props.id}
                                    instrumentName={props.name}
                                    questions={p.questions} 
                                    correctResponses={p.correctResponses} 
                                    title={p.title}
                                    addData={handleAddData}
                                    next={handleNext}
                                    training={p.training}
                                    hideButton={props.hideButton}
                                    nextLinked={props.nextLinked}
                                    language={props.language}/>
                            )
                        default:
                            return <div></div>
                    }
                }) :
                <Message warning='Nenhuma questão encontrada'/>
            }
            {
                props.timer && props.timer > 0 &&
                <TimerComponent duration={props.timer} fixed top />
            }
        </Container>
    )
}

export default Survey