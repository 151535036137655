import React, { useState, useContext, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'

import Message from '../Layout/Message'
import { FirebaseContext } from '../Firebase'

function PasswordForget() {
    const [email, setEmail] = useState('')
    const [error, setError] = useState(null as null|firebase.auth.Error)
    const [language, setLanguage] = useState('')
    const isInvalid = email === ''
    const firebase = useContext(FirebaseContext)

    const handleSubmit = (e:React.FormEvent) => {
        e.preventDefault()

        firebase?.doPasswordReset(email)
            .then(() => {
                setEmail('')
                setError(null)
            })
            .catch(error => {
                setError(error)
            })     
    }

    useEffect(() => {
        setLanguage(navigator.language)
    }, [])

    return (
        <Container className='my-5'>
            <Row className='my-3'>
                <Col>
                    <ButtonGroup size='sm'>
                        <Button
                            id='language-1'
                            variant={language === 'pt-BR' ? 'secondary' : 'outline-secondary'}
                            onClick={() => setLanguage('pt-BR')}>
                            Português
                        </Button>
                        <Button
                            id="language-2"
                            variant={language === 'pt-BR' ? 'outline-secondary' : 'secondary'}
                            onClick={() => setLanguage('en')}>
                            English
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col>
                    <Form onSubmit={handleSubmit}>
                        <Form.Text><h1>{language === 'pt-BR' ? 'Mudar senha' : 'Change password'}</h1></Form.Text>
                        <Form.Group controlId='email'>
                            <Form.Label>Email</Form.Label>
                            <Form.Control 
                                type='email' 
                                placeholder={language === 'pt-BR' ? 'Informe seu email' : 'Inform your email'}
                                value={email}
                                onChange={e => setEmail(e.target.value)}/>
                            <Form.Text>
                                {
                                    language === 'pt-BR' ? 
                                        'Um link será enviado para seu email para criar uma nova senha' : 
                                        'A password reset link will be sent to your email'
                                }
                            </Form.Text>
                        </Form.Group>
                        <Button variant='primary' type='submit' disabled={isInvalid}>
                            {language === 'pt-BR' ? 'Enviar link' : 'Send link'}
                        </Button>
                        <Message error={error}/>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default PasswordForget