import React, { useState, useContext, useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { FirebaseContext } from '../../Firebase'
import { IconButton, ICONS, Loading } from '../../Layout'

import * as ROUTES from '../../../constants/routes'

function ReadingLoggerOptions() {
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState([] as any)
    const [newParagraph, setNewParagraph] = useState('')
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true)
    const [instrumentName, setInstrumentName] = useState('')
    const match = useRouteMatch<{experimentId: string, instrumentId: string}>(ROUTES.RESEARCHER_EXPERIMENTS_INSTRUMENTS_DETAILS)
    const firebase = useContext(FirebaseContext)
    const history = useHistory()
    const addParagraphButtonDisabled = !newParagraph

    const handleRemoveOption = (index: number) => {
        setOptions(options.filter((q: any, i: number) => i !== index))
        setSaveButtonDisabled(false)
    }

    const handleAddParagraph = () => {
        if (options) {
            setOptions(
                [...options, {text: newParagraph}]
            )
        } else {
            setOptions([{text: newParagraph}])
        }

        setNewParagraph('')
        setSaveButtonDisabled(false)
    }

    const handleChangeInstrumentName = (e: React.ChangeEvent<any>) => {
        firebase?.instrument(
            match?.params.experimentId as string, 
            match?.params.instrumentId as string
        ).child('name').set(e.target.value)

        setInstrumentName(e.target.value)
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        setLoading(true)

        firebase?.instrument(match?.params.experimentId as string, match?.params.instrumentId as string).child('options')
            .set(options).then(() => {
                setSaveButtonDisabled(true)
                history.push(`/pesquisador/experimentos/${match?.params.experimentId}`)
            })
    }

    useEffect(() => {
        firebase?.instrument(match?.params.experimentId as string, match?.params.instrumentId as string).on('value', snapshot => {
            setOptions(snapshot.val().options)
            
            if (snapshot.val().name) {
                setInstrumentName(snapshot.val().name)
            }
        })

        return () => {
            firebase?.instrument(
                match?.params.experimentId as string, 
                match?.params.instrumentId as string
            ).off()}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        loading ? <Loading/> :
        <>
            <h2>Editar Texto</h2>
            <Row>
                <Col xs={6}>
                    <Form.Group>
                        <Form.Label>Nome do instrumento</Form.Label>
                        <Form.Control 
                            value={instrumentName}
                            onChange={handleChangeInstrumentName}/>
                    </Form.Group>
                </Col>
            </Row>
            <Table striped bordered hover responsive className='my-3'>
                <thead>
                    <tr>
                        <th>Conteúdo</th>
                        <th>Tipo</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        options?.map(
                            (o: {text?: string}, i: number) => (
                            <tr key={uuid()}>
                                <td>{o.text}</td>
                                <td>
                                    {o.text && 'Texto'}
                                </td>
                                <td className='text-center'>
                                    <IconButton 
                                        icon={ICONS.DELETE} 
                                        variant='danger' 
                                        size='sm'
                                        onClick={() => handleRemoveOption(i)}/>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
            
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Form.Text>
                            <h3>Adicionar conteúdo</h3>
                        </Form.Text>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Página</Form.Label>
                            <InputGroup>
                                <Form.Control 
                                    as='textarea'
                                    value={newParagraph}
                                    onChange={e => setNewParagraph(
                                        e.target.value
                                    )}/>

                                <InputGroup.Append>
                                    <IconButton 
                                        icon={ICONS.PLUS} 
                                        variant='success'
                                        onClick={handleAddParagraph}
                                        disabled={addParagraphButtonDisabled}/>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <IconButton
                            icon={ICONS.BACK}
                            onClick={() => {history.goBack()}}>
                            
                            Voltar
                        </IconButton>
                        <IconButton 
                            type='submit' 
                            icon={ICONS.SAVE}
                            className='ml-1'
                            disabled={saveButtonDisabled}
                            variant={!saveButtonDisabled ? 'warning' : undefined}>
                            
                            Salvar
                        </IconButton>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default ReadingLoggerOptions