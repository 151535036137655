import React, { useState, useEffect, useContext } from 'react'
import Form from 'react-bootstrap/Form'
import { useHistory } from 'react-router-dom'

import { FirebaseContext } from '../../Firebase'
import { Message, IconButton, ICONS } from '../../Layout'

import * as ROUTES from '../../../constants/routes'
import * as ROLES from '../../../constants/roles'

function SignUpFormBase() {
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [error, setError] = useState(null as null|firebase.auth.Error)
    const firebase = useContext(FirebaseContext)
    const history = useHistory()
    const isInvalid = 
        password !== passwordConfirm ||
        password === '' ||
        email === '' ||
        username === ''

    const handleGoogleSignIn = () => {
        firebase?.doSignInWithGoogle()
            .then((socialAuthUser: any) => {
                if (socialAuthUser.additionalUserInfo.isNewUser) {
                    return firebase?.user(socialAuthUser.user.uid)
                        .set({
                            displayName: socialAuthUser.user.displayName,
                            email: socialAuthUser.user.email,
                            roles: {[ROLES.RESEARCHER]: ROLES.RESEARCHER}
                        })
                } else {
                    return firebase?.user(socialAuthUser.user.uid)
                        .update({
                            displayName: socialAuthUser.user.displayName,
                            email: socialAuthUser.user.email
                        })
                }
            })
            .then(() => {
                setError(null)
                history.push(ROUTES.HOME)
            })
            .catch(error => {
                setError(error)
            })
    }

    const handleSubmit = (e:React.FormEvent) => {
        e.preventDefault()

        const roles = {
            [ROLES.RESEARCHER]: ROLES.RESEARCHER,
            [ROLES.PARTICIPANT]: ROLES.PARTICIPANT
        } as ROLES.Type

        firebase?.doCreateUserWithEmailAndPassword(email, password)
            .then((authUser: any) => {
                return firebase?.user(authUser.user?.uid)
                    .set({
                        displayName: username,
                        email,
                        roles
                    })
            })
            .then(() => {
                return firebase?.doSendEmailVerification()
            })
            .then(() => {
                setUsername('')
                setEmail('')
                setPassword('')
                setPasswordConfirm('')
                setError(null)

                history.push(ROUTES.HOME)
            }).catch((error: firebase.auth.Error) => {
                setError(error)
            })
    }

    useEffect(() => {
        if (password !== '' && passwordConfirm !== '' && password !== passwordConfirm) {
            setError({code: 'auth/passwordConfirmFailed', message: 'As senhas não correspondem'})
        } else {
            setError(null)
        }
    }, [password, passwordConfirm])

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Text><h1>Criar conta</h1></Form.Text>
            <Form.Group className='my-5'>
                <IconButton icon={ICONS.GOOGLE} onClick={handleGoogleSignIn}>Registre-se com sua conta Google</IconButton>
            </Form.Group>
            <Form.Text><h5>Ou preencha os campos abaixo:</h5></Form.Text>
            <Form.Group controlId='username'>
                <Form.Label>Nome</Form.Label>
                <Form.Control 
                    type='text' 
                    placeholder='Insira seu nome'
                    value={username}
                    onChange={e => setUsername(e.target.value)}/>
            </Form.Group>
            <Form.Group controlId='email'>
                <Form.Label>Email</Form.Label>
                <Form.Control 
                    type='email' 
                    placeholder='Informe seu email'
                    value={email}
                    onChange={e => setEmail(e.target.value)}/>
            </Form.Group>
            <Form.Group controlId='password'>
                <Form.Label>Senha</Form.Label>
                <Form.Control 
                    type='password' 
                    placeholder='Crie uma senha'
                    value={password}
                    onChange={e => setPassword(e.target.value)}/>
            </Form.Group>
            <Form.Group controlId='password-confirm'>
                <Form.Label>Confirmar senha</Form.Label>
                <Form.Control 
                    type='password' 
                    placeholder='Confirme a senha' 
                    value={passwordConfirm}
                    onChange={e => setPasswordConfirm(e.target.value)}/>
            </Form.Group>
            <IconButton icon={ICONS.NEW_USER} type='submit' disabled={isInvalid}>Criar conta</IconButton>
            <Message error={error}></Message>
        </Form>
    )
}

const SignUpForm = SignUpFormBase

export default SignUpForm