const sentences_training = [
    'O satélite foi lançado em 1990, mas nunca funcionou - ficou numa órbita errada',
    'A iniciativa deve partir da própria pessoa interessada em ter um corpo bonito e saudável',
    'Entre as armas apreendidas nesse sábado estão dois fuzis',
    'As bactérias degradam as emulsões coloridas do filme, criando imagens que podem ser definidas como futuristas',
    'A padronização agrícola, para atender aos consumidores, ameaça a diversidade biológica do mundo vegetal',
    'O governo desistiu de limitar a importação de carros, conforme proposta defendida pela secretaria nacional da economia',
    'Para realizar as atividades cerebrais do pensamento, os neurônios tiram energia do oxigênio e da glicose',
    'O processo de fabricação é o problema que aflige a maior parte dos pequenos empresários',
    'Cerca de 250 milhões de pessoas, ao redor do mundo, se encontram na mais profunda depressão'
]

export default sentences_training