import React from 'react'
import ConfigType from '../../../types/ConfigType'
import { patterns_training, patterns } from './patterns'

import { TRIALS } from '../../../constants/instruments'


const config_training: ConfigType[] = []

// Training
for (let set of patterns_training) {
    for (let group of set) {
        // Comparisons
        config_training.push({
            type: TRIALS.STIMULUS,
            prompt: 
                <>
                    <span>F diferente</span>
                    <span>J igual</span>
                </>,
            stimulus: group.patterns,
            response: {
                type: 'key',
                keys: ['f', 'j'],
                showCorrect: true, //Only in trainning
            },
            correctResponses: [(group.equal ? 'j' : 'f')],
            training: true,
            description: group.patterns.props.src
        })

        // Blank
        config_training.push({
            type: TRIALS.BLANK,
            training: true
        })
    }
}

const config_list1: ConfigType[] = []

// List 1
for (let group of patterns[0]) {
    // Comparisons
    config_list1.push({
        type: TRIALS.STIMULUS,
        stimulus: group.patterns,
        response: {
            type: 'key',
            keys: ['f', 'j'],
        },
        correctResponses: [(group.equal ? 'j' : 'f')],
        description: group.patterns.props.src
    })

    // Blank
    config_list1.push({
        type: TRIALS.BLANK
    })
}

const config_list2: ConfigType[] = []

// List 1
for (let group of patterns[1]) {
    // Comparisons
    config_list2.push({
        type: TRIALS.STIMULUS,
        stimulus: group.patterns,
        response: {
            type: 'key',
            keys: ['f', 'j'],
        },
        correctResponses: [(group.equal ? 'j' : 'f')],
        description: group.patterns.props.src
    })

    // Blank
    config_list2.push({
        type: TRIALS.BLANK
    })
}

export {config_training, config_list1, config_list2}