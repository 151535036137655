const equations_training = [
    [
        {equation: '(9 ÷ 3) - 2 = 2', true: false, result: '2'}, // 1
        {equation: '(8 ÷ 4) - 1 = 1', true: true, result: '1'}, // 2
    ],
    [
        {equation: '(6 ÷ 2) + 1 = 4', true: true, result: '4'}, // 3
        {equation: '(6 x 3) - 2 = 11', true: false, result: '11'}, // 4
        {equation: '(4 x 2) + 1 = 9', true: true, result: '9'}, // 5
    ],
    [
        {equation: '(10 ÷ 2) + 4 = 9', true: true, result: '9'}, //6
        {equation: '(2 + 3) + 3 = 8', true: true, result: '8'}, // 7
        {equation: '(2 + 7) + 4 = 12', true: false, result: '12'}, // 8
        {equation: '(3 - 1) + 1 = 1', true: false, result: '1'}, // 9
    ]
]

const equations = [
    [
        {equation: '(10 ÷ 2) - 3 = 2', true: true, result: '2'}, // 1
        {equation: '(10 ÷ 10) - 1 = 3', true: false, result: '3'}, // 2
    ],
    [
        {equation: '(7 ÷ 1) + 2 = 7', true: false, result: '7'}, // 3
        {equation: '(3 ÷ 1) - 2 = 3', true: false, result: '3'}, // 4
    ],
    [
        {equation: '(2 x 1) - 1 = 1', true: true, result: '1'}, // 5
        {equation: '(10 ÷ 1) + 3 = 13', true: true, result: '13'}, // 6
    ],
    [
        {equation: '(9 x 2) + 1 = 18', true: false, result: '18'}, // 7
        {equation: '(9 ÷ 1) - 7 = 4', true: false, result: '4'}, // 8
        {equation: '(8 x 4) - 2 = 32', true: false, result: '32'}, // 9
    ],
    [
        {equation: '(9 x 3) - 3 = 24', true: true, result: '24'}, // 10
        {equation: '(4 ÷ 1) + 1 = 4', true: false, result: '4'}, // 11
        {equation: '(10 ÷ 1) - 1 = 9', true: true, result: '9'}, //12
    ],
    [
        {equation: '(8 x 4) + 2 = 34', true: true, result: '34'}, // 13
        {equation: '(6 x 3) + 2 = 21', true: false, result: '21'}, // 14
        {equation: '(6 ÷ 3) + 2 = 5', true: false, result: '5'}, // 15
    ],
    [
        {equation: '(6 x 2) - 3 = 10', true: false, result: '10'}, // 16
        {equation: '(8 ÷ 2) + 4 = 6', true: false, result: '6'}, // 17
        {equation: '(8 ÷ 2) - 1 = 3', true: true, result: '3'}, // 18
        {equation: '(9 ÷ 1) - 5 = 4', true: true, result: '4'}, // 19
    ],
    [
        {equation: '(6 ÷ 2) - 2 = 2', true: false, result: '2'}, // 20
        {equation: '(7 x 2) - 1 = 13', true: true, result: '13'}, // 21
        {equation: '(6 x 2) - 2 = 10', true: true, result: '10'}, // 22
        {equation: '(2 x 2) + 1 = 4', true: false, result: '4'}, // 23
    ],
    [
        {equation: '(7 x 1) + 6 = 13', true: true, result: '13'}, // 24
        {equation: '(3 ÷ 1) + 3 = 6', true: true, result: '6'}, // 25
        {equation: '(10 ÷ 1) + 1 = 10', true: false, result: '10'}, // 26
        {equation: '(4 x 4) + 1 = 17', true: true, result: '17'}, // 27
    ],
    [
        {equation: '(3 x 3) - 1 = 8', true: true, result: '8'}, // 28
        {equation: '(3 x 1) + 2 = 6', true: false, result: '6'}, // 29
        {equation: '(4 ÷ 2) + 1 = 3', true: true, result: '3'}, // 30
        {equation: '(5 ÷ 5) + 1 = 2', true: true, result: '2'}, // 31
        {equation: '(2 x 3) + 1 = 4', true: false, result: '4'}, // 32
    ],
    [
        {equation: '(9 ÷ 3) - 2 = 1', true: true, result: '1'}, // 33
        {equation: '(10 ÷ 2) - 4 = 3', true: false, result: '3'}, // 34
        {equation: '(5 ÷ 1) + 4 = 9', true: true, result: '9'}, // 35
        {equation: '(10 x 2) + 3 = 23', true: true, result: '23'}, // 36
        {equation: '(7 ÷ 1) + 6 = 12', true: false, result: '12'}, // 37
    ],
    [
        {equation: '(3 x 2) + 1 = 7', true: true, result: '7'}, // 38
        {equation: '(6 x 4) + 1 = 25', true: true, result: '25'}, // 39
        {equation: '(9 ÷ 3) - 1 = 2', true: true, result: '2'}, // 40
        {equation: '(8 ÷ 1) - 6 = 4', true: false, result: '4'}, // 41
        {equation: '(9 x 1) + 9 = 1', true: false, result: '1'}, // 42
    ]
]

export {equations_training, equations}