import React, { useState, useEffect, Dispatch, SetStateAction, useContext } from 'react'
import Container from 'react-bootstrap/Container'
import { v4 as uuid } from 'uuid'
import shuffle from 'shuffle-array'

import DataType from '../../../types/DataType'
import ConfigType from '../../../types/ConfigType'
import { FirebaseContext } from '../../Firebase'
import { Loading, Message } from '../../Layout'
import Stimulus from '../Stimulus'
import AudioElement from '../AudioElement'

import { INSTRUMENTS, TRIALS } from '../../../constants/instruments'

import '../index.css'
import Blank from '../Blank'

function PrimingTask(props: {
    id: string,
    name: string,
    show: boolean,
    data: DataType[], 
    experimentId: string,
    addData: Dispatch<SetStateAction<DataType[]>>,
    next: (finish?: boolean) => void,
    hideButton?: boolean,
    language: 'br'|'en'
}) {
  const [finish, setFinish] = useState(false)
  const [options, setOptions] = useState([] as {prime: {text?: string, audio?: string, img?: string}, target: {text?: string, audio?: string, img?: string}}[])
  const [config, setConfig] = useState([] as ConfigType[])
  const [keys, setKeys] = useState([] as string[])
  const [show, setShow] = useState([] as boolean[])
  const [loading, setLoading] = useState(true)
  const firebase = useContext(FirebaseContext)

  const handleNext = () => {
      if (!show[show.length - 1]) {
          let setTrue = false

          setShow(show.map((s: boolean) => {
              if (s) {
                  setTrue = true
                  return false
              }

              if (setTrue) {
                  setTrue = false
                  return true
              }

              return false
          }))
      } else {
          setFinish(true)
      }
  }

  const handleAddData = (newData: DataType) => {
      props.addData(data => {
          return [...data, newData]
      })
  }

  useEffect(() => {
      firebase?.experiment(props.experimentId).once(
          'value', snapshot => {
              setOptions(
                  snapshot.val().instruments.filter(
                      (i: any) => i.id === props.id)[0].options
                  )
          }
      )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
      const config: ConfigType[] = []
      const shuffledOptions = shuffle([...options])

      if (shuffledOptions && shuffledOptions.length > 0) {
        shuffledOptions.forEach(o => {
            config.push({
                type: TRIALS.BLANK
            })

            if (o.prime.text) {
                config.push({
                    type: TRIALS.TEXT,
                    timeout: 1000,
                    stimulus: o.prime.text,
                })
            } else if (o.prime.audio) {
                config.push({
                    type: TRIALS.AUDIO,
                    timeout: 1000,
                    stimulus: o.prime.audio
                })
            } else if (o.prime.img) {
                config.push({
                    type: TRIALS.IMAGE,
                    timeout: 1000,
                    stimulus: o.prime.text
                })
            }
            
            if (o.target.text) {
                config.push({
                    type: TRIALS.TEXT,
                    stimulus: o.target.text
                })
            } else if (o.target.audio) {
                config.push({
                    type: TRIALS.AUDIO,
                    stimulus: o.target.audio
                })
            } else if (o.target.img) {
                config.push({
                    type: TRIALS.IMAGE,
                    stimulus: o.target.text
                })
            }
        })
      } else {
        config.push({
            type: TRIALS.TEXT,
            prompt: <Message warning='O instrumento não foi configurado corretamente.' />
        })
      }

      setConfig(config)
      setKeys(config.map(() => uuid()))
      setShow(config.map((c: ConfigType, i: number) => i === 0 ? true : false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  useEffect(() => {
    setLoading(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show, options])

  useEffect(() => {
      if (finish) {
          props.next()
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finish])

  return (
      !props.show ? null :
      loading ? <Loading /> :
        <Container 
            className='PrimingTask instrument-container d-flex flex-column justify-content-center align-items-center position-relative'
            fluid>

            {
                config.map((p: ConfigType, i: number) => {
                    switch (p.type) {
                        case TRIALS.TEXT:
                            return (
                                <Stimulus
                                    key={keys[i]}
                                    show={show[i]}
                                    instrument={INSTRUMENTS.PRIMINGTASK}
                                    instrumentId={props.id}
                                    instrumentName={props.name}
                                    response={{type: 'key', keys: ['0', '1']}}
                                    timeout={p.timeout}
                                    data={props.data}
                                    addData={handleAddData}
                                    next={handleNext}
                                    language={props.language}>
                                    
                                    {p.stimulus}
                                </Stimulus>
                            )
                        case TRIALS.AUDIO:
                            return (
                                <AudioElement
                                    key={keys[i]}
                                    show={show[i]}
                                    instrument={INSTRUMENTS.PRIMINGTASK}
                                    instrumentId={props.id}
                                    url={p.stimulus as string}
                                    data={props.data}
                                    addData={handleAddData}
                                    next={handleNext} />
                            )
                        case TRIALS.BLANK:
                            return (
                                <Blank
                                    key={keys[i]}
                                    show={show[i]}
                                    instrument={INSTRUMENTS.PRIMINGTASK}
                                    instrumentId={props.id}
                                    instrumentName={props.name}
                                    response={{type: 'key', keys: [' ']}}
                                    addData={handleAddData}
                                    next={handleNext}
                                    fixationPoint={true}
                                    timeout={30 * 60 * 1000} />
                            )
                        default:
                            return <div key={keys[i]}></div>
                    }
                })
            }
        </Container>
  )
}

export default PrimingTask