import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import { v4 as uuid } from 'uuid'

import DataType from '../../../types/DataType'
import ConfigType from '../../../types/ConfigType'
import Text from '../Text'
import Stimulus from '../Stimulus'
import Blank from '../Blank'
import Question from '../Question'
import { TRIALS, INSTRUMENTS } from '../../../constants/instruments'

import getConfig from './config'
import AudioElement from '../AudioElement'

function DRM(props: {
    id: string,
    name: string,
    show: boolean,
    data: DataType[], 
    addData: (data: any) => void,
    next: (finish?: boolean) => void,
    list: string,
    hideButton?: boolean,
    language: 'br'|'en'
}) {
    const [config, setConfig] = useState(getConfig(props.list))
    const [show, setShow] = useState(config.map((c: ConfigType, i: number) => i === 0 ? true : false))
    const [keys] = useState(config.map(() => uuid()))
    const [finish, setFinish] = useState(false)
    const handleAddData = (newData: DataType) => {
        props.addData((data: any) => [...data, newData])
    }

    const handleNext = () => {
        if (!show[show.length - 1]) {
            let setTrue = false

            setShow(show.map((s: boolean) => {
                if (s) {
                    setTrue = true
                    return false
                }

                if (setTrue) {
                    setTrue = false
                    return true
                }

                return false
            }))
        } else {
            setFinish(true)
        }
    }

    useEffect(() => {
        setConfig(getConfig(props.list))
    }, [props.list])

    useEffect(() => {
        if (props.show && finish) {
            props.addData((data: any) => {
                return [...data, {instrument: INSTRUMENTS.DRM, instrumentId: props.id, type: 'Summary', response: '', correct: [], time: 0}]
            })
            props.next()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finish])

    return (
        !props.show ? null :
        <Container className='instrument-container d-flex justify-content-center align-items-center position-relative' fluid>
            {
                config.map((p: ConfigType, i: number) => {
                    switch (p.type) {
                        case TRIALS.TEXT:
                            return <Text 
                                        key={keys[i]}
                                        show={show[i]}
                                        instrument={INSTRUMENTS.DRM}
                                        instrumentId={props.id}
                                        instrumentName={props.name}
                                        addData={handleAddData}
                                        next={handleNext}
                                        language={props.language}>
                                            
                                        {p.prompt}
                                    </Text>
                        case TRIALS.STIMULUS:
                            return <Stimulus 
                                        key={keys[i]}
                                        show={show[i]}
                                        instrument={INSTRUMENTS.DRM}
                                        instrumentId={props.id}
                                        instrumentName={props.name}
                                        response={p.response}
                                        data={props.data}
                                        addData={handleAddData} 
                                        next={handleNext}
                                        timeout={p.timeout}
                                        training={p.training}
                                        prompt={p.prompt}
                                        language={props.language}>
                                            
                                        {p.stimulus}
                                    </Stimulus>
                        case TRIALS.BLANK:
                            return (
                                <Blank 
                                    key={keys[i]}
                                    show={show[i]}
                                    instrument={INSTRUMENTS.DRM} 
                                    instrumentId={props.id}
                                    instrumentName={props.name}
                                    response={p.response}
                                    addData={handleAddData}
                                    next={handleNext}
                                    timeout={p.timeout}
                                    fixationPoint={p.fixationPoint}/>
                            )
                        case TRIALS.QUESTION:
                            return (
                                <Question 
                                    key={keys[i]}
                                    show={show[i]}
                                    instrument={INSTRUMENTS.DRM}
                                    instrumentId={props.id}
                                    instrumentName={props.name}
                                    questions={p.questions} 
                                    correctResponses={p.correctResponses} 
                                    title={p.title} 
                                    addData={handleAddData}
                                    next={handleNext}
                                    timeout={p.timeout}
                                    training={p.training}
                                    layout={p.layout}
                                    language={props.language}/>
                            )
                            case TRIALS.AUDIO:
                                return (
                                    <AudioElement 
                                        key={keys[i]}
                                        show={show[i]}
                                        instrument={INSTRUMENTS.DRM}
                                        instrumentId={props.id}
                                        url={p.url as string}
                                        fixationPoint={p.fixationPoint}
                                        data={props.data}
                                        addData={handleAddData}
                                        next={handleNext}/>
                                )
                        default:
                            return <div></div>
                    }
                })
            }
        </Container>
    )
}

export default DRM