import React, { useEffect, useState, useContext, ChangeEvent } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import swal from 'sweetalert'
import { v4 as uuid } from 'uuid'

import { FirebaseContext } from '../../Firebase'
import { IconButton, ICONS, Loading } from '../../Layout'
import DataType from '../../../types/DataType'
import ExperimentType from '../../../types/ExperimentType'
import InstrumentType from '../../../types/InstrumentType'

import * as ROUTES from '../../../constants/routes'
import { INSTRUMENTS, TRIALS, FIELDS } from '../../../constants/instruments'

export type DatasetType = {
    [key: string]: {
        data: DataType[],
        experiment: string,
        experimentId: string,
        participantId: string,
        trash?: boolean,
    }
}

function Experiment(props: {loading?: boolean}) {
    const match = useRouteMatch<{id: string}>(ROUTES.RESEARCHER_EXPERIMENTS_DETAILS)
    const [experiment, setExperiment] = useState(null as ExperimentType|null)
    const [loading, setLoading] = useState(true)
    const [loadingData, setLoadingData] = useState(true)
    const [id, setId] = useState(match?.params.id as string)
    const [dataset, setDataset] = useState({} as DatasetType)
    const [linkCsv, setLinkCsv] = useState('')
    const [linkJson, setLinkJson] = useState('')
    const [selectedInstrument, setSelectedInstrument] = useState('')
    const [language, setLanguage] = useState('' as 'br'|'en'|'')
    const [timer, setTimer] = useState<number>()
    const [titleReadOnly, setTitleReadOnly] = useState(true);
    const firebase = useContext(FirebaseContext)
    const history = useHistory()

    const handleDeleteExperiment = () => {
        swal({
            title: 'Confirmar exclusão do experimento?',
            text: 'Esta ação não pode ser desfeita',
            icon: 'warning',
            buttons: ['Cancelar', 'Confirmar'],
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete) {
                firebase?.experiment(id).remove()
                history.push(ROUTES.RESEARCHER)
            }
        });
    }

    const handleDeleteExperimentData = () => {
        swal({
            title: 'Enviar dados deste experimento para a lixeira?',
            text: 'Dados na lixeira não são contabilizados e não são salvos em CSV, mas podem ser verificados no JSON.',
            icon: 'warning',
            buttons: ['Cancelar', 'Confirmar'],
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete) {
                firebase?.dataset().orderByChild('experimentId').equalTo(id).once('value', snapshot => {
                    snapshot.forEach(child => {
                        if (child.key) {
                            firebase.dataset().child(child.key).child('trash').set(true)
                        }
                    })
                })
            }
        });
    }

    const handleDeleteInstrument = (instrumentId: string, index: string) => {
        swal({
            title: "Confirmar exclusão do instrumento?",
            text: "Esta ação não pode ser desfeita",
            icon: 'warning',
            buttons: ['Cancelar', 'Confirmar'],
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete) {
                const newInstrumentsList = experiment?.instruments.filter((i: any) => i.id !== instrumentId)
                
                firebase?.experiment(id).child('instruments').set(newInstrumentsList).then(() => {
                    if (experiment) {
                        const newExperiment = {...experiment}
        
                        newExperiment.instruments = newInstrumentsList || []
                        
                        setExperiment(newExperiment)
                    }
                })
            }
        })
    }

    const handleShiftUp = (index: number) => {
        const reversedElements = experiment?.instruments.slice(index - 1, index + 1).reverse()
        const updatedInstrumentsList = experiment?.instruments.filter((i: any, iIndex: number) => iIndex !== index - 1 && iIndex !== index)
        
        updatedInstrumentsList?.splice(index - 1, 0, ...(reversedElements || []))
        
        firebase?.experiment(id).child('instruments').set(updatedInstrumentsList).then(() => {
            if (experiment) {
                const newExperiment = {...experiment}

                newExperiment.instruments = updatedInstrumentsList || []
                
                setExperiment(newExperiment)
            }
        })
    }

    const handleShiftDown = (index: number) => {
        const reversedElements = experiment?.instruments.slice(index, index + 2).reverse()
        const updatedInstrumentsList = experiment?.instruments.filter((i: string, iIndex: number) => iIndex !== index && iIndex !== index + 1)

        updatedInstrumentsList?.splice(index, 0, ...(reversedElements || []))
        
        firebase?.experiment(id).child('instruments').set(updatedInstrumentsList).then(() => {
            if (experiment) {
                const newExperiment = {...experiment}

                newExperiment.instruments = updatedInstrumentsList || []
                
                setExperiment(newExperiment)
            }
        })
    }

    const handleLink = (index: number) => {
        const updatedInstrumentsList = experiment?.instruments.map((i: any, iIndex: number) => {
            return (iIndex === index) ? {...i, linked: !i.linked} : i
        })

        firebase?.experiment(id).child('instruments').set(updatedInstrumentsList).then(() => {
            if (experiment) {
                const newExperiment = {...experiment}

                newExperiment.instruments = updatedInstrumentsList || []
                
                setExperiment(newExperiment)
            }
        })
    }

    const handleAddInstrument = () => {
        if (selectedInstrument) {
            let options = {}

            if (selectedInstrument === INSTRUMENTS.SURVEY) {
                options = {
                    questions: [
                        {prompt: 'Nome', type: FIELDS.INPUT}
                    ]
                }
            }

            const newInstruments = {...experiment}.instruments
    
            newInstruments?.push({id: uuid(), type: selectedInstrument, options})
            
            firebase?.experiment(id).child('instruments').set(newInstruments).then(() => {
                if (experiment) {
                    const newExperiment = {...experiment}
    
                    newExperiment.instruments = newInstruments
                    
                    setExperiment(newExperiment)
                }
            })
        }
    }

    const handleCopyInstrument = (instrumentId: string) => {
        const newInstruments = {...experiment}.instruments
        const instrumentCopy = newInstruments.filter((i: InstrumentType) => i.id === instrumentId)[0]

        newInstruments?.push({
            ...instrumentCopy, id: uuid(), 
            name: instrumentCopy.name ? `Cópia de ${instrumentCopy.name}` : 'Cópia de página sem nome'
        })

        firebase?.experiment(id).child('instruments').set(newInstruments).then(() => {
            if (experiment) {
                const newExperiment = {...experiment}

                newExperiment.instruments = newInstruments
                
                setExperiment(newExperiment)
            }
        })
    }

    const handleChangeLanguage = (e: ChangeEvent<HTMLInputElement>) => {
        firebase?.experiment(id).child('language').set(e.target.value)
        setLanguage(e.target.value === 'br' || e.target.value === 'en' ? e.target.value : '')
    }

    const handleChangeTimer = (e: ChangeEvent<any>) => {
        firebase?.experiment(id).child('timer').set(e.target.value)
        setTimer(e.target.value)
    }

    const handleChangeTitle = (e: ChangeEvent<any>) => {
        const newExperiment = experiment
        if (newExperiment) {
            firebase?.experiment(id).child('title').set(e.target.value)

            newExperiment.title = e.target.value

            setExperiment(newExperiment)
        }
    }

    // Change language
    useEffect(() => {
        if (language !== '') {
            firebase?.experiment(id).child('language').set(language)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language])

    useEffect(() => {
        if (timer) {
            firebase?.experiment(id).child('timer').set(timer)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timer])

    useEffect(() => {
        if (experiment && id === match?.params.id) {
            return
        } else {
            setId(match?.params.id as string)
        }

        firebase?.experiment(match?.params.id as string).on('value', (snapshot) => {
            const experiment = snapshot.val()

            if (experiment) {
                experiment.uid = snapshot.key as string

                firebase?.dataset().orderByChild('experimentId').equalTo(experiment.uid as string).on('value', snapshot => {
                    if (snapshot.val()) {
                        const dataset: DatasetType = snapshot.val()
                    
                        setDataset(dataset)
                    } else {
                        setDataset({})
                    }
                })
            } else {
                history.push(ROUTES.HOME)
                history.push(ROUTES.RESEARCHER)
            }

            if (experiment.timer) {
                setTimer(experiment.timer)
            }

            setExperiment(experiment)
            setLanguage(experiment.language)
            setLoading(false)
        })

        return () => {
            firebase?.experiment(match?.params.id as string).off()
        }
    }, [experiment, match, firebase, id, history])

    useEffect(() => {
        const csv: [string[]] = [['Participant', 'Instrument Type', 'Instrument Name', 'Page Type', 'Response', 'Correct', 'Time']]

        Object.values(dataset).forEach(d => {
            if (!d.trash) {
                const row = [d.participantId]

                d.data.forEach(r => {
                    csv.push([...row,
                        r.instrument as string,
                        r.instrumentName as string, 
                        r.type as string, 
                        JSON.stringify(r.response), 
                        JSON.stringify(r.correct), 
                        JSON.stringify(r.time)
                    ])
                })
            }
        })

        const csvBlob = new Blob(
            [csv.map(r => r.join('\t')).join('\r\n')], 
            {type: 'text/csv'}
        )

        setLinkCsv(window.URL.createObjectURL(csvBlob))
        setLinkJson('data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(dataset)))
        setLoadingData(false)
    }, [dataset])

    return (
        loading || props.loading || !experiment
        ? 
        <Loading/>
        : 
        <Container>
            <Row>
                <Col>
                    <h2>
                    <Form.Control 
                        as='textarea'
                        type='text' 
                        value={experiment?.title}
                        onChange={handleChangeTitle}
                        readOnly={titleReadOnly}
                        onClick={() => setTitleReadOnly(false)}
                        onBlur={() => setTitleReadOnly(true)} 
                        style={{resize: titleReadOnly ? 'none' : 'unset'}} />
                    </h2>
                    <p className='my-3'>
                        Link: <a 
                            href={`https://${window.location.host}/experimento/${experiment?.uid}`} 
                            title='Link para execução do experimento'> 
                            
                            {`https://${window.location.host}/experimento/${experiment?.uid}`}
                        </a>
                    </p>
                    <p>
                        O experimento já foi completado <strong>{Object.values(dataset).filter(d => !d.trash).length}</strong> vezes
                    </p>
                    <h3 className='mt-5'>
                        Língua: &nbsp;
                        <ButtonGroup toggle title='Língua do texto das instruções básicas'>
                            <ToggleButton
                                id='br' 
                                name='br'
                                type='radio'
                                variant='outline-primary'
                                value='br'
                                onChange={e => handleChangeLanguage(e)}
                                checked={language === 'br'}>
                                    Português Brasileiro
                            </ToggleButton>
                            <ToggleButton
                                id='en' 
                                name='en'
                                type='radio'
                                variant='outline-primary'
                                value='en'
                                onChange={e => handleChangeLanguage(e)}
                                checked={language === 'en'}>
                                    Inglês
                            </ToggleButton>
                        </ButtonGroup> 
                    </h3>
                    <h3 className='mt-5'>
                        <Form inline>
                            <Form.Group>
                                <Form.Label>Timer (ms): &nbsp;</Form.Label>
                                <Form.Control 
                                    type='number' 
                                    min={0} 
                                    step={100} 
                                    value={timer} 
                                    onChange={e => handleChangeTimer(e)} />
                            </Form.Group>
                        </Form>
                    </h3>
                    <h3 className='mt-5'>Instrumentos</h3>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>Ordem</th>
                                <th>Tipo/Nome</th>
                                <th>Opções</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                experiment?.instruments.map((i: any, index: number, a: any[]) => {
                                    return (
                                        <React.Fragment key={i.id}>
                                            <tr>
                                                <td style={{minWidth: '130px'}}>
                                                    <ButtonGroup className='mr-1'>
                                                        <IconButton 
                                                            className='hide-tip'
                                                            disabled={index === 0}
                                                            icon={ICONS.UP} 
                                                            size='sm' 
                                                            variant='outline-info'
                                                            onClick={() => handleShiftUp(index)}/>
                                                        <IconButton 
                                                            className='hide-tip'
                                                            disabled={index === a.length - 1}
                                                            icon={ICONS.DOWN} 
                                                            size='sm' 
                                                            variant='outline-info'
                                                            onClick={() => handleShiftDown(index)}/>
                                                    </ButtonGroup>
                                                    <ButtonGroup className='mr-1'>
                                                        <IconButton
                                                            className='hide-tip'
                                                            disabled={index === 0}
                                                            icon={ICONS.ANCHOR}
                                                            size='sm'
                                                            variant={!i.linked ? 'outline-info' : 'info'}
                                                            onClick={() => handleLink(index)}/>
                                                    </ButtonGroup>
                                                    {index + 1}º
                                                </td>
                                                <td>
                                                    <span className='small'>{i.type}</span><br/>
                                                    {i.name}
                                                </td>
                                                <td>
                                                    <ButtonGroup>
                                                        {
                                                            (
                                                                i.type === INSTRUMENTS.SURVEY ||
                                                                i.type === INSTRUMENTS.TCLE ||
                                                                i.type === INSTRUMENTS.INSTRUCTIONPAGE ||
                                                                i.type === INSTRUMENTS.TEXT ||
                                                                i.type === INSTRUMENTS.SONGSELECTOR ||
                                                                i.type === INSTRUMENTS.DRM ||
                                                                i.type === INSTRUMENTS.READINGLOGGER ||
                                                                i.type === INSTRUMENTS.PRIMINGTASK ||
                                                                i.type === INSTRUMENTS.LETCOM ||
                                                                i.type === INSTRUMENTS.LETCOMEnglish ||
                                                                i.type === INSTRUMENTS.PATCOM ||
                                                                i.type === INSTRUMENTS.PATCOMEnglish ||
                                                                i.type === INSTRUMENTS.WORDBYWORD ||
                                                                i.type === INSTRUMENTS.LEXICALDECISION
                                                            )
                                                            && 
                                                            <IconButton 
                                                                icon={ICONS.EDIT} 
                                                                size='sm' 
                                                                variant='outline-primary'
                                                                onClick={() => history.push(`/pesquisador/experimentos/${experiment?.uid}/${index}`)}> 

                                                                Editar
                                                            </IconButton>
                                                        }
                                                        <IconButton
                                                            icon={ICONS.COPY}
                                                            size='sm'
                                                            variant='outline-primary'
                                                            onClick={() => handleCopyInstrument(i.id)}>

                                                            Copiar
                                                        </IconButton>
                                                        <IconButton
                                                            icon={ICONS.DELETE}
                                                            size='sm'
                                                            variant='outline-danger'
                                                            onClick={() => handleDeleteInstrument(i.id, index.toString())}>

                                                            Excluir
                                                        </IconButton>
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    <Form>
                        <Form.Group className='w-100'>
                            <Form.Label>Adicionar instrumento</Form.Label>
                            <InputGroup className='w-100'>    
                                <Form.Control 
                                    as='select'
                                    value={selectedInstrument}
                                    onChange={e => {setSelectedInstrument(e.target.value)}}>
                                    
                                    <option value=''></option>
                                    {
                                        Object.values(INSTRUMENTS).map(i => (
                                            <option key={uuid()} value={i}>{i}</option>
                                        ))
                                    }
                                </Form.Control>
                                <InputGroup.Append>
                                    <IconButton                               
                                        icon={ICONS.PLUS}
                                        variant='success'
                                        type='button'
                                        onClick={handleAddInstrument}/>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>
                    </Form>
                    <h3 className='mt-5'>Resumo dos dados</h3>
                    <ol>
                        {
                            experiment?.instruments.map((i: any, index: number) => {
                                
                                return (
                                    <React.Fragment key={i.id}>
                                        <li className='my-3'>
                                            {i.type}
                                                {
                                                    i.type === INSTRUMENTS.TCLE &&
                                                    <>
                                                         : aceito&nbsp;
                                                        <strong>
                                                            {
                                                                Object.values(dataset).map(d => {
                                                                    return d.data.filter((r: DataType) => {
                                                                        if (r.instrumentId === i.id && r.type === TRIALS.QUESTION) {
                                                                            return new RegExp('concordo em participar', 'i').test(r.response[0].answer) &&
                                                                                !new RegExp('não concordo em participar', 'i').test(r.response[0].answer)
                                                                        }
                                                                        else {
                                                                            return null
                                                                        }
                                                                    })
                                                                }).length
                                                            }
                                                        </strong>
                                                        &nbsp;vezes
                                                    </>
                                                    
                                                }
                                        </li>
                                    </React.Fragment>
                                )
                            })
                        }
                    </ol>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ButtonGroup className='mt-5'>
                        <IconButton 
                            icon={ICONS.DOWNLOAD}
                            variant='primary'
                            type='link'
                            href={linkCsv}
                            download={
                                `${experiment.title.replace(/[^a-z0-9-]+/gi, '_').replace(/^_|_$/g, '').toLowerCase()}_data_${new Date().getTime()}.csv`
                            }>

                            {loadingData ? 'Carrgando...' : 'Baixar CSV'}
                        </IconButton>
                        <IconButton 
                            icon={ICONS.DOWNLOAD}
                            variant='primary'
                            type='link'
                            href={linkJson}
                            download={
                                `${experiment.title.replace(/[^a-z0-9-]+/gi, '_').replace(/^_|_$/g, '').toLowerCase()}_data_${new Date().getTime()}.json`
                            }>

                            {loadingData ? 'Carrgando...' : 'Baixar JSON'}
                        </IconButton>
                        <IconButton variant='warning' icon={ICONS.DELETE} onClick={handleDeleteExperimentData}>Enviar dados para a lixeira</IconButton>
                        <IconButton variant='danger' icon={ICONS.DELETE} onClick={handleDeleteExperiment}>Excluir experimento</IconButton>
                    </ButtonGroup>
                </Col>
            </Row>
        </Container>
    )
}

export default Experiment