import React from 'react'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'

function Message(props:{error?: firebase.auth.Error|null, warning?: string}) {
    let translatedError = props.error
    
    if (props.error) {
        console.error(props.error)

        switch (props.error.code) {
            case 'auth/weak-password':
                translatedError = {...props.error, message: 'A senha deve ter pelo menos 6 caracteres'}
                break
            case 'auth/email-already-in-use':
                translatedError = {...props.error, message: 'Email já utilizado em outra conta'}
                break
            case 'auth/wrong-password':
                translatedError = {...props.error, message: 'Senha inválida. Tente entrar com o Google ou redefinir a senha.'}
                break
            case 'auth/user-not-found':
                translatedError = {...props.error, message: 'Não existe usuário com esse email'}
                break
            case 'auth/invalid-email':
                translatedError = {...props.error, message: 'Email inválido'}
                break
            case 'auth/popup-closed-by-user':
                translatedError = null //{...props.error, message: 'A janela do Google foi fechada antes do login ter sido efetuado'}
                break
            case 'auth/network-request-failed':
                translatedError = {...props.error, message: 'Erro de rede. Verifique sua conexão com a internet e tente novamente.'}
                break
            case 'auth/cancelled-popup-request':
                translatedError = null //{...props.error, message: 'Já existe outra janela do Google aberta.'}
        }
    }
    
    return (
        <Form.Text>
            {
                translatedError && 
                <Alert variant='danger' className='my-3'>{translatedError?.message}</Alert>
            }
            {
                props.warning &&
                <Alert variant='warning' className='my-3'>{props.warning}</Alert>
            }
        </Form.Text>)
}

export default Message