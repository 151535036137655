import React, { useContext, useState } from 'react'
import Form from 'react-bootstrap/Form'
import { Link, useHistory } from 'react-router-dom'

import { FirebaseContext } from '../../Firebase'
import Message from '../../Layout/Message'
import { IconButton, ICONS } from '../../Layout'

import * as ROUTES from '../../../constants/routes'
import * as ROLES from '../../../constants/roles'

function SignInFormBase() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(null as null|firebase.auth.Error)
    const firebase = useContext(FirebaseContext)
    const history = useHistory()
    const isInvalid = password === '' || email === ''

    const handleSubmit = (e:React.FormEvent) => {
        e.preventDefault()

        firebase?.auth.fetchSignInMethodsForEmail(email)
            .then(userCredential => {
                if (userCredential[0] === 'google.com') {
                    setEmail('')
                    setPassword('')
                    handleGoogleSignIn()
                } else {
                    firebase?.doSignInWithEmailAndPassword(email, password)
                        .then((authUser) => {
                            setEmail('')
                            setPassword('')
                            setError(null)

                            history.push(ROUTES.HOME)
                        })
                        .catch((error:firebase.auth.Error) => {
                            setError(error)
                        })
                }
            })
            .catch((error:firebase.auth.Error) => {
                setError(error)
            })
            
    }

    const handleGoogleSignIn = () => {
        firebase?.doSignInWithGoogle()
            .then((socialAuthUser: any) => {
                if (socialAuthUser.additionalUserInfo.isNewUser) {
                    return firebase?.user(socialAuthUser.user.uid)
                        .set({
                            displayName: socialAuthUser.user.displayName,
                            email: socialAuthUser.user.email,
                            roles: {
                                [ROLES.RESEARCHER]: ROLES.RESEARCHER,
                                [ROLES.PARTICIPANT]: ROLES.PARTICIPANT
                            }
                        })
                } else {
                    return firebase?.user(socialAuthUser.user.uid)
                        .update({
                            displayName: socialAuthUser.user.displayName,
                            email: socialAuthUser.user.email
                        })
                }
            })
            .then(() => {
                setError(null)
                history.push(ROUTES.HOME)
            })
            .catch(error => {
                setError(error)
            })
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Text><h1>Entrar</h1></Form.Text>
            <Form.Group className='my-5'>
                <IconButton icon={ICONS.GOOGLE} onClick={handleGoogleSignIn}>Entrar com o Google</IconButton>
            </Form.Group>
            <Form.Text><h5>Entrar com email e senha:</h5></Form.Text>
            <Form.Group controlId='email'>
                <Form.Label>Email</Form.Label>
                <Form.Control 
                    type='email' 
                    placeholder='Informe seu email'
                    value={email}
                    onChange={e => setEmail(e.target.value)}/>
            </Form.Group>
            <Form.Group controlId='password'>
                <Form.Label>Senha</Form.Label>
                <Form.Control 
                    type='password' 
                    placeholder='Informe sua senha'
                    value={password}
                    onChange={e => setPassword(e.target.value)}/>
            </Form.Group>
            <IconButton icon={ICONS.SIGN_IN} type='submit' disabled={isInvalid} className='mr-3'>Entrar</IconButton>
            <p className='mt-3'><Link to={ROUTES.PASSWORD_FORGET}>Esqueceu a senha?</Link></p>
            <Message error={error}></Message>
        </Form>
    )
}

const SignInForm = SignInFormBase

export default SignInForm