import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { AuthUserContext } from '../Session'
import PasswordChange from '../PasswordChange'
import { withAuthorization, withEmailVerification } from '../Session'


function Account() {
    return (
        <AuthUserContext.Consumer>
            {(authUser: any) => {
                return (
                    <>
                        <Container className='my-5 text-center'>
                            <Row>
                                <Col>
                                    <h1>Minha conta</h1>
                                    <h2>{authUser?.displayName}</h2>
                                    <h3>{authUser?.email}</h3>
                                </Col>
                            </Row>
                        </Container>
                        <PasswordChange/>
                    </>
            )}}
        </AuthUserContext.Consumer>
    )
}

const condition = (authUser: any) => !!authUser

export default withAuthorization(condition)(withEmailVerification(Account))