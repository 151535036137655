const words_training = [
    'laje',
    'rama',
    'tato',
    'caju',
    'maca',
    'cipo',
    'pote'
]

export default words_training