import React, { useEffect, useState, useContext } from 'react'
import { Switch, Route, Link, useHistory } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import md5 from 'crypto-js/md5'
import { v4 as uuid} from 'uuid'

import { FirebaseContext } from '../Firebase'
import Experiment from './Experiment'
import { withAuthorization, withEmailVerification, AuthUserContext } from '../Session'
import { Message, IconButton, ICONS, Loading } from '../Layout'
import ExperimentType from '../../types/ExperimentType'
import InstrumentOptions from './InstrumentOptions'

import * as ROLES from '../../constants/roles'
import * as ROUTES from '../../constants/routes'
import { FIELDS, INSTRUMENTS } from '../../constants/instruments'

import './index.css'
import userImage from './user_image.png'

function Researcher() {
    const [experiments, setExperiments] = useState(null as ExperimentType[]|null)
    const [newExperimentName, setNewExperimentName] = useState('')
    const [selectedInstrument, setSelectedInstrument] = useState('')
    const [instruments, setInstruments] = useState([] as any[])
    const [loading, setLoading] = useState(true)
    const [loadingExperiment, setLoadingExperiment] = useState(false)
    const addButtonDisabled = newExperimentName === '' || Object.keys(instruments).length === 0
    const authUser: any = useContext(AuthUserContext)
    const firebase = useContext(FirebaseContext)
    const history = useHistory()

    const handleAddInstrument = (id: string) => {
        if (selectedInstrument) {
            let options = {}

            if (selectedInstrument === INSTRUMENTS.SURVEY) {
                options = {
                    questions: [
                        {prompt: 'Nome', type: FIELDS.INPUT}
                    ]
                }
            }

            setInstruments([...instruments, {id, type: selectedInstrument, options}])
        }
    }

    const handleDeleteInstrument = (id: string) => {
        const newInstruments = instruments.filter(i => i.id !== id)

        setInstruments(newInstruments)
    }

    const handleSubmit = (e: React.FormEvent, authUser: any) => {
        e.preventDefault()

        setLoadingExperiment(true)

        firebase?.experiments().push({
            researcherId: md5(authUser.email).toString(),
            title: newExperimentName,
            instruments: instruments,
            language: 'br'
        }).then((data: any) => {
            setNewExperimentName('')
            setSelectedInstrument('')
            setInstruments([])
            setLoadingExperiment(false)
            history.push(`/pesquisador/experimentos/${data.path.pieces_[1]}`)
        })
    }

    useEffect(() => {
        setLoading(true)
        firebase?.experiments()
            .orderByChild('researcherId')
            .equalTo(md5(authUser.email).toString())
            .on('value', snapshot => {

            const experimentsObject = snapshot.val()
            if (experimentsObject) {
                const experimentsList = Object.keys(experimentsObject).map(k => ({...experimentsObject[k], uid: k}))
            
                setExperiments(experimentsList)
            }

            setLoading(false)
        })

        return () => firebase?.experiments().off()
    }, [firebase, authUser])

    return (
        <Container className='my-5' fluid>
            <AuthUserContext.Consumer>
                {(authUser: any) => (
                    <Row>
                        <Col lg={4} className='bg-light border p-4 d-flex flex-column' style={{overflowX: 'clip'}}>
                            <div className='d-flex'>
                                {
                                    authUser.providerData[0].photoURL
                                    ? 
                                    <img 
                                        src={authUser.providerData[0].photoURL} 
                                        alt='Foto do perfil'
                                        className='img-thumbnail rounded-circle mx-3' />
                                    :
                                    <img
                                        src={userImage}
                                        alt='Foto do perfil'
                                        className='img-thumbnail rounded-circle mx-3' />
                                }
                                <div className='d-flex flex-column justify-content-center'>
                                    <span>{authUser.displayName}</span>
                                    <span>{authUser.email}</span>
                                </div>
                            </div>
                            <h3 className='mt-3'>Processamento de dados</h3>
                            <div className='my-3'>
                                <ul>
                                    <li key={uuid()}>
                                        <Link to={'/processamento/readinglogger'}>ReadingLogger</Link>
                                    </li>
                                    <li key={uuid()}>
                                        <Link to={'/processamento/writinglogger'}>WritingLogger</Link>
                                    </li>
                                </ul>
                            </div>
                            
                            <h3 className='mt-3'>Experimentos</h3>
                            <div className='my-3'>
                                {
                                    experiments
                                    ?
                                    <ul>
                                        {
                                            experiments?.map(e => (
                                                <li key={e.uid}>
                                                    <Link to={`/pesquisador/experimentos/${e.uid}`}>{e.title}</Link>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                    :
                                        loading 
                                        ? <Loading/>
                                        : <Message warning='Você ainda não criou nenhum experimento'/>
                                }
                            </div>
                            
                            <Form onSubmit={e => handleSubmit(e, authUser)} className='p-1'>
                                <Form.Row className='align-items-end'>
                                    <Form.Text>
                                        <h4>Criar novo experimento</h4>
                                    </Form.Text>
                                    <Form.Group className='w-100'>
                                        <Form.Label className='mr-2'>Nome do experimento</Form.Label>
                                        <Form.Control 
                                            type='text' 
                                            className='w-100'
                                            value={newExperimentName}
                                            onChange={e => setNewExperimentName(e.target.value)}/>
                                    </Form.Group>
                                    <Form.Group className='w-100'>
                                        <Form.Label>Adicionar instrumentos</Form.Label>
                                        <InputGroup className='w-100'>    
                                            <Form.Control 
                                                as='select'
                                                value={selectedInstrument}
                                                onChange={e => {setSelectedInstrument(e.target.value)}}>
                                                
                                                <option value=''></option>
                                                {
                                                    Object.values(INSTRUMENTS).map(i => (
                                                        <option key={uuid()} value={i}>{i}</option>
                                                    ))
                                                }
                                            </Form.Control>
                                            <InputGroup.Append>
                                                <IconButton                               
                                                    icon={ICONS.PLUS}
                                                    variant='success'
                                                    type='button'
                                                    onClick={() => {handleAddInstrument(uuid())}}/>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Form.Group>
                                    <ul id='instruments-list'>
                                        {
                                            instruments.map((i: any) => (
                                                <li key={i.id} className='mb-1'>
                                                    {i.type} 
                                                    <IconButton
                                                        icon={ICONS.DELETE}
                                                        variant='danger'
                                                        size='sm'
                                                        className='ml-1'
                                                        onClick={() => {handleDeleteInstrument(i.id)}}/>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                    <Form.Group className='w-100'>
                                        <IconButton 
                                            icon={ICONS.PLUS} 
                                            variant='success' 
                                            type='submit' 
                                            disabled={addButtonDisabled}>
                                                Adicionar
                                            </IconButton>
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        </Col>
                        <Switch>
                            <Route exact path={ROUTES.RESEARCHER}>
                                <Col className='my-4'>
                                    <p className='text-justify'>
                                        Use o menu ao lado para criar um novo 
                                        experimento ou para selecionar um 
                                        experimento criado anteriormente.
                                    </p>
                                    <p className='text-justify'>
                                        Para criar um novo experimento você
                                        só precisa dar um nome e adicionar os
                                        instrumentos necessários na ordem
                                        desejada.
                                    </p>
                                    <p className='text-justify'>
                                        Ao clicar em um experimento já 
                                        existente você tem acesso ao link
                                        de aplicação do instrumento. Basta
                                        enviar o link para os participantes
                                        e aguardar as respostas.
                                    </p>
                                </Col>
                            </Route>
                            <Route exact path={ROUTES.RESEARCHER_EXPERIMENTS_DETAILS}>
                                <Col className='my-4'>
                                    <Experiment loading={loadingExperiment}/>
                                </Col>
                            </Route>
                            <Route exact path={ROUTES.RESEARCHER_EXPERIMENTS_INSTRUMENTS_DETAILS}>
                                <Col className='my-4' style={{overflowX: 'auto', overflowY: 'hidden'}}>
                                    <InstrumentOptions/>
                                </Col>
                            </Route>
                        </Switch>
                    </Row>
                )}
            </AuthUserContext.Consumer>
        </Container>
    )
}

const condition = (authUser: any) => authUser && !!authUser.roles[ROLES.RESEARCHER]

export default withAuthorization(condition)(withEmailVerification(Researcher))