import React, { useContext, useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { FirebaseContext } from '../../Firebase'
import { IconButton, ICONS, Loading, Audio } from '../../Layout'

import * as ROUTES from '../../../constants/routes'

function SongSelectorOptions() {
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState([] as any)
    const [newSong, setNewSong] = useState('')
    const [newSongName, setNewSongName] = useState('')
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true)
    const match = useRouteMatch<{experimentId: string, instrumentId: string}>(ROUTES.RESEARCHER_EXPERIMENTS_INSTRUMENTS_DETAILS)
    const firebase = useContext(FirebaseContext)
    const history = useHistory()
    const addButtonDisabled = !newSong

    const handleRemoveOption = (index: number) => {
        setOptions(options.filter((q: any, i: number) => i !== index))
        setSaveButtonDisabled(false)
    }

    const handleAddSong = () => {
        if (options) {
            setOptions(
                [...options, {song: newSong, name: newSongName}]
            )
        } else {
            setOptions([{song: newSong, name: newSongName}])
        }

        setNewSong('')
        setNewSongName('')
        setSaveButtonDisabled(false)
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        setLoading(true)

        firebase?.instrument(match?.params.experimentId as string, match?.params.instrumentId as string).child('options')
            .set(options).then(() => {
                setSaveButtonDisabled(true)
                history.push(`/pesquisador/experimentos/${match?.params.experimentId}`)
            })
    }

    useEffect(() => {
        firebase?.instrument(match?.params.experimentId as string, match?.params.instrumentId as string).on('value', snapshot => {
            setOptions(snapshot.val().options)
        })

        return () => {
            firebase?.instrument(
                match?.params.experimentId as string, 
                match?.params.instrumentId as string
            ).off()}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        loading ? <Loading/> :
        <>
            <h2>Editar Seletor de Música</h2>
            <Table striped bordered hover responsive className='my-3'>
                <thead>
                    <tr>
                        <th>Música</th>
                        <th>Nome da música</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        options?.map(
                            (o: {song: string, name: string}, i: number) => (
                            <tr key={uuid()}>
                                <td>
                                    <Audio src={o.song} clip/>
                                </td>
                                <td>{o.name}</td>
                                <td className='text-center'>
                                    <IconButton 
                                        icon={ICONS.DELETE} 
                                        variant='danger' 
                                        size='sm'
                                        onClick={() => handleRemoveOption(i)}/>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
            
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Form.Text>
                            <h3>Adicionar música</h3>
                        </Form.Text>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Link da música</Form.Label>
                            <Form.Control 
                                type='text' 
                                value={newSong}
                                onChange={e => setNewSong(e.target.value)}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Nome da música</Form.Label>
                            <InputGroup>
                                <Form.Control 
                                    type='text'
                                    value={newSongName}
                                    onChange={e => setNewSongName(e.target.value)}/>
                                <InputGroup.Append>
                                    <IconButton 
                                        icon={ICONS.PLUS} 
                                        variant='success'
                                        onClick={handleAddSong}
                                        disabled={addButtonDisabled}/>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <IconButton
                            icon={ICONS.BACK}
                            onClick={() => {history.goBack()}}>
                            
                            Voltar
                        </IconButton>
                        <IconButton 
                            type='submit' 
                            icon={ICONS.SAVE}
                            className='ml-1'
                            disabled={saveButtonDisabled}
                            variant={!saveButtonDisabled ? 'warning' : undefined}>
                            
                            Salvar
                        </IconButton>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default SongSelectorOptions