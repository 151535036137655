import React from 'react'
import shuffle from 'shuffle-array'

import ConfigType from '../../../types/ConfigType'
import words_training from './words_training'

import { FIELDS, TRIALS } from '../../../constants/instruments'

const configure = (words: string[]) => {
    const config: ConfigType[] = []

    // Initial Instructions
    config.push({
        type: TRIALS.TEXT,
        prompt: (
            <>
                <h1 className='text-center mb-3'>Word Span</h1>
                <p>
                    Esse teste requer concentração e, por isso, deve ser feito em
                    ambiente tranquilo e sem interrupções. Se não estiver nessas
                    condições no momento, por favor realize o teste em outro momento.
                </p>
                <p className='text-center'>
                    Caso esteja em condições ideiais para realização do teste, clique em continuar
                </p>
            </>
        )
    })

    config.push({
        type: TRIALS.TEXT,
        prompt: (
            <>
                <h1 className='text-center mb-3'>Word Span</h1>
                <p>
                    Nesse teste, você ouvirá algumas palavras. Você terá que tentar 
                    lembrar as palavras na ordem em que elas foram ouvidas. 
                </p>
                <p>
                    O teste é dividido em níveis tornando-se mais desafiador a cada 
                    novo nível, pois você terá que ouvir e tentar lembrar um número 
                    maior de palavras.
                </p>
            </>
        )
    })

    // Instructions - Trainning
    config.push({
        type: TRIALS.TEXT,
        prompt: (
            <>
                <h1 className='text-center mb-3'>
                    Sessão de familiarização - Nível 1 - duas palavras
                </h1>
                <p>
                    Vamos iniciar com uma sessão de familiarização para você entender melhor o teste.
                    Você ouvirá uma palavra. Tente lembrar dessa palavra. Logo em seguida você ouvirá 
                    outra palavra. Tente lembrar as duas palavras, na ordem em que foram ouvidas.
                    Por fim, você terá que digitar as palavras memorizadas na ordem em que foram ouvidas.
                </p>
            </>
        )
    })

    // Training
    shuffle(words_training)

    // Training - Level 1
    for (let i = 0; i < 2; i++) {
        // Word
        config.push({
            type: TRIALS.AUDIO,
            url: `/audio/${words_training[i]}.mp3`,
            fixationPoint: true,
            training: true
        })

        // Blank
        config.push({
            type: TRIALS.BLANK,
            fixationPoint: true,
            timeout: 2000
        })
    }

    // Training - Recall
    config.push({
        type: TRIALS.QUESTION,
        questions: [1, 2].map(() => ({prompt: '', type: FIELDS.INPUT})),
        correctResponses: [words_training[0], words_training[1]],
        training: true
    })

    // Trainning - Instructions 2
    config.push({
        type: TRIALS.TEXT,
        prompt: (
            <>
                <h1 className='text-center mb-3'>
                    Sessão de familiarização - Nível 1 - duas palavras
                </h1>
                <p className='text-center'>
                    Entendeu? Cada nível é composto por dois grupos de palavras. Faça mais uma para completar o Nível 1
                </p>
            </>
        )
    })

    // Training - Level 1
    for (let i = 2; i < 4; i++) {
        // Word
        config.push({
            type: TRIALS.AUDIO,
            url: `/audio/${words_training[i]}.mp3`,
            fixationPoint: true,
            training: true
        })
    
        // Blank
        config.push({
            type: TRIALS.BLANK,
            fixationPoint: true,
            timeout: 2000
        })
    }
        
    // Training - Recall
    config.push({
        type: TRIALS.QUESTION,
        questions: [1, 2].map(() => ({prompt: '', type: FIELDS.INPUT})),
        correctResponses: [words_training[2], words_training[3]],
        training: true
    })

    // Trainning - Instructions 3
    config.push({
        type: TRIALS.TEXT,
        prompt: (
            <>
                <h1 className='text-center mb-3'>
                    Sessão de familiarização - Nível 2 - três palavras
                </h1>
                <p className='text-center'>
                    Para finalizar a sessão de familiarização, faça uma série do Nível 2, com três palavras.
                </p>
            </>
        )
    })

    for (let i = 4; i < 7; i++) {
        // Word
        config.push({
            type: TRIALS.AUDIO,
            url: `/audio/${words_training[i]}.mp3`,
            fixationPoint: true,
            training: true
        })

        // Blank
        config.push({
            type: TRIALS.BLANK,
            fixationPoint: true,
            timeout: 2000
        })
    }

    // Training - Recall
    config.push({
        type: TRIALS.QUESTION,
        questions: [1, 2, 3].map(() => ({prompt: '', type: FIELDS.INPUT})),
        correctResponses: [words_training[4], words_training[5], words_training[6]],
        training: true
    })

    // Trainning - Final instructions
    config.push({
        type: TRIALS.TEXT,
        prompt: (
            <>
                <h1 className='text-center mb-3'>
                    Sessão de familiarização concluída
                </h1>
                <p>
                    Se tiver alguma dúvida, favor entrar em contato com quem 
                    disponibilizou o link para você e só após tirar as dúvidas
                    continue com a tarefa.
                </p>
                <p>
                    Lembre-se que se fechar essa janela ou aba o experimento será reiniciado.
                    Você pode deixar essa janela ou aba aberta, tirar as dúvidas e
                    depois continuar a tarefa desse ponto.
                </p>
                <p>
                    Se optar por começar o teste agora, basta clicar em continuar.
                </p>
            </>
        )
    })

    // Main task
    shuffle(words)

    // Level 1
    // Instructions - Level 1
    config.push({
        type: TRIALS.TEXT,
        prompt: <h1 className='text-center mb-3'>Nível 1 - duas palavras</h1>
    })

    for (let i = 0; i < 70; i++) {
        // Word
        config.push({
            type: TRIALS.AUDIO,
            url: `/audio/${words[i]}.mp3`,
            fixationPoint: true,
            training: true
        })

        // Blank
        config.push({
            type: TRIALS.BLANK,
            fixationPoint: true,
            timeout: 2000
        })

        if (i === 1 || i === 3) {
            // Recall
            config.push({
                type: TRIALS.QUESTION,
                questions: [1, 2].map(() => ({prompt: '', type: FIELDS.INPUT})),
                correctResponses: [
                    words[i - 1], 
                    words[i]
                ]
            })
        }

        if (i === 3) {
            // Instructions - Level 2
            config.push({
                type: TRIALS.TEXT,
                prompt: <h1 className='text-center mb-3'>Nível 2 - três palavras</h1>
            })
        }

        if (i === 6 || i === 9) {
            // Recall
            config.push({
                type: TRIALS.QUESTION,
                questions: [1, 2, 3].map(() => ({prompt: '', type: FIELDS.INPUT})),
                correctResponses: [
                    words[i - 2], 
                    words[i - 1], 
                    words[i]
                ]
            })
        }

        if (i === 9) {
            // Instructions - Level 3
            config.push({
                type: TRIALS.TEXT,
                prompt: <h1 className='text-center mb-3'>Nível 3 - quatro palavras</h1>
            })
        }

        if (i === 13 || i === 17) {
            // Recall
            config.push({
                type: TRIALS.QUESTION,
                questions: [1, 2, 3, 4].map(() => ({prompt: '', type: FIELDS.INPUT})),
                correctResponses: [
                    words[i - 3], 
                    words[i - 2], 
                    words[i - 1], 
                    words[i]
                ]
            })
        }

        if (i === 17) {
            // Instructions - Level 4
            config.push({
                type: TRIALS.TEXT,
                prompt: <h1 className='text-center mb-3'>Nível 4 - cinco palavras</h1>
            })
        }

        if (i === 22 || i === 27) {
            // Recall
            config.push({
                type: TRIALS.QUESTION,
                questions: [1, 2, 3, 4, 5].map(() => ({prompt: '', type: FIELDS.INPUT})),
                correctResponses: [
                    words[i - 4], 
                    words[i - 3], 
                    words[i - 2], 
                    words[i - 1], 
                    words[i]
                ]
            })
        }

        if (i === 27) {
            // Instructions - Level 5
            config.push({
                type: TRIALS.TEXT,
                prompt: <h1 className='text-center mb-3'>Nível 5 - seis palavras</h1>
            })
        }

        if (i === 33 || i === 39) {
            // Recall
            config.push({
                type: TRIALS.QUESTION,
                questions: [1, 2, 3, 4, 5, 6].map(() => ({prompt: '', type: FIELDS.INPUT})),
                correctResponses: [
                    words[i - 5], 
                    words[i - 4], 
                    words[i - 3], 
                    words[i - 2], 
                    words[i - 1], 
                    words[i]
                ]
            })
        }

        if (i === 39) {
            // Instructions - Level 6
            config.push({
                type: TRIALS.TEXT,
                prompt: <h1 className='text-center mb-3'>Nível 6 - sete palavras</h1>
            })
        }

        if (i === 46 || i === 53) {
            // Recall
            config.push({
                type: TRIALS.QUESTION,
                questions: [1, 2, 3, 4, 5, 6, 7].map(() => ({prompt: '', type: FIELDS.INPUT})),
                correctResponses: [
                    words[i - 6], 
                    words[i - 5], 
                    words[i - 4], 
                    words[i - 3], 
                    words[i - 2], 
                    words[i - 1],
                    words[i]
                ]
            })
        }

        if (i === 53) {
            // Instructions - Level 7
            config.push({
                type: TRIALS.TEXT,
                prompt: <h1 className='text-center mb-3'>Nível 7 - oito palavras</h1>
            })
        }

        if (i === 61 || i === 69) {
            // Recall
            config.push({
                type: TRIALS.QUESTION,
                questions: [1, 2, 3, 4, 5, 6, 7, 8].map(() => ({prompt: '', type: FIELDS.INPUT})),
                correctResponses: [
                    words[i - 7], 
                    words[i - 6], 
                    words[i - 5], 
                    words[i - 4], 
                    words[i - 3], 
                    words[i - 2],
                    words[i - 1],
                    words[i]
                ]
            })
        }
    }

    return config
}

export default configure