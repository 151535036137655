import React, { useState, useEffect, useContext } from 'react'
import { useRouteMatch } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import { FirebaseContext} from '../../Firebase'
import { Loading } from '../../Layout'

import * as ROUTES from '../../../constants/routes'

function UserItem(props: {location: {state: any}}) {
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState(props.location.state as any)
    const [participantRole, setParticipantRole] = useState('')
    const [researcherRole, setResearcherRole] = useState('')
    const match = useRouteMatch<{id: string}>(ROUTES.ADMIN_USERS_DETAILS)
    const firebase = useContext(FirebaseContext)

    const handleClick = () => {
        firebase?.doPasswordReset(user.email)
    }

    const handleChangeParticipantRole = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            firebase?.user(match?.params.id as string).child('roles/PARTICIPANT').set('PARTICIPANT')
            setParticipantRole('PARTICIPANT')
        } else {
            firebase?.user(match?.params.id as string).child('roles/PARTICIPANT').remove()
            setParticipantRole('')
        }
    }
    
    const handleChangeResearcherRole = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            firebase?.user(match?.params.id as string).child('roles/RESEARCHER').set('RESEARCHER')
            setResearcherRole('RESEARCHER')
        } else {
            firebase?.user(match?.params.id as string).child('roles/RESEARCHER').remove()
            setResearcherRole('')
        }

    }
    
    useEffect(() => {
        setLoading(true)

        firebase?.user(match?.params.id as string).on('value', (snapshot) => {
            const user = snapshot.val()

            setUser(user)
            setParticipantRole(user.roles.PARTICIPANT)
            setResearcherRole(user.roles.RESEARCHER)
            setLoading(false)
        })

        return () => firebase?.user(match?.params.id as string).off()
    // eslint-disable-next-line
    }, [firebase, participantRole, researcherRole])

    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <h2>Usuários</h2>
                    </Col>
                </Row>
            </Container>
            {
                loading 
                ? <Loading/>
                :
                <Container>
                    <Row>
                        <Col>
                            <h3>Usuário {user?.displayName}</h3>     
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Email: {user?.email}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                Papéis: {
                                    user?.roles && 
                                    <>
                                        <input 
                                            type="checkbox"
                                            value={participantRole}
                                            checked={participantRole === 'PARTICIPANT'} 
                                            onChange={handleChangeParticipantRole} /> Participante &nbsp;
                                        
                                        <input 
                                            type="checkbox"
                                            value={researcherRole}
                                            checked={researcherRole === 'RESEARCHER'}
                                            onChange={handleChangeResearcherRole} /> Pesquisador
                                    </>
                                }
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button variant='warning' type='button' onClick={handleClick}>
                                Enviar link de redefinição de senha
                            </Button>
                        </Col>
                    </Row>
                </Container>
            }
        </>
    )
}

export default UserItem