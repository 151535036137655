import React, { useContext, useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'

import { FirebaseContext } from '../../Firebase'
import { IconButton, ICONS, Message } from '../../Layout'

import * as ROUTES from '../../../constants/routes'
import * as ROLES from '../../../constants/roles'

function ParticipantSignIn() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(null as null|firebase.auth.Error)
    const [language, setLanguage] = useState('')
    const firebase = useContext(FirebaseContext)
    const history = useHistory()
    const isInvalid = password === '' || email === ''
    const match = useRouteMatch<{id: string}>(ROUTES.DATA_COLLECTION_SIGN_IN)

    const handleSubmit = (e:React.FormEvent) => {
        e.preventDefault()

        firebase?.auth.fetchSignInMethodsForEmail(email)
            .then(userCredential => {
                if (userCredential[0] === 'google.com') {
                    setEmail('')
                    setPassword('')
                    handleGoogleSignIn()
                } else {
                    firebase?.doSignInWithEmailAndPassword(email, password)
                        .then((authUser) => {
                            setEmail('')
                            setPassword('')
                            setError(null)

                            history.push(`/experimento/${match?.params.id}`)
                        })
                        .catch((error:firebase.auth.Error) => {
                            setError(error)
                        })
                }
            })
            .catch((error:firebase.auth.Error) => {
                setError(error)
            })
            
    }

    const handleGoogleSignIn = () => {
        firebase?.doSignInWithGoogle()
            .then((socialAuthUser: any) => {
                if (socialAuthUser.additionalUserInfo.isNewUser) {
                    return firebase?.user(socialAuthUser.user.uid)
                        .set({
                            displayName: socialAuthUser.user.displayName,
                            email: socialAuthUser.user.email,
                            roles: {
                                [ROLES.PARTICIPANT]: ROLES.PARTICIPANT
                            }
                        })
                } else {
                    return firebase?.user(socialAuthUser.user.uid)
                        .update({
                            displayName: socialAuthUser.user.displayName,
                            email: socialAuthUser.user.email
                        })
                }
            })
            .then(() => {
                setError(null)
                history.push(`/experimento/${match?.params.id}`)
            })
            .catch(error => {
                setError(error)
            })
    }

    useEffect(() => {
        setLanguage(navigator.language)
    }, [])

    return (
        <Container className='my-5'>
            <Row className='my-3'>
                <Col>
                    <ButtonGroup size='sm'>
                        <Button
                            id='language-1'
                            variant={language === 'pt-BR' ? 'secondary' : 'outline-secondary'}
                            onClick={() => setLanguage('pt-BR')}>
                            Português
                        </Button>
                        <Button
                            id="language-2"
                            variant={language === 'pt-BR' ? 'outline-secondary' : 'secondary'}
                            onClick={() => setLanguage('en')}>
                            English
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col>
                <Form onSubmit={handleSubmit}>
                    <Form.Text><h1>{language === 'pt-BR' ? 'Entrar' : 'Sign in'}</h1></Form.Text>
                    <Form.Group className='my-5'>
                        <IconButton icon={ICONS.GOOGLE} onClick={handleGoogleSignIn}>
                            {language === 'pt-BR' ? 'Entrar com o Google' : 'Google Sign in'}
                        </IconButton>
                    </Form.Group>
                    <Form.Text>
                        <h5>
                            {
                                language === 'pt-BR' ? 
                                'Entrar com email e senha:' : 
                                'Sign in with email and password:'
                            }
                        </h5>
                    </Form.Text>
                    <Form.Group controlId='email'>
                        <Form.Label>Email</Form.Label>
                        <Form.Control 
                            type='email' 
                            placeholder={language === 'pt-BR' ? 'Informe seu email' : 'Inform your email'}
                            value={email}
                            onChange={e => setEmail(e.target.value)}/>
                    </Form.Group>
                    <Form.Group controlId='password'>
                        <Form.Label>{language === 'pt-BR' ? 'Senha' : 'Password'}</Form.Label>
                        <Form.Control 
                            type='password' 
                            placeholder={language === 'pt-BR' ? 'Informe sua senha' : 'Inform your password'}
                            value={password}
                            onChange={e => setPassword(e.target.value)}/>
                    </Form.Group>
                    <IconButton icon={ICONS.SIGN_IN} type='submit' disabled={isInvalid} className='mr-3'>
                        {language === 'pt-BR' ? 'Entrar' : 'Sign in'}
                    </IconButton>
                    <p className='mt-3'>
                        <Link to={ROUTES.PASSWORD_FORGET}>
                            {language === 'pt-BR' ? 'Esqueceu a senha?' : 'Password recovery'}
                        </Link>
                    </p>
                    <Message error={error}></Message>
                </Form>
                </Col>
                <Col className='bg-light border p-4'>
                    <h2 className='mb-3'>
                        {language === 'pt-BR' ? 'Boas vindas!' : 'Welcome!'}
                    </h2>
                    <p>
                        {
                            language === 'pt-BR' ? 
                            'Para participar dessa pesquisa você precisa fazer login.' : 
                            'To participate in this study, you need to sign in.'
                        }
                    </p>
                    <p className='text-justify'>
                        {
                            language === 'pt-BR' ? 
                            <>
                                Para fazer login no Lapsi, basta clicar em 'Entrar com o Google'
                                para acessar com sua conta Google ou entrar com 
                                email e senha informados na criação da sua conta. Caso
                                ainda não tenha criado uma conta, você pode clicar
                                no botão 'Entrar com o Google' para acessar com sua
                                conta Google (por exemplo, Gmail) ou 
                                <Link to={`/experimento/${match?.params.id}/registrar`}> clicar aqui para criar uma nova conta</Link>.
                            </> : 
                            <>
                                To sign in to Lapsi, use the 'Google Sign in' button or
                                enter the email and password provided during your account 
                                creation. If you haven't created an account yet, you can click
                                on the 'Google Sign in' button to access with your Google account
                                (e.g., Gmail) or 
                                <Link to={`/experimento/${match?.params.id}/registrar`}> click here to create a new account</Link>.
                            </>
                        }
                    </p>
                </Col>
            </Row>
        </Container>
    )
}

export default ParticipantSignIn