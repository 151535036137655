import React from 'react'
import shuffle from 'shuffle-array'

import ConfigType from '../../../types/ConfigType'
import { sentences_training, sentences } from './sentences'

import { FIELDS, TRIALS } from '../../../constants/instruments'


const config: ConfigType[] = []

// Instructions - Training
config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <h1 className='text-center mb-3'>Instruções Sessão de Prática</h1>
            <p>Neste teste, você lerá sentenças em inglês enquanto também tenta memorizar a última palavra de cada sentença.</p>
            <p>Nos próximos minutos, você fará uma sessão de prática para se familiarizar com o funcionamento do teste.</p> 
            <p>Você lerá conjuntos de duas, três e quatro sentenças apresentadas aleatoriamente.</p>
            <p>Clique com o botão do mouse para começar.</p>
        </>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>As sentenças aparecerão na tela uma de cada vez, assim:</p>
            <p><em>“I like to run in the park”</em>.</p>
            <p>Assim que você vir a sentença, você deve lê-la e determinar se ela faz sentido ou não. A sentença acima faz sentido.</p>
            <p>Um exemplo de frase que não faz sentido seria:</p>
            <p><em>“I like to run in the sky”</em>.</p>
            <p>Você também precisa memorizar a última palavra da sentença que você viu.</p>
            <p>Após ler a sentença para determinar se ela faz sentido ou não, e memorizar sua última palavra, clique no botão do mouse.</p>
            <p>Reserve um momento para certificar-se de que entendeu as instruções.</p>
            <p>Clique com o botão do mouse para continuar.</p>
        </>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>
                Logo após o clique, a seguinte mensagem aparecerá: “A sentença lida faz sentido” exibida na próxima tela, com caixas com os dizeres VERDADEIRO e FALSO.
            </p>
            <p>Se a sentença na tela anterior fizer sentido, clique na caixa VERDADEIRO. Se a sentença não fizer sentido, clique na caixa FALSO.</p>
            <p>Após clicar em uma das caixas, o computador informará se você fez a escolha certa.</p>
            <p>É MUITO importante ler as sentenças e julgá-las da forma mais RÁPIDA e CORRETA possível.</p>
            <p>Reserve um momento para certificar-se de que entendeu as instruções.</p>
            <p>Clique com o botão do mouse para continuar.</p>
        </>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>Outra sentença aparecerá para você ler e decidir se faz sentido ou não, e memorizar sua última palavra.</p>
            <p>
                Quando você tiver julgado todas as sentenças em um conjunto, uma tela de recordação aparecerá para você escrever a última palavra de cada sentença na ordem em que foram apresentadas.
            </p>
            <p>Se você esquecer uma das palavras, deixe o espaço correspondente em branco.</p>
            <p>Lembre-se, é MUITO importante escrever as palavras na mesma ordem em que você as viu.</p>
            <p>Reserve um momento para certificar-se de que entendeu as instruções.</p>
            <p>Clique com o botão do mouse para continuar.</p>
        </>
    )
})

config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>É MUITO importante que você analise as orações CORRETAMENTE.</p>
            <p>
                Também é importante que você tente ler as orações o mais RAPIDAMENTE possível ENQUANTO faz o possível para lembrar o maior número de últimas palavras na ordem em que foram apresentadas.
            </p>
            <p>
                <strong>IMPORTANTE:</strong> você não pode anotar as últimas palavras durante a apresentação das sentenças. Você deve confiar em sua memória para lembrá-las.
            </p>
            <p>Reserve um momento para certificar-se de que entendeu as instruções.</p>
            <p>Quando estiver pronto, clique com o botão do mouse para começar a prática.</p>
        </>
    )
})

// Training
shuffle(sentences_training)

// Training
for (let set of sentences_training) {
    for (let sentence of set) {
        // Sentence
        config.push({
            type: TRIALS.STIMULUS,
            stimulus: sentence.sentence,
            response: {
                type: 'click',
            },
            training: true
        })

        // Semantic acceptability judgement
        config.push({
            type: TRIALS.STIMULUS,
            stimulus: 'Esta sentença faz sentido.',
            response: {
                type: 'button',
                buttons: [`${sentence.sensical ? 'VERDADEIRO' : 'FALSO'}`, `${!sentence.sensical ? 'VERDADEIRO' : 'FALSO'}`],
                centralizeButtons: true,
                showCorrect: true, //Only in trainning
                order: 'desc'
            },
            training: true
        })

        // Blank
        config.push({
            type: TRIALS.BLANK,
            training: true
        })
    }

    // Recall
    config.push({
        type: TRIALS.QUESTION,
        title: <span>Escreva a última palavra<br/> de cada sentença na ordem<br/> em que elas apareceram.</span>,
        questions: set.map(() => ({prompt: '', type: FIELDS.INPUT})),
        correctResponses: set.map((s) => s.word),
        training: true
    })
}

// Instructions
config.push({
    type: TRIALS.TEXT,
    prompt: (
        <>
            <p>Esse é o fim da sessão de prática. O teste real será parecido com a prática que você acabou de completar.</p>
            <p>Você lerá conjuntos de duas a cinco sentenças apresentadas aleatoriamente.</p>
            <p>É importante que você faça o seu melhor tanto para decidir se as sentenças fazem sentido quanto para memorizar a última palavra de cada sentença.</p>
            <p>Você não será informado se julgou as sentenças corretamente.</p>
            <p>Reserve um momento para certificar-se de que entendeu as instruções.</p>
            <p>Clique com o botão do mouse para iniciar a tarefa.</p>
        </>
    )
})

// Test
shuffle(sentences)

// Test
for (let set of sentences) {
    for (let sentence of set) {
        // Sentence
        config.push({
            type: TRIALS.STIMULUS,
            stimulus: sentence.sentence,
            response: {
                type: 'click',
            },
        })

        // Semantic acceptability judgement
        config.push({
            type: TRIALS.STIMULUS,
            stimulus: 'Esta sentença faz sentido.',
            response: {
                type: 'button',
                buttons: [`${sentence.sensical ? 'VERDADEIRO' : 'FALSO'}`, `${!sentence.sensical ? 'VERDADEIRO' : 'FALSO'}`],
                centralizeButtons: true,
                order: 'desc'
            },
        })

        // Blank
        config.push({
            type: TRIALS.BLANK,
        })
    }

    // Recall
    config.push({
        type: TRIALS.QUESTION,
        title: <span>Escreva a última palavra<br/> de cada sentença na ordem<br/> em que elas apareceram.</span>,
        questions: set.map(() => ({prompt: '', type: FIELDS.INPUT})),
        correctResponses: set.map((s) => s.word)
    })
}


export default config