import React from 'react'

class Timer {
    timeoutId = 0
    initialTimestamp = 0
    lastTimestamp = 0
    count = 0

    setTimeout(time: number, callback: () => void) {
        if (this.timeoutId === 0) {
            this.timeoutId = window.setTimeout(() => { 
                callback()
                this.clearTimeout()
            }, time)
        }
    }

    clearTimeout() {
        window.clearTimeout(this.timeoutId)
        this.timeoutId = 0
    }

    setInitialTimestamp() {
        this.initialTimestamp = new Date().getTime()
    }

    getInterval() {
        if (this.initialTimestamp === 0) {
            console.error('Initial Timestamp not set. Call Timer.setInitialTimestamp()')
            return 0
        } else {
            const now = new Date().getTime()
            const interval = now - (this.lastTimestamp === 0 ? this.initialTimestamp : this.lastTimestamp)
            this.lastTimestamp = now

            return interval
        }
    }

    getElapsedTime() {
        if (this.initialTimestamp === 0) {
            console.error('Initial Timestamp not set. Call Timer.setInitialTimestamp()')
            return 0
        } else {
            const now = new Date().getTime()
            const elapsed = now - this.initialTimestamp

            return elapsed
        }
    }

    getCurrentTimestamp() {
        return new Date().getTime()
    }

    getCount() {
        return this.count++
    }
}

const TimerContext = React.createContext(new Timer())

export default Timer
export { TimerContext }